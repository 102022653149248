import { Message } from '@bamboohr/utils/lib/message';
import {
	Button,
	Flex,
	StandardModal
} from "@bamboohr/fabric";
import Ajax from '@utils/ajax';

export function ConfirmDelete(props) {
	const {
		id,
		onClose,
		visible,
		onDelete,
		payeeName,
	} = props;

	const actions = [
        <Button
            color="secondary"
            key="close-button"
            onClick={ onClose }
            type="button"
            variant="outlined"
        >
            { $.__(`Don't Delete It`) }
        </Button>,
        <Button
            key="button"
            onClick={ () => deletePayee(id, onDelete) }
            type="button"
        >
            { $.__('Yes, Delete It') }
        </Button>,
    ];

	return (
        <StandardModal
			isOpen={ visible }
			onCloseComplete={ onClose }
			onRequestClose={ onClose }
		>
            <StandardModal.Body
				renderFooter={(
					<StandardModal.Footer
						actions={actions}
					/>
				)}
				renderHeader={(
					<StandardModal.Header
						hasCloseButton={ true }
						title={ $.__('Just Checking...') }
					/>
				)}
			>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline
						icon="trash-can-regular"
						iconColor="error-medium"
						subtext={
							<Flex justifyContent="center">
								{$.__('It will no longer show up in your list of payees.') }
							</Flex>
						}
						text={
							<Message
								params={ [payeeName] }
								text={ $._('Are you sure you want to delete the payee **{1}**?') }
							/>
						}
					/>
				</StandardModal.Constraint>
			</StandardModal.Body>
        </StandardModal>
    );
}

function deletePayee(id, successCallback) {
	Ajax.delete(`/payroll/non_benefit_deductions/payee/${ id }`)
		.then((response) => {
			if (response.status === 200) {
				successCallback(id);
			} else {
				window.setMessage(`Uh oh...something went wrong, and the payee wasn't saved. Try it again?`, 'error');
			}
		})
		.catch(() => {
			window.setMessage(`Uh oh...something went wrong, and the payee wasn't saved. Try it again?`, 'error');
		});
}
