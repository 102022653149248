import Ctrl, {
	onClick,
	onPjaxEnd,
	onReady,
} from 'ctrl.deco';
import Ajax from '@utils/ajax';

@Ctrl('/settings/holidays/view')
class SettingsHolidayViewPage {

	@onReady()
	@onPjaxEnd()
	_initTableSort() {
		$('.js-HolidaySettings__table')
			.tablesorter({
				sortList: [[1, 0]],
				onRenderHeader() {
					const $this = $(this);
					if (!$this.hasClass('noSort')) {
						$this.addClass('fab-Table__header--sortable')
					}
				},
				headers: {
					0: {
						parser: false,
					},
					1: {
						parser: 'isoDate',
					},
					2: {
						sorter: false,
						parser: false,
					},
					3: {
						sorter: false,
						parser: false,
					},
				},
				textExtraction: window.TSextractDataAttr,
			})
			.on('sortEnd', function() {
				$('.fab-Table__header--sortable').each(function(){
					const isDescending = $(this).hasClass('sort-descending');
					const isAscending = $(this).hasClass('sort-ascending');

					$(this).html($(this).text());

					if( isAscending ){
						$(this).wrapInner('<span class="fab-Table__sorted fab-Table__sorted--asc"></span>')
					}
					if( isDescending ){
						$(this).wrapInner('<span class="fab-Table__sorted fab-Table__sorted--desc"></span>')
					}
				});
			});
	}

	@onClick('.js-copy-holidays')
	_copyPreviousYearHolidaysEvent(event) {

		const { target: {
			dataset,
			classList
		} } = event;
		const { year } = dataset;

		classList.add('fab-Button--processing');

		Ajax.post('/settings/holidays/copyPrevious', {
			year
		}).then((response) => {
			switch (response.status) {
				case 200:
				case 400:
					window.location.reload();
					break;
				case 403:
					window.location.href = '../';
					break;
			}
		});
	}

	@onClick('.js-add-standard-holidays')
	_addStandardHolidaysEvent(event) {
		const { year } = event.target.dataset;

		const content = `
			<form id="standardHolidayModal" action="/settings/holidays/copyStandard" method="post" class="BhrForms">
				<input type="hidden" name="year" value="${ year }">
				<fieldset>
					<legend class="fab-CheckboxGroup__legend">
						${ $.__('Which countries would you like to copy standard holidays from?') }
					</legend>

					<div class="fab-CheckboxGroup">
						<div class="fab-Checkbox">
							<input class="fab-Checkbox__input" type="checkbox" name="countries[]" value="1" id="cbus">
							<label class="fab-Checkbox__label" for="cbus">
								<span></span>
								${ $.__('United States') }
							</label>
						</div>
						<div class="fab-Checkbox">
							<input class="fab-Checkbox__input" type="checkbox" name="countries[]" value="2" id="cbcan">
							<label class="fab-Checkbox__label" for="cbcan">
								<span></span>
								${ $.__('Canada') }
							</label>
						</div>
					</div>
				</fieldset>
			</form>
		`;

		window.BambooHR.Modal.setState({
			isOpen: true,
			dangerousContent: content,
			title: $.__('Add Standard Holidays'),
			primaryAction() {
				const countryArray = [];

				$('#standardHolidayModal input:checked').each(function mapCheckedCountries() {
					countryArray.push($(this).val());
				});

				const countries = countryArray.join(',');

				if (countries == '') {
					return;
				}
				window.BambooHR.Modal.setState({ isProcessing: true }, true);

				Ajax.post('/settings/holidays/copyStandard', {
					year,
					countries
				}).then((response) => {
					switch (response.status) {
						case 200:
						case 400:
							window.location.reload();
							break;
						case 403:
							window.location.href = '../';
							break;
					}
				}).catch(() => {
					window.BambooHR.Modal.setState({ isProcessing: false }, true);
				});
			},
			primaryActionText: $.__('Continue'),
			biId: 'settings-holidays-add-save'
		});

	}

	@onClick('.js-edit-holiday')
	_editModalEvent({ target }) {
		const editButton = target.closest('.js-edit-holiday');
		const { editId } = editButton.dataset;

		window.location.href = `/settings/holidays/add/${editId}`;
	}

	@onClick('.js-delete-holiday')
	_deleteModalEvent({ target }) {
		const deleteButton = target.closest('.js-delete-holiday');
		const { deleteId, deleteName } = deleteButton.dataset;

		window.BambooHR.Modal.setState({
			isOpen: true,
			title: $.__('Delete Holiday'),
			primaryAction() {
				window.BambooHR.Modal.setState({ isProcessing: true }, true);
				Ajax.delete(`/settings/holidays/${ deleteId }/delete`).then((response) => {
					window.BambooHR.Modal.setState({ isOpen: false });
					switch (response.status) {
						case 400:
						case 200:
							window.location.reload();
							break;
						case 403:
							window.location.href = '../';
							break;
					}
				});
			},
			primaryActionText: $.__('Yes'),
			headline: $.__('Are you sure you want to delete %s?', deleteName),
			icon: 'trash-regular',
			iconColor: 'danger',
			iconV2Color: 'error-strong'
		});
	}

	@onPjaxEnd()
	_dateChangeHandler() {
		const highlightedRows = document.querySelectorAll('.fab-Table__row--highlighted');

		if (highlightedRows.length > 0) {
			window.setMessage($.__('There is a holiday that falls on a weekend this year. You may wish to change the observance date.'), 'info');
			return;
		}
		window.closeMessage();
	}
}
