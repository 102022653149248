import { useEffect, useState } from 'react';
import { Divider, Flex, Headline, LayoutBox, Link } from '@bamboohr/fabric';
import {
	CancellationDropdown,
	CancellationModal,
	CancellationType,
	Services,
	SadToGoBox,
} from 'in-app-cancellation.mod';
import {
	CancelAccountModal,
	RADIO_ITEMS,
} from '../../../cancel-account-modal.react/cancel-account-modal';

export function CompanyInfoHeader(props) {
	const [cancellationType, setCancellationType] = useState(
		CancellationType.PAYROLL,
	);
	const [cancellationData, setCancellationData] = useState(null);
	const [cancellationStatus, setCancellationStatus] = useState([]);
	const [cancellationPending, setCancellationPending] = useState(false);
	const [cancellationModalOpen, setCancellationModalOpen] = useState(false);
	const [followUpModalOpen, setFollowUpModalOpen] = useState(false);

	useEffect(() => {
		getCancellationStatus();
	}, []);

	const getCancellationStatus = () => {
		Services.getCancellationFeatureStatus(CancellationType.PAYROLL).then(
			(resp) => {
				setCancellationData(resp.data);
				setCancellationPending(resp.data.pending_cancellation);
			},
		);
		Services.getCancellationStatusAll().then((resp) => {
			// formatting for sadbox
			setCancellationStatus(
				resp.data.filter(
					(item) =>
						item.feature === CancellationType.PAYROLL ||
						item.feature === CancellationType.ACCOUNT,
				),
			);
		});
	};

	const { anotherEinLink, hasStatus } = props;

	const handleOnSelect = () => {
		setCancellationModalOpen(true);
	};

	const cancellationDropdown =
		!!cancellationData && !cancellationPending ? (
			<LayoutBox>
				<CancellationDropdown
					cancellationData={cancellationData}
					handleOnSelect={handleOnSelect}
					title={$.__('Payroll')}
				/>
				<CancellationModal
					isOpen={cancellationModalOpen}
					onClose={() => setCancellationModalOpen(false)}
					onSubmitSuccess={() => {
						getCancellationStatus();
						if (cancellationType === CancellationType.PAYROLL) {
							setFollowUpModalOpen(true);
						}
					}}
					types={[cancellationType]}
				/>
				<CancelAccountModal
					isOpen={followUpModalOpen}
					onClose={() => setFollowUpModalOpen(false)}
					onNext={(val) => {
						// if 'yes' is selected.
						if (val === RADIO_ITEMS[0].value) {
							setCancellationType(CancellationType.ACCOUNT);
							setCancellationModalOpen(true);
						}
						setFollowUpModalOpen(false);
					}}
				/>
			</LayoutBox>
		) : null;

	return (
		<>
			<Flex alignItems="center" justifyContent="space-between">
				<Headline size="small">{$.__('Company Information')}</Headline>
				<Flex gap={2} alignItems="center">
					{anotherEinLink ? (
						<Link
							href="https://bamboohr.formstack.com/forms/payrollqs?type=Add-EIN"
							target="_blank"
							color="muted"
							size="small"
						>
							{$.__('Need to add another company/EIN?')}
						</Link>
					) : null}
					{cancellationDropdown}
				</Flex>
			</Flex>
			{cancellationPending ? (
				<LayoutBox marginTop={1}>
					<SadToGoBox
						cancellationData={cancellationStatus}
						updateCancellationData={getCancellationStatus}
						isAddon={true}
					/>
				</LayoutBox>
			) : null}
			{!hasStatus ? (
				<LayoutBox marginTop={3} marginBottom={1}>
					<Divider color="neutral-weak" />
				</LayoutBox>
			) : null}
		</>
	);
}
