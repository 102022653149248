import { BodyText, Flex, TextButton } from '@bamboohr/fabric';

interface InactiveProps {
	isRestorable: boolean;
	planId: number;
}

export function Inactive(props: InactiveProps): JSX.Element {
	const { isRestorable, planId } = props;

	return (
		<Flex alignItems="center" gap={1}>
			<BodyText color="neutral-weak">{$.__('Inactive plan')}</BodyText>
			{Boolean(isRestorable) && (
				<TextButton
					data-bi-id="reactivate-plan"
					inline={true}
					onClick={() => {
						location.href = `/settings/benefits/plan_wizard/plan/${planId}`;
					}}
					type="button"
				>
					{$.__('Reactivate')}
				</TextButton>
			)}
		</Flex>
	);
}
