import {
	Flex,
	LayoutBox,
	Section,
	TextField
} from '@bamboohr/fabric';
import { isEnabled } from 'FeatureToggle.util';
import moment from 'moment.lib';
import { FirstDayInformationContainer } from 'NHPTemplates.mod/Form/containers/first-day';
import { TeamIntroductionContainer } from 'NHPTemplates.mod/Form/containers/team-introduction';
import {
	EmailFilterData,
	Question,
	Recipients,
	SelectOptions,
} from 'NHPTemplates.mod/Form/Interfaces';
import {
	dateOptions,
	registerTemplateFormData,
	updateFormState,
} from 'NHPTemplates.mod/Form/utils';
import { NHPTPreviewerContainer } from 'NHPTemplates.mod/Previewer/Containers/NHPT_Previewer';
import { pandaWallace } from 'NHPTemplates.mod/Previewer/pandas';
import { createRef, PureComponent, ReactElement } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { TasksToCompleteBlankState } from './components/tasks-to-complete-blank-state';
import './styles.styl';

interface State {
	errorState: boolean;
	name: string;
}

interface TemplateData {
	arriveByTime: string;
	contactEmployeeId: number | string;
	contactOptions: Array<SelectOptions>;
	createdUserId: number | string;
	default: boolean;
	getToKnowYouEmailTemplateId: number | string;
	gtkyQuestions: Array<Question>;
	gtkyRecipients: Array<Recipients>;
	id: number;
	includeBasicInfo: boolean;
	location: string;
	managerUserId?: number | string;
	name: string;
	otherInstructions: string;
	sendGetToKnowYouEmail: boolean;
}

interface Props {
	errorState: boolean;
	emailFilterData: EmailFilterData;
	emailPreviewer: (gtkyQuestions: Array<string>) => void;
	templateData: TemplateData;
	logo: Logo;
	saveForm: () => void;
}

export class NewHirePacketTemplate extends PureComponent<Props, State> {
	private inputRef = createRef<HTMLInputElement>();

	static defaultProps = {
		emailFilterData: {
			selectData: [],
			selectedName: '',
			selectedParentId: null,
			selectedValues: [],
		},
		templateData: {
			arriveByTime: '',
			contactOptions: [],
			contactUser: {},
			location: '',
			newHirePacket: {},
			otherInstructions: '',
			gtkyQuestions: {},
			gtkyRecipients: [],
			sendGetToKnowYouEmail: false,
			templateId: null,
		},
	};

	constructor(props: Props) {
		super(props);

		const { templateData } = this.props;
		const {
			arriveByTime = '08:00:00',
			contactEmployeeId,
			location,
			name,
			otherInstructions,
			gtkyQuestions,
			gtkyRecipients,
			sendGetToKnowYouEmail,
			id,
		} = templateData;

		registerTemplateFormData({
			arriveByTime: [arriveByTime],
			selectedContactOptions: contactEmployeeId,
			id,
			location,
			name,
			otherInstructions,
			gtkyQuestions,
			gtkyRecipients,
			sendGetToKnowYouEmail,
		});

		this.state = {
			errorState: false,
			name,
		};

		this._addErrorState = this._addErrorState.bind(this);
		this._handleUpdateName = this._handleUpdateName.bind(this);
	}

	_addErrorState() {
		this.setState(
			{
				errorState: true,
			},
			this.focus,
		);
	}

	_handleUpdateName(event): void {
		const { value = '' } = event.target as HTMLInputElement;

		const { errorState } = this.state;

		const newErrorState = errorState ? value === '' : false;

		this.setState(
			{
				name: value,
				errorState: newErrorState,
			},
			() => {
				updateFormState({ name: this.state.name });
			},
		);
	}

	focus(): void {
		const node = this.inputRef.current;

		if (node) {
			node.focus();
		}
	}

	componentDidMount(): void {
		this.focus();
		document.addEventListener(
			'nhpTemplateMissingData:name:error',
			this._addErrorState,
		);
	}

	componentWillUnmount(): void {
		document.removeEventListener(
			'nhpTemplateMissingData:name:error',
			this._addErrorState,
		);
	}

	render(): ReactElement {
		const { emailFilterData, templateData, emailPreviewer, logo, saveForm } =
			this.props;
		const {
			arriveByTime = '08:00:00',
			contactOptions,
			contactEmployeeId = '',
			location,
			sendGetToKnowYouEmail,
			otherInstructions,
			gtkyQuestions,
			id: templateId,
		} = templateData;

		const { name } = this.state;

		// @ts-ignore
		const companyName: string = window.SESSION_COMPANY.name;

		const previewerData = {
			logo,
			headerName: templateData.name,
			firstDayDate: moment(Date.now())
				.add(14, 'days')
				.format(moment.defaultFormat),
			companyName,
			contact: pandaWallace,
			employeeName: 'Panda',
			arriveByTime: templateData.arriveByTime,
			arrivalLocation: templateData.location,
			otherInstructions: templateData.otherInstructions,
			withFinishedFooter: false,
			sendGetToKnowYouEmail: templateData.sendGetToKnowYouEmail,
			headerAction: saveForm,
		};

		return (
			<BrowserRouter basename={window.location.pathname}>
				<Switch>
					<Route path='/'>
						<LayoutBox>
							{isEnabled('NHP_Previewer') && <NHPTPreviewerContainer {...previewerData} />}
							<form className='NHPTemplateForm'>
								<Flex flexDirection='column' gap={5}>
									{templateId > 0 && <input name='templateId' type='hidden' value={templateId} />}
									<Section paddingTop='24px'>
										<TextField
											id='name'
											label={$.__('Template Name')}
											onBlur={this._handleUpdateName}
											onChange={this._handleUpdateName}
											ref={this.inputRef}
											required={true}
											value={name}
											width={7}
										/>
									</Section>
									<FirstDayInformationContainer
										arrivalTimes={dateOptions()}
										contactOptions={contactOptions}
										location={location}
										name='the new employee'
										otherInstructions={otherInstructions}
										selectedArrivalTime={[arriveByTime]}
										selectedContactOptions={[`${contactEmployeeId}`]}
										templateView={true}
									/>
									<TeamIntroductionContainer
										customQuestions={gtkyQuestions}
										emailFilterData={emailFilterData}
										employeeName={$.__('the New Employee')}
										previewNewHireEmail={emailPreviewer}
										sendGetToKnowYouEmail={sendGetToKnowYouEmail ? 'yes' : 'no'}
									/>
									<TasksToCompleteBlankState />
								</Flex>
							</form>
						</LayoutBox>
					</Route>
					<Redirect to="/" />
				</Switch>
			</BrowserRouter>
		);
	}
}
