import { ReactElement } from 'react';
import { ActiveMissingInfoProvider } from './context/active-missing-info-provider';
import { ActiveMissingInfoContent } from './components/active-missing-info-content';

import { ActiveMissingInfoProps } from './types';
import { LayoutBox, PageHeader, Section } from '@bamboohr/fabric';

export function ActiveMissingInfo(props: ActiveMissingInfoProps): ReactElement {
	const { isMultiEin, companies, isNewOnboardingEnabled } = props;

	return (
		<ActiveMissingInfoProvider
			companies={companies}
			isMultiEin={isMultiEin}
			isNewOnboardingEnabled={isNewOnboardingEnabled}
		>
			<>
				<PageHeader
					divider={false}
					title={$.__('Clean Up Missing Employee Info')}
				/>

				<LayoutBox marginY={3}>
					<Section height="100%">
						<ActiveMissingInfoContent />
					</Section>
				</LayoutBox>
			</>
		</ActiveMissingInfoProvider>
	);
}
