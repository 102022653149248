import { Fragment, useEffect, useState } from 'react';
import { BlankState, IconV2, Loader, Table } from '@bamboohr/fabric';
import { useValidation, ValidationError } from '@utils/validation';

import { redirect } from 'BambooHR.util';
import { PoMicroFrontend } from 'micro-frontend.react';

import { Eligible, EndDate, PlanName, Status } from './columns';
import { ValidationContext } from './context';
import { useBenefitsSettingsStore } from './data/benefits-settings-store';
import { DeleteModal } from './delete-modal';
import { DuplicateModal } from './duplicate-modal';
import { benefitPlansContractShim } from './etc/benefit-plans-contract-shim';
import { getFilteredPlans } from './etc/get-filtered-plans';
import { isBenefitPlanActive } from './etc/is-benefit-plan-active';
import { BenefitPlansHeader } from './plans-header';
import {
	DEFAULT_REVISION_MODAL_DATA,
	getRevisionAvailableActivePlans,
	RevisionModalData,
} from './revision-modal';
import {
	BenefitPlanListContract,
	PlanFilter,
	PlanYear,
	ValidationContextState,
} from './types';
import {
	getDeductionMigration,
	getPlansData,
	getPlanYearsData,
	getShowBenefitsBanner,
	postDismissBenefitsBanner,
} from './utils/api-service';
import { getPlanActivityState } from './utils/benefit-plan-service';
import { getActionContent } from './utils/get-action-content';
import { getBenefitTypeTableGroups } from './utils/get-benefit-type-table-groups';
import { getCoverageLevelRelationships } from '../api/get-coverage-level-relationships';
import {
	BenefitsAdminUpsellBanner,
	OnDemoRequest,
} from '../benefits-admin-upsell';
import { DeductionMigrationModal } from '../shared/deduction-migration-modal.react';
import { DeductionMigrationContract } from '../shared/deduction-migration-modal.react/types';
import { PlanTypeEnumContract, SpecialErrors } from '../types';
import { CoverageTypes } from '../types/coverage-type';
import { hasPlanStarted } from '../utils';
import './benefit-plans.styl';

interface BenefitPlansProps {
	isCompanyInTrial: boolean;
	onDemoRequest: OnDemoRequest;
	showBenefitsAdminDemoRequestContent: boolean;
}

export function BenefitPlans(props: BenefitPlansProps): JSX.Element {
	const {
		isCompanyInTrial,
		onDemoRequest,
		showBenefitsAdminDemoRequestContent,
	} = props;
	const [isTraxClient, setIsTraxClient] = useState<boolean>(false);
	const [benefitPlans, setBenefitPlans] = useState<BenefitPlanListContract[]>(
		[],
	);
	const [isDeductionMigrationModalOpen, setIsDeductionMigrationModalOpen] =
		useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [isDeleteModalProcessing, setIsDeleteModalProcessing] =
		useState<boolean>(false);
	const [isDuplicateModalOpen, setIsDuplicateModalOpen] =
		useState<boolean>(false);
	const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
	const [deductionMigrationData, setDeductionMigrationData] =
		useState<DeductionMigrationContract>(null);
	const [deleteModalState, setDeleteModalState] = useState(null);
	const [duplicateModalState, setDuplicateModalState] = useState(null);
	const [planFilter, setPlanFilter] = useState<PlanFilter>(PlanFilter.Active);
	const [specialErrors, setSpecialErrors] = useState({});
	const [canShowUpsellBanner, setCanShowUpsellBanner] =
		useState<boolean>(false);
	const [supportedCoverageLevels, setSupportedCoverageLevels] =
		useState<CoverageTypes>({});

	const isRevisionModalEnabled = isTraxClient;
	const [revisionModalData, setRevisionModalData] = useState<RevisionModalData>(
		DEFAULT_REVISION_MODAL_DATA,
	);
	const [planYears, setPlanYears] = useState<PlanYear[]>([]);
	const [arePlanYearsLoading, setArePlanYearsLoading] =
		useState<boolean>(false);
	const [{ isBenefitsAdminEnabled }] = useBenefitsSettingsStore();

	const {
		benefitType,
		isModalOpen: isRevisionModalOpen,
		planId: basePlanId,
		plans,
	} = revisionModalData;

	const validation = useValidation() as ValidationContextState;
	validation.specialErrors = specialErrors;
	validation.setSpecialErrors = (
		fieldName: keyof SpecialErrors,
		newValue: ValidationError | null,
	): void => {
		setSpecialErrors((state) => {
			const newState = { ...state };
			if (newValue === null) {
				delete newState[fieldName];
			} else {
				newState[fieldName] = newValue;
			}
			return newState;
		});
	};
	validation.resetSpecialErrors = () => setSpecialErrors({});

	const benefitPlansMissingDetails: number[] = benefitPlans.reduce(
		(acc: number[], plan: BenefitPlanListContract) => {
			if (
				plan.missingPlanDetails &&
				!plan.isDraft &&
				(plan.active || !hasPlanStarted(plan.startDate))
			) {
				acc.push(plan.id);
			}
			return acc;
		},
		[],
	);

	const getBenefitPlans = () => {
		setIsPageLoading(true);
		getPlansData().then(
			(response) => {
				if (response) {
					const { benefitPlans, migrationsNeeded, hasTrax } =
						benefitPlansContractShim(response);
					if (benefitPlans) {
						setBenefitPlans(benefitPlans);
					}
					if (migrationsNeeded && migrationsNeeded.deductionDateRule) {
						setIsDeductionMigrationModalOpen(true);
					}
					if (hasTrax) {
						setIsTraxClient(true);
					}
				}
				setIsPageLoading(false);
			},
			(error) => {
				console.error(error);
				setIsPageLoading(false);
			},
		);
	};

	const getPlanYears = () => {
		setArePlanYearsLoading(true);
		getPlanYearsData().then(
			(response) => {
				if (response && response.data) {
					setPlanYears(response.data.planYears);
					setArePlanYearsLoading(false)
				}
			},
			(error) => {
				setArePlanYearsLoading(false);
				console.error(error);
			},
		);
	};

	useEffect(() => {
		getShowBenefitsBanner().then(
			(response) => {
				if (response.data) {
					const { showBanner } = response.data;
					setCanShowUpsellBanner(showBanner);
				}
			},
			(error) => {
				console.error(error);
			},
		);
	}, []);

	useEffect(() => {
		getCoverageLevelRelationships().then(
			(response) => {
				if (response && response.data) {
					setSupportedCoverageLevels(response.data.coverages);
				}
			},
			(err) => {
				console.error(err);
			},
		);
	}, []);

	useEffect(() => {
		setIsPageLoading(true);
		getBenefitPlans();
		getDeductionMigration().then(
			(response) => {
				if (response && response.data) {
					setDeductionMigrationData(response.data);
				}
			},
			(error) => {
				console.error(error);
			},
		);
	}, []);

	useEffect(() => {
		if (isBenefitsAdminEnabled) {
			getPlanYears();
		}
	}, [isBenefitsAdminEnabled]);

	const generateBiIdForRow = (row: BenefitPlanListContract): string => {
		const planActivityState = getPlanActivityState(row);
		return `edit-${planActivityState}-plan`;
	};

	const getDeleteIconButton = (row: BenefitPlanListContract) => {
		const defaultDeleteActions = () => {
			setDeleteModalState(row);
			setIsDeleteModalOpen(true);
		};

		const { deleteActions, tooltipContent } = getActionContent(
			row.hasHadEnrollments && isTraxClient,
			defaultDeleteActions,
		);

		return {
			action: deleteActions,
			icon: 'trash-can-regular',
			tooltipContent,
			biId: 'delete-plan-icon-button',
		};
	};

	const dismissBanner = () => {
		postDismissBenefitsBanner();
		setCanShowUpsellBanner(false);
	};

	const handleOnDemoRequest = (data) => {
		onDemoRequest(data);
		setCanShowUpsellBanner(false);
	};

	const tableGroups = getBenefitTypeTableGroups();

	const columns = [
		{
			cell: (row: BenefitPlanListContract) => (
				<PlanName
					biId={generateBiIdForRow(row)}
					planId={row.id}
					planName={row.planName}
					scheduledChanges={row.scheduledChanges}
				/>
			),
			header: $.__('Plan Name'),
			key: 'planName',
			width: '27%',
		},
		{
			cell: (row: BenefitPlanListContract) => (
				<EndDate active={row.active} endDate={row.endDate} />
			),
			header: $.__('End Date'),
			key: 'endDate',
			width: '11%',
		},
		{
			cell: (row: BenefitPlanListContract) => (
				<Eligible
					active={row.active}
					eligibleList={row.eligible}
					endDate={row.endDate}
				/>
			),
			header: $.__('Eligibility'),
			key: 'eligible',
			width: '29%',
		},
		{
			cell: (row: BenefitPlanListContract) => (
				<Status
					coverages={row.coverages}
					endDate={row.endDate}
					getBenefitPlans={() => {
						setBenefitPlans([]);
						getBenefitPlans();
					}}
					isActive={row.active}
					isDraft={row.isDraft}
					isFutureDated={!hasPlanStarted(row.startDate)}
					isRestorable={row.isRestorable}
					missingPlanDetails={
						benefitPlansMissingDetails.includes(row.id) &&
						row.missingPlanDetails
					}
					missingPlanYear={
						row.activeFuturePlanYearIds &&
						row.activeFuturePlanYearIds.length === 0 &&
						(row.active || !hasPlanStarted(row.startDate)) &&
						!row.isDraft
					}
					planCarrier={row.carrier}
					planCategoryDataOptions={row.planCategoryDataOptions}
					planId={row.id}
					planName={row.planName}
					planType={row.type}
					planYears={planYears}
					startDate={row.startDate}
					status={row.status}
					supportedCoverageLevels={supportedCoverageLevels}
				/>
			),
			header: $.__('Status'),
			key: 'status',
			width: '33%',
		},
		{
			cell: {
				type: 'actions',
				actions: (row: BenefitPlanListContract) => [
					{
						action: () => {
							setIsPageLoading(true);
							const revisionAvailablePlans = isRevisionModalEnabled
								? getRevisionAvailableActivePlans(row.type, benefitPlans)
								: [];
							if (
								isBenefitPlanActive(row) &&
								revisionAvailablePlans.length > 0
							) {
								setRevisionModalData({
									benefitType: row.type,
									isModalOpen: true,
									planId: String(row.id),
									plans: revisionAvailablePlans,
								});
							} else {
								redirect(
									`/settings/benefits/plan_wizard/plan/${row.id}/duplicate`,
								);
							}
							setIsPageLoading(false);
						},
						icon: 'clone-regular',
						tooltipContent: 'Duplicate',
						biId: 'duplicate-plan-icon-button',
					},
					getDeleteIconButton(row),
				],
			},
			headerAriaLabel: $.__('Actions'),
			key: 'actions',
			showOnHover: true,
		},
	];
	return (
		<Fragment>
			<ValidationContext.Provider value={validation}>
				<div className="BenefitPlans">
					{showBenefitsAdminDemoRequestContent && canShowUpsellBanner && (
						<BenefitsAdminUpsellBanner
							dismissBanner={dismissBanner}
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={handleOnDemoRequest}
						/>
					)}

					<BenefitPlansHeader
						benefitPlansMissingDetailsCount={benefitPlansMissingDetails.length}
						benefitPlansMissingPlanYearCount={
							benefitPlans.filter(
								(plan) =>
									plan.activeFuturePlanYearIds &&
									plan.activeFuturePlanYearIds.length === 0 &&
									(plan.active || !hasPlanStarted(plan.startDate)) &&
									!plan.isDraft,
							).length
						}
						onFilterChange={(value: PlanFilter) => setPlanFilter(value)}
						onNewTypeSelect={(selected: PlanTypeEnumContract) => {
							const revisionAvailablePlans = isRevisionModalEnabled
								? getRevisionAvailableActivePlans(selected, benefitPlans)
								: [];
							if (revisionAvailablePlans.length > 0) {
								setRevisionModalData({
									benefitType: selected,
									isModalOpen: true,
									planId: '',
									plans: revisionAvailablePlans,
								});
							} else {
								redirect(`/settings/benefits/plan_wizard/type/${selected}`);
							}
						}}
						planFilter={planFilter}
					/>
					{benefitPlans && benefitPlans.length > 0 ? (
						<div>
							{(isPageLoading || arePlanYearsLoading) && (
								<div className="BenefitPlans__loader">
									<Loader />
								</div>
							)}
							<Table
								caption={$.__('Benefit plans list')}
								columns={columns}
								groupBy={(row: BenefitPlanListContract) => row.type}
								groups={tableGroups}
								rowKey={(row: BenefitPlanListContract) => row.id}
								rows={getFilteredPlans(benefitPlans, planFilter)}
							/>
							<DuplicateModal
								isOpen={isDuplicateModalOpen}
								onClose={() => {
									setIsDuplicateModalOpen(false);
								}}
								onDuplicate={() => {
									getPlansData().then(
										(response) => {
											const { benefitPlans } =
												benefitPlansContractShim(response);
											if (benefitPlans) {
												setBenefitPlans(benefitPlans);
												setIsDuplicateModalOpen(false);
											}
										},
										(error) => {
											console.error(error);
										},
									);
								}}
								rowData={duplicateModalState}
							/>

							<DeleteModal
								isOpen={isDeleteModalOpen}
								onClose={() => {
									setIsDeleteModalOpen(false);
								}}
								onDelete={(planName: string) => {
									getPlansData().then(
										(response) => {
											const { benefitPlans } =
												benefitPlansContractShim(response);
											if (benefitPlans) {
												setBenefitPlans(benefitPlans);
												setIsDeleteModalOpen(false);
												setIsDeleteModalProcessing(false);
												window.setMessage(
													$.__('%1$s was deleted successfully', planName),
													'success',
												);
											}
										},
										(error) => {
											console.error(error);
										},
									);
								}}
								onProcessingChange={(isProcessing: boolean) =>
									setIsDeleteModalProcessing(isProcessing)
								}
								rowData={deleteModalState}
							/>
						</div>
					) : (
						<div className="BenefitPlans__blank">
							{isPageLoading ? (
								<div className="BenefitPlans__loader">
									<Loader />
								</div>
							) : (
									<BlankState
											icon={
												<IconV2 name="heart-circle-plus-regular" size={72} />
											}
											title={$.__('Add a new benefit plan')}
										/>
							)}
						</div>
					)}
				</div>
			</ValidationContext.Provider>
			<DeductionMigrationModal
				individualPlans={
					deductionMigrationData && deductionMigrationData.individualPlans
						? deductionMigrationData.individualPlans
						: []
				}
				isOpen={isDeductionMigrationModalOpen}
				rollupPlans={
					deductionMigrationData && deductionMigrationData.rollupPlans
						? deductionMigrationData.rollupPlans
						: []
				}
			/>
			{isRevisionModalEnabled && (
				<PoMicroFrontend
					benefitType={benefitType}
					isModalOpen={isRevisionModalOpen}
					onClose={() => setRevisionModalData(DEFAULT_REVISION_MODAL_DATA)}
					planId={basePlanId}
					plans={plans}
					route="/settings/benefits/plan-revision-decision-modal"
				/>
			)}
		</Fragment>
	);
}
