import {
	createStyles,
	makeStyles,
} from '@bamboohr/fabric';

export const useStyles = makeStyles(({
	palette,
	spacing,
}) => {
	return createStyles({
		root: {
			alignItems: 'flex-start',
			backgroundColor: "unset",
			borderTopColor: palette.gray[300],
			borderTopStyle: 'solid',
			borderTopWidth: 1,
			display: 'flex',
			paddingBottom: 0,
			paddingLeft: 0,
			paddingRight: spacing(1),
			paddingTop: spacing(2),
			marginTop: spacing(1),
			margin: "0px 20px"
		},
		message: {
			color: palette.gray[800],
		},
	});
});
