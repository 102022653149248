import { Fragment, useEffect, useMemo, useState } from 'react';

import { doGetUserMfaData } from 'multi-factor-authentication.mod';
import { showModal, closeModal } from 'show-modal.mod';

import { App, State } from '../../context/state';
import { Actions } from '../../context';

import InstallModal from './install';
import ConfigModal from './config';
import ConflictModal from './conflict';
import UninstallModal from './uninstall';
import { getMfaSheetProps } from './utils';

export type ModalType = 'install' | 'uninstall' | 'config' | 'conflict';

interface AppModalProps {
	app: App;
	type: ModalType;
	state: State;
	actions: Actions;
}

const mfaConflictList = [];

const AppModalWrapper: React.FunctionComponent<React.PropsWithChildren<AppModalProps>> = ({ app, type, state, actions }) => {
	const [hasMfaEnabled, setHasMfaEnabled] = useState(false);
	const sheetProps = getMfaSheetProps({
		app,
		callback: () => setHasMfaEnabled(false),
		hasMfaEnabled: hasMfaEnabled && mfaConflictList.includes(app.name),
	});

	useEffect(() => {
		doGetUserMfaData()
			.then(() => setHasMfaEnabled(true))
			.catch(() => setHasMfaEnabled(false));
	}, []);

	const conflict = useMemo(
		() =>
			type === 'install' &&
			(app ? state.appList.find((a) => a.installed && Array.isArray(app.conflicts) && app.conflicts.includes(a.name)) : false),
		[type, app, state.appList]
	);

	if (type === 'install') {
		if (conflict) {
			type = 'conflict';
		} else if (app.hasConfig) {
			type = 'config';
		}
	}

	return (
		<Fragment>
			<InstallModal app={app} isOpen={type === 'install'} sheetProps={sheetProps} {...{ state, actions }} />
			<ConfigModal app={app} isOpen={type === 'config'} sheetProps={sheetProps} {...{ state, actions }} />
			<ConflictModal app={app} conflict={conflict} isOpen={type === 'conflict'} {...{ state, actions }} />
			<UninstallModal app={app} isOpen={type === 'uninstall'} {...{ state, actions }} />
		</Fragment>
	);
};

AppModalWrapper.displayName = 'AppModalWrapper';

export default AppModalWrapper;

export const showAppModal = (app: App, type: ModalType, context: [State, Actions]): void => {
	if (type === 'install' && app.newPageInstall) {
		window.location.href = app.installUrl;
		return;
	}

	showModal(<AppModalWrapper {...{ app, type }} actions={context[1]} state={context[0]} />);
};

export const closeAppModal = (): void => closeModal();
