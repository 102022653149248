import {
	BodyText,
	Button,
	Flex,
	InPageMessaging,
	LayoutBox,
} from '@bamboohr/fabric';
import { canUseLocalStorage } from '@utils/localstorage';
import { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';

import { isEnabled } from 'FeatureToggle.util';
import {
	BANK_APP_STATE,
	BANK_FILES_ARRAY,
} from '../../../bank-info.react/utils/shared';
import {
	COMPANY_APP_STATE,
	companyFiles,
	getFilesDownloadList,
	takeToBankAccountSection,
} from '../../utils/shared';
import { FileTile } from '../file-tile';

export const InfoCard = (props) => {
	const {
		company,
		clientId,
		handleOpenVerificationModal,
		handleLinkSuccess,
		plaidLinkToken,
		showPlaidFailedMessage,
		handlePlaidUpdateSuccess,
	} = props;

	const isPlaidAdoptionAndSufficientBalanceEnabled = isEnabled(
		'PLAID_ADOPTION_AND_SUFFICIENT_BALANCE',
	);
	const isPlaidAccountBalanceVerificationEnabled = isEnabled(
		'PLAID_ACCOUNT_BALANCE_VERIFICATION',
	);
	const [showPlaidConnect, setShowPlaidConnect] = useState(null);
	const [isDownloaded, setIsDownloaded] = useState(false);
	// eslint-disable-next-line no-nested-ternary
	const _company = company ? (company.length ? company[0] : company) : null;

	let bank = null;
	if (_company?.bankAccounts) {
		bank = _company.bankAccounts[0];
	}

	// eslint-disable-next-line no-confusing-arrow
	const successFn = (token, metadata) =>
		bank?.accountNumber
			? handlePlaidUpdateSuccess(token, metadata, bank.bankId, bank.clientId)
			: handleLinkSuccess(token, metadata, clientId);

	const { open, ready, error } = usePlaidLink({
		token: plaidLinkToken,
		onSuccess: successFn,
	});

	const plaidIsNotConnected =
		bank?.plaidStatus === null || bank?.plaidStatus === 'Exempt';

	useEffect(() => {
		if (canUseLocalStorage) {
			// eslint-disable-next-line no-lonely-if
			if (!!bank && showPlaidConnect === null) {
				let val;
				if (isPlaidAccountBalanceVerificationEnabled) {
					val = localStorage.getItem(
						`_plaid_messaging_2_day_${bank.accountNickname + bank.bankId}_`,
					);
					localStorage.removeItem(
						`_plaid_messaging_${bank.accountNickname + bank.bankId}_`,
					);
				} else {
					val = localStorage.getItem(
						`_plaid_messaging_${bank.accountNickname + bank.bankId}_`,
					);
				}
				if (!val) {
					setShowPlaidConnect(true);
				} else {
					setShowPlaidConnect(false);
				}
			}
		} else if (plaidIsNotConnected) {
			// if we can't access localStorage, and plaid has not been connected, show banner
			setShowPlaidConnect(true);
		} else {
			setShowPlaidConnect(false);
		}
	}, []);

	const handleHidePlaidConnect = () => {
		document.dispatchEvent(
			new CustomEvent('PlaidBannerActions:Dismissed', { detail: bank?.bankId }),
		);
		if (!canUseLocalStorage) {
			setShowPlaidConnect(false);
		} else {
			setShowPlaidConnect(false);
			if (isPlaidAccountBalanceVerificationEnabled) {
				localStorage.setItem(
					`_plaid_messaging_2_day_${
						bank?.accountNickname + bank?.bankId || 'showing'
					}_`,
					'hide',
				);
			} else {
				localStorage.setItem(
					`_plaid_messaging_${
						bank?.accountNickname + bank?.bankId || 'showing'
					}_`,
					'hide',
				);
			}
		}
	};

	const PlaidConnectionCheck = ({ bankVerifiedButMakePlaidCheck }) => {
		// If a bank is connected to plaid, but token is expired, show reconnect messaging
		// If a bank is verified, we need to check if it is connected to Plaid and show the messaging if needed (handled in plaidConnectPanel)
		return (
			<>
				{(bank?.plaidStatus === 'Failed' || bank?.plaidStatus === 'Error') &&
				isPlaidAdoptionAndSufficientBalanceEnabled ? (
					<PlaidReconnectPanel />
				) : null}
				{bankVerifiedButMakePlaidCheck &&
					isPlaidAdoptionAndSufficientBalanceEnabled &&
					plaidConnectPanel(bank)}
			</>
		);
	};

	const renderMultiRequiredDownloadAndUploadState = () => {
		return (
			<>
				<InPageMessaging
					body={$.__(
						'These forms are required so we can get your company set up to run payroll with BambooHR Payroll.',
					)}
					header={$.__(
						'You need to sign the required payroll forms for %1$s below',
						company.companyLegalName,
					)}
					icon="file-signature-solid"
					type="info"
				/>
			</>
		);
	};

	const PlaidReconnectPanel = () => {
		return (
			<InPageMessaging
				body={$.__(
					'Please connect again to continue smooth payroll processing.',
				)}
				customAction={
					<Button
						color="secondary"
						onClick={() => {
							if (ready) {
								open();
							}
						}}
						size="teenie"
						type="button"
						variant="outlined"
					>
						{$.__('Reconnect')}
					</Button>
				}
				header={$.__(
					'Your Plaid connection has expired for %1$s',
					bank.accountNickname,
				)}
				icon="triangle-exclamation-solid"
				type="warning"
			/>
		);
	};

	const renderMultiRequiredBankState = () => {
		return (
			<>
				<InPageMessaging
					body={$.__(
						'Before we can verify your company information, you will need to add the payroll bank account information.',
					)}
					header={$.__(
						'Add bank account information for %1$s below',
						company.companyLegalName,
					)}
					icon="building-columns-solid"
					type="info"
				/>
				<PlaidConnectionCheck />
				{isPlaidAdoptionAndSufficientBalanceEnabled && plaidConnectPanel(bank)}
			</>
		);
	};

	const plaidConnectPanel = (bank) => {
		if (
			!bank ||
			bank?.plaidStatus === 'Linked' ||
			bank?.plaidStatus === 'Failed' ||
			bank?.plaidStatus === 'Error' ||
			!showPlaidConnect
		) {
			return null;
		}
		if (error) {
			showPlaidFailedMessage(error);
		}

		return (
			<InPageMessaging
				body={$.__(
					'With real-time account info, we make sure your team gets paid on time, every time.',
				)}
				customAction={
					<Button
						color="secondary"
						size="teenie"
						type="button"
						variant="outlined"
						onClick={() => {
							if (ready) {
								open();
							}
						}}
					>
						{$.__('Start Now')}
					</Button>
				}
				header={$.__(`Connect %1$s with Plaid.`, bank.accountNickname)}
				icon="plug-circle-plus-solid"
				type="info"
				onClose={() => {
					handleHidePlaidConnect();
				}}
			/>
		);
	};

	const renderMultiLinkPlaidState = () => {
		return (
			<>
				<InPageMessaging
					body={$.__(
						'Once it is authenticated you will be able to use this bank account for payroll.',
					)}
					header={$.__(
						'You need to link a bank account to %1$s below',
						company.companyLegalName,
					)}
					icon="building-columns-solid"
					type="info"
				/>
				<PlaidConnectionCheck />
			</>
		);
	};

	const renderAddBankAccountState = () => {
		return (
			<>
				<InPageMessaging
					body={
						<>
							<BodyText>
								{$.__(
									'Before we can verify your company information, you will need to complete your bank account setup.',
								)}
							</BodyText>
							<LayoutBox marginTop={2}>
								<Button onClick={takeToBankAccountSection} type="button">
									{$.__('Go to Bank Account Settings')}
								</Button>
							</LayoutBox>
						</>
					}
					header={$.__('You need to set up your payroll bank account')}
					icon="building-columns-solid"
					type="info"
				/>
				<PlaidConnectionCheck />
			</>
		);
	};

	const renderBankVerifyDepositState = () => {
		return (
			<>
				{!bank.penniesHasBeenStored ? (
					<>
						<InPageMessaging
							body={$.__(
								'We will send a small deposit to your account. You will see a deposit from "NATPAY or Penny Test". Once the deposit appears in your bank account, come back and enter the amount to verify your account.',
							)}
							header={$.__(
								'Verify the deposit amount for %1$s below',
								bank.accountNickname,
							)}
							icon="money-bill-wave-solid"
							type="info"
							customAction={
								isPlaidAdoptionAndSufficientBalanceEnabled ? (
									<Button
										variant="outlined"
										color="secondary"
										size="small"
										onClick={() => {
											handleOpenVerificationModal(bank);
										}}
									>
										{$.__('Verify Account')}
									</Button>
								) : undefined
							}
						/>
					</>
				) : null}
				<PlaidConnectionCheck />
				{isPlaidAdoptionAndSufficientBalanceEnabled && plaidConnectPanel(bank)}
			</>
		);
	};

	const renderBankVerifyDepositSingleState = () => {
		const openVerificationModal = () => {
			const { buttonFunction } = props;

			buttonFunction(true);
		};

		return (
			<>
				{!bank?.penniesHasBeenStored && (
					<InPageMessaging
						body={
							<>
								<BodyText>
									{$.__(
										'We will send a small deposit to your account. You will see a deposit from "NATPAY or Penny Test". Once the deposit appears in your bank account, come back and enter the amount to verify your account.',
									)}
								</BodyText>
								{!isPlaidAdoptionAndSufficientBalanceEnabled && (
									<LayoutBox marginTop={2}>
										<Button onClick={openVerificationModal} type="button">
											{$.__('Verify Account')}
										</Button>
									</LayoutBox>
								)}
							</>
						}
						header={$.__(
							'Confirm deposit amount to complete bank account setup',
						)}
						icon="money-bill-wave-solid"
						type="info"
						customAction={
							isPlaidAdoptionAndSufficientBalanceEnabled ? (
								<Button
									variant="outlined"
									color="secondary"
									size="small"
									type="button"
									onClick={openVerificationModal}
								>
									{$.__('Verify Amount')}
								</Button>
							) : undefined
						}
					/>
				)}
				<PlaidConnectionCheck />
				{isPlaidAdoptionAndSufficientBalanceEnabled && plaidConnectPanel(bank)}
			</>
		);
	};

	const renderDownloadAndSignState = () => {
		const { clientId, onFileDownload, isProcessing } = props;

		const companyFileTiles = companyFiles.map((file) => {
			return (
				<FileTile
					clientId={clientId}
					fileName={file.name}
					fileType={file.type}
					isProcessing={isProcessing}
					key={file.id}
					onFileDownload={onFileDownload}
				/>
			);
		});

		return (
			<>
				<InPageMessaging
					body={
						<>
							<BodyText>
								{$.__(
									'These forms are required so we can get your company set up to run payroll with BambooHR Payroll.',
								)}
							</BodyText>

							<Flex gap={2} marginTop={2}>
								{companyFileTiles}
							</Flex>
						</>
					}
					header={$.__('Download and sign your company payroll forms')}
					icon="file-signature-solid"
					type="info"
				/>
				<PlaidConnectionCheck />
				{isPlaidAdoptionAndSufficientBalanceEnabled && plaidConnectPanel(bank)}
			</>
		);
	};

	const renderBankDownloadAndSignState = (downloaded) => {
		const { clientId, onFileDownload, buttonFunction, isProcessing } = props;

		const openUploadFileModal = () => {
			buttonFunction(bank);
		};

		const files = BANK_FILES_ARRAY;

		// Handle multi-file download
		const initFileDownload = (e) => {
			e.preventDefault();
			var temporaryDownloadLink = document.createElement('a');
			temporaryDownloadLink.style.display = 'none';

			document.body.appendChild(temporaryDownloadLink);

			files.forEach((file) => {
				temporaryDownloadLink.setAttribute(
					'href',
					`/payroll/documents/prefilled_client_doc/${file.type}/${clientId}`,
				);
				temporaryDownloadLink.setAttribute('download', file.name);

				temporaryDownloadLink.click();
			});

			document.body.removeChild(temporaryDownloadLink);

			setIsDownloaded(true);
			onFileDownload(files[0].type);
		};

		const bankFileTiles = BANK_FILES_ARRAY.map((file) => {
			return (
				<FileTile
					key={file.id}
					clientId={clientId}
					fileName={file.name}
					fileType={file.type}
					isProcessing={isProcessing}
					onFileDownload={onFileDownload}
				/>
			);
		});

		if (!isPlaidAdoptionAndSufficientBalanceEnabled) {
			return (
				<InPageMessaging
					icon="file-signature-solid"
					header={$.__(
						'Download and sign your bank account authorization forms',
					)}
					type="info"
					body={
						<>
							<BodyText>
								{$.__(
									'We need your permission to verify and make withdrawals and deposits to your bank account.',
								)}
							</BodyText>
							<Flex gap={2} marginTop={2}>
								{bankFileTiles}
							</Flex>
						</>
					}
				/>
			);
		}

		return (
			<>
				<InPageMessaging
					body={
						<BodyText>
							{downloaded
								? $.__('You can redownload your file if needed.')
								: $.__(
										'We need your permission to verify and make withdrawals and deposits to your bank account.',
								  )}
						</BodyText>
					}
					header={
						downloaded
							? $.__('Upload your signed bank account authorization form.')
							: $.__('Download and sign your bank account authorization form.')
					}
					icon="file-signature-solid"
					type={downloaded || isDownloaded ? 'neutral' : 'info'}
					customAction={
						<Flex flexDirection="row" gap="12px">
							<a download={true} onClick={initFileDownload}>
								<Button
									color="secondary"
									size="small"
									startIcon={
										isDownloaded || downloaded ? undefined : 'download-regular'
									}
									type="button"
									variant="outlined"
								>
									{isDownloaded || downloaded
										? $.__('Redownload')
										: $.__('ACH Authorization Form')}
								</Button>
							</a>
							{(isDownloaded || downloaded) && (
								<Button
									size="small"
									startIcon="upload-regular"
									type="button"
									onClick={openUploadFileModal}
								>
									{$.__('Signed Form')}
								</Button>
							)}
						</Flex>
					}
				/>
				<PlaidConnectionCheck />
				{isPlaidAdoptionAndSufficientBalanceEnabled && plaidConnectPanel(bank)}
			</>
		);
	};

	const renderDocsUploadedState = () => {
		const openUploadFileModal = () => {
			const { buttonFunction } = props;

			buttonFunction(true);
		};

		return (
			<>
				<InPageMessaging
					body={
						<>
							{getFilesDownloadList(companyFiles, props.clientId)}
							<LayoutBox marginTop={2}>
								<Button
									onClick={openUploadFileModal}
									startIcon="upload-solid"
									type="button"
								>
									{$.__('Upload Signed Forms')}
								</Button>
							</LayoutBox>
						</>
					}
					header={$.__('Upload your completed company payroll forms')}
					icon="file-arrow-up-solid"
					type="info"
				/>
			</>
		);
	};

	const renderBankUploadedState = () => {
		const { onFileDownload } = props;
		const openUploadFileModal = () => {
			const { buttonFunction } = props;

			buttonFunction(true);
		};

		const files = BANK_FILES_ARRAY;

		const initFileDownload = (e) => {
			e.preventDefault();
			var temporaryDownloadLink = document.createElement('a');
			temporaryDownloadLink.style.display = 'none';

			document.body.appendChild(temporaryDownloadLink);

			files.forEach((file) => {
				temporaryDownloadLink.setAttribute(
					'href',
					`/payroll/documents/prefilled_client_doc/${file.type}/${props.clientId}`,
				);
				temporaryDownloadLink.setAttribute('download', file.name);

				temporaryDownloadLink.click();
			});

			document.body.removeChild(temporaryDownloadLink);

			setIsDownloaded(true);
			onFileDownload(files[0].type);
		};

		return (
			<>
				<InPageMessaging
					body={
						!isPlaidAdoptionAndSufficientBalanceEnabled ? (
							<>
								{getFilesDownloadList(BANK_FILES_ARRAY, props.clientId)}
								<LayoutBox marginTop={2}>
									<Button
										onClick={openUploadFileModal}
										startIcon="upload-solid"
										type="button"
									>
										{$.__('Upload Forms')}
									</Button>
								</LayoutBox>
							</>
						) : (
							$.__('You can redownload your files if needed.')
						)
					}
					header={$.__('Upload your signed bank account authorization forms')}
					icon="file-arrow-up-solid"
					type={isPlaidAdoptionAndSufficientBalanceEnabled ? 'neutral' : 'info'}
					customAction={
						isPlaidAdoptionAndSufficientBalanceEnabled ? (
							<Flex flexDirection="row" gap="12px">
								<a download={true} onClick={initFileDownload}>
									<Button
										color="secondary"
										size="small"
										type="button"
										variant="outlined"
									>
										{$.__('Redownload')}
									</Button>
								</a>
								<Button
									size="small"
									startIcon="upload-regular"
									type="button"
									onClick={openUploadFileModal}
								>
									{$.__('Signed Forms')}
								</Button>
							</Flex>
						) : undefined
					}
				/>
				<PlaidConnectionCheck />
				{isPlaidAdoptionAndSufficientBalanceEnabled && plaidConnectPanel(bank)}
			</>
		);
	};
	const { status, isMultiEin } = props;

	switch (status) {
		case BANK_APP_STATE.VERIFIED:
			return <PlaidConnectionCheck bankVerifiedButMakePlaidCheck={true} />;
		case COMPANY_APP_STATE.COMPANY_SAVED:
			return isMultiEin
				? renderMultiRequiredDownloadAndUploadState()
				: renderDownloadAndSignState();

		case COMPANY_APP_STATE.DOCS_DOWNLOADED:
			return isMultiEin
				? renderMultiRequiredDownloadAndUploadState()
				: renderDocsUploadedState();

		case COMPANY_APP_STATE.DOCS_UPLOADED:
			return isMultiEin
				? renderMultiRequiredBankState()
				: renderAddBankAccountState();

		// BANK SECTION
		case BANK_APP_STATE.BANK_SAVED:
			return renderBankDownloadAndSignState();

		case BANK_APP_STATE.DOCS_DOWNLOADED:
			return isMultiEin
				? renderBankDownloadAndSignState(true)
				: renderBankUploadedState();

		case BANK_APP_STATE.DOCS_UPLOADED:
			return isMultiEin
				? renderBankVerifyDepositState()
				: renderBankVerifyDepositSingleState();

		case BANK_APP_STATE.LINK_PLAID_SINGLE:
			return isMultiEin ? renderMultiLinkPlaidState() : null;

		default:
			return null;
	}
};
