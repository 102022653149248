import moment from 'moment.lib';
import { DATE_MASK_TO_MOMENT } from '../plans.react/constants';

export const hasPlanStarted = (startDate: string): boolean => {
	return Boolean(startDate) && moment(startDate).isSameOrBefore(moment(), 'day');
};

export const hasPlanEnded = (endDate: string): boolean => {
	return Boolean(endDate) && moment(endDate).isSameOrBefore(moment(), 'day');
};

export function getFormattedDate(date: string): string {
	if (date) {
		return moment(date).format(DATE_MASK_TO_MOMENT[window.GLOBAL_DATE_MASK]);
	}
	return '';
}
