import {
	Fragment,
} from 'react';

import { LayoutBox } from '@bamboohr/fabric';


import { HeadlineWrapper } from '../headline-wrapper';
import {
	AcaStatusAlignmentTable,
	formatStatuses,
} from 'aca-status-alignment-table.react';

/** @type {React.FunctionComponent} */
export default function ModalContentEnableStep3(props) {
	const {
		acaTypes,
		employmentStatusAcaTypes,
		employmentStatuses,
		onAcaStatusAssignment,
	} = props;

	const onFormChange = (employmentStatusId, acaStatusValue) => {
		onAcaStatusAssignment(acaStatusValue[0], employmentStatusId);
	};

	return (
		<Fragment>
			<HeadlineWrapper
				headlineText={$.__('Choose an ACA status for each Employment Status')}
				subHeadlineText={$.__(`The IRS has specific designations for employment status. Choose the ACA status that best fits each of your employment statuses. Don't worry, you can change these later in Settings >> Employee Fields >> Employment Status.`)}
			/>
			<LayoutBox marginBottom={0.5}>
				<AcaStatusAlignmentTable
					acaTypes={ acaTypes }
					alignments={ employmentStatusAcaTypes }
					onFormChange={ onFormChange }
					statusTypes={ formatStatuses(employmentStatuses) }
				/>
			</LayoutBox>
		</Fragment>
	);
}
