import { Component } from 'react';
import { cloneDeep } from 'lodash';

import {
	COMPANY_APP_STATE,
	getStatesList,
	nullCompanyObject,
	postUploadFiles,
	getAppStateFromData,
	getComponentForState,
	getCompanyInformation,
	postSaveCompanyInformation,
	putSaveCompanyInformation,
	scrollToTopOfPage,
	isValidPhoneNumber,
	showBadPhoneMsg,
	BAD_EIN,
	BAD_ZIP,
} from './utils/shared';

import { PageLoader } from './components/page-loader/page-loader';

export class CompanyInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			initialPayload: null,
			stateList: null,

			isLoading: true,
			isProcessing: false,
			revokePreviousPayrollClientPermission: undefined,
		};
	}

	getPropsFromData = (currentState, state) => {
		switch (currentState) {
			case COMPANY_APP_STATE.PRE_EIN:
				return {
					company: state.company,
					contacts: state.contacts,
					stateList: state.stateList,
					isProcessing: state.isProcessing,
					onInputChange: this._handleInputChange,
					onSubmit: this._handlePostSaveCompany,
					recordId: state.addedEinsSuggestedNames[0].recordId,
					revokePreviousContactPermission:
						state.revokePreviousPayrollClientPermission,
					onRevokePreviousContactPermission:
						this._handleRevokePreviousContactPermission,
				};

			case COMPANY_APP_STATE.COMPANY_SAVED:
				return {
					company: state.company,
					contacts: state.contacts,
					isProcessing: state.isProcessing,
					stateList: state.stateList,
					statuses: currentState,
					onFileDownload: this._handleFileDownload,
					onInputChange: this._handleInputChange,
					onSubmit: this._handlePutUpdateCompany,
					revokePreviousContactPermission:
						state.revokePreviousPayrollClientPermission,
					onRevokePreviousContactPermission:
						this._handleRevokePreviousContactPermission,
				};

			case COMPANY_APP_STATE.DOCS_DOWNLOADED:
				return {
					company: state.company,
					contacts: state.contacts,
					isProcessing: state.isProcessing,
					stateList: state.stateList,
					statuses: currentState,
					onInputChange: this._handleInputChange,
					onSubmit: this._handlePutUpdateCompany,
					onUploadForms: this._handlePostFileUploads,
					revokePreviousContactPermission:
						state.revokePreviousPayrollClientPermission,
					onRevokePreviousContactPermission:
						this._handleRevokePreviousContactPermission,
				};

			case COMPANY_APP_STATE.DOCS_UPLOADED:
				return {
					company: state.company,
					contacts: state.contacts,
					isProcessing: state.isProcessing,
					stateList: state.stateList,
					statuses: currentState,
					onInputChange: this._handleInputChange,
					onSubmit: this._handlePutUpdateCompany,
					revokePreviousContactPermission:
						state.revokePreviousPayrollClientPermission,
					onRevokePreviousContactPermission:
						this._handleRevokePreviousContactPermission,
				};

			case COMPANY_APP_STATE.VERIFIED:
				return {
					company: state.company,
					contacts: state.contacts,
					isProcessing: state.isProcessing,
					stateList: state.stateList,
					statuses: currentState,
					refreshData: this.refreshData,
					onInputChange: this._handleInputChange,
					onSubmit: this._handlePutUpdateCompany,
					revokePreviousContactPermission:
						state.revokePreviousPayrollClientPermission,
					onRevokePreviousContactPermission:
						this._handleRevokePreviousContactPermission,
				};

			case COMPANY_APP_STATE.MULTI_COMPANIES_TABLE:
				return {
					companies: state.companies,
					contacts: state.contacts,
					isProcessing: state.isProcessing,
					stateList: state.stateList,
					statuses: currentState,
					onFileDownload: this._handleMultiEinFileDownload,
					addedEinsSuggestedNames: state.addedEinsSuggestedNames,
				};

			default:
				break;
		}
	};

	_handleMultiEinFileDownload = () => {
		this.setState(
			{
				isProcessing: true,
			},
			() => {
				setTimeout(() => {
					this.setState({
						isProcessing: false,
					});
				}, 1000);
			},
		);
	};

	_handleFileDownload = (fileType) => {
		this.setState(
			{
				isProcessing: true,
			},
			() => {
				setTimeout(() => {
					this.setState((prevState) => {
						prevState.company[fileType].fileStatus = 'downloaded';
						prevState.isProcessing = false;

						return prevState;
					});
				}, 600);
			},
		);
	};

	_handlePostFileUploads = (clientId, uploadedFiles) => {
		this.setState(
			{
				isProcessing: true,
				isLoading: true,
			},
			() => {
				postUploadFiles(clientId, uploadedFiles)
					.then(() => {
						this.refreshData();
						window.setMessage(
							$.__(
								"Forms uploaded successfully! Almost there, just add your company's payroll bank account information",
							),
							'success',
						);
						scrollToTopOfPage();
					})
					.catch(() => {
						window.setMessage(
							$.__(
								'Uh oh...something went wrong uploading your files. Please try again.',
							),
							'error',
						);
					});
			},
		);
	};

	_handlePostSaveCompany = (data, recordId = null) => {
		if (
			!isValidPhoneNumber(data.workPhone) ||
			!isValidPhoneNumber(data.phoneNumber)
		) {
			showBadPhoneMsg();
			return;
		}

		const { revokePreviousPayrollClientPermission } = this.state;

		this.setState(
			{
				isProcessing: true,
				isLoading: true,
			},
			() => {
				postSaveCompanyInformation(
					{ ...data, revokePreviousPayrollClientPermission },
					recordId,
				)
					.then((res) => {
						this.setState({
							company: {
								...res.data.company,
								phoneNumber: data.phoneNumber,
								website: data.website || '',
								businessType: data.businessType,
							},
							isProcessing: false,
							isLoading: false,
							revokePreviousPayrollClientPermission: undefined,
						});

						window.setMessage(
							'Success! Please download and complete your company payroll forms below.',
							'success',
						);
						scrollToTopOfPage();
					})
					.catch((error) => {
						let message = $.__(
							'Uh oh...something went wrong saving your company information. Please try again.',
						);

						this._resetPendingStates();

						if (
							error.response.headers['x-bamboohr-error-message'] ==
							'ein is not valid.'
						) {
							message = BAD_EIN;
						}

						if (
							error.response.headers['x-bamboohr-error-message'] ==
							'Store Payroll Client Validation Error. : zip is not valid.'
						) {
							message = BAD_ZIP;
						}

						window.setMessage(message, 'error');
						scrollToTopOfPage();
					});
			},
		);
	};

	_handlePutUpdateCompany = (data, href = null) => {
		if (
			!isValidPhoneNumber(data.workPhone) ||
			!isValidPhoneNumber(data.phoneNumber)
		) {
			showBadPhoneMsg();
			return;
		}

		const { revokePreviousPayrollClientPermission } = this.state;

		this.setState(
			{
				isProcessing: true,
			},
			() => {
				putSaveCompanyInformation({
					...data,
					revokePreviousPayrollClientPermission,
				})
					.then((res) => {
						this.setState({
							company: {
								...res.data.company,
								contactName: data.contactName,
								workEmail: data.workEmail,
								workExtension: data.workExtension,
								workPhone: data.workPhone,
								phoneNumber: data.phoneNumber,
								website: data.website || null,
								businessType: data.businessType,
							},
							isProcessing: false,
							isLoading: false,
							revokePreviousPayrollClientPermission: undefined,
						});
						window.setMessage(res.data.message, 'success');
						scrollToTopOfPage();

						if (href) {
							window.location = href;
						}
					})
					.catch((error) => {
						let message = $.__(
							'Uh oh...something went wrong saving your company information. Please try again.',
						);

						this._resetPendingStates();

						if (
							error.response.headers['x-bamboohr-error-message'] ==
							'ein is not valid.'
						) {
							message = BAD_EIN;
						}

						if (
							error.response.headers['x-bamboohr-error-message'] ==
							'Store Payroll Client Validation Error. : zip is not valid.'
						) {
							message = BAD_ZIP;
						}

						scrollToTopOfPage();
						window.setMessage(message, 'error');
					});
			},
		);
	};

	_resetPendingStates = () => {
		this.setState({
			isProcessing: false,
			isLoading: false,
		});
	};

	_handleInputChange = (propertyName, newPropertyValue) => {
		this.setState((prevState) => {
			prevState.company[propertyName] = newPropertyValue;

			return prevState;
		});
	};

	_handleRevokePreviousContactPermission = (revokeAccess) => {
		this.setState({ revokePreviousPayrollClientPermission: revokeAccess });
	};

	renderContents = (StateToRender, props) => {
		return (
			//Class is used for move away listeners
			<div className="CompanyInformationContainer">
				<StateToRender {...props} />
			</div>
		);
	};

	renderLoading = () => {
		return <PageLoader />;
	};

	refreshData = () => {
		getCompanyInformation()
			.then((res) => {
				const { data } = res;

				this._resetPendingStates();

				if (data.potentialEins > 1) {
					// Multi EIN
					this.setState({
						initialPayload: cloneDeep(data),
						potentialEins: data.potentialEins,
						addedEinsSuggestedNames: data.addedEinsSuggestedNames,

						contacts: data.contacts,
						companies: data.companies,
						revokePreviousPayrollClientPermission: undefined,
					});
				} else {
					// Single EIN
					this.setState({
						initialPayload: cloneDeep(data),
						potentialEins: data.potentialEins,
						addedEinsSuggestedNames: data.addedEinsSuggestedNames,

						contacts: data.contacts,
						company: data.companies.length
							? data.companies[0]
							: nullCompanyObject(),
						revokePreviousPayrollClientPermission: undefined,
					});
				}
			})
			.catch(() => {
				window.setMessage(
					$.__(
						'Uh oh...something went wrong retrieving your company information. Please try again.',
					),
					'error',
				);
			});
	};

	componentWillMount() {
		getStatesList()
			.then((res) => {
				this.setState({
					stateList: res.data.data,
				});
			})
			.catch(() => {
				window.setMessage(
					$.__('Uh oh...something went wrong. Please try again later.'),
					'error',
				);
			});

		this.refreshData();
	}

	render() {
		const { company, companies, potentialEins, isLoading } = this.state;

		if (isLoading) {
			return this.renderLoading();
		}

		if (potentialEins == 1 && company) {
			const currentAppState = getAppStateFromData(company);
			const StateToRender = getComponentForState(currentAppState);
			const propsForComponent = this.getPropsFromData(
				currentAppState,
				this.state,
			);

			return this.renderContents(StateToRender, propsForComponent);
		}

		if (potentialEins > 1 && companies) {
			const StateToRender = getComponentForState(
				COMPANY_APP_STATE.MULTI_COMPANIES_TABLE,
			);
			const propsForComponent = this.getPropsFromData(
				COMPANY_APP_STATE.MULTI_COMPANIES_TABLE,
				this.state,
			);

			return this.renderContents(StateToRender, propsForComponent);
		}

		return null;
	}
}
