import { Select } from '@fabric/select';

import {
	useReducerContext,
} from '../state';

export default function LangSelector() {
	const {
		languages,
		language: {
			value,
		},
		loading,
		bem,
		dispatch,
		SET_LANGUAGE,
	} = useReducerContext();

	return (
        <div className={ bem('langSelector') }>
            <Select
				isClearable={ false }
				isDisabled={ loading }
				items={ Object.values(languages) }
				onChange={ ([id]) => dispatch(SET_LANGUAGE({ id })) }
				selectedValues={ [value] }
				size={'small'}
				variant={'single'}
			/>
        </div>
    );
}
