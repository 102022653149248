import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { PoMicroFrontend } from 'micro-frontend.react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
	LayoutBox,
	Section,
} from '@bamboohr/fabric';
import Ajax from '@utils/ajax';

import {
	SadToGoBox,
	CancellationType,
	CancellationDropdown,
	CancellationModal,
	Services,
} from 'in-app-cancellation.mod';
import { DemoRequest } from '../benefits-admin-upsell';
import { BenefitsTabs } from '../benefits-tabs';
import { getUserDemoRequestContentPermissions } from '../benefits-tabs/utils/get-demo-request-content-permissions';
import { useBenefitsSettingsStore } from './data/benefits-settings-store';

interface PlansLandingProps {
	demoRequest: DemoRequest;
}

export const PlansLanding = (props: PlansLandingProps): ReactElement => {
	const [{ isBenefitsAdminEnabled }] = useBenefitsSettingsStore();

	const [cancellationData, setCancellationData] = useState<any>({});
	const [cancellationPending, setCancellationPending] = useState(false);
	const [isCompanyInTrial, setIsCompanyInTrial] = useState<boolean>(false);
	const [
		showBenefitsAdminDemoRequestContent,
		setShowBenefitsAdminDemoRequestContent,
	] = useState<boolean>(false);
	const [benAdminEmployeeCount, setBenAdminEmployeeCount] = useState<
		number | undefined
	>();

	const [modalOpen, setModalOpen] = useState(false);

	const getBenAdminEmployeeData = useCallback(() => {
		Ajax.get('/settings/benefits/employees').then(
			(response) => {
				if (
					response.data?.employees &&
					response.data?.isEligibilityBasedBilling
				) {
					const employees = Object.keys(response.data.employees);
					setBenAdminEmployeeCount(employees.length);
				}
			},
			(err) => {
				console.error(err);
			},
		);
	}, []);

	const getCancellationStatusAll = () => {
		Ajax.get('/settings/cancellation/status_all').then((response) => {
			if (response.data) {
				setCancellationData(
					response.data.filter(
						(item) =>
							item.feature === CancellationType.BENEFIT_ADMIN ||
							item.feature === CancellationType.ACCOUNT,
					),
				);
				setCancellationPending(
					response.data.find(
						(item) => item.feature === CancellationType.BENEFIT_ADMIN,
					).pending_cancellation,
				);
			}
		});
	};

	const getCancellationStatus = () => {
		Services.getCancellationFeatureStatus(CancellationType.BENEFIT_ADMIN).then(
			(resp) => {
				setCancellationData(resp.data);
				setCancellationPending(resp.data.pending_cancellation);
			},
		);
	};

	useEffect(() => {
		getCancellationStatusAll();
	}, [cancellationPending, modalOpen]);

	useEffect(() => {
		getUserDemoRequestContentPermissions().then((response) => {
			if (response.data) {
				const { isCompanyInTrial, showBenefitsAdminDemoRequestContent } =
					response.data;
				setShowBenefitsAdminDemoRequestContent(
					showBenefitsAdminDemoRequestContent,
				);
				setIsCompanyInTrial(isCompanyInTrial);
			}
		});
	}, []);

	useEffect(() => {
		getBenAdminEmployeeData();
	}, [getBenAdminEmployeeData]);

	const handleOnSelect = () => {
		setModalOpen(true);
	};

	const isAdmin =
		window.SESSION_USER?.supportAdmin ||
		window.SESSION_USER?.isAdmin ||
		window.SESSION_USER?.isOwner;

	return (
		<>
			<LayoutBox marginBottom={3}>
				<Section.Header
					actions={[<>
						{isAdmin && !!cancellationData && !cancellationPending && (
							<>
								{isBenefitsAdminEnabled && (
									<>
										<CancellationDropdown
											cancellationData={cancellationData}
											handleOnSelect={handleOnSelect}
											title={$.__('Benefits Management')}
										/>
										<CancellationModal
											isOpen={modalOpen}
											onClose={() => setModalOpen(false)}
											onSubmitSuccess={getCancellationStatus}
											types={[CancellationType.BENEFIT_ADMIN]}
										/>
									</>
								)}
							</>
						)}
					</>]}
					size="large"
					title={$.__('Benefits')}
				/>
			</LayoutBox>
			<>
				{!!cancellationData && cancellationPending && (
					<SadToGoBox
						cancellationData={cancellationData}
						updateCancellationData={getCancellationStatusAll}
						isAddon={true}
					/>
				)}
			</>
			<PoMicroFrontend
				customClassNamePrefix="resolution-link-banner"
				route="/settings/benefits/resolution-link-banner"
			/>
			<Router basename="/settings/benefits/plans">
				<BenefitsTabs
					benAdminEmployeeCount={benAdminEmployeeCount}
					isCompanyInTrial={isCompanyInTrial}
					showBenefitsAdminDemoRequestContent={
						showBenefitsAdminDemoRequestContent
					}
					{...props}
				/>
			</Router>
		</>
	);
};
