import { DisplayFileCard, Flex } from '@bamboohr/fabric';

export function CompletedForms(props) {
	const openPreview = (file) => {
		window.previewFile(
			file.fileDataId,
			file.fileId,
			file.displayFileName,
			false,
			'div',
			false,
			'previewer',
		);
	};

	const renderFiles = () => {
		const { files } = props;

		return files.map((file) => {
			if (!file.fileId) {
				return null;
			}

			return (
				<DisplayFileCard
					fileType="pdf"
					onClick={() => openPreview(file)}
					fileName={file.displayFileName}
					fileId={file.fileId}
				/>
			);
		});
	};

	const { isBank = false } = props;

	const legendText = isBank
		? $.__('Bank Authorization Forms')
		: $.__('Payroll Forms');
	const subLegendText = isBank
		? $.__(
				'Here is a copy of your completed account authorization forms. Keep these for your records.',
		  )
		: $.__('Here is a copy of your completed forms.');

	return (
		<div className="fab-FormSection">
			<legend className="fab-FormSection__legend">
				<span className="fab-FormSection__legendText">{legendText}</span>
				<span className="fab-FormSection__legendNote">{subLegendText}</span>
			</legend>

			<Flex gap={2}>{renderFiles()}</Flex>
		</div>
	);
}
