import { Button, LayoutBox, Headline, Flex } from '@bamboohr/fabric';
import { useEffect, useState } from 'react';
import { AuthOptions } from '../../constants';
import { getHeaderText } from './etc';
import { MfaCodeAuthentication } from './mfa-code-authentication';
import { AuthType as AuthScreen, InterstitialAuthProps } from './types';

export function InterstitialAuth(props: InterstitialAuthProps): JSX.Element {
	const {
		isInvalid = false,
		enabledMfaChannels,
		onChange,
		onNext,
		onResendSms,
		preferredChannel = AuthOptions.APP,
		lastFour,
		hitLimit,
	} = props;
	const [code, setCode] = useState<string>('');
	const [type, setType] = useState<AuthScreen>(preferredChannel);
	const [rememberMeIsChecked, setRememberMeIsChecked] = useState<boolean>(false);
	const [isProcessing, setIsProcessing] = useState(false)

	useEffect(() => {
		setType(preferredChannel);
	}, [preferredChannel]);

	return (
		<LayoutBox padding={2.5}>
			<LayoutBox paddingBottom={2.5}>
				<Headline size="medium">{getHeaderText()}</Headline>
			</LayoutBox>
			<LayoutBox paddingTop={4}>
				<MfaCodeAuthentication
					enabledMfaChannels={enabledMfaChannels}
					hitLimit={hitLimit}
					isInvalid={isInvalid}
					lastFour={lastFour}
					onCodeChange={onChange}
					onRememberMeChange={setRememberMeIsChecked}
					onResendSms={onResendSms}
					rememberMeIsChecked={rememberMeIsChecked}
					setCode={setCode}
					setType={setType}
					type={type}
				/>
			</LayoutBox>
			<Flex justifyContent="end">
				<Button
						data-button-next="next"
						onClick={async () => {
							setIsProcessing(true);
							const result = await onNext(code, rememberMeIsChecked, type);
							if (result === 'invalid code' || result === 'unknown error') {
								// leave processing until redirect, unless there's an unknown error
								setIsProcessing(false);
							}
						}}
						processing={isProcessing}
						type="button"
				>
					{$.__('Continue')}
				</Button>
			</Flex>
		</LayoutBox>
	);
}
