import { cloneDeep, debounce, flatten, uniq } from 'lodash';
import { Component, Fragment } from 'react';

import {
	Button,
	Flex,
	IconV2,
	Label,
	LayoutBox,
	Loader,
	Nub,
	Select,
	TextField,
} from '@bamboohr/fabric';
import Ajax from '@utils/ajax';
import { showSlidedown } from 'Alerts.mod';
import { isEnabled } from 'FeatureToggle.util';

import { CancellationType, SadToGoBox } from 'in-app-cancellation.mod';
import { MissingTaxIdMessages } from './components/missing-tax-id-messages';
import { AddTaxMessageMicro } from './components/add-tax-message-micro';
import { TaxesTable } from './components/taxes-table';
import { renderCompanySelectOption } from './utils';

export class CompanyTaxes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cancellationData: {},
			cancellationPending: false,
			filters: {
				activeFilter: 'active',
				clientId: null,
				einFilter: null,
				searchFilter: null,
			},
			taxesMissingIdsAreLoading: true,
			taxesMissingIdArray: null,
			clientMetadataArray: null,
			missingTaxesAreLoading: true,
			missingTaxesArray: null,
			missingTaxesMetadata: null,
			addTaxMessageMicroProps: null,
		};

		this._getCancellationStatusAll = this._getCancellationStatusAll.bind(this);
	}

	_getAllTaxesMissingIds = () => {
		const { companyTaxes } = this.props;

		Ajax.post('/auth/token_sessions')
			.then((response) => {
				if (response?.data?.token) {
					const headers = {
						headers: {
							Authorization: `Bearer ${response.data.token}`,
						},
					};
					const allClientIds = companyTaxes.map(
						(companyTaxObj) => companyTaxObj.clientId,
					);

					// Get client metadata, including master tax start dates
					return Ajax.get(
						`https://${
							window.PAYROLLSERVICES_HOST
						}/v2/clients?ids=${allClientIds.join()}`,
						null,
						headers,
					).then((clientMetadataResponse) => {
						const allTaxGetPromises = allClientIds.map((clientId) =>
							Ajax.get(
								`https://${window.PAYROLLSERVICES_HOST}/v2/clients/${clientId}/taxes?needsSetup=1`,
								null,
								headers,
							),
						);

						return Promise.all(allTaxGetPromises).then((responseArray) => {
							if (responseArray.every((res) => res.status === 200)) {
								this.setState({
									taxesMissingIdsAreLoading: false,
									taxesMissingIdArray: responseArray.map(
										(taxResponse) => taxResponse?.data,
									),
									clientMetadataArray: clientMetadataResponse?.data || null,
								});
							} else {
								this._handleTaxesMissingIdsGetFailure();
							}
						});
					});
				}
				this._handleTaxesMissingIdsGetFailure();
			})
			.catch(this._handleTaxesMissingIdsGetFailure);
	};

	_getAllMissingTaxes = () => {
		const { companyTaxes } = this.props;
		const missingTaxAccountIdsByEinPromises = companyTaxes.map(
			(companyTaxObj) =>
				Ajax.get(
					`/api/payroll/validation_errors/${companyTaxObj.clientId}?errorTypeIds=159,161`,
				),
		);
		Promise.all(missingTaxAccountIdsByEinPromises)
			.then((responseArray) => {
				if (responseArray.every((response) => response.status === 200)) {
					const missingTaxesArray = flatten(
						responseArray.map((response) => response.data),
					);
					this.setState({
						missingTaxesArray,
						missingTaxesAreLoading: missingTaxesArray.length > 0,
					});
				} else {
					this.setState({
						missingTaxesAreLoading: false,
					});
				}
			})
			.catch(() =>
				this.setState({
					missingTaxesAreLoading: false,
				}),
			);
	};

	_getAllMissingTaxMetadata = () => {
		const { missingTaxesArray } = this.state;

		Ajax.post('/auth/token_sessions')
			.then((response) => {
				if (response?.data?.token) {
					const headers = {
						headers: {
							Authorization: `Bearer ${response.data.token}`,
						},
					};
					const uniqueMissingTaxIds = uniq(
						missingTaxesArray.map((missingTax) => missingTax.relatedTraxId),
					);

					// Get missing tax account metadata
					Ajax.get(
						`https://${
							window.PAYROLLSERVICES_HOST
						}/v2/global/taxes/types?taxTypeIds[]=${uniqueMissingTaxIds.join(
							'&taxTypeIds[]=',
						)}`,
						null,
						headers,
					).then((response) => {
						if (response.status === 200 && response.data.length) {
							this.setState({
								missingTaxesMetadata: response.data,
								missingTaxesAreLoading: false,
							});
						} else {
							this.setState({
								missingTaxesAreLoading: false,
							});
						}
					});
				} else {
					this.setState({
						missingTaxesAreLoading: false,
					});
				}
			})
			.catch(() =>
				this.setState({
					missingTaxesAreLoading: false,
				}),
			);
	};

	_getPropsForAddTaxMessageMicro = () => {
		const { missingTaxesArray, missingTaxesMetadata } = this.state;
		const { companyTaxes } = this.props;
		const isMultiEin = companyTaxes.length > 1;

		if (!missingTaxesArray.length || !missingTaxesMetadata.length) {
			return;
		}

		const propsForAddTaxMessageMicro = missingTaxesArray.map((missingTax) => {
			const { clientName, clientEin } = companyTaxes.find(
				(company) => company.clientId === missingTax.clientId,
			);
			const taxTypeMetadata = missingTaxesMetadata.find(
				(taxMetadata) => taxMetadata.id === parseInt(missingTax.relatedTraxId),
			);

			return {
				clientId: isMultiEin ? missingTax.clientId : undefined,
				clientName: isMultiEin && clientName ? clientName : undefined,
				ein: isMultiEin && clientEin ? clientEin : undefined,
				isTaxSettingsPage: true,
				taxTypes: [
					{
						actions: taxTypeMetadata.actions,
						taxType: taxTypeMetadata.taxType,
						taxTypeId: taxTypeMetadata.id,
						state: taxTypeMetadata.state,
					},
				],
			};
		});

		this.setState({
			addTaxMessageMicroProps: propsForAddTaxMessageMicro,
		});
	};

	_handleTaxesMissingIdsGetFailure = () => {
		this.setState({ taxesMissingIdsAreLoading: false });
		showSlidedown(
			$.__(
				'Uh oh...we were unable to get Tax Accounts that need to be setup. Please try again later.',
			),
			'error',
		);
	};

	_handleActiveFilterSelect = (value) => {
		this._updateFilters(value, 'activeFilter');
	};

	_handleCompanySelect = (value) => {
		this._updateFilters(value, 'clientId');
	};

	_handleSearchFilterChange = (event) => {
		event.persist();

		if (!this.debouncedFn) {
			this.debouncedFn = debounce(() => {
				this._updateFilters(event.target.value, event.target.name);
			}, 750);
		}
		this.debouncedFn();
	};

	_updateFilters = (value, name) => {
		const { filters } = this.state;
		const newFilters = cloneDeep(filters);
		newFilters[name] = value;

		this.setState({
			filters: newFilters,
		});
	};

	componentDidMount() {
		this._getAllTaxesMissingIds();
		this._getCancellationStatusAll();
		this._getAllMissingTaxes();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { missingTaxesArray, missingTaxesMetadata } = this.state;

		if (
			missingTaxesArray !== prevState.missingTaxesArray &&
			missingTaxesArray.length
		) {
			this._getAllMissingTaxMetadata();
		}

		if (
			missingTaxesMetadata !== prevState.missingTaxesMetadata &&
			missingTaxesMetadata.length
		) {
			this._getPropsForAddTaxMessageMicro();
		}
	}

	_getCancellationStatusAll() {
		Ajax.get('/settings/cancellation/status_all').then((response) => {
			if (response.data) {
				this.setState({
					cancellationData: response.data.filter(
						(item) =>
							item.feature === CancellationType.PAYROLL ||
							item.feature === CancellationType.ACCOUNT,
					),
					cancellationPending: response.data.find(
						(item) => item.feature === CancellationType.PAYROLL,
					).pending_cancellation,
				});
			}
		});
	}

	render() {
		const { allowableEndDates, companyTaxes } = this.props;

		const {
			filters,
			taxesMissingIdsAreLoading,
			taxesMissingIdArray,
			clientMetadataArray,
			cancellationData,
			addTaxMessageMicroProps,
			missingTaxesAreLoading,
		} = this.state;
		const isMultiEin = companyTaxes.length > 1;

		const tpauEnabled = isEnabled('TPAU_VIEW_ACCESS');

		const activeFilterOptions = [
			{
				text: tpauEnabled ? 'Set Up & Active' : 'Active',
				value: 'active',
			},
			{
				text: 'All',
				value: 'all',
			},
		];

		const companyFilterOptions = [
			{
				text: 'All Companies/EIN’s',
				value: null,
			},
		];

		for (let i = 0; i < companyTaxes.length; i++) {
			companyFilterOptions.push({
				text: companyTaxes[i].clientName,
				value: companyTaxes[i].clientId,
				clientEin: companyTaxes[i].clientEin,
			});
		}

		if (taxesMissingIdsAreLoading || missingTaxesAreLoading) {
			return (
				<LayoutBox paddingTop="50px">
					<Loader />
				</LayoutBox>
			);
		}
		// These banners that are used in other places, and we don't want to adjust their padding/spacing, so some padding may seem odd here
		const bannerShowing =
			this.state.cancellationPending ||
			taxesMissingIdArray?.length ||
			addTaxMessageMicroProps ||
			true;

		return (
			<Fragment>
				<LayoutBox padding={bannerShowing ? '10px 0 16px 0' : '10px 0 24px 0'}>
					<Flex flexWrap="wrap">
						<Button
							color="secondary"
							href="/settings/payroll/taxes/tax_type"
							size="medium"
							startIcon="circle-plus-solid"
							type="button"
						>
							{$.__('Add Tax')}
						</Button>
					</Flex>
				</LayoutBox>
				{this.state.cancellationPending && (
					<SadToGoBox
						cancellationData={cancellationData}
						isAddon={true}
						updateCancellationData={this._getCancellationStatusAll}
					/>
				)}
				{addTaxMessageMicroProps && (
					<LayoutBox paddingTop="15px">
						<AddTaxMessageMicro
							addTaxMessagePropsArray={addTaxMessageMicroProps}
						/>
					</LayoutBox>
				)}
				{taxesMissingIdArray ? (
					<MissingTaxIdMessages
						clientMetadataArray={clientMetadataArray}
						filters={filters}
						isMultiEin={isMultiEin}
						taxesMissingIdArray={taxesMissingIdArray}
					/>
				) : null}
				<Flex
					direction="row"
					flexWrap="wrap"
					gap={2}
					justifyContent="space-between"
					marginRight="16px"
					paddingTop={bannerShowing ? 1 : 0}
					width="100%"
				>
					<Flex flexBasis="192px">
						<TextField
							InputProps={{
								startAdornment: (
									<Nub position="start">
										<IconV2 name="magnifying-glass-regular" size={12} />
									</Nub>
								),
							}}
							name="searchFilter"
							onChange={this._handleSearchFilterChange}
							placeholder={`${$.__('Search')}...`}
							size="medium"
							width="6"
						/>
					</Flex>
					<Flex flexDirection="row" gap="12px">
						<Flex direction="row" gap="12px">
							<Label htmlFor="show-status">{$.__('Show')}</Label>
							<Select
								id="show-status"
								isClearable={false}
								items={activeFilterOptions}
								menuWidth={4}
								onSelect={this._handleActiveFilterSelect}
								selectedValues={[filters.activeFilter]}
								size="medium"
								width="4"
							/>
						</Flex>
						{isMultiEin && (
							<Flex direction="row" gap="12px">
								<Label htmlFor="filter-ein">{$.__('for')}</Label>
								<Select
									id="filter-ein"
									isClearable={false}
									items={companyFilterOptions}
									onSelect={this._handleCompanySelect}
									renderOptionContent={renderCompanySelectOption}
									renderToggleContent={renderCompanySelectOption}
									selectedValues={[filters.clientId]}
									size="medium"
									width="7"
								/>
							</Flex>
						)}
					</Flex>
				</Flex>
				{tpauEnabled ? (
					<TaxesTable
						allowableEndDates={allowableEndDates}
						companyTaxes={companyTaxes}
						filters={filters}
						tableType="setupTaxes"
					/>
				) : null}
				<TaxesTable
					allowableEndDates={allowableEndDates}
					companyTaxes={companyTaxes}
					filters={filters}
					tableType="completeTaxes"
				/>
			</Fragment>
		);
	}
}
