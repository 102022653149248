import { isString } from 'lodash';

import { InfoCard } from '../info-card';

import {
	BANK_APP_STATE,
	getAppStateFromData,
} from '../../../bank-info.react/utils/shared';
import { COMPANY_APP_STATE, statusCardNeeded } from '../../utils/shared';

import { Flex } from '@bamboohr/fabric';

export function InfoCardManager(props) {
	const {
		statuses,
		company: companyFromProps,
		companies,
		isMultiEin,
		isMultiEinBank,
		plaidLinkToken,
		handleLinkSuccess,
		handleOpenDownloadModal,
		handleOpenUploadModal,
		handleOpenVerificationModal,
		handlePlaidUpdateSuccess,
	} = props;

	if (!isMultiEinBank && (!statuses || !statusCardNeeded(statuses))) {
		return null;
	}

	let contents = [];
	if (companies && isMultiEinBank) {
		contents = createContentsForMultiEinBank();
	} else {
		contents = createContentsForSingleEin();
	}

	function createContentsForSingleEin() {
		const {
			statuses,
			buttonFunction,
			clientId,
			isMultiEin,
			isProcessing,
			onFileDownload,
		} = props;

		const testCases = [
			COMPANY_APP_STATE.VERIFIED,
			COMPANY_APP_STATE.PRE_EIN,
			BANK_APP_STATE.PRE_BANK,
			BANK_APP_STATE.VERIFIED,
		];

		const filteredStatuses = statuses.filter((status) => {
			if (status.state) {
				return !testCases.includes(status.state);
			}

			return !testCases.includes(status);
		});

		return filteredStatuses.map((status, i) => {
			const _company = isString(status) ? companyFromProps : status.company;

			return (
				<InfoCard
					key={i}
					buttonFunction={buttonFunction}
					clientId={clientId}
					company={_company}
					isMultiEin={isMultiEin}
					isProcessing={isProcessing}
					onFileDownload={onFileDownload}
					status={isString(status) ? status : status.state}
					handleLinkSuccess={handleLinkSuccess}
					handleOpenDownloadModal={handleOpenDownloadModal}
					handleOpenUploadModal={handleOpenUploadModal}
					handleOpenVerificationModal={handleOpenVerificationModal}
					handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
					plaidLinkToken={plaidLinkToken}
				/>
			);
		});
	}

	function createContentsForMultiEinBank() {
		const {
			buttonFunction,
			onFileDownload,
			isProcessing,
			handleLinkSuccess,
			handleOpenDownloadModal,
			handleOpenUploadModal,
			handleOpenVerificationModal,
		} = props;

		const results = companies.map((company, companyIndex) => {
			const { bankAccounts } = company;
			if (Array.isArray(bankAccounts) && bankAccounts.length === 0) {
				return [
					<InfoCard
						key={`${companyIndex}`}
						buttonFunction={buttonFunction}
						clientId={company.clientId}
						company={company}
						handleLinkSuccess={handleLinkSuccess}
						handleOpenDownloadModal={handleOpenDownloadModal}
						handleOpenUploadModal={handleOpenUploadModal}
						handleOpenVerificationModal={handleOpenVerificationModal}
						isMultiEin={true}
						isProcessing={isProcessing}
						onFileDownload={onFileDownload}
						status={getAppStateFromData([company])}
						plaidLinkToken={plaidLinkToken}
						handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
					/>,
				];
			}

			return bankAccounts.map((bankAccount, bankIndex) => {
				const tempCompany = [{ bankAccounts: [] }];
				tempCompany[0].companyLegalName = company.companyLegalName;
				tempCompany[0].bankAccounts.push(bankAccount);
				const tempCompanyState = getAppStateFromData(tempCompany);

				return (
					<InfoCard
						key={`${companyIndex}${bankIndex}`}
						buttonFunction={buttonFunction}
						clientId={companies[companyIndex].clientId}
						company={tempCompany}
						handleLinkSuccess={handleLinkSuccess}
						handleOpenDownloadModal={handleOpenDownloadModal}
						handleOpenUploadModal={handleOpenUploadModal}
						handleOpenVerificationModal={handleOpenVerificationModal}
						isMultiEin={true}
						isProcessing={isProcessing}
						onFileDownload={onFileDownload}
						status={tempCompanyState}
						plaidLinkToken={plaidLinkToken}
						handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
					/>
				);
			});
		});

		const filteredResults = results.filter((result) => {
			return result.length && result[0] !== false;
		});

		return filteredResults;
	}

	if (!contents.length) {
		return null;
	}

	return (
		<Flex
			marginTop={2}
			marginBottom={isMultiEin || isMultiEinBank ? 4 : undefined}
			flexDirection="column"
			gap={2}
		>
			{contents}
		</Flex>
	);
}
