import { useState } from 'react';

import { BodyText, Button, Flex, StandardModal } from '@bamboohr/fabric';

import { FileTile } from '../file-tile';

import { companyFiles } from '../../utils/shared';
import { BANK_FILES_ARRAY } from '../../../bank-info.react/utils/shared';

export function DownloadFormsModal(props) {
	const [isProcessing, setIsProcessing] = useState(false);
	const { visible, onClose, type } = props;

	const buildFileTiles = (filesArray) => {
		const { company, onFileDownload } = props;

		return filesArray.map((file) => {
			return (
				<FileTile
					horizontal
					clientId={company.clientId}
					fileName={file.name}
					fileType={file.type}
					key={file.id}
					onFileDownload={onFileDownload}
				/>
			);
		});
	};

	const fileTiles =
		type === 'bank'
			? buildFileTiles(BANK_FILES_ARRAY)
			: buildFileTiles(companyFiles);
	const headerText =
		type === 'bank'
			? $.__('Complete and sign the following bank forms')
			: $.__('Complete and sign the following forms');
	const contentText =
		type === 'bank'
			? $.__('Download and sign this form. Once completed, upload it below.')
			: $.__(
					'Download and sign these %1$s forms. Once completed, upload them below.',
					companyFiles.length,
			  );
	const modalTitle =
		type === 'bank' ? $.__('Required Bank Forms') : $.__('Required Forms');

	const renderModalContents = () => {
		return (
			<Flex flexDirection="column" justifyContent="start" gap={2}>
				<BodyText>{contentText}</BodyText>
				<Flex flexDirection="column" gap={1.5}>
					{fileTiles}
				</Flex>
			</Flex>
		);
	};

	const handleSubmit = () => {
		const { onSubmit } = props;

		setIsProcessing(true);
		onSubmit();
	};

	return (
		<StandardModal
			onRequestClose={onClose}
			isOpen={visible}
			isProcessing={isProcessing}
		>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<Button key="primaryAction" onClick={handleSubmit}>
								{$.__('Done')}
							</Button>,
						]}
					/>
				}
				renderHeader={<StandardModal.Header title={modalTitle} />}
			>
				<StandardModal.StandardHeadline
					icon="building-columns-regular"
					text={headerText}
				/>
				<StandardModal.Constraint>
					{renderModalContents()}
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
