import { Button } from '@bamboohr/fabric';
import { redirect } from 'BambooHR.util';
import { ReactElement } from 'react';

export function HeaderGoogleButton(): ReactElement {
	return (
		<Button
			color="secondary"
			data-bi-id="settings-access-levels-add-employee-btn"
			key="secondaryGoogle"
			onClick={() =>
				redirect(`/settings/permissions/import_users_from_google.php`)
			}
			outline={true}
			size="small"
			startIcon={'circle-plus-regular'}
			text={$.__('Add Google Users')}
			type="button"
		/>
	);
}
