/* eslint-disable max-classes-per-file */
import { render } from 'base/wrapped-render';
import Ctrl, { onPjaxEnd, onReady } from 'ctrl.deco';
import { getJsonData } from 'Data.util';
import { GarnishmentPayments } from './garnishment-payments.react';
import './pay-groups-settings';
import { PayeeSettings } from './payee-settings.react';
import PayrollUpsell from './PayrollUpsell';
import './setup-tour-settings';

import { PoMicroFrontend } from 'micro-frontend.react';
import { BankInfo } from './bank-info.react';
import { CompanyInformation } from './company-information.react';
import { CompanyFormStateComponent } from './company-information.react/states/multi-ein/2-company-form';

import { AddEditBankAccount } from './bank-info.react/states/multi-account/1-add-edit-bank-account';
import { AddTaxRate } from './company-taxes.react/add-tax-rate/add-tax-rate';
import { CompanyTaxesWrapper } from './company-taxes.react/company-taxes-wrapper';
import { UpdateCodesRates } from './company-taxes.react/update-codes-rates';
import { DeductionSettings } from './deductions.react/deduction-settings';
import { PayInfoMigration } from './pay-info-migration.react';
import { ActiveMissingInfo } from './payroll-checklist/active-missing-info/active-missing-info';

import './pay-info-migration.react/migration-tasks-settings.styl';

// Target default payroll settings URL and payees-specific URL
@Ctrl(['/settings/payroll', '/settings/payroll/payees'])
class Payroll {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		// TODO: Remove toggle logic AFTER Non-benefits Deductions is released
		if (
			window.hasNonBenefitDeductionTempFeature ||
			window.hasPayrollSettingsToggle
		) {
			const garnishmentData = getJsonData('#garnishmentData');
			const garnishmentInfoByType = getJsonData('#garnishmentInfoByType');
			const payeeData = getJsonData('#payeeData');
			const statesData = getJsonData('#statesData');
			const allowEditPayee = !(
				window.SESSION_USER.supportAdmin || window.SESSION_USER.auditUserId
			);

			const garnishmentsRoot = document.getElementById('garnishmentsRoot');
			const payeesRoot = document.getElementById('payeesRoot');

			if (
				garnishmentData &&
				garnishmentInfoByType &&
				payeeData &&
				statesData &&
				garnishmentsRoot
			) {
				render(
					<GarnishmentPayments
						{...garnishmentData}
						byType={garnishmentInfoByType}
						payees={payeeData}
						states={statesData}
					/>,
					garnishmentsRoot,
				);
			}

			// Render Payees sub-section
			if (payeeData && statesData && payeesRoot) {
				render(
					<PayeeSettings
						allowEditPayee={allowEditPayee}
						byType={garnishmentInfoByType}
						payeeData={payeeData}
						states={statesData}
					/>,
					payeesRoot,
				);
			}
		}
	}
}

@Ctrl(['/settings/payroll/company_information'])
class CompanyInformationSection {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		const companyInformationRoot = document.getElementById(
			'companyInformationRoot',
		);

		if (companyInformationRoot) {
			render(<CompanyInformation />, companyInformationRoot);
		}
	}
}

@Ctrl(['/settings/payroll/company_information/multi_ein'])
class CompanyInformationMultiSection {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		const contactsData = getJsonData('#contacts');
		const recordId = getJsonData('#recordId');
		const clientId = getJsonData('#clientId');

		const companyInformationMultiRoot = document.getElementById(
			'companyInformationMultiRoot',
		);

		if (companyInformationMultiRoot) {
			render(
				<CompanyFormStateComponent
					clientId={clientId}
					contacts={contactsData}
					recordId={recordId}
				/>,
				companyInformationMultiRoot,
			);
		}
	}
}

@Ctrl(['/settings/payroll/bank_account'])
class BankInfoSection {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		const bankInfoRoot = document.getElementById('bankInfoRoot');
		const plaidData = getJsonData('.js-plaidData');
		const { plaidLinkToken } = plaidData;

		if (bankInfoRoot) {
			render(<BankInfo plaidLinkToken={plaidLinkToken} />, bankInfoRoot);
		}
	}
}

@Ctrl(['/settings/payroll/bank_account/multi_ein/:clientId/:bankId?'])
class AddEditBankAccountCtrl {
	@onReady()
	_readyHandler(e, ctrl) {
		const {
			_params: { clientId, bankId },
		} = ctrl;

		const addEditBankAccountRoot = document.getElementById(
			'addEditBankAccountRoot',
		);
		const { plaidLinkToken } = getJsonData('.js-plaidData');

		if (addEditBankAccountRoot) {
			render(
				<AddEditBankAccount
					bankId={bankId}
					clientId={clientId}
					isEdit={!!bankId}
					plaidLinkToken={plaidLinkToken}
				/>,
				addEditBankAccountRoot,
			);
		}
	}
}

@Ctrl('/settings/payroll/taxes')
class CompanyTaxesCtrl {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		const companyTaxesRoot = document.getElementById('companyTaxesRoot');
		const { companyTaxes, allowableEndDates } =
			getJsonData('#companyTaxesData');

		if (companyTaxesRoot && companyTaxes) {
			render(
				<CompanyTaxesWrapper
					allowableEndDates={allowableEndDates}
					companyTaxes={companyTaxes}
				/>,
				companyTaxesRoot,
			);
		}
	}
}

@Ctrl('/settings/payroll/taxes/tax_type*')
class AddTaxCtrl {
	@onReady()
	_readyHandler() {
		const addEditData = getJsonData('#addEditData');

		const addEditTaxRoot = document.getElementById('manageTaxTypeRoot');

		if (addEditTaxRoot) {
			render(
				<PoMicroFrontend
					addEditData={addEditData}
					isEdit={false}
					payroll={{ payrollServicesHost: window.PAYROLLSERVICES_HOST }}
					route="/settings/taxes/add-edit-taxes"
				/>,
				addEditTaxRoot,
			);
		}
	}
}

@Ctrl('/settings/payroll/taxes/edit_tax*')
class EditTaxCtrl {
	@onReady()
	_readyHandler() {
		const addEditData = getJsonData('#addEditData');

		const addEditTaxRoot = document.getElementById('manageTaxTypeRoot');

		if (addEditTaxRoot) {
			render(
				<PoMicroFrontend
					addEditData={addEditData}
					isEdit={true}
					payroll={{ payrollServicesHost: window.PAYROLLSERVICES_HOST }}
					route="/settings/taxes/add-edit-taxes"
				/>,
				addEditTaxRoot,
			);
		}
	}
}

@Ctrl('/settings/payroll/taxes/tax_type/add_tax_rate*')
class AddTaxRateCtrl {
	@onReady()
	_readyHandler() {
		const addTaxRateData = getJsonData('#addTaxRateData');

		const addTaxRateRoot = document.getElementById('addNewRateForTaxTypeRoot');

		if (addTaxRateRoot) {
			render(<AddTaxRate {...addTaxRateData} />, addTaxRateRoot);
		}
	}
}

@Ctrl('/settings/payroll/request_demo')
class PayrollUpsellCtrl {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		const payrollUpsellRoot = document.querySelector('.js-payrollUpsellRoot');
		const demoRequest = getJsonData('.js-payrollUpsellDemoRequest');

		if (payrollUpsellRoot) {
			return render(
				<PayrollUpsell demoRequest={demoRequest} />,
				payrollUpsellRoot,
			);
		}
	}
}

@Ctrl('/settings/payroll/taxes/tax_type/update_codes_rates*')
class UpdateCodesRatesCtrl {
	@onReady()
	_readyHandler() {
		const updateCodesRatesRoot = document.querySelector(
			'.js-updateCodesRatesRoot',
		);
		const updateCodesRatesData = getJsonData('.js-updateCodesRatesData');

		if (updateCodesRatesRoot && updateCodesRatesData) {
			render(
				<UpdateCodesRates {...updateCodesRatesData} />,
				updateCodesRatesRoot,
			);
		}
	}
}

@Ctrl('/settings/payroll/deductions')
class DeductionSettingsCtrl {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		const deductionSettingsRoot = document.querySelector(
			'.js-deductionSettingsRoot',
		);
		const deductionTypes = getJsonData('.js-deductionTypes');

		if (deductionSettingsRoot && deductionTypes) {
			render(
				<DeductionSettings deductionTypes={deductionTypes} />,
				deductionSettingsRoot,
			);
		}
	}
}

@Ctrl('/settings/payroll/extra_pay')
class ExtraPaySettingsCtrl {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		const extraPayRoot = document.getElementById('extraPayRoot');
		render(
			<PoMicroFrontend
				payroll={{ payrollServicesHost: window.PAYROLLSERVICES_HOST }}
				route="/settings/payroll/extraPay"
			/>,
			extraPayRoot,
		);
	}
}

@Ctrl('/settings/payroll/journal_entry')
class JournalEntrySettingsCtrl {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		const journalEntryRoot = document.getElementById('journalEntryRoot');
		render(
			<PoMicroFrontend
				payroll={{ payrollServicesHost: window.PAYROLLSERVICES_HOST }}
				route="/settings/payroll/journalEntry"
			/>,
			journalEntryRoot,
		);
	}
}

@Ctrl('/settings/payroll/workers_comp')
class WorkersCompSettingsCtrl {
	@onReady()
	@onPjaxEnd()
	_readyHandler() {
		const workersCompRoot = document.getElementById('workersCompRoot');
		render(
			<PoMicroFrontend
				payroll={{ payrollServicesHost: window.PAYROLLSERVICES_HOST }}
				route="/settings/payroll/workersComp"
			/>,
			workersCompRoot,
		);
	}
}

@Ctrl('/settings/payroll/migration_tasks/resolve')
class PayInfoMigrationCtrl {
	@onReady()
	_readyHandler() {
		const payInfoMigrationRoot = document.querySelector(
			'.js-payInfoMigrationRoot',
		);

		if (payInfoMigrationRoot) {
			render(<PayInfoMigration />, payInfoMigrationRoot);
		}
	}
}

// TEMP: Mock data for terminated missing fields epic
const mockData = {
	selectOptions: {
		maritalStatusOptions: [
			{ text: 'Married', value: 'married' },
			{ text: 'Single', value: 'single' },
		],
		jobTitleOptions: [
			{ text: 'CEO', value: 'ceo' },
			{ text: 'Software Engineer', value: 'softwareEngineer' },
		],
		payTypeOptions: [
			{ text: 'Salary', value: 'salary' },
			{ text: 'Hourly', value: 'hourly' },
		],
		payPerOptions: [
			{ text: 'Month', value: 'month' },
			{ text: 'Year', value: 'year' },
		],
		overtimeStatusOptions: [
			{ text: 'Exempt', value: 'exempt' },
			{ text: 'Non-Exempt', value: 'nonExempt' },
		],
		countryOptions: [
			{ text: 'United States', value: 'unitedStates' },
			{ text: 'Canada', value: 'canada' },
		],
	},
};

@Ctrl('/settings/payroll/payroll_checklist/active_employees')
class ActiveEmployeesCtrl {
	@onReady()
	_readyHandler() {
		const activeEmployeesRoot = document.querySelector(
			'.js-activeEmployeesRoot',
		);
		const activeEmployeesData = getJsonData('.js-activeEmployeesData');

		if (activeEmployeesRoot && activeEmployeesData) {
			const { companies, isMultiEin, isCustomerInNewOnboardingExperience } =
				activeEmployeesData;

			render(
				<ActiveMissingInfo
					companies={companies}
					isMultiEin={isMultiEin}
					isNewOnboardingEnabled={isCustomerInNewOnboardingExperience}
				/>,
				activeEmployeesRoot,
			);
		}
	}
}
