import { Fragment } from 'react';
import { BlankState, Button, LayoutBox } from '@bamboohr/fabric';

import { BankInfoHeader } from '../../../components/bank-info-header';

import { COMPANY_INFO_URL } from '../../../utils/shared';

export function BlankStateComponent() {
	const redirectToCompanyInfoPage = () => {
		window.location = COMPANY_INFO_URL;
	};

	const title = $.__("Hold on, something's missing here");
	const subtitle = $.__(
		'You need to complete your company information before setting up a bank account.',
	);
	const buttonText = $.__('Complete Company Info');

	return (
		<Fragment>
			<BankInfoHeader noBorder={true} />
			<LayoutBox marginTop="120px">
				<BlankState
					actions={[
						<Button
							color="secondary"
							key="redirectToCompanyPage"
							onClick={redirectToCompanyInfoPage}
							variant="contained"
						>
							{buttonText}
						</Button>,
					]}
					icon="bs-magnifying-glass"
					subtitle={subtitle}
					title={title}
				/>
			</LayoutBox>
		</Fragment>
	);
}
