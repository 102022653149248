import {
	Button,
	Flex,
	LayoutBox,
	RadioGroup,
	TextButton,
	RoundedToggle,
	Headline,
	Label,
	Divider,
} from '@bamboohr/fabric';
import { isEmpty, isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { usePerformanceSettingsContext } from '../../../../context';
import { useGoalSettingsContext } from '../../context';
import { saveGoalFilters } from '../../services/save-goal-filters';
import { updateEmployeeMatches } from '../../services/update-employee-matches';
import { EmployeesIncluded, FilterListData } from '../../types';
import { EmployeeFilter } from '../employee-filter.react';
import { radioOptions } from './constants';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface Props {
	filterListData: FilterListData;
	selectedGoalGroup: string;
}

export const GoalSettingsWrapper = (props: Props): JSX.Element => {
	const { filterListData, selectedGoalGroup = 'none' } = props;
	const [{
		selectedFilters,
		usingCascadingGoals: savedCascadingGoalsEnabled,
	}, {
		setCancelButtonClicked,
		setSelectedFilters,
		setUsingCascadingGoals
	}] =
		useGoalSettingsContext();
	const [{ performanceTools }, { setSlidedownData }] = usePerformanceSettingsContext();

	const originalSelectedFilters = useRef(selectedFilters);

	const [tempCascadingGoalsEnabled, setTempCascadingGoalsEnabled] = useState<boolean>(savedCascadingGoalsEnabled);
	const [defaultSelectedOption, setDefaultSelectedOption] = useState<string>(selectedGoalGroup);
	const [selectedGroupOption, setSelectedGroupOption] = useState<string>(defaultSelectedOption);
	const [selectedEmployeeCount, setSelectedEmployeeCount] = useState<number>(null);
	const [areButtonsProcessing, setAreButtonsProcessing] = useState<boolean>(false);

	useEffect(() => {
		updateEmployeeMatches(selectedFilters).then((data: EmployeesIncluded) => {
			setSelectedEmployeeCount(data.count);
		});
	}, [selectedFilters]);

	const handleCancelSelect = () => {
		setSelectedGroupOption(defaultSelectedOption);
		setTempCascadingGoalsEnabled(savedCascadingGoalsEnabled);

		if (defaultSelectedOption !== 'some') {
			setSelectedFilters(null);
		} else if (defaultSelectedOption === 'some') {
			setSelectedFilters(originalSelectedFilters.current);
			setCancelButtonClicked(true);
		}
	};

	const onOptionSave = async () => {
		let goalGroupSelected = selectedGroupOption;
		let cascadingGoalsEnabledPayload = tempCascadingGoalsEnabled;
		setAreButtonsProcessing(true);

		if (selectedGroupOption === 'some' && isEmpty(selectedFilters)) {
			goalGroupSelected = 'all';
			setSelectedGroupOption('all');
		} else if (selectedGroupOption !== 'some') {
			setSelectedFilters(null);
		}
		if (selectedGroupOption === 'none') {
			cascadingGoalsEnabledPayload = false;
		}

		await saveGoalFilters(selectedFilters, goalGroupSelected, cascadingGoalsEnabledPayload)
			.then(() => {
				setDefaultSelectedOption(goalGroupSelected);
				setUsingCascadingGoals(cascadingGoalsEnabledPayload);
				setTempCascadingGoalsEnabled(cascadingGoalsEnabledPayload);
				setSlidedownData({
					message: $.__('Goal settings were successfully saved.'),
					type: 'success',
				});
				originalSelectedFilters.current = selectedFilters;
			})
			.catch(() => {
				setSlidedownData({
					message: $.__('Oops something went wrong! Try saving again later.'),
					type: 'error',
				});
			});

		setAreButtonsProcessing(false);
	};

	const shouldSaveButtonDisable = () => {
		const isCascadingGoalsChanged = performanceTools.cascadingGoalsTool ? tempCascadingGoalsEnabled !== savedCascadingGoalsEnabled : false;
		const isDefaultAndSelectedOptionSomeEmployees = defaultSelectedOption === 'some' && selectedGroupOption === 'some';
		const areFiltersChanged = !isEqual(selectedFilters, originalSelectedFilters.current);

		if ((isDefaultAndSelectedOptionSomeEmployees && areFiltersChanged) || isCascadingGoalsChanged) {
			return false;
		}

		return defaultSelectedOption === selectedGroupOption;
	};

	return (
		<>
			<form>
				<LayoutBox marginBottom="21px">
					{ifFeature('CASCADING_GOALS_TOGGLE', (
						<LayoutBox marginBottom={2}>
							<Headline size="extra-small">{$.__('Goal Users')}</Headline>
						</LayoutBox>
					))}
					<RadioGroup
						items={radioOptions}
						label={$.__(
							'Which employees do you want to be able to create and share goals?',
						)}
						onChange={({ value }): void =>
							setSelectedGroupOption(value)
						}
						value={selectedGroupOption}
					/>

					{selectedGroupOption === 'some' && (
						<LayoutBox margin="18px 0 3px">
							<EmployeeFilter
								filterListData={filterListData}
								selectedEmployeeCount={selectedEmployeeCount}
							/>
						</LayoutBox>
					)}
				</LayoutBox>
				{performanceTools.cascadingGoalsTool && (
					<LayoutBox marginBottom={1}>
						<Headline size="extra-small">{$.__("Cascading Goals")}</Headline>
						<Flex gap={2} marginY={2}>
							<RoundedToggle
								biId="goal-settings-cascading-goals-toggle"
								id="cascading-goals-toggle"
								isChecked={selectedGroupOption === 'none' ? false : tempCascadingGoalsEnabled}
								isControlled={true}
								isDisabled={selectedGroupOption === 'none'}
								onChange={({ target }) => {
									setTempCascadingGoalsEnabled(target.checked);
								}}
								size="large"
							/>
							<Label htmlFor='cascading-goals-toggle'>{$.__("Use Cascading Goals in Performance")}</Label>
						</Flex>
						<Divider color="neutral-extra-weak" />
					</LayoutBox>
				)}

				<Flex alignItems="center" gap={1} paddingTop={1}>
					<Button
						data-bi-id="goal-settings-save-settings-button"
						disabled={shouldSaveButtonDisable()}
						onClick={() => onOptionSave()}
						processing={areButtonsProcessing}
						size="medium"
						type="button"
					>
						{$.__('Save')}
					</Button>
					{!shouldSaveButtonDisable() && (
						<TextButton
							data-bi-id="goal-settings-cancel-settings-button"
							onClick={() => handleCancelSelect()}
							processing={areButtonsProcessing}
							size="medium"
							type="button"
						>
							{$.__('Cancel')}
						</TextButton>
					)}
				</Flex>
			</form>
		</>
	);
};
