import { COMPANY_APP_STATE } from '../../utils/shared';
import { BANK_APP_STATE } from '../../../bank-info.react/utils/shared';

import { TextButton } from '@fabric/button';
import { BodyText, Flex, IconV2 } from '@bamboohr/fabric';

import { InternalLink, Message } from '@bamboohr/utils/lib/message';

const commonFlexProps = {
	alignItems: 'center',
	gap: 1,
	marginTop: 4,
	marginBottom: 2,
};

export function StatusRow(props) {
	const renderSavedState = (text) => {
		return (
			<Flex {...commonFlexProps}>
				<IconV2 name="hourglass-solid" color="neutral-medium" size={16} />
				<BodyText color="neutral-medium">{text}</BodyText>
			</Flex>
		);
	};

	const renderVerfiedState = () => {
		return (
			<Flex {...commonFlexProps}>
				<IconV2 name="circle-check-solid" color="success-strong" size={16} />
				<BodyText color="success-strong">{$.__('Verified')}</BodyText>
			</Flex>
		);
	};

	const renderDocsUploadedState = () => {
		return (
			<Flex {...commonFlexProps}>
				<IconV2 name="hourglass-solid" color="neutral-medium" size={16} />

				<BodyText color="neutral-medium">
					<Message
						text={$._('Pending - [Bank Account Setup]')}
						link={InternalLink('/settings/payroll/bank_account')}
					/>
				</BodyText>
			</Flex>
		);
	};

	const renderVerifyingManualModeState = () => {
		return (
			<Flex {...commonFlexProps}>
				<IconV2 name="hourglass-solid" color="neutral-medium" size={16} />
				<BodyText color="neutral-medium">
					{$.__('Pending - Verifying deposit amount')}
				</BodyText>
			</Flex>
		);
	};

	const renderVerifyBankState = () => {
		const doClickAction = () => {
			const { clickAction } = props;

			clickAction(true);
		};

		return (
			<Flex {...commonFlexProps}>
				<IconV2 name="hourglass-solid" color="neutral-medium" size={16} />

				<BodyText color="neutral-medium">
					<Message
						text={$._('Pending - [Verify bank account]')}
						link={(text) => (
							<TextButton onClick={doClickAction} inline={true} type="button">
								{text}
							</TextButton>
						)}
					/>
				</BodyText>
			</Flex>
		);
	};

	const { status } = props;

	switch (status) {
		// COMPANY SECTION
		case COMPANY_APP_STATE.COMPANY_SAVED:
		case COMPANY_APP_STATE.DOCS_DOWNLOADED:
			return renderSavedState(
				$.__('Pending - Sign and upload your company payroll forms'),
			);

		case COMPANY_APP_STATE.DOCS_UPLOADED:
			return renderDocsUploadedState();

		case COMPANY_APP_STATE.VERIFIED:
			return renderVerfiedState();

		// BANK SECTION
		case BANK_APP_STATE.BANK_SAVED:
			return renderSavedState(
				$.__('Pending - Sign and upload your bank account authorization forms'),
			);

		case BANK_APP_STATE.DOCS_UPLOADED:
			return renderVerifyBankState();

		case BANK_APP_STATE.VERIFIED:
			return renderVerfiedState();

		case BANK_APP_STATE.VERIFYING_MANUAL_MODE:
			return renderVerifyingManualModeState();

		default:
			return null;
	}
}
