import { cloneDeep, merge, sortBy } from 'lodash';

import {
	CLOSE_CONFIRM_MODAL,
	CREATE_NEW_CANCEL,
	CREATE_NEW_PENDING,
	DELETE_APPROVAL_ERROR,
	DELETE_APPROVAL_PENDING,
	DELETE_APPROVAL_SUCCESS,
	DELETE_CHILD_PATH_ERROR,
	DELETE_CHILD_PATH_PENDING,
	DELETE_CHILD_PATH_SUCCESS,
	DISABLE_APPROVAL_ERROR,
	DISABLE_APPROVAL_PENDING,
	DISABLE_APPROVAL_SUCCESS,
	ENABLE_APPROVAL_ERROR,
	ENABLE_APPROVAL_PENDING,
	ENABLE_APPROVAL_SUCCESS,
	GET_DATA_ERROR,
	GET_DATA_PENDING,
	GET_DATA_SUCCESS,
	EDIT_WORKFLOW_START,
	EDIT_WORKFLOW_CANCEL,
	FORM_SUBMIT_CONFIRMATION,
	FORM_SUBMIT_ERROR,
	FORM_SUBMIT_PENDING,
	FORM_SUBMIT_SUCCESS,
	PROCESSING_ACTION,
	REMOVE_PATH,
	RESTORE_APPROVAL_ERROR,
	RESTORE_APPROVAL_PENDING,
	RESTORE_APPROVAL_SUCCESS,
	SAVE_PATH,
	SUBNAV_ITEMS_PENDING,
	SUBNAV_ITEMS_SUCCESS,
	UPDATE_EDITING_APPROVERS,
	UPDATE_EDITING_LEVELS,
	UPDATE_EDITING_PATH_OPTIONS
} from './actionTypes';
import {
	deleteApproval,
	deleteApprovalModal,
	deleteApprovalPath,
	disableApprovalModal,
	disableApproval,
	enableApproval,
	getWorkflowsSubNav,
	getWorkflowData,
	restoreApprovalModal,
	restoreApproval,
	submitApproval
} from '../utils';

export const closeConfirmModalAction = () => {
	return {
		type: CLOSE_CONFIRM_MODAL
	};
};

export const createNew = () => {
	return (dispatch) => {
		dispatch({ type: CREATE_NEW_PENDING });
	};
};

export const createNewCancel = () => {
	return (dispatch) => {
		dispatch({ type: CREATE_NEW_CANCEL });
	};
};

export const deleteChildPath = (childId, parentId) => {
	return (dispatch) => {
		dispatch({ type: DELETE_CHILD_PATH_PENDING });
		return deleteApprovalPath(childId).then((data) => {
			if (data.success) {
				dispatch({
					type: DELETE_CHILD_PATH_SUCCESS
				});
				setMessage($.__('Approval path deleted.'), 'success');
				dispatch(getData(parentId));
			} else {
				throw new Error();
			}
		}).catch(() => {
			dispatch({
				type: DELETE_CHILD_PATH_ERROR
			});
			setMessage($.__('Whoops... There was a problem trying to remove this advanced approval path.'), 'error');
		});
	};
};

export const deletePathConfirmation = (id, paths) => {
	return {
		payload: {
			confirmationRequired: true,
			confirmationType: 'delete',
			pathsChanging: paths,
			processing: false,
			workflowId: id
		},
		type: REMOVE_PATH
	};
};

export const editWorkflow = (workflowId, newPathSelectedOptions) => {
	return (dispatch, getState) => {
		if (typeof workflowId === 'undefined') {
			return dispatch({ type: EDIT_WORKFLOW_CANCEL });
		}
		const { pathTypeChildren, workflow } = getState();
		const editingWorkflow = editingWorkflowSetup(pathTypeChildren, workflow, newPathSelectedOptions, workflowId);

		dispatch({
			payload: {
				editState: true,
				editingWorkflow,
				editingWorkflowId: workflowId,
				newPathOpen: false,
				newPathSelectedOptions: newPathSelectedOptions || []
			},
			type: EDIT_WORKFLOW_START
		});
	};
};

export const getData = (workflowId = undefined) => {
	workflowId = Number(workflowId);
	return (dispatch) => {
		if (workflowId === -1) {
			return false;
		}
		dispatch({
			payload: {
				workflowId: workflowId
			},
			type: GET_DATA_PENDING
		});
		return getWorkflowData(workflowId).then((workflowData) => {
			const { workflow } = workflowData;
			if (workflow.name) {
				document.title = `${ workflow.name }`;
			} else {
				document.title = $.__('Approvals');
			}
			dispatch({
				payload: {
					workflowId,
					...workflowData
				},
				type: GET_DATA_SUCCESS
			});
		}).catch((err) => {
			if (err) {
				window.Rollbar.error(
					'WORKFLOWS: Error when retrieving data',
					err
				);
			}
			dispatch({
				payload: workflowId,
				type: GET_DATA_ERROR
			});
		});
	};
};

export const editingWorkflowSetup = (pathTypeChildren, workflow, newPathSelectedOptions, workflowId) => {
	const childWorkflow = cloneDeep(pathTypeChildren.find((pathTypeChild) => {
		return Number(pathTypeChild.id) === workflowId;
	}));
	const {
		id,
		pathType,
		workflowApprovals,
		workflowInitiators,
		workflowPaths
	} = workflow;
	let editingWorkflow = {
		id,
		parentId: id,
		pathType,
		workflowApprovals,
		workflowInitiators,
		workflowPaths
	};

	if (Array.isArray(newPathSelectedOptions) && newPathSelectedOptions.length > 0) {
		editingWorkflow.workflowApprovals = {
			'new_1': {
				approvalBy: 'owner/admin',
				approvalGroupIds: null,
				id: 'new_1',
				position: '1',
				specificPersonUserId: null,
				workflowId: 0
			}
		};
		editingWorkflow.id = 0;
		editingWorkflow.workflowInitiators = ['reports to'];
		editingWorkflow.workflowPaths = newPathSelectedOptions || [];
	} else if (childWorkflow) {
		const {
			id,
			workflowApprovals,
			workflowInitiators,
			workflowPaths
		} = childWorkflow;

		editingWorkflow = merge({}, editingWorkflow, {
			id,
			workflowInitiators,
			workflowPaths
		});

		editingWorkflow.workflowApprovals = workflowApprovals;
	}

	const sortedWorkflowApprovals = sortBy(editingWorkflow.workflowApprovals, 'position');
	editingWorkflow.levels = sortedWorkflowApprovals.map((approval, index) => {
		return {
			approval,
			workflowLevel: index + 1
		};
	});

	editingWorkflow.customApprovers = editingWorkflow.workflowInitiators;

	return editingWorkflow;
};

export const handleDeleteApproval = (workflowId, name) => {
	return (dispatch) => {
		return deleteApprovalModal(workflowId, name).then(({ data }) => {
			if (data.success) { //If successful, show the modal
				let $html = $(data.html);
				const headline = $html.find('.js-deleteCustomApproval__title').remove().text();

				window.BambooHR.Modal.setState({
					dangerousContent: $html.html(),
					headline,
					icon: 'trash-can-regular',
					iconColor: 'danger',
					iconV2Color: 'error-strong',
					primaryAction: () => {
						window.BambooHR.Modal.setState({isProcessing: true}, true);
						dispatch({ type: DELETE_APPROVAL_PENDING });
						deleteApproval(workflowId).then(({ data }) => {
							if (!data.success) {
								window.BambooHR.Modal.setState({isProcessing: false}, true);
								throw new Error(data.message);
							}
							dispatch({
								payload: {
									workflowId: workflowId
								},
								type: DELETE_APPROVAL_SUCCESS
							});
							if (data.message) {
								window.setMessage(data.message, 'success');
							}
							dispatch({
								payload: {
									subNavItems: []
								},
								type: SUBNAV_ITEMS_PENDING
							});
							getWorkflowsSubNav().then(({ data }) => {
								window.history.replaceState({}, '', '/settings/workflows/');
								const {
									customApprovalPerm = false,
									workflows = {}
								} = data;
								dispatch({
									payload: {
										customApprovalEnabled: customApprovalPerm,
										subNavItems: workflows
									},
									type: SUBNAV_ITEMS_SUCCESS
								});
							}).finally(window.BambooHR.Modal.setState({isOpen: false}));
						}).catch(() => {
							dispatch({
								payload: {
									message: $.__('An error occurred'),
									messageType: 'error'
								},
								type: DELETE_APPROVAL_ERROR
							});
						});
					},
					primaryActionText: data.save,
					isOpen: true,
					title: data.title
				});
			} else { //Otherwise show an error message
				dispatch({
					payload: {
						message: data.error,
						messageType: 'error'
					},
					type: DELETE_APPROVAL_ERROR
				});
			}
		}).catch(() => {
			dispatch({
				payload: {
					message: $.__('Whoops... There was a problem trying to remove this advanced approval path.'),
					messageType: 'error'
				},
				type: DELETE_APPROVAL_ERROR
			});
		});
	};
};

export const handleDisableApproval = (workflowId) => {
	return (dispatch) => {
		return disableApprovalModal().then(({ data }) => {
			if (data.success) { //If successful, show the modal
				let $html = $(data.html);

				const headline = $html.find('.js-disableCustomApproval__headline').remove().text();
				window.BambooHR.Modal.setState({
					contentHasPadding: true,
					dangerousContent: $html.html(),
					headline,
					icon: 'triangle-exclamation-regular',
					iconColor: 'attention',
					iconV2Color: 'warning-strong',
					primaryAction: () => {
						dispatch({ type: DISABLE_APPROVAL_PENDING });
						disableApproval(workflowId).then(({ data }) => {
							if (!data.success) {
								throw new Error(data.message);
							}
							dispatch({ type: DISABLE_APPROVAL_SUCCESS });
							dispatch(getData(workflowId));
							window.BambooHR.Modal.setState({isOpen: false});
						}).catch(() => {
							dispatch({
								payload: {
									message: $.__('An error occurred'),
									messageType: 'error'
								},
								type: DISABLE_APPROVAL_ERROR
							});
						});
					},
					primaryActionText: data.save,
					isOpen: true,
					title: data.title
				});
			} else { //Otherwise show an error message
				dispatch({
					payload: {
						message: data.error,
						messageType: 'error'
					},
					type: DISABLE_APPROVAL_ERROR
				});
			}
		}).catch(() => {
			dispatch({
				payload: {
					message: $.__('An error occurred'),
					messageType: 'error'
				},
				type: DISABLE_APPROVAL_ERROR
			});
		});
	};
};

export const handleEnableApproval = (workflowId) => {
	return (dispatch) => {
		dispatch({ type: ENABLE_APPROVAL_PENDING });
		enableApproval(workflowId).then(({ data }) => {
			if (!data.success) {
				throw new Error(data.message);
			}
			dispatch({ type: ENABLE_APPROVAL_SUCCESS });
			dispatch(getData(workflowId));
			window.closeModalDialog();
		}).catch(() => {
			dispatch({
				payload: {
					message: $.__('An error occurred'),
					messageType: 'error'
				},
				type: ENABLE_APPROVAL_ERROR
			});
		});
	};
};

export const handleRestoreApproval = (workflowId, templateName) => {
	return (dispatch) => {
		return restoreApprovalModal().then(({ data }) => {
			if (data.success) { //If successful, show the modal
				let $html = $(data.html);
				const headline = $html.find('.js-restoreCustomApproval__headline').remove().text();

				window.BambooHR.Modal.setState({
					headline,
					contentHasPadding: true,
					dangerousContent: $html.html(),
					icon: 'triangle-exclamation-regular',
					iconV2Color: 'warning-strong',
					primaryAction: () => {
						dispatch({ type: RESTORE_APPROVAL_PENDING });
						restoreApproval(templateName).then(({ data }) => {
							if (!data.success) {
								throw new Error(data.message);
							}
							dispatch({ type: RESTORE_APPROVAL_SUCCESS });
							dispatch(getData(workflowId));
							window.BambooHR.Modal.setState({isOpen: false});
						}).catch(() => {
							dispatch({
								payload: {
									message: $.__('An error occurred'),
									messageType: 'error'
								},
								type: RESTORE_APPROVAL_ERROR
							});
						});
					},
					primaryActionText: data.save,
					isOpen: true,
					title: data.title
				});
			} else { //Otherwise show an error message
				dispatch({
					payload: {
						message: data.error,
						messageType: 'error'
					},
					type: RESTORE_APPROVAL_ERROR
				});
			}
		}).catch(() => {
			dispatch({
				payload: {
					message: $.__('An error occurred'),
					messageType: 'error'
				},
				type: RESTORE_APPROVAL_ERROR
			});
		});
	};
};

export const processingBegin = () => {
	return {
		payload: {
			processing: true
		},
		type: PROCESSING_ACTION
	};
};

export const processingEnd = () => {
	return {
		payload: {
			processing: false
		},
		type: PROCESSING_ACTION
	};
};

export const savePathConfirmation = (paths) => {
	return {
		payload: {
			confirmationRequired: true,
			confirmationType: 'override',
			pathsChanging: paths,
			processing: false
		},
		type: SAVE_PATH
	};
};

/**
 * Serializes the form data for sending to the back end. returns false if data is missing otherwise returns an array
 * @param editingWorkflow
 * @param override
 * @returns [] | false
 */
export const serializeFormData = (editingWorkflow, override) => {
	const {
		customApprovers = [],
		levels,
		id: workflowId,
		parentId,
		workflowPaths = []
	} = editingWorkflow;

	if (!isNaN(parseInt(workflowId)) && parentId && Array.isArray(levels) && levels.length > 0) {
		const startingData = [
			'form_action=Save',
			`override=${ override ? true : '' }`,
			`parentWorkflowId=${ parentId }`,
			`workflow_id=${ workflowId }`
		];

		customApprovers.forEach((customApprover) => {
			startingData.push(`customRoles[]=${ encodeURIComponent(customApprover) }`);
		});

		workflowPaths.forEach((selectedPathId) => {
			startingData.push(`pathId[]=${ encodeURIComponent(selectedPathId) }`);
		});

		return levels.reduce((serializingData, level) => {
			const {
				workflowLevel
			} = level;

			if (!level.approval) {
				return serializingData;
			}

			const {
				approvalBy,
				approvalGroupIds,
				specificPersonUserId
			} = level.approval;

			serializingData.push(`approval_by[${ workflowLevel }]=${ encodeURIComponent(approvalBy) }`);
			serializingData.push(`specific_person[${ workflowLevel }]=${ specificPersonUserId || '' }`);
			if (approvalGroupIds) {
				approvalGroupIds.forEach((approvalGroupId) => {
					serializingData.push(`approval_groups[${ workflowLevel }][]=${ approvalGroupId }`);
				});
			} else {
				serializingData.push(`approval_groups[${ workflowLevel }][]=`);
			}

			return serializingData;
		}, startingData);
	}

	return false;
};

export const submitPathForm = (override) => {
	return (dispatch, getState) => {
		processingBegin();
		const {
			editingWorkflow
		} =	getState();
		dispatch({ type: FORM_SUBMIT_PENDING });
		const {
			id: workflowId,
			parentId
		} = editingWorkflow;

		const serializedData = serializeFormData(editingWorkflow, override);

		if (serializedData) {
			submitApproval(workflowId, serializedData.join('&')).then(({ data }) => {
				if (!data.success) {
					if (data.errorType === 'conflict') {
						let paths = data.error.split(',');
						let parsedPaths = [];
						paths.map((path) => {
							parsedPaths.push(parseInt(path));
						});
						dispatch({ type: FORM_SUBMIT_CONFIRMATION });
						dispatch(savePathConfirmation(parsedPaths));
						return false;
					}
					window.setMessage(data.message, 'error');
					throw new Error(data.message);
				}
				dispatch({
					type: FORM_SUBMIT_SUCCESS
				});
				setMessage(data.message, 'success');
				dispatch(getData(parentId));
			});
		} else {
			dispatch({
				type: FORM_SUBMIT_ERROR
			});
			window.setMessage($.__('Workflow settings update encountered an error.', 'error'));
		}

		dispatch(processingEnd());

	};
};

export const updateEditingWorkflowCustomApprovers = (approvers) => {
	return ({
		payload: {
			customApprovers: approvers
		},
		type: UPDATE_EDITING_APPROVERS
	});
};

export const updateEditingWorkflowLevels = (levels) => {
	return {
		payload: {
			levels: levels
		},
		type: UPDATE_EDITING_LEVELS
	};
};

export const updateSelectedEditingPath = (paths) => {
	return {
		payload: {
			selectedPaths: paths
		},
		type: UPDATE_EDITING_PATH_OPTIONS
	};
};
