import {
	CriticalIllnessCategoryDataOptionsContract,
	PlanCategoryDataOptionsContract,
	SupplementalPlanType,
} from '../plans.react/types';
import { PlanTypeEnumContract } from '../types';

const performTypeAction = <FlagType extends string, ReturnType>(
	flag: string | null | undefined,
	actions: { [key in FlagType]?: () => ReturnType },
	defaultCase: () => ReturnType,
): ReturnType => {
	if (!flag) {
		return defaultCase();
	}
	const action = actions[flag as FlagType] ?? defaultCase;
	return action();
};

export const getIsCriticalIllnessPlanThatSupportsCoverageAmounts =
	(benefitPlan: {
		benefitType: PlanTypeEnumContract;
		planCategoryDataOptions: PlanCategoryDataOptionsContract | null;
	}): boolean => {
		const defaultCase = () => false;

		return performTypeAction<PlanTypeEnumContract, boolean>(
			benefitPlan.benefitType,
			{
				[PlanTypeEnumContract.supplemental]: () =>
					performTypeAction<SupplementalPlanType, boolean>(
						benefitPlan.planCategoryDataOptions?.typeId ?? null,
						{
							[SupplementalPlanType.CriticalIllness]: () => {
								const criticalIllnessOptions =
									benefitPlan.planCategoryDataOptions as CriticalIllnessCategoryDataOptionsContract | null;
								return Boolean(criticalIllnessOptions?.employeeCoverageAmount); // older versions of critical illness plans do not have coverage amounts
							},
						},
						defaultCase,
					),
			},
			defaultCase,
		);
	}
