import {
	BlankState,
	Button,
	Flex,
	Headline,
	LayoutBox
} from '@bamboohr/fabric';


import {
	CompanyTaxes,
} from './company-taxes';


import {
	CompanyTaxSettings,
} from './components/company-tax-settings/company-tax-settings';

import './company-taxes-wrapper.styl';

export function CompanyTaxesWrapper(props) {
	const {
		companyTaxes,
	} = props;
	const shouldRenderCompanyTaxSettings = Array.isArray(companyTaxes) && companyTaxes.length > 0;
	return (
		<div className="CompanyTaxesWrapper">
			<Flex alignItems="center" direction="row" justifyContent="space-between" marginBottom="14px" marginTop="10px">
				<Headline color="primary" size="small">{$.__('Taxes') }</Headline>
				{ shouldRenderCompanyTaxSettings ? (
					<CompanyTaxSettings
						companyTaxes={ companyTaxes }
					/>
				) : null }
			</Flex>
			{ pageContent(props) }
		</div>
	);
}

function pageContent(props) {
	const {
		companyTaxes,
	} = props;

	if (!companyTaxes || companyTaxes.length === 0) {
		return (
			<LayoutBox marginTop={3}>
				<BlankState
					icon='building-circle-exclamation-regular'
					subtitle={ $.__('You need to complete your company information before setting up taxes.') }
					title={ $.__(`Hold on, something’s missing here.`) }
				>
					<Button
						color="secondary"
						href="/settings/payroll/company_information"
						size="medium"
					>
						{ $.__('Complete Company Info') }
					</Button>
				</BlankState>
			</LayoutBox>
		);
	}

	const clientIncludedTaxesArray = [];

	companyTaxes.forEach((company) => {
		if (company.clientIncludedTaxes) {
			company.clientIncludedTaxes.forEach((clientIncludedTax) => {
				clientIncludedTaxesArray.push(clientIncludedTax);
			});
		}
	});

	if (clientIncludedTaxesArray.length === 0) {
		return  (
			<LayoutBox marginTop="24px">
				<BlankState
					icon="folder-open-regular"
					subtitle={ $.__('This is were you can manage your company’s payroll taxes and rates.') }
					title={ $.__('No payroll taxes have been added.') }
				>
					<Button
						color="secondary"
						href="/settings/payroll/taxes/tax_type"
						size="medium"
					>
						{ $.__('Add a Tax') }
					</Button>
				</BlankState>
			</LayoutBox>
		);
	}

	return (
		<CompanyTaxes { ...props } />
	);
}
