import { BlankState, LayoutBox } from '@bamboohr/fabric';

export function ProjectTrackingBlankState({ addProjectBtn }) {
	return(
		<LayoutBox paddingTop={6}>
			<BlankState
				actions={[addProjectBtn]}
				icon="bs-clipboard"
				subtitle={$.__('Let your people track their work on projects and tasks.')}
				title={$.__('Need to get specific?')}
			/>
		</LayoutBox>
	);
}
