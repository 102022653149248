import {
	useMemo,
} from 'react';

import { IconV2 } from '@bamboohr/fabric';

import {
	useModalData,
} from '../state';
import {
	bem,
} from '../util';

const MAX_USERS = 5;

export default function UserList() {
	const {
		users,
	} = useModalData();

	const listedUsers = useMemo(() => (
		users.slice(0, MAX_USERS)
	), [users]);
	const more = useMemo(() => (
		users.slice(MAX_USERS)
	), [users]);

	return (
		<ul className={ bem('headerList') }>
			{ listedUsers.map((user, i) => (
				<User
					key={ user.id }
					more={ (
						more.length > 0 &&
						i + 1 === MAX_USERS
					) ? more : false }
					{ ...user }
				/>
			)) }
		</ul>
	);
}

/**
 *
 * @param {import('../state').User & { more?: import('../state').User[] }} props
 */
function User({
	name,
	more,
}) {
	return (
        (<li className={ bem('headerListItem') }>
            <span className={ bem('headerListItemIco') }>
				{<IconV2 color='primary-strong' name='user-solid' size={12}/>}
			</span>
            <span className={ bem('headerListItemText') }>
				{ more ? (
					$.__(`%1$s and %2$s more...`, name, more.length)
				) : name }
			</span>
        </li>)
    );
}
