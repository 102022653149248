import { Flex, LayoutBox, NameValuePair } from '@bamboohr/fabric';
import './tax-type-details.styl';

export function TaxTypeDetails(props) {
	const { details } = props;
	const detailSections = details.map((detail) => {
		return <NameValuePair key={ detail.header } name={ detail.header } value={ detail.value }/>
	});
	return <LayoutBox paddingTop={1}>
		<Flex flexDirection="row" gap={5}>
			{ detailSections }
		</Flex>
	</LayoutBox>
}
