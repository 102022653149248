import {
	Fragment,
} from 'react';

import {
	CompanyTaxSettingsDropdown,
} from './company-tax-settings-dropdown';
import {
	useStyles,
} from './company-tax-settings.styles';
import type {
	CompanyTaxDataCollection,
} from './types';

interface CompanyTaxSettingsProps {
	companyTaxes: CompanyTaxDataCollection;
}

export function CompanyTaxSettings(props: CompanyTaxSettingsProps) {
	const classes = useStyles();
	const {
		companyTaxes,
	} = props;
	return (
		<Fragment>
			<div className={ classes.spacer }></div>
			<div className={ classes.actions }>
				<CompanyTaxSettingsDropdown
					companyTaxes={ companyTaxes }
				/>
			</div>
		</Fragment>
	);
}
