import { LayoutBox } from '@bamboohr/fabric';
import { isEnabled } from '@bamboohr/utils/lib/feature';
import { HighestPriorityMissingTaxIdMessageMicro } from '../highest-priority-missing-tax-id-message-micro';
import { MissingTaxIdMessageMicro } from '../missing-tax-id-message-micro';
import { CompanyTaxObject } from '../missing-tax-id-message-micro/missing-tax-id-message-micro';

interface MissingTaxIdMessagesProps {
	taxesMissingIdArray: Array<Array<CompanyTaxObject>>;
	filters: {
		clientId: null | number;
	};
	isMultiEin: boolean;
	clientMetadataArray:
		| Array<{ id: number; masterTaxStartDate?: string | null }>
		| undefined;
}

export function MissingTaxIdMessages(props: MissingTaxIdMessagesProps) {
	const { taxesMissingIdArray, filters, isMultiEin, clientMetadataArray } =
		props;
	const tpauEnabled = isEnabled('TPAU_VIEW_ACCESS');

	if (tpauEnabled) {
		return <LayoutBox
			marginBottom={2}
			paddingTop={1}
		>
			<HighestPriorityMissingTaxIdMessageMicro
				showSetupNow={false}
			/>
		</LayoutBox>
	}

	return (
		<>
			{taxesMissingIdArray.map((taxesMissingIds) => {
				if (!taxesMissingIds.length) {
					return null;
				}

				const currentClientId = taxesMissingIds[0].clientId;

				if (
					filters.clientId === null ||
					filters.clientId === currentClientId
				) {
					return <LayoutBox
						key={currentClientId.toString()}
						marginBottom={2}
						paddingTop={1}
					>
						<MissingTaxIdMessageMicro
							isMultiEin={isMultiEin}
							masterTaxStartDate={
								clientMetadataArray?.find(
									(clientMetadataObj) => clientMetadataObj.id === currentClientId,
								)?.masterTaxStartDate
							}
							showSetupNow={false}
							taxes={taxesMissingIds}
						/>
					</LayoutBox>
				}
				return null;
			})}
		</>
	);
}
