import { BodyText, LayoutBox, Link } from '@bamboohr/fabric';
import { getFormattedDate } from '../../utils/benefit-plan-service';

interface PlanNameProps {
	biId?: string;
	planId: number;
	planName: string;
	scheduledChanges: string;
}

export function PlanName(props: PlanNameProps): JSX.Element {
	const { biId, planId, planName, scheduledChanges } = props;

	return (
		<>
			<Link
				data-bi-id={biId}
				href={`/settings/benefits/plan_wizard/plan/${planId}`}
			>
				{planName}
			</Link>
			{scheduledChanges && (
				<BodyText color="neutral-weak" size="small">
					{$.__('Changes scheduled:')}
					<LayoutBox>{getFormattedDate(scheduledChanges)}</LayoutBox>
				</BodyText>
			)}
		</>
	);
}
