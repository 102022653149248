import { Dropdown, Flex, Section } from '@bamboohr/fabric';
import React from 'react';
import { downloadCsvFile } from '../../helpers';
import { getInvoicesExport } from '../../services';
import {
	BlankStateError,
	InvoiceDateRangePicker,
	InvoiceHistoryTable,
	InvoiceHistoryLoader,
} from '..';

export function InvoiceHistoryBody({
	billingCurrency,
	isLoading,
	isError,
	onRowSelect,
	selectedInvoices,
	rows,
	...props
}) {
	const hasSelectedInvoices = selectedInvoices.length > 0;

	const handleExportSelect = () => {
		const invoiceIds = selectedInvoices.map((inv) => inv.id);

		getInvoicesExport(invoiceIds)
			.then(({data}) => downloadCsvFile(data, selectedInvoices))
			.catch((error) => {
				window.Rollbar.error('POSH: Unable to retrieve invoice data', {
					error,
					invoiceIds,
				});
				window.setMessage(
					'Oops! Looks like the file export failed. Please try again.',
					'error',
				);
			});
	};

	return (
		<Section height="100%">
			{!isLoading && !isError && (
				<>
					<Flex alignItems="flex-end" marginBottom={4}>
						<InvoiceDateRangePicker {...props} />
						<Dropdown
							ButtonProps={{
								outline: true,
								secondary: true,
								title: $.__('Export'),
							}}
							biId="settings-account-billing-invoice-history-export"
							isDisabled={!hasSelectedInvoices}
							items={[
								{
									items: [
										{ key: 'csv', text: $.__('CSV Spreadsheet'), value: 'csv' },
									],
									key: 'export',
									text: $.__('Export as...'),
									type: 'group',
								},
							]}
							onSelect={handleExportSelect}
						>
							{$.__('Export')}
						</Dropdown>
					</Flex>
					<InvoiceHistoryTable
						billingCurrency={billingCurrency}
						onRowSelect={onRowSelect}
						rows={rows}
					/>
				</>
			)}
			{isLoading && <InvoiceHistoryLoader />}
			{isError && <BlankStateError />}
		</Section>
	);
}
