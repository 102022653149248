import { IconTile, LayoutBox } from '@bamboohr/fabric';
import {
	ExternalLink,
	Message,
} from '@bamboohr/utils/lib/message';

import {
	supportHelpLink,
} from '../constants';
import {
	L_MODAL_FOOTER_MESSAGE_LINK_TEXT,
} from '../language';
import {
	useStyles,
} from './modal-footer-message-link.styles';

export function ModalFooterMessageLink() {
	const classes = useStyles();
	return (
		<div className={ classes.root }>
			<LayoutBox marginRight={ 2 }>
				<IconTile icon="circle-info-regular" size={ 44 } variant="muted" />
			</LayoutBox>
			<div className={ classes.message }>
				<Message
					// eslint-disable-next-line new-cap
					link={ ExternalLink(supportHelpLink) }
					text={ L_MODAL_FOOTER_MESSAGE_LINK_TEXT }
				/>
			</div>
		</div>
	);
}
