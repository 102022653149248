import { Component } from 'react';

import { LayoutBox, Loader } from '@bamboohr/fabric';
import Ajax from '@utils/ajax';

import {
	BANK_APP_STATE,
	BANK_SUBMISSION_CREATE_MESSAGE,
	DEFAULT_ERROR_MESSAGE,
	getAppStateFromData,
	getBankInformation,
	getComponentForState,
	getUpdateMessage,
	handlePlaidUpdateSuccess,
	nullBankAccount,
	postCompanyBankData,
	putCompanyBankData,
	putCompanyBankNickname,
	showPlaidFailedMessage,
} from './utils/shared';

import {
	postUploadFiles,
	scrollToTopOfPage,
} from '../company-information.react/utils/shared';

export class BankInfo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isProcessing: false,
			bypassPlaid: false,
			companies: [],
		};
	}

	renderLoading = () => {
		return (
			<LayoutBox margin="0 auto" paddingTop="300px">
				<Loader />
			</LayoutBox>
		);
	};

	renderContents = (StateToRender, props) => {
		return (
			//Classname important for move away listeners
			<div className="BankInfoContainer">
				<StateToRender {...props} />
			</div>
		);
	};

	getPropsFromData = (currentState, state) => {
		const { plaidLinkToken } = this.props;

		switch (currentState) {
			case BANK_APP_STATE.BLANK:
				return {};

			case BANK_APP_STATE.LINK_PLAID_SINGLE:
				return {
					plaidLinkToken,
					handleLinkSuccess: this.refreshData,
					clientId: state.companies[0].clientId,
					toggleBankInfoLoading: this._toggleLoadingState,
					onAddManually: this._handleAddManuallyClick,
				};

			case BANK_APP_STATE.PRE_BANK:
				return {
					company: state.companies[0],
					isProcessing: state.isProcessing,
					onInputChange: this._handleInputChange,
					onSubmit: this._handlePostSaveBank,
					plaidLinkToken,
					handlePlaidUpdateSuccess: this._handlePlaidUpdateSingle,
				};

			case BANK_APP_STATE.BANK_SAVED:
				return {
					company: state.companies[0],
					isProcessing: state.isProcessing,
					onUploadForms: this._handlePostFileUploads,
					onInputChange: this._handleInputChange,
					onSubmit: this._handlePutUpdateBank,
					onFileDownload: this._handleFileDownload,
					onVerificationModalSubmit: this._handleVerificationModalSubmit,
					plaidLinkToken,
					handlePlaidUpdateSuccess: this._handlePlaidUpdateSingle,
				};

			case BANK_APP_STATE.DOCS_DOWNLOADED:
				return {
					company: state.companies[0],
					isProcessing: state.isProcessing,
					onInputChange: this._handleInputChange,
					onUploadForms: this._handlePostFileUploads,
					onSubmit: this._handlePutUpdateBank,
					plaidLinkToken,
					handlePlaidUpdateSuccess: this._handlePlaidUpdateSingle,
				};

			case BANK_APP_STATE.DOCS_UPLOADED:
				return {
					company: state.companies[0],
					isProcessing: state.isProcessing,
					onInputChange: this._handleInputChange,
					onSubmit: this._handlePutUpdateBank,
					onVerificationModalSubmit: this._handleVerificationModalSubmit,
					plaidLinkToken,
					handlePlaidUpdateSuccess: this._handlePlaidUpdateSingle,
				};

			case BANK_APP_STATE.VERIFIED:
				return {
					company: state.companies[0],
					isProcessing: state.isProcessing,
					onInputChange: this._handleInputChange,
					onSubmit: this._handlePutUpdateBank,
					plaidLinkToken,
					handlePlaidUpdateSuccess: this._handlePlaidUpdateSingle,
				};

			case BANK_APP_STATE.MULTI_BANK_ACCOUNTS:
				return {
					company: state.companies[0],
					isProcessing: state.isProcessing,
					onInputChange: this._handleInputChange,
					onFileDownload: this._handleFileDownload,
					onUploadForms: this._handlePostFileUploads,
					onVerificationModalSubmit: this._handleVerificationModalSubmit,
					plaidLinkToken,
					handlePlaidUpdateSuccess: this._handlePlaidUpdateSingle,
				};

			case BANK_APP_STATE.MULTI_COMPANIES:
				return {
					companies: state.companies,
					isProcessing: state.isProcessing,
					onInputChange: this._handleInputChange,
					onFileDownload: this._handleMultiEinFileDownload,
					onUploadForms: this._handlePostFileUploads,
					onVerificationModalSubmit: this._handleVerificationModalSubmit,
					plaidLinkToken,
					handleLinkSuccess: this._handleAddPlaidAccount,
					handlePlaidUpdateSuccess: this._handlePlaidUpdateSingle,
				};

			default:
				break;
		}
	};

	refreshData = () => {
		getBankInformation()
			.then((res) => {
				const { data } = res;
				this.setState({
					isLoading: false,
					isProcessing: false,

					companies: data.companies,
				});
			})
			.catch((error) => {
				window.setMessage(DEFAULT_ERROR_MESSAGE, 'error');
			});
	};

	_toggleLoadingState = (isLoading) => this.setState({ isLoading });

	_handleAddPlaidAccount = (token, metadata, clientId) => {
		this.setState({ isLoading: true }, () => {
			const { account_id } = metadata;
			Ajax.post('/settings/payroll/bank_account/plaid', {
				clientId,
				plaidToken: token,
				plaidAccountId: account_id,
			})
				.then((response) => {
					if (response.status === 200) {
						this.refreshData();
						window.setMessage(BANK_SUBMISSION_CREATE_MESSAGE, 'success');
					} else {
						showPlaidFailedMessage();
						this.setState({ isLoading: false });
					}
				})
				.catch(() => {
					showPlaidFailedMessage();
					this.setState({ isLoading: false });
				});
		});
	};

	_handlePlaidUpdateSingle = (
		token,
		metadata,
		optionalBankId,
		optionalClientId,
	) => {
		const { clientId, bankAccounts } = this.state.companies[0];
		const { bankId, isLegacy, isVerified } = bankAccounts[0];
		this.setState(
			{
				isProcessing: true,
				isLoading: true,
			},
			() =>
				handlePlaidUpdateSuccess(
					token,
					metadata,
					{
						clientId: optionalClientId || clientId,
						bankId: optionalBankId || bankId,
					},
					() => {
						this.refreshData();
						window.setMessage(
							getUpdateMessage(isLegacy || isVerified),
							'success',
						);
					},
				).finally(() => {
					scrollToTopOfPage();
				}),
		);
	};

	_handleFileDownload = (fileType) => {
		this.setState(
			{
				isProcessing: true,
			},
			() => {
				setTimeout(() => {
					this.setState((prevState) => {
						const fileName = `form${
							fileType.charAt(0).toUpperCase() + fileType.slice(1)
						}`;

						// TODO -- THIS NEEDS TO SUPPORT MULTI BANKS AND EINS
						prevState.companies[0].bankAccounts[0][fileName].fileStatus =
							'downloaded';
						prevState.isProcessing = false;

						return prevState;
					});
				}, 600);
			},
		);
	};

	_handleVerificationModalSubmit = () => {
		this.setState(
			{
				isProcessing: true,
				isLoading: true,
			},
			() => {
				this.refreshData();
			},
		);
	};

	_handleInputChange = (ein = null, propertyName, newPropertyValue) => {
		const { companies } = this.state;

		this.setState((prevState) => {
			companies.forEach((company, index) => {
				if (companies[index].ein === ein) {
					if (prevState.companies[index].bankAccounts.length === 0) {
						prevState.companies[index].bankAccounts.push(nullBankAccount);
					}

					if (prevState.companies[index].bankAccounts.length === 1) {
						prevState.companies[index].bankAccounts[0][propertyName] =
							newPropertyValue;
					}
				}
			});

			return prevState;
		});
	};

	_handlePostSaveBank = (clientId, data) => {
		this.setState(
			{
				isProcessing: true,
				isLoading: true,
			},
			() => {
				postCompanyBankData(clientId, data)
					.then((res) => {
						this.refreshData();
						window.setMessage(
							'Success! Please download and complete the bank account authorization forms below.',
							'success',
						);
						window.hasFormChanges = false;
						scrollToTopOfPage();
					})
					.catch((error) => {
						window.setMessage(DEFAULT_ERROR_MESSAGE, 'error');
						scrollToTopOfPage();
					});
			},
		);
	};

	_handlePutUpdateBank = (clientId, data, isAccountEditDisabled) => {
		const updateBankAccount = isAccountEditDisabled
			? putCompanyBankNickname
			: putCompanyBankData;
		this.setState(
			{
				isProcessing: true,
				isLoading: true,
			},
			() => {
				updateBankAccount(clientId, data)
					.then(() => {
						this.refreshData();
						window.setMessage(
							getUpdateMessage(
								isAccountEditDisabled || data.isLegacy || data.isVerified,
							),
							'success',
						);
						window.hasFormChanges = false;
						scrollToTopOfPage();
					})
					.catch((error) => {
						window.setMessage(DEFAULT_ERROR_MESSAGE, 'error');
						scrollToTopOfPage();
					});
			},
		);
	};

	_handleMultiEinFileDownload = () => {
		this.setState(
			{
				isProcessing: true,
			},
			() => {
				setTimeout(() => {
					this.setState({
						isProcessing: false,
					});
				}, 600);
			},
		);
	};

	_handlePostFileUploads = (clientId, uploadedFiles) => {
		this.setState(
			{
				isProcessing: true,
				isLoading: true,
			},
			() => {
				postUploadFiles(clientId, uploadedFiles)
					.then((res) => {
						this.refreshData();
						window.setMessage(
							"Forms uploaded successfully! Almost there, just add your company's payroll bank account information",
							'success',
						);
						scrollToTopOfPage();
					})
					.catch((error) => {
						window.setMessage(DEFAULT_ERROR_MESSAGE, 'error');
						scrollToTopOfPage();
					});
			},
		);
	};

	_handleAddManuallyClick = () => this.setState({ bypassPlaid: true });

	componentDidMount() {
		this.refreshData();
	}

	render() {
		const { isLoading, companies, bypassPlaid } = this.state;

		if (isLoading) {
			return this.renderLoading();
		}

		const currentState = getAppStateFromData(companies, bypassPlaid);
		const StateToRender = getComponentForState(currentState);
		const propsForComponent = this.getPropsFromData(currentState, this.state);

		return this.renderContents(StateToRender, propsForComponent);
	}
}
