import {
	useState,
	useEffect,
	useCallback,
	useContext,
	createRef,
	ReactElement,
} from 'react';
import { isEqual, cloneDeep } from 'lodash';
import Ajax from '@utils/ajax';
import {
	Button,
	StandardModal,
	TextButton,
	BadgeV2,
	BodyText,
	Flex,
} from '@bamboohr/fabric';
import { showSlidedown } from 'Alerts.mod';
import { EmployeeInfo } from './employee-info';
import { CompensationInfo } from './compensation-info';
import { TaxWithholdingInfo } from './tax-withholding-info';
import { DirectDepositInfo } from './direct-deposit-info';
import {
	formatEmployeeFieldDataFromGet,
	formatSaveData,
	saveEmployeeData,
} from '../utils';
import { ActiveMissingInfoContext } from '../context/active-missing-info-provider';

import {
	UpdateMissingInfoBtnProps,
	EmployeeFieldData,
	GetEmployeeFieldDataResponse,
	EmployeeSelectField,
	ActiveMissingInfoContextData,
} from '../types';
import { SectionDivider } from './section-divider';
import { JobInfo } from './job-info';

const INITIAL_FIELD_DATA: EmployeeFieldData = {
	fields: {},
	missingFields: {},
	missingEmployeeInfo: 0,
	missingTaxInfo: 0,
};

export function UpdateMissingInfoBtn(
	props: UpdateMissingInfoBtnProps,
): ReactElement {
	const [missingInfoText, setMissingInfoText] = useState<string>('');
	const [buttonProcessing, setButtonProcessing] = useState<boolean>(false);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [modalProcessing, setModalProcessing] = useState<boolean>(false);
	const [employeeData, setEmployeeData] =
		useState<EmployeeFieldData>(INITIAL_FIELD_DATA);
	const [isContractor, setIsContractor] = useState<boolean>(false);
	const { updateTableData, stillMissingTaxInfo } = useContext(
		ActiveMissingInfoContext,
	) as ActiveMissingInfoContextData;
	const taxDataForm = createRef<HTMLFormElement>();
	const {
		employeeId,
		firstName,
		lastName,
		jobTitle,
		totalMissingFields,
		missingDirectDeposit,
	} = props;
	const {
		missingEmployeeInfo,
		missingTaxInfo,
		fields,
		fields: { jobLocation, stateId, isPaidByCheck },
		missingFields,
		missingFields: { jobLocation: jobLocationMissing, stateId: stateIdMissing },
	} = employeeData;

	useEffect(() => {
		setMissingInfoText(
			getMissingInfoText(totalMissingFields, missingDirectDeposit, firstName),
		);
	}, [totalMissingFields, missingDirectDeposit, firstName]);

	const handleGetDataFailure = useCallback(() => {
		failedToGetMsg();
		setButtonProcessing(false);
	}, []);

	const handleModalClose = useCallback(() => {
		setModalOpen(false);
	}, []);

	const updateEmployeeData = useCallback(() => {
		setButtonProcessing(true);
		Ajax.get(
			`/settings/payroll/payroll_checklist/active_employees/employee/${employeeId}`,
		)
			.then((response: GetEmployeeFieldDataResponse) => {
				const { status, data } = response;

				const reformattedData = formatEmployeeFieldDataFromGet(data);

				if (status === 200) {
					setEmployeeData(reformattedData);
					setButtonProcessing(false);
					setModalOpen(true);
				} else {
					handleGetDataFailure();
				}
			})
			.catch(handleGetDataFailure);
	}, [employeeId, handleGetDataFailure]);

	const handleButtonClick = useCallback(() => {
		if (isEqual(employeeData, INITIAL_FIELD_DATA)) {
			updateEmployeeData();
		} else {
			setModalOpen(true);
		}
	}, [employeeData, updateEmployeeData]);

	const handleEmployeeDataChange = useCallback(
		(name: string, value: string | boolean, isSelect: boolean) => {
			const newEmployeeData = cloneDeep(employeeData);
			if (name === 'employmentStatus') {
				const { options } = fields.employmentStatus as {
					options: {
						text: string;
						value: string;
					}[];
				};
				const currentSelected = options.find(
					(selected) => selected.value === value,
				);
				setIsContractor(currentSelected.text === 'Contractor');
			}
			if (isSelect) {
				const field = newEmployeeData.fields[name] as EmployeeSelectField;
				field.selected = value as string;
			} else {
				newEmployeeData.fields[name] = value;
			}
			setEmployeeData(newEmployeeData);
		},
		[employeeData, setEmployeeData],
	);

	const handleSaveDataSuccess = useCallback(() => {
		setModalProcessing(false);
		handleModalClose();
		updateTableData();
	}, [handleModalClose, updateTableData]);

	const handleSaveDataFailure = useCallback(() => {
		setModalProcessing(false);
	}, []);

	const handleSaveEmployeeData = useCallback(() => {
		setModalProcessing(true);
		const { fields } = formatSaveData(employeeData.fields);

		const federalFilingSelected = (
			document.getElementsByName('fed[status2020]')[0] as HTMLInputElement
		)?.value;
		const stateLocationSelected = (
			document.getElementsByName('state[location]')[0] as HTMLInputElement
		)?.value;
		const uiLocationSelected = (
			document.getElementsByName('uiState[location]')[0] as HTMLInputElement
		)?.value;
		const { federalFilingStatus, stateTaxLocation, unemploymentTaxLocation } =
			stillMissingTaxInfo;
		const federalSelected = !!federalFilingSelected || !!federalFilingStatus;
		const stateSelected = !!stateLocationSelected || !!stateTaxLocation;
		const uiSelected = !!uiLocationSelected || !!unemploymentTaxLocation;
		const stillTaxMissing = [] as string[];
		if (!federalSelected) {
			stillTaxMissing.push('Filing Status');
		}
		if (!stateSelected) {
			stillTaxMissing.push('State Tax Location');
		}
		if (!uiSelected) {
			stillTaxMissing.push('Unemployment Insurance Location');
		}
		const fullName = `${firstName} ${lastName}`;
		if (!!employeeData.missingFields.ssn === false) {
			const key = 'ssn';
			delete fields[key];
		}

		saveEmployeeData(
			employeeId,
			fullName,
			taxDataForm.current,
			fields,
			handleSaveDataSuccess,
			handleSaveDataFailure,
		);
	}, [
		employeeData,
		employeeId,
		taxDataForm,
		handleSaveDataSuccess,
		firstName,
		lastName,
	]);

	const footerActions = [
		<Button onClick={() => handleSaveEmployeeData()} key="done" type="button">
			{$.__('Save')}
		</Button>,
		<TextButton onClick={handleModalClose} key="cancel" type="button">
			{$.__('Cancel')}
		</TextButton>,
	];

	return (
		<>
			<Button
				processing={buttonProcessing}
				onClick={handleButtonClick}
				size="small"
				type="button"
				variant="outlined"
			>
				{$.__('Update Missing Info')}
			</Button>
			<StandardModal
				isOpen={modalOpen}
				isProcessing={modalProcessing}
				onRequestClose={handleModalClose}
				shouldFocusAfterRender={true}
				shouldReturnFocusAfterClose={false}
			>
				<StandardModal.Body
					renderFooter={
						<StandardModal.Footer actions={footerActions.reverse()} />
					}
					renderHeader={
						<StandardModal.Header
							hasCloseButton={true}
							title={$.__('Update Missing Payroll Info')}
						/>
					}
				>
					<StandardModal.UpperContent>
						<BadgeV2
							icon={
								<BadgeV2.Avatar
									alt="Image of employee"
									src={`/employees/photos/?id=${employeeId}&s=3`}
								/>
							}
							title={`${firstName} ${lastName}`}
							subtitle={jobTitle}
						/>
					</StandardModal.UpperContent>

					<StandardModal.Constraint>
						<Flex flexDirection="column" gap={4}>
							<BodyText
								color="warning-strong"
								icon={'triangle-exclamation-solid'}
							>
								{/* the . is intentional */}
								{missingInfoText}.
							</BodyText>

							{missingEmployeeInfo > 0 && (
								<>
									<EmployeeInfo
										employeeId={employeeId}
										fields={fields}
										missingFields={missingFields}
										onChange={handleEmployeeDataChange}
									/>

									<JobInfo
										employeeId={employeeId}
										fields={fields}
										missingFields={missingFields}
										onChange={handleEmployeeDataChange}
									/>

									<CompensationInfo
										fields={fields}
										missingFields={missingFields}
										onChange={handleEmployeeDataChange}
									/>
								</>
							)}
							{missingTaxInfo > 0 && !isContractor && (
								<form ref={taxDataForm}>
									<SectionDivider />
									<TaxWithholdingInfo
										employeeId={employeeId}
										jobLocation={jobLocation as EmployeeSelectField}
										jobLocationMissing={!!jobLocationMissing}
										stateId={stateId as string}
										stateIdMissing={stateIdMissing as boolean}
									/>
								</form>
							)}

							{missingDirectDeposit && (
								<DirectDepositInfo
									employeeId={employeeId}
									employeeName={firstName}
									isPaidByCheck={isPaidByCheck as boolean}
									onChange={handleEmployeeDataChange}
								/>
							)}
						</Flex>
					</StandardModal.Constraint>
				</StandardModal.Body>
			</StandardModal>
		</>
	);
}

// Utilities
function getMissingInfoText(
	numOfMissingFields: number,
	missingDirectDeposit: boolean,
	firstName: string,
): string {
	if (numOfMissingFields && missingDirectDeposit) {
		return $.__n(
			`%2$s is missing %1$s field required for payroll and a direct deposit account`,
			`%2$s is missing %1$s fields required for payroll and a direct deposit account`,
			numOfMissingFields,
			firstName,
		);
	}
	if (numOfMissingFields && !missingDirectDeposit) {
		return $.__n(
			`%2$s is missing %1$s field required for payroll`,
			`%2$s is missing %1$s fields required for payroll`,
			numOfMissingFields,
			firstName,
		);
	}
	if (numOfMissingFields === 0 && missingDirectDeposit) {
		return $.__(`%1$s doesn't have a direct deposit account`, firstName);
	}
}

function failedToGetMsg() {
	showSlidedown(
		$.__(
			'Uh oh...something went wrong getting employee data. Please try again.',
		),
		'error',
	);
}
