import classNames from 'classnames';
import { Component } from 'react';
import { StyledBox, Flex, LayoutBox, IconV2 } from '@bamboohr/fabric';
import { Select } from '@fabric/select';

export class NewPath extends Component {
	constructor(props) {
		super(props);

		const {
			availablePathOptions,
			pathType
		} = props;
		this._availablePathOptionsDropdownSettings = {
			canSelectMultiple: true,
			menuWidth: 7,
			placeholder: this._getAvailableSelectPlaceholderTranslation(pathType),
			size: 'small',
			width: 7
		};

		this.state = {
			availablePathOptionsDropdownData: {
				items: availablePathOptions.map(({ id, name }) => {
					return {
						displayText: name,
						selected: false,
						text: name,
						value: id
					};
				})
			},
			selectedOptions: []
		};
	}

	_getAvailableSelectPlaceholderTranslation = (pathType) => {
		let translation = '';

		switch (pathType.toLowerCase()) {
			case 'department':
				translation = '- Choose a Department -';
				break;
			case 'division':
				translation = '- Choose a Division -';
				break;
			case 'location':
				translation = '- Choose a Location -';
				break;
		}

		return translation;
	};

	_getNewPathTitleTranslation = (pathType) => {
		let translation = '';

		switch (pathType.toLowerCase()) {
			case 'department':
				translation = 'New Department Approval';
				break;
			case 'division':
				translation = 'New Division Approval';
				break;
			case 'location':
				translation = 'New Location Approval';
				break;
		}

		return translation;
	};

	_getPathIcon = (pathType) => {
		let iconName = '';

		switch (pathType.toLowerCase()) {
			case 'department':
				iconName = 'user-group-solid';
				break;
			case 'division':
				iconName = 'sitemap-solid';
				break;
			case 'location':
				iconName = 'location-dot-solid';
				break;
		}

		return <IconV2 color="primary-strong" name={iconName} size={16} />;
	};

	_handleApplyPath = () => {
		const {
			availablePathOptions,
			handleCreateNew
		} = this.props;
		const {
			selectedOptions
		} = this.state;

		const newPathSelectedOptions = availablePathOptions.filter((availablePathOption) => {
			return selectedOptions.includes(availablePathOption.id);
		}).map((newPathSelectedOption) => {
			return newPathSelectedOption.id;
		});

		handleCreateNew(0, newPathSelectedOptions);
	};

	_handleCancel = () => {
		const { handleCreateNewCancel, notInitial } = this.props;
		this._handleUpdateAvailableSelect([]);
		if (notInitial) {
			handleCreateNewCancel();
		}
	};

	_handleUpdateAvailableSelect = (itemId, currentlySelected, selectedObj) => {
		const {
			availablePathOptions
		} = this.props;

		let {
			selectedOptions
		} = this.state;

		const {
			item = {},
		} = selectedObj || {};

		if (!itemId.length > 0) { // cleared the select completely
			selectedOptions = [];
		} else if (item && item.selected) { // already checked, removing checked
			selectedOptions = selectedOptions.filter((selectedOption) => {
				return item.value !== selectedOption;
			});
		} else { // not checked, adding checked
			selectedOptions.push(item.value);
		}

		this.setState(() => {
			return {
				availablePathOptionsDropdownData: {
					items: availablePathOptions.map(({ id, name }) => {
						return {
							displayText: name,
							selected: selectedOptions.includes(id),
							text: name,
							value: id
						};
					})
				},
				selectedOptions
			};
		});
	};

	render() {
		const {
			notInitial,
			pathType
		} = this.props;
		const {
			availablePathOptionsDropdownData,
			selectedOptions
		} = this.state;

		const hasSelections = selectedOptions.length > 0;

		return (
			<StyledBox
				backgroundColor="neutral-forced-white"
				borderColor="neutral-weak"
				borderRadius="small"
				borderStyle="solid"
				padding="16px 20px 24px 20px"
				margin="16px 0px 24px 16px"
			>
				<div className={classNames(
					'AdvancedApprovalsNewPathContainer'
				)}
				>
					<Flex alignItems="center" flexDirection="row">
						<div className="AdvancedApprovalsNewPathContainer__icon-container">
							{this._getPathIcon(pathType)}
						</div>
						<div className="AdvancedApprovalsNewPathContainer__title">
							{this._getNewPathTitleTranslation(pathType)}
						</div>
					</Flex>
					<div className="AdvancedApprovalsNewPathContainer__actions-container">
						<LayoutBox marginRight={2}>
							<Select
								{...this._availablePathOptionsDropdownSettings}
								items={availablePathOptionsDropdownData.items}
								onChange={this._handleUpdateAvailableSelect}
								onDeselect={this._handleUpdateAvailableSelect}
								onSelect={this._handleUpdateAvailableSelect}
								selectedValues={selectedOptions}
								showSearch='always'
							/>
						</LayoutBox>
						<div className="AdvancedApprovalsNewPathContainer__actions-container__buttons">
							<button
								className={classNames(
									'fab-Button',
									'fab-Button--small',
									{ hidden: !hasSelections }
								)}
								onClick={this._handleApplyPath}
								type="button"
							>
								{$.__('Apply')}
							</button>
							<button
								className={classNames({ hidden: !hasSelections && !notInitial }, 'fab-TextButton', 'fab-link')}
								onClick={this._handleCancel}
								type="button"
							>
								{$.__('Cancel')}
							</button>
						</div>
					</div>
				</div>
			</StyledBox>
		);
	}
}
