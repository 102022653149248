import { Divider, LayoutBox } from '@bamboohr/fabric';
import { ReactElement, useEffect, useState } from 'react';

import { disableQrLogin } from '../../services/disable-qr-login';
import { enableQrLogin } from '../../services/enable-qr-login';
import { getQrLoginEnabledStatus } from '../../services/get-qr-login-enabled-status';
import { MobileQrLoginControls } from '../mobile-qr-login-controls';

export const MobileQrLoginWrapper = (): ReactElement => {
	const [toggleStatus, setToggleStatus] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [canUserViewQrSettings, setCanUserViewQrSettings] = useState<boolean>(false);

	useEffect(() => {
		getQrLoginEnabledStatus()
			.then((response) => {
				setToggleStatus(response.enabled ? 'on' : 'off');
				setCanUserViewQrSettings(true);
				setIsLoading(false);
			})
			.catch((err) => {
				if (err?.cause === 403) {
					return;
				}

				setIsLoading(false);
				setCanUserViewQrSettings(true);
				window.setMessage($.__('There was an error retrieving QR login settings.'), 'error');
			});
	}, []);

	const handleRadioChange = (value: string) => {
		const hasToggleChanged = value !== toggleStatus;
		setToggleStatus(value);

		if (value === 'on' && hasToggleChanged) {
			enableQrLogin().catch(() => {
				setToggleStatus('off');
				window.setMessage($.__('There was an error enabling QR login.'), 'error');
			});

			return;
		}

		if (value === 'off' && hasToggleChanged) {
			disableQrLogin().catch(() => {
				setToggleStatus('on');
				window.setMessage($.__('There was an error disabling QR login.'), 'error');
			});
		}
	};

	return (
		canUserViewQrSettings && (
			<>
				<LayoutBox marginBottom={2.25} marginTop={3}>
					<Divider color='neutral-extra-weak'/>
				</LayoutBox>
				<MobileQrLoginControls
					handleRadioChange={handleRadioChange}
					isLoading={isLoading}
					toggleStatus={toggleStatus}
				/>
			</>
		)
	);
};
