import { PageHeader } from '@bamboohr/fabric';
import { redirect } from 'base/_utils/BambooHR.util';

export interface AddEditHeaderProps {
	isAdd: boolean;
}

export function AddEditHeader({ isAdd }: AddEditHeaderProps) {
	return (
		<PageHeader
			back={$.__('Company Information')}
			onBack={() => redirect('/settings/payroll/company_information')}
			title={isAdd ? $.__('Add Company/EIN') : $.__('Edit Company Info')}
			divider={false}
		/>
	);
}
