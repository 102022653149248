import { ResponseOption } from '../responseOption';
import { useStyles } from './styles';

type ResponseOptionsProps = {
	answers: Array<{ label: string; value: number; }>;
	handleAnswerChange: (value: number) => void;
	isSmallScreen: boolean;
	selectedAnswerValue: number;
	disabled: boolean;
};

export const ResponseOptions: React.FC<React.PropsWithChildren<ResponseOptionsProps>> = ({
	answers = [],
	handleAnswerChange,
	isSmallScreen,
	selectedAnswerValue,
	disabled,
}) => {
	const classes = useStyles({ isSmallScreen });

	return (
		<div className={ classes.container }>
			{ !isSmallScreen && <div className={ classes.connectingBar } /> }
			<div className={ classes.responseOptionContainer }>
				{ answers.map(({ label, value }) => {
					return (
						<ResponseOption
							disabled={disabled}
							handleClick={ handleAnswerChange }
							isSelected={ value === selectedAnswerValue }
							isSmallScreen={ isSmallScreen }
							key={ value }
							label={ label }
							value={ value }
						/>
					);
				}) }
			</div>
		</div>
	);
};
