import { EnrollmentStatusTab } from './bulk-editing.react/enrollment-status-tabs/types';
import { DeductionFrequencyEnumContract } from './types';

export type BenefitPlanTypeEnumContract =
	| 'health'
	| 'dental'
	| 'vision'
	| 'supplemental'
	| 'retirement'
	| 'reimbursement'
	| 'hsa'
	| 'flex'
	| 'life'
	| 'disability'
	| 'other';

export const BENEFIT_KEYS = {
	health: 'health',
	dental: 'dental',
	vision: 'vision',
	supplemental: 'supplemental',
	retirement: 'retirement',
	reimbursement: 'reimbursement',
	hsa: 'hsa',
	flex: 'flex',
	life: 'life',
	disability: 'disability',
	other: 'other',
};

export const BENEFIT_PLAN_TYPE_NAMES = {
	[BENEFIT_KEYS.health]: $.__('Medical'),
	[BENEFIT_KEYS.dental]: $.__('Dental'),
	[BENEFIT_KEYS.vision]: $.__('Vision'),
	[BENEFIT_KEYS.supplemental]: $.__('Supplemental'),
	[BENEFIT_KEYS.retirement]: $.__('Retirement'),
	[BENEFIT_KEYS.reimbursement]: $.__('Reimbursement'),
	[BENEFIT_KEYS.hsa]: $.__('HSA'),
	[BENEFIT_KEYS.flex]: $.__('FSA'),
	[BENEFIT_KEYS.life]: $.__('Life'),
	[BENEFIT_KEYS.disability]: $.__('Disability'),
	[BENEFIT_KEYS.other]: $.__('Other'),
};

export const V2_BENEFIT_TYPE_ICON_NAMES = {
	[BENEFIT_KEYS.health]: 'heart-pulse',
	[BENEFIT_KEYS.dental]: 'tooth',
	[BENEFIT_KEYS.vision]: 'eye',
	[BENEFIT_KEYS.supplemental]: 'heart-circle-plus',
	[BENEFIT_KEYS.retirement]: 'piggy-bank',
	[BENEFIT_KEYS.reimbursement]: 'rotate-left',
	[BENEFIT_KEYS.hsa]: 'building-columns',
	[BENEFIT_KEYS.flex]: 'wallet',
	[BENEFIT_KEYS.life]: 'shield-heart',
	[BENEFIT_KEYS.disability]: 'crutch',
	[BENEFIT_KEYS.other]: 'star',
};

export const DEDUCTION_FREQUENCY_LABELS: { [key in DeductionFrequencyEnumContract]: string } = {
	[DeductionFrequencyEnumContract.daily]: $.__('Daily'),
	[DeductionFrequencyEnumContract.weekly]: $.__('Weekly'),
	[DeductionFrequencyEnumContract.everyOtherWeek]: $.__('Every other week'),
	[DeductionFrequencyEnumContract.twiceAMonth]: $.__('Twice a month'),
	[DeductionFrequencyEnumContract.fourTimesAMonth]: $.__('Four times a month'),
	[DeductionFrequencyEnumContract.monthly]: $.__('Monthly'),
	[DeductionFrequencyEnumContract.quarterly]: $.__('Quarterly'),
	[DeductionFrequencyEnumContract.twiceAYear]: $.__('Twice a year'),
	[DeductionFrequencyEnumContract.yearly]: $.__('Yearly'),
};

export const ENROLLMENT_STATUS_TAB_LABEL: { [key in EnrollmentStatusTab]: string } = {
	[EnrollmentStatusTab.enrolled]: $.__('Enrolled'),
	[EnrollmentStatusTab.eligible]: $.__('Eligible'),
	[EnrollmentStatusTab.waived]: $.__('Waived'),
};
