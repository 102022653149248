import { IconV2, Tooltip } from '@bamboohr/fabric';
import { format, isBefore, isSameDay } from '@bamboohr/utils/lib/datetime';
import { ReactElement } from 'react';
import { ReviewCycleConfigurationData } from '../../../../types';
import { setTooltipTextPosition } from './set-tooltip-text-position';

interface Props {
	rowData: ReviewCycleConfigurationData;
}

export const ScheduleIcon = (props: Props): ReactElement => {
	const {
		rowData: {
			assessmentNextStartDate,
			assessmentStatus,
			feedbackNextStartDate,
			feedbackStatus,
			scheduleType,
			status,
		},
	} = props;

	const isAnniversarySchedule = scheduleType === 'anniversary';
	const scheduleTypeString = isAnniversarySchedule
		? $.__('By Hire Date')
		: $.__('On Fixed Date');
	const isInactiveOrDraft = status === 'draft' || status === 'inactive';

	const renderIcon = (): ReactElement => {
		const scheduleIcon = isAnniversarySchedule ? (
			<IconV2 color="neutral-medium" name="circle-user-regular" size={20} />
		) : (
			<IconV2 color="neutral-medium" name="calendar-regular" size={20} />
		);
		const assessmentInProgressString =
			assessmentStatus === 'inProgress' && $.__('Assessments In Progress');
		const feedbackInProgressString =
			feedbackStatus === 'inProgress' && $.__('Feedback In Progress');
		const feedbackDate = new Date(feedbackNextStartDate);
		const assessmentDate = new Date(assessmentNextStartDate);
		// Since date-fns automatically converts the date to the local timezone, we need to convert it back to UTC to get the correct date.
		const feedbackOffsetTimezone = new Date(
			feedbackDate.valueOf() + feedbackDate.getTimezoneOffset() * 60 * 1000,
		);
		const assessmentOffsetTimezone = new Date(
			assessmentDate.valueOf() + assessmentDate.getTimezoneOffset() * 60 * 1000,
		);
		const formattedFeedbackDate = format(feedbackOffsetTimezone, 'MMM dd');
		const formattedAssessmentDate = format(assessmentOffsetTimezone, 'MMM dd');
		const feedbackMessage = $.__('Feedback Starts %1', formattedFeedbackDate);
		const assessmentMessage = $.__(
			'Assessments Start %1',
			formattedAssessmentDate,
		);
		const areAssessmentsSameDay = isSameDay(
			assessmentOffsetTimezone,
			feedbackOffsetTimezone,
		);
		const areAssessmentsBeforeFeedback = isBefore(
			assessmentOffsetTimezone,
			feedbackOffsetTimezone,
		);

		let tooltipContent = null;

		if (isAnniversarySchedule || isInactiveOrDraft) {
			tooltipContent = scheduleTypeString;
		} else if (assessmentInProgressString) {
			if (feedbackInProgressString) {
				tooltipContent = setTooltipTextPosition(
					assessmentInProgressString,
					feedbackInProgressString,
				);
			} else if (feedbackNextStartDate) {
				tooltipContent = setTooltipTextPosition(
					assessmentInProgressString,
					feedbackMessage,
				);
			} else {
				tooltipContent = assessmentInProgressString;
			}
		} else if (assessmentNextStartDate) {
			if (feedbackInProgressString) {
				tooltipContent = setTooltipTextPosition(
					feedbackInProgressString,
					assessmentMessage,
				);
			} else if (feedbackNextStartDate) {
				if (areAssessmentsSameDay || areAssessmentsBeforeFeedback) {
					tooltipContent = setTooltipTextPosition(
						assessmentMessage,
						feedbackMessage,
					);
				} else {
					tooltipContent = setTooltipTextPosition(
						feedbackMessage,
						assessmentMessage,
					);
				}
			} else {
				tooltipContent = assessmentMessage;
			}
		} else if (feedbackNextStartDate) {
			tooltipContent = feedbackMessage;
		} else if (feedbackInProgressString) {
			tooltipContent = feedbackInProgressString;
		}

		if (!tooltipContent) {
			return scheduleIcon;
		}

		return (
			<Tooltip content={<div>{tooltipContent}</div>}>
				<span>{scheduleIcon}</span>
			</Tooltip>
		);
	};

	return renderIcon();
};
