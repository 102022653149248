import { LayoutBox, TextButton } from '@bamboohr/fabric';

import { isEnabled as isFeatureEnabled } from 'FeatureToggle.util';

import { StatusTooltip } from '../components';
import { useBenefitsSettingsStore } from '../../../data/benefits-settings-store';
import { BenefitPlanStatusContract } from '../../../types';
import { FEATURE_TOGGLE_KEYS } from '../../../../etc/feature-toggle';

interface EnrolledProps {
	onClick: () => void;
	planIsMissingPlanYearOrDetails: boolean;
	setIsEligibilityModalOpen: (open: boolean) => void;
	status: BenefitPlanStatusContract;
}

export const Enrolled = (props: EnrolledProps): JSX.Element => {
	const {
		onClick,
		planIsMissingPlanYearOrDetails,
		setIsEligibilityModalOpen,
		status,
	} = props;
	const [{ isBenefitsAdminEnabled }] = useBenefitsSettingsStore();

	const enrolledWaivedLink = (
		<TextButton
			color='secondary'
			disabled={isFeatureEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_DATA_PARITY_PLAN_YEAR) && planIsMissingPlanYearOrDetails && isBenefitsAdminEnabled}
			inline={true}
			onClick={onClick}
			type="button"
		>
			<>
				{$.__('%s Enrolled', status.enrolled)}
				{status.notEnrolledOrWaived > 0 && (
					<>{$.__(' %s Not Enrolled/Waived', status.notEnrolledOrWaived)}</>
				)}
			</>
		</TextButton>
	);

	const eligibleLink = (
		<TextButton
			color='secondary'
			disabled={isFeatureEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_DATA_PARITY_PLAN_YEAR) && planIsMissingPlanYearOrDetails && isBenefitsAdminEnabled}
			inline={true}
			onClick={() => {
				setIsEligibilityModalOpen(true);
			}}
			type="button"
		>
			{$.__(
				'%s will be eligible when marked manually.',
				status.eligibleWhenMarkedManually,
			)}
		</TextButton>
	);

	return (
		<StatusTooltip
			isOpen={isFeatureEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_DATA_PARITY_PLAN_YEAR) && planIsMissingPlanYearOrDetails && isBenefitsAdminEnabled}
		>
			<>
				<LayoutBox>{enrolledWaivedLink}</LayoutBox>
				{status.eligibleWhenMarkedManually > 0 && (
					<LayoutBox>{eligibleLink}</LayoutBox>
				)}
			</>
		</StatusTooltip>
	);
};
