import { getParameterByName } from 'BambooHR.util';
import { initSettings } from './init-settings';
import initUpsell from './upsell';
import initTrial from './trial';
import createNavigationPopover from './navigation-popover';


import './styles.styl';

window.TimeTrackingSettings = {
	initEmployees: initSettings,
	initUpsell,
	initTrial,
};

const whichTimeTrackingSetupPopover = getParameterByName('time_tracking_setup');

if (whichTimeTrackingSetupPopover !== false) {
	switch (whichTimeTrackingSetupPopover) {
		case 'pay_schedule':
			createNavigationPopover('.settingsTimeTrackingLink', $.__('Pay Schedules'));
			break;
		case 'holidays':
			createNavigationPopover('.settingsTimeTrackingLink', $.__('Holidays'));
			break;
		case 'locations':
			createNavigationPopover('.settingsTimeTrackingLink', $.__('Locations'));
			break;
		// other popovers are handled in Po
		default:
			break;
	}
}
