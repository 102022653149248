import { makeStyles, createStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette, typography, constructs }) => {
	return createStyles({
		boxLabel: {
			height: '110px',
		},
		mfaLevelOption: {
			marginRight: '16px',
		},
		mfaLevelOptionContainer: {
			marginTop: '12px',
			marginBottom: '22px',
		},
		mfaSettingsContentHeader: {
			fontSize: typography.fabricFontSize('biggie'),
			fontWeight: typography.fontWeightMedium,
			marginTop: '0',
			color: constructs.text.neutral.xStrong,
		},
		mfaStartDateWrapper: {
			marginTop: '23px',
		},
		dividerLine: {
			padding: 0,
			marginTop: '24px',
			marginBottom: '24px',
			borderTop: `solid 1px ${palette.gray[300]}`,
		},
		selectedNote: {
			fontSize: typography.fabricFontSize('teenie'),
			marginTop: '12px',
			lineHeight: typography.fabricLineHeight('teenie'),
		},
		dateContainer: {
			marginTop: '26px',
		},
		optInNoteContainer: {
			padding: '10px 0 8px',
		},
		optInNote: {
			fontWeight: typography.fontWeightSemibold,
			color: constructs.text.neutral.xStrong,
		},
	});
});
