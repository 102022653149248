import { Popover } from '@bamboohr/fabric';

import './styles.styl';

type BestCadencePopoverProps = {
	showPopover: boolean;
	setShowPopover: (show: boolean) => void;
};
export const BestCadencePopover: React.FC<React.PropsWithChildren<BestCadencePopoverProps>> = ({ showPopover, setShowPopover }) => {
	return (
		<Popover
			anchorEl={document.getElementById('popoverAnchor')}
			id='popover'
			onClose={() => setShowPopover(false)}
			open={showPopover}
			title={$.__('What’s the right frequency for my organization?')}
		>
			<p>{$.__('Here are some things to keep in mind:')}</p>
			<span className='listHeader'>{$.__('Faster Cadence')}</span>
			<ul>
				<li>
					<span>+</span>
					<span>{$.__('Easier to see changes over time')}</span>
				</li>
				<li>
					<span>+</span>
					<span>{$.__('Start seeing trends more quickly')}</span>
				</li>
				<li>
					<span>-</span>
					<span>{$.__('Employees may tire of surveys, response rate could go down')}</span>
				</li>
			</ul>
			<span className='listHeader'>{$.__('Slower Cadence')}</span>
			<ul>
				<li>
					<span>+</span>
					<span>{$.__('Employees less likely to tire of surveys')}</span>
				</li>
				<li>
					<span>+</span>
					<span>{$.__('More time for org to react to feedback')}</span>
				</li>
				<li>
					<span>-</span>
					<span>{$.__('Takes longer to gather data and see trends')}</span>
				</li>
			</ul>
			<p>{$.__('You can adjust your survey frequency at any time.')}</p>
		</Popover>
	);
};
