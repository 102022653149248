import { Dropdown } from '@bamboohr/fabric';
import { ReactElement } from 'react';
import { handleActionSelect } from '../../etc/actions';
import { getDropdownItems } from '../../etc/get-dropdown-items';
import { HeaderData, HeaderTmplData } from '../../types';

export function renderHeaderDropdown(
	permission: HeaderTmplData,
	mainTemplateData: HeaderData,
): ReactElement {
	if (permission.canAdd || permission.canDuplicate || permission.canDelete) {
		return (
			<Dropdown
				ButtonProps={{
					color: 'secondary',
					startIcon: 'gear-regular',
					variant: 'outlined',
					size: 'small',
				}}
				items={getDropdownItems(permission)}
				onSelect={(val) =>
					handleActionSelect(val, permission.levelId, mainTemplateData)
				}
				type={'icon'}
			/>
		);
	}
}
