import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ typography, palette }) => ({
	boldCountdown: {
		fontWeight: typography.fontWeightSemibold,
		display: 'inline-block',
		marginRight: '3px',
		marginLeft: '3px',
	},
}));
