import { Button, StandardModal, TextButton } from '@bamboohr/fabric';
import { isEnabled } from 'FeatureToggle.util';

import { getReverseEmploymentSuffix, showSiblingMessage } from './domain';

export function DeleteTaxModal(props) {
	const vtccAccessEnabled = isEnabled('VERMONT_CHILD_CARE_TAX_VIEW_ACCESS');

	const {
		clearModalData,
		deleteTax,
		processing,
		targetRowData,
	} = props;

	const {
		payer,
		paymentsPreviouslyMade,
		taxStartDate,
		taxTypeEngine,
		taxTypeName,
		state: stateName, 
	} = targetRowData;

	if (paymentsPreviouslyMade) {
		let headline = $.__(`This tax can't be removed because withholdings for this tax have already begun.`);
		if (taxTypeName && taxStartDate) {
			headline = $.__(`%1$s can't be removed because withholdings for this tax began on %2$s`, taxTypeName, moment(taxStartDate, 'M/D/YYYY').format('MMMM D, YYYY'));
		} else if (!taxTypeName && taxStartDate) {
			headline = $.__(`This tax can't be removed because withholdings for this tax began on %1$s`, moment(taxStartDate, 'M/D/YYYY').format('MMMM D, YYYY'));
		}
		return <StandardModal isOpen={ true } isProcessing={ processing } onRequestClose={ clearModalData }>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<TextButton
								key='cancelGotItButton'
								onClick={ clearModalData }
								type="button"
							>
								{ $.__('Cancel') }
							</TextButton>,
							<Button key='gotItDeleteButton' onClick={ clearModalData }>
								{ $.__('Got It') }
							</Button>,
						]}
					/>
				}
				renderHeader={<StandardModal.Header title={ $.__('Just So You Know…') } />}
			>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline icon='circle-exclamation-solid' iconColor='info-medium' subtext={ $.__('To stop these withholdings from continuing, please schedule an end date.') } text={ headline } />
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	}


	let headline;
	if (vtccAccessEnabled && showSiblingMessage(targetRowData.employeePercent, payer, taxTypeEngine, taxTypeName, stateName)) {
		headline = $.__('Are you sure you want to remove %1$s and %2$s', targetRowData.taxTypeName, getReverseEmploymentSuffix(targetRowData.taxTypeName));
	} else if (taxTypeName) {
		headline = $.__('Are you sure you want to remove %1$s?', taxTypeName);
	} else {
		headline = $.__('Are you sure you want to remove this tax?');
	}
	
	let bodyMessage;
	if (paymentsPreviouslyMade) {
		bodyMessage = $.__('It will be removed from your active taxes. However, in order to maintain history, it will be archived in your inactive payroll taxes.');

		if (vtccAccessEnabled && showSiblingMessage(targetRowData.employeePercent, payer, taxTypeEngine, taxTypeName, stateName)) {
			bodyMessage = $.__('These taxes will be removed from your active taxes. However, in order to maintain history, they will be archived in your inactive payroll taxes.');
		}
	} else {
		bodyMessage = $.__('No payments for this tax have been made. This will permanently remove it from your company payroll taxes.');
		
		if (vtccAccessEnabled && showSiblingMessage(targetRowData.employeePercent, payer, taxTypeEngine, taxTypeName, stateName)) {
			bodyMessage = $.__('No payments for these taxes have been made. This will permanently remove them from your company taxes.');
		}
	}

	return <StandardModal isOpen={ true } isProcessing={ processing } onRequestClose={ clearModalData }>
		<StandardModal.Body
			renderFooter={
				<StandardModal.Footer
					actions={[
						<TextButton
							key='cancelDeleteButton'
							onClick={ clearModalData }
							type="button"
						>
							{ $.__('Cancel') }
						</TextButton>,
						<Button key='saveDeleteButton' onClick={ deleteTax }>
							{$.__('Delete')}
						</Button>,
					]}
				/>
			}
			renderHeader={<StandardModal.Header title={ $.__('Just Checking…') } />}
		>
			<StandardModal.Constraint>
				<StandardModal.HeroHeadline icon='trash-can-regular' iconColor='error-strong' subtext={ bodyMessage } text={ headline } />
			</StandardModal.Constraint>
		</StandardModal.Body>
	</StandardModal>
}
