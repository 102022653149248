import {
	Flex,
	StandardModal,
} from '@bamboohr/fabric';


import {
	FederalConnector,
} from './federal-connector';
import {
	ModalFooterMessage,
} from './modal-footer-message';
import {
	StateConnector,
} from './state-connector';

export function ModalBodyDefault() {
	return (
		<StandardModal.Constraint>
			<Flex flexDirection='column' marginLeft="-20px" marginTop="-32px">
				<StateConnector />
				<FederalConnector />
				<ModalFooterMessage />
			</Flex>
		</StandardModal.Constraint>
	);
}
