import {
	BodyText,
	Button,
	Flex,
	Headline,
	LayoutBox,
	StyledBox,
	TextButton,
} from '@bamboohr/fabric';
import { ReactElement } from 'react';

interface UpsellBannerContentProps {
	dismissBanner: () => void;
	onClick: () => void;
}

export const UpsellBannerContent = (
	props: UpsellBannerContentProps,
): ReactElement => {
	const { dismissBanner, onClick } = props;

	const headline = $.__('Streamline Your Benefits Management');
	const description = $.__(
		'Discover how BambooHR Benefits Administration can save time for both you and your employees during benefit selection and enrollment. Upgrade now to automate enrollment, ensure data accuracy, and simplify compliance.',
	);
	const primaryLabel = $.__('Learn More');
	const cancelLabel = $.__('Not right now');
	const buttons = (
		<>
			<Button onClick={onClick} type="button">
				{primaryLabel}
			</Button>
			<TextButton onClick={dismissBanner} type="button">
				{cancelLabel}
			</TextButton>
		</>
	);

	return (
		<LayoutBox>
			<LayoutBox marginBottom={2} marginTop="0px">
				<StyledBox marginTop="0px">
					<Headline color="neutral-strong" size="small">
						{headline}
					</Headline>
				</StyledBox>
				<BodyText>{description}</BodyText>
			</LayoutBox>
			<Flex alignItems="center" gap={2}>
				{buttons}
			</Flex>
		</LayoutBox>
	);
};
