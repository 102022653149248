import { render } from 'base/wrapped-render';
import Ajax from '@utils/ajax';
import { getParameterByName } from '@utils/url';
import { PoMicroFrontend } from 'micro-frontend.react';
import {
	isNumber
} from 'lodash';

import Ctrl, {
	onReady,
	onPjaxEnd,
} from 'ctrl.deco';

import { getJsonData } from 'Data.util';
import {
	htmlentities,
} from 'BambooHR.util';
import { deleteParams } from 'History.util';

import {
	showDeleteEmployeeFieldModal,
	showEditEmployeeFieldModal,
	showFabricEmployeeFieldFixItModal
} from './modals';
import { EmploymentStatusWrapper } from '../employment-status';

import {
	convertDataToSnake
} from './utils';

import {
	isEnabled
} from 'FeatureToggle.util'

import {
	showModal,
	closeModal
} from 'show-modal.mod'

import {
	EmployeeListModal
} from 'employee-list-modal'

import './styles.styl';

let isLocationPage = window.isLocationPage || false;
let fieldTitle = window.employeeFieldTitle || $.__('Employee Field');
const isDeepLinkEnabled = isEnabled('ElfDeepLinking');

@Ctrl('/settings/list.php*')
class SettingsEmployeeFieldsDefaultCtrl {
	@onPjaxEnd()
	@onReady()
	_employeeFieldsDefaultInit() {
		renderEmploymentStatusContent();
		renderTeamsContent();
		onSettingsPageOpen();
	}
}

function renderEmploymentStatusContent() {
	const employmentStatusRoot = document.getElementById('js-employmentStatusRoot');
	const employmentStatusData = getJsonData('#js-employmentStatusData');

	if (employmentStatusRoot && employmentStatusData) {
		render(<EmploymentStatusWrapper { ...employmentStatusData } />, employmentStatusRoot);
	}
}

function renderTeamsContent() {
	const teamsIsEnabled = isEnabled('CORE_EMPLOYEE_TEAMS');
	if (!teamsIsEnabled) {
		return;
	}
	const teamsRoot = document.getElementById('js-teamsRoot');
	if (teamsRoot) {
		render(
			<PoMicroFrontend route="/settings/employee-fields/teams" />, teamsRoot
		)
	}
}

function renderEmployeeListModal(listId, listValueId, title, subtitle){
	showModal(<EmployeeListModal 
		isOpen={true} 
		listId={listId}
		listValueId={listValueId}
		onClose={closeModal}
		subtitle={subtitle}
		title={title}
	/>)
}

function editItemCallBack(data) {
	if (data.ret == 'SAVED') {

		const {
			count,
			id,
			label,
			list_id
		} = data.extra;

		// Update a row
		const $tr = $(`#lv${ data.extra.id }`).closest('tr');
		data.extra.location = data.savedLocation;
		$tr[0].dataset.json = JSON.stringify(
			convertDataToSnake(data.extra)
		);

		$tr.find('.title').html(`
			<span id='lv${ id }'>${ htmlentities(label) }</span>
		`);

		const locationHtml = generateSavedLocation(data);
		$tr.find('.location').html(locationHtml);

		if (data.savedLocation) {
			$tr.find('.phone').html(data.savedLocation.phoneNumber);
		}

		// Update the count and link action
		if (count > 0) {
			getHistoricalCountLink($tr).attr('href', `/employees/?tab=1&lid=${ list_id }&lidval=${ id }`).text(count);
		} else {
			$tr.find('.js-historical-count').html(count);
		}
		if (data.message) {
			window.setMessage(data.message, 'success');
		}

		updateTable();

	} else {
		window.setMessage(data.error, 'error');
	}
}

function updateTable() {
	const $table = $('.js-employee-fields-form');

	$('#add-label').val('').focus();
	$('js-employee-fields-form .template').remove();
	$('.blankState').hide();
	$table.show();
	$table.trigger('update', { resort: true });
}

function generateSavedLocation(data) {
	let html;

	if (data.savedLocation) {
		const {
			addressLine1,
			addressLine2,
			city,
			countryId,
			stateId,
			remoteLocation,
			zipcode

		} = data.savedLocation;


		if (remoteLocation === 'yes') {
			html = $.__('Remote Location');
		} else {
			const country = window.countries[countryId];
			const state = (stateId == parseInt(stateId) ? window.states[stateId] : htmlentities(stateId));

			html = `
				<div>${ htmlentities(addressLine1) }</div>
					<div>${ htmlentities(addressLine2) }</div>
					<div>${ htmlentities(city) }, ${ state } ${ htmlentities(zipcode) }</div>
					<div>${ country }</div>
			`;
		}
	} else {
		html = `
			<div>${ $.__('No Address') }</div>
			<div>
				<button class="edit">${ $.__('Add Address Now') }</button>
			<\div>
		`;
	}

	return html;
}

function addNewItemCallBack(data) {
	const $table = $('.js-employee-fields-form');

	if (data.ret == 'SAVED') {
		const {
			count = 0,
			id,
			label,
			list_id
		} = data.extra;

		//create a new row
		const $tr = $('.js-employee-fields-form tr').last().clone();
		const locationHtml = generateSavedLocation(data);
		const jsonData = data.savedListValue || data.extra;

		$tr.removeClass('disabled');
		$tr.find('td').removeClass('fab-Table__mutedSubCell disabled');
		data.extra.location = data.savedLocation;

		$tr[0].dataset.json = JSON.stringify(
			convertDataToSnake(jsonData)
		);

		$tr.find('.title').html(`<span id='lv${ id }'>${ htmlentities(label) }</span>`);

		$tr.find('.EEOCategory').html(``);

		$tr.find('.location').html(locationHtml);

		if (data.savedLocation) {
			$tr.find('.phone').html(data.savedLocation.phoneNumber);
		}

		//update the count and link action
		if (count > 0) {
			getHistoricalCountLink($tr)
				.attr('href',`/employees/?tab=1&lid=${ list_id }&lidval=${ id }`).html(count);
		} else {
			$tr.find('.js-historical-count').html(count);
		}

		$table.append($tr);
		$tr.removeClass('template');
		$('.js-employee-fields-form .template').remove();


		if (data.message) {
			window.setMessage(data.message, 'success');
		}

		updateTable();
	} else {
		window.setMessage(data.error, 'error');
	}
}

function addNewItem(id) {
	const $labelValue = $('#add-label').val();

	if ($.trim($labelValue) == '') {
		window.setMessage($.__('Please enter a name for the new item', 'error'));
		return false;
	}
	window.closeMessage();

	Ajax.post(`/settings/list.php?id=${ id }`, Ajax.prepareData({
		action: 'Add',
		ajax: 1,
		label: $labelValue
	}, Ajax.prepareDataTypes.FORM_DATA))
		.then(res => addNewItemCallBack(res.data))
		.catch(() => window.errorFallBack());
}

// Note: not called, but keeping
function removeArchive() {
	$('.archive-link, .unarchive-link').remove();
}

function getHistoricalCountLink($tr) {
	const $td = $tr.find('.js-historical-count');
	let $historicalCountLink = $td.find('.js-historical-count-link');
	if ($historicalCountLink.length === 0) {
		$td.html('<a class="js-historical-count-link"></a>');
		$historicalCountLink = $td.find('.js-historical-count-link');
	}

	return $historicalCountLink;
}

function onSettingsPageOpen() {
	// Get the updated field type
	fieldTitle = window.employeeFieldTitle;
	isLocationPage = window.isLocationPage;
	const $empFieldsForm = $('.js-employee-fields-form');

	window.textCursorFocus($('#add-form'));

	$('#add-label').on('keydown', function (e) {
		if (e.keyCode == 13) {
			e.preventDefault();
			addNewItem(this.dataset.listId);
		}
	});

	$('#add-button').click(function() {
		addNewItem(this.dataset.listId);
	});

	if ($('.js-employee-fields-form th').length > 1) {
		const $sortableTable = $empFieldsForm.has('tr:nth-child(2)');
		window.BambooHR.Utils.jQueryFabTableSorter($sortableTable);
	} else {
		$empFieldsForm.addClass('noSort');
	}

	if (isLocationPage) {

		$('#add-button-location').click(function() {
			window.locationModal(null, {}, 'undefined', addNewItemCallBack);
		});

		$empFieldsForm.on('click', '.js-edit-employee-field', function(e) {
			const data = $(this).closest('tr').data('json');

			const {
				id,
				label,
				location
			} = data;

			window.locationModal(location ? location.id : null, {
				label,
				'location[listValueId]': id
			}, true, editItemCallBack);
		});

		// If openEditId URL parameter exists and matches a location's 'id', automatically open the associated location modal.
		const openEditIdParam = getParameterByName('openEditId');
		if (openEditIdParam) {
			const locationRows = document.querySelectorAll('.fab-Table__row[data-json]');
			for (const locationRow of locationRows) {
				const data = JSON.parse(locationRow.dataset.json);
				const {
					id,
					label,
					location
				} = data;

				// eslint-disable-next-line max-depth
				if (id === openEditIdParam) {
					window.locationModal(location ? location.id : null, {
						label,
						'location[listValueId]': id
					}, true, editItemCallBack);
					break;
				}
			}
			deleteParams(['openEditId']);
		}
	} else {

		$empFieldsForm.on('click', '.js-edit-employee-field', function(e) {
			const data = $(this).closest('tr').data('json');

			Ajax.get('/ajax/manage/list.php', {
				id: data.list_id,
				vid: data.id
			})
				.then((res) => {
					$.extend(data, {
						totalHistoryCount: res.data.totalHistoryCount
					});
					showEditEmployeeFieldModal(data);
				})
				.catch(() => {
					window.errorFallBack();
				});
		});
	}

	if(isDeepLinkEnabled){
		const links = $('.js-historical-count-link');
		links.on('click', e => {
			const target = $(e.target)
			const listId = target.attr('data-list-id')
			const listValueId = target.attr('data-list-value-id')
			const label = target.attr('data-field-name')
			renderEmployeeListModal(listId, listValueId, fieldTitle, label)
		})
		//TODO: When releasing to GA, just remove the href in the twig, it won't be needed anymore.
		links.removeAttr('href')
	}


	$empFieldsForm.on('click', '.js-archive-employee-field', function(e) {
		const data = $(this).closest('tr').data('json');
		const deleteRestrictedList = ['Job Title', 'Department', 'Division', 'Location', 'Employment Status'];

		Ajax.get('/ajax/manage/list.php', {
			id: data.list_id,
			vid: data.id
		})
			.then((res) => {
				const {
					isUsedByApprovals,
					totalHistoryCount,
					activeCount
				} = res.data;

				if (totalHistoryCount !== 'ERROR') {
					let action = 'Archive';

					if (totalHistoryCount === 0) {
						action = 'Delete';
					}

					$.extend(data, {
						action,
						totalHistoryCount,
						isUsedByApprovals
					});

					data.title = $.parseHTML(fieldTitle)[0].data;
					data.count = isNumber(data.count) ? data.count : null;
					if (activeCount > 0 && deleteRestrictedList.includes(fieldTitle)) {
						showFabricEmployeeFieldFixItModal(activeCount, fieldTitle);
					} else {
						showDeleteEmployeeFieldModal(data);
					}
					e.preventDefault();
				}

			})
			.catch((error) => {
				window.errorFallBack();
			});
	});
}
