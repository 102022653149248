import React, { useContext } from 'react';
import { SlidedownPortal } from "@bamboohr/fabric";
import { SlidedownStatus, SlidedownContext } from "./utils";


export const EmployeeWellbeingSettingsSlidedown: React.FC<React.PropsWithChildren<unknown>> = () => {
    const {status, visible} = useContext(SlidedownContext);
    return (
        <SlidedownPortal 
            action={
                status === SlidedownStatus.Error 
                    ? {
                        text: $.__('Refresh Page'),
                        url: window.location.href,
                    }
                    : null
            }
            message={   status === SlidedownStatus.Success ? $.__('Employee Wellbeing settings have been successfully saved.') : $.__('Something went wrong.') }
            show={ visible }
            type={ status }
        />
    )
};
