import { ReactElement } from 'react';
import { SelectableBox, SelectableBoxGroup, SelectField, ItemType, Flex, Divider } from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';

import { StartDatePicker } from '../start-date-picker';
import { useStyles } from './styles';
import { EditStartDate } from '../edit-start-date';
import { MFA_LEVELS } from '../../constants';

interface EditMfaSettingsProps {
	accessLevelOptions: ItemType[];
	activeStartDateIsPast: boolean;
	isDirty: boolean;
	selectedAccessLevelIds: string[];
	selectedAccessLevelsById: Record<string, string>;
	setSelectedAccessLevelIds: (ids: string[]) => void;
	setSelectedAccessLevelsById: (ids: Record<string, string>) => void;
	selectedMfaLevel: MFA_LEVELS;
	setSelectedMfaLevel: (level: MFA_LEVELS) => void;
	selectedStartDate: string;
	setSelectedStartDate: (date: string) => void;
}

export function EditMfaSettingsModalContent(props: EditMfaSettingsProps): ReactElement {
	const {
		accessLevelOptions,
		activeStartDateIsPast,
		isDirty,
		selectedAccessLevelIds,
		selectedAccessLevelsById,
		setSelectedAccessLevelIds,
		setSelectedAccessLevelsById,
		selectedMfaLevel,
		setSelectedMfaLevel,
		selectedStartDate,
		setSelectedStartDate,
	} = props;
	const styles = useStyles();
	const selectableBoxType = 'radio';
	const boxWidth = '237px';
	const startDateLabel = $.__('Set a Start Date');

	function handleOnChange(name: string, value: MFA_LEVELS) {
		setSelectedMfaLevel(value);
	}

	return (
		<form>
			<p className={styles.mfaSettingsContentHeader}>{$.__('Select a Requirement Level')}</p>
			<div className={styles.mfaLevelOptionContainer}>
				<SelectableBoxGroup type={selectableBoxType}>
				<Flex gap={2}>
					<SelectableBox
						boxWidth={boxWidth}
						className={styles.mfaLevelOption}
						classes={{ label: styles.boxLabel }}
						description={$.__('2-Step Login is an option available for all users')}
						inputId='accessLevelOptIn'
						isChecked={selectedMfaLevel === MFA_LEVELS.USER_OPT_IN}
						key='mfaLevelOptionSelfEnroll'
						name='mfaLevelRadio'
						onChange={handleOnChange}
						title={$.__('Enabled For All')}
						type={selectableBoxType}
						value={MFA_LEVELS.USER_OPT_IN}
					/>
					<SelectableBox
						boxWidth={boxWidth}
						className={styles.mfaLevelOption}
						classes={{ label: styles.boxLabel }}
						description={$.__('2-Step Login is required for all users')}
						inputId='accessLevelAll'
						isChecked={selectedMfaLevel === MFA_LEVELS.COMPANY}
						key='mfaLevelOptionAll'
						name='mfaLevelRadio'
						onChange={handleOnChange}
						title={$.__('Required For All')}
						type={selectableBoxType}
						value={MFA_LEVELS.COMPANY}
					/>
					<SelectableBox
						boxWidth={boxWidth}
						classes={{ label: styles.boxLabel }}
						description={$.__('2-Step Login is required for users in selected Access Levels')}
						inputId='accessLevelRoleBased'
						isChecked={selectedMfaLevel === MFA_LEVELS.ACCESS_LEVEL}
						key='mfaLevelOptionLevels'
						name='mfaLevelRadio'
						onChange={handleOnChange}
						title={$.__('Required By Access Level')}
						type={selectableBoxType}
						value={MFA_LEVELS.ACCESS_LEVEL}
					/>
				</Flex>
				</SelectableBoxGroup>
			</div>
			{selectedMfaLevel === MFA_LEVELS.ACCESS_LEVEL && (
				<>
					<div>
						<SelectField
							canSelectMultiple={true}
							id='1'
							items={accessLevelOptions}
							label={$.__('Select Access Levels')}
							note={$.__('Requiring 2-Step Login for all Admins is highly recommended.')}
							onClear={() => {
								setSelectedAccessLevelIds([]);
								setSelectedAccessLevelsById({});
							}}
							onDeselect={(itemId, selectedIds) => {
								const selectedLevelsById = { ...selectedAccessLevelsById };
								delete selectedLevelsById[itemId];
								setSelectedAccessLevelsById(selectedLevelsById);
								setSelectedAccessLevelIds(selectedIds);
							}}
							onSelect={(itemId, selectedIds, { item }) => {
								setSelectedAccessLevelIds(selectedIds);
								const selectedLevelsById = { ...selectedAccessLevelsById };
								selectedLevelsById[itemId] = item.text as string;
								setSelectedAccessLevelsById(selectedLevelsById);
							}}
							value={selectedAccessLevelIds}
							width={7}
						/>
						{Object.values(selectedAccessLevelsById).length > 0 && (
							<p className={styles.selectedNote}>
								<Message params={[Object.values(selectedAccessLevelsById).join(', ')]} text={$._('**Selected:** {1}')} />
							</p>
						)}
					</div>
					<Divider marginY={3}/>
				</>
			)}
			<div>
				{selectedMfaLevel === MFA_LEVELS.USER_OPT_IN && (
					<div className={styles.optInNoteContainer}>
						<p className={styles.optInNote}>{$.__('Users can use 2-Step Login immediately after this option is enabled.')}</p>
					</div>
				)}
				{selectedMfaLevel !== MFA_LEVELS.USER_OPT_IN && activeStartDateIsPast && (
					<EditStartDate
						label={startDateLabel}
						renderRadios={isDirty}
						selectedStartDate={selectedStartDate}
						setSelectedStartDate={setSelectedStartDate}
					/>
				)}
				{selectedMfaLevel && selectedMfaLevel !== MFA_LEVELS.USER_OPT_IN && !activeStartDateIsPast && (
					<div className={styles.dateContainer}>
						<StartDatePicker
							activeStartDateIsPast={activeStartDateIsPast}
							label={startDateLabel}
							selectedStartDate={selectedStartDate}
							setSelectedStartDate={setSelectedStartDate}
						/>
					</div>
				)}
			</div>
		</form>
	);
}
