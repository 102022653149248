import { Flex } from '@bamboohr/fabric';
import { ReactElement, useEffect, useState } from 'react';
import { RenderSideSubNavigation } from './components/index';
import { getAccessLevelNav } from './utils';
import { NavData } from './utils/types';

export function AccessLevelSubNav({ initialActiveTab = '' }): ReactElement {
	const [activeTab, setActiveTab] = useState(initialActiveTab);
	const [canAddLevels, setCanAddLevels] = useState(false);
	const [levelTypes, setLevelTypes] = useState([]);

	const getData = () => {
		getAccessLevelNav().then((data: NavData) => {
			setCanAddLevels(data.canAddLevels);
			setLevelTypes(data.levelTypes);
		});
	};

	const handleTabUpdate = (value) => {
		setActiveTab(value);
		const allAccessLevels = levelTypes.reduce((res, current) => {
			if (Array.isArray(current)) {
				return [...res, ...current];
			}
			if (current.levels) {
				return [...res, ...current.levels];
			}
			return [...res, current];
		}, []);
		const selectedOption = allAccessLevels.find(
			(option) => option.id == value || option.name === value,
		);
		window.history.pushState({}, null, selectedOption.href);
	};

	useEffect(() => {
		getData();
	}, []);
	return (
		<Flex alignItems="baseline">
			{
				<RenderSideSubNavigation
					activeTab={activeTab}
					canAddLevels={canAddLevels}
					levelTypes={levelTypes}
					setActiveTab={handleTabUpdate}
				/>
			}
		</Flex>
	);
}
