import {
	Flex,
	Label,
	LayoutBox,
	NameValuePair,
	Section,
	Select
} from '@bamboohr/fabric';

import classNames from 'classnames';
import {
	useEffect,
	useState,
} from 'react';

import { CodesAndRates } from '../components/shared/codes-and-rates';
import {
	saveCodesRatesUpdate,
} from './utils';

export function UpdateCodesRates(props) {
	const {
		taxDetails: {
			codesAndRates,
			company,
			ein,
			taxTypeState,
			taxType,
			frequency,
			clientTaxTypeIncludeId: taxId,
			clientId,
		},
		effectiveDateOptions,
	} = props;
	const [hasError, setHasError] = useState(false);
	const [selectedDate, setSelectedDate] = useState(['']);
	const effectiveDateError = hasError && !selectedDate[0];
	const taxTypeDisplay = taxType || 'WA L&I'; // WA L&I is the default for now
	const effectiveDateLabelClasses = classNames(
		'fab-Label fab-Label--required',
		{ 'fab-Label--error': effectiveDateError }
	);
	const formattedEffectiveDateOptions = effectiveDateOptions.map((option) => {
		const {
			value,
			quarterDate,
			quarterText,
		} = option;
		return {
			text: `${ quarterText } (${ quarterDate })`,
			value,
		};
	});

	useEffect(() => {
		const saveButton = document.querySelector('button[data-action="SiteFooterAction:saveCodesRatesUpdate"]');
		saveButton.addEventListener('click', () => saveCodesRatesUpdate('.js-updateCodesRates', setHasError));
	}, []);

	return (
		<form className='js-updateCodesRates'>
			<Flex flexDirection="column" gap="40px">
				<input name="taxId" type="hidden" value={ taxId || '' } />
				<input name="clientId" type="hidden" value={ clientId || '' } />
				<Section title={ $.__('Tax Details') }>
					<Flex flexDirection="row" gap="40px" marginTop="24px">
					{ company && ein && <NameValuePair name={$.__('Company/EIN')} value={`${ company } — ${ ein }`} /> }
					{ taxTypeState && <NameValuePair name={$.__('State')} value={taxTypeState} /> }
					{ taxTypeDisplay && <NameValuePair name={$.__('Tax Type')} value={taxTypeDisplay} /> }
					{ frequency && <NameValuePair name={$.__('Pay Frequency')} value={frequency} /> }
					{ taxId && <NameValuePair name={$.__('Tax ID')} value={taxId} /> }
					</Flex>
				</Section>

				<Section title={ $.__('When will these changes take effect?') }>
					<Flex flexDirection="column" marginTop={3}>
						<Label className={ effectiveDateLabelClasses } htmlFor="effectiveDate">
							{ $.__('Effective Date') }
						</Label>
						<Select
							condition={ effectiveDateError ? 'error' : 'none' }
							items={ formattedEffectiveDateOptions }
							name="effectiveDate"
							onChange={ value => setSelectedDate(value) }
							selectedValues={ selectedDate }
							width="7"
						/>
					</Flex>
				</Section>
				<Section title={ $.__('Codes & Rates') }>
					<LayoutBox marginTop={3}>
						<CodesAndRates
							codesRatesData={ codesAndRates }
							hasError={ hasError }
							isCodesRatesEdit={ true }
							isTaxEdit={ false }
						/>
					</LayoutBox>
				</Section>
			</Flex>
		</form>
	);
}

