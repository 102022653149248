import React from 'react';
import PropTypes from 'prop-types';

import { BodyText, Divider, Headline, LayoutBox } from '@bamboohr/fabric';

/**
 * Wrap headline and sub-headline for use in ACA modal
 *
 * @param {object} props
 * @param {string} props.headlineText
 * @param {string} props.subHeadlineText
 * @returns {JSX.Element}
 */
export function HeadlineWrapper(props) {
	const {
		headlineText,
		subHeadlineText,
	} = props;

	return (
		<LayoutBox marginBottom={3}>
			<>
				<Headline
					color="neutral-strong"
					size="small"
				>{ headlineText }</Headline>
				<LayoutBox marginBottom={2.5}>
					<BodyText
						color="neutral-weak"
						component="p"
						size="large"
						weight='regular'
					>
						{ subHeadlineText }
					</BodyText>
				</LayoutBox>
			</>
			<Divider color='neutral-extra-weak' />
		</LayoutBox>
	);
}

HeadlineWrapper.propTypes = {
	headlineText: PropTypes.string.isRequired,
	subHeadlineText: PropTypes.string.isRequired,
};
