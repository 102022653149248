import {
	createContext,
	useContext as _useContext,
	useMemo,
	useReducer,
} from 'react';

import {
	reducer,
	initialState,
	State,
} from './state';
import * as Api from '../../api';

export type Actions = {
	[method in keyof typeof Api]: (...args: Parameters<typeof Api[method]>) => Promise<void>;
};

const StateContext = createContext({} as State);
const ActionContext = createContext({} as Actions);

export const useSettingsAppsState = (): State => _useContext(StateContext);
export const useSettingsAppsActions = (): Actions => _useContext(ActionContext);
export const useSettingsApps = (): [State, Actions] => [useSettingsAppsState(), useSettingsAppsActions()];

export const SettingsAppsProvider: React.FunctionComponent<React.PropsWithChildren<{ value?: Partial<State> }>> = ({
	value,
	children,
}) => {
	const [state, dispatch] = useReducer(reducer, {
		...initialState,
		...value,
	});
	const actions = useMemo(() => ({
		saveConfig: (...args) => Api.saveConfig(...args)
			.then((data) => {
				dispatch({ type: 'updateInstalledApps', ...data });
				return data;
			}),
		uninstall: (...args) => Api.uninstall(...args)
			.then((data) => {
				dispatch({ type: 'updateInstalledApps', ...data });
				return data;
			}),
	} as Actions), [dispatch]);

	return (
		<StateContext.Provider value={ state }>
			<ActionContext.Provider value={ actions }>
				{ children }
			</ActionContext.Provider>
		</StateContext.Provider>
	);
};

SettingsAppsProvider.displayName = 'SettingsAppsProvider';
