import { Dropdown } from '@fabric/dropdown';
import { isEnabled } from 'FeatureToggle.util';
import { usePlaidLink } from 'react-plaid-link';

interface LaunchPlaidProps {
	plaidLinkToken: string;
	isAddAccount: boolean;
	onSuccess(token: string, metadata: object): void;
	onError(error: ErrorEvent): void;
	clickManualUpdate: () => void;
	clickManualAdd: () => void;
}

enum DropdownActions {
	LOGIN = 'login',
	MANUAL = 'manual',
	ADD = 'add',
}

export function PlaidOrManualDropdown(props: LaunchPlaidProps): JSX.Element {
	const {
		plaidLinkToken: token,
		isAddAccount,
		onSuccess,
		onError,
		clickManualAdd,
		clickManualUpdate,
	} = props;
	const { open, ready, error } = usePlaidLink({ token, onSuccess });
	const PLAID_ADOPTION_AND_SUFFICIENT_BALANCE = isEnabled(
		'PLAID_ADOPTION_AND_SUFFICIENT_BALANCE',
	);

	if (error) {
		onError(error);
	}

	const title = isAddAccount
		? $.__('+ Add Bank Account')
		: $.__('Change Account Info');
	const options = [
		{
			text: PLAID_ADOPTION_AND_SUFFICIENT_BALANCE
				? $.__(`Connect account with Plaid`)
				: $.__(`Log in to my bank and select an account`),
			value: 'login',
		},
		isAddAccount
			? { text: $.__(`Manually add my account`), value: 'add' }
			: { text: $.__(`Manually change my account`), value: 'manual' },
	];

	const userSelectOption = (selectedValue: DropdownActions) => {
		switch (selectedValue) {
			case DropdownActions.LOGIN:
				open();
				break;
			case DropdownActions.MANUAL:
				clickManualUpdate();
				break;
			case DropdownActions.ADD:
				clickManualAdd();
				break;
			default:
				// Do nothing
				break;
		}
	};

	return (
		<Dropdown
			isDisabled={!ready || !!error}
			items={options}
			onSelect={userSelectOption}
			placement={{
				align: 'start',
				side: 'bottom',
			}}
			type="text"
		>
			{title}
		</Dropdown>
	);
}
