import { useEffect, useState } from 'react';
import { Modal } from 'modal-legacy';

import { DeductionMigrationConfigure } from './deduction-migration-configure';
import { postDeductionMigrationModalData } from './service';

export function DeductionMigrationModal(props) {
	const { individualPlans, isOpen, rollupPlans, useLegacyTrigger } = props;
	const [isConfirmSheetOpen, setIsConfirmSheetOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(isOpen);
	const [isProcessing, setIsProcessing] = useState(false);
	const [rollupPlansToValidate, setRollupPlansToValidate] = useState({});
	const [individualPlansToValidate, setIndividualPlansToValidate] = useState(
		{},
	);
	const [rollupPlansToPost, setRollupPlansToPost] = useState([]);
	const [individualPlansToPost, setIndividualPlansToPost] = useState([]);

	useEffect(() => {
		if (useLegacyTrigger) {
			BambooHR.subscribe(
				'benefitDeductionDate:modal:open',
				(data) => {
					setIsModalOpen(data.isOpen);
				},
				this,
			);
			setIsModalOpen(true);
		}
	}, []);

	useEffect(() => {
		setIsModalOpen(isOpen);
	}, [isOpen]);

	function cleanRollupData(data) {
		return [].concat(data).map((rollup) => {
			return {
				type: rollup.type,
				deductionRuleNumber: rollup.deductionRuleNumber,
				deductionRuleDirection: rollup.deductionRuleDirection,
			};
		});
	}

	function cleanIndividualData(data) {
		return [].concat(data).map((individual) => {
			return {
				id: individual.id,
				deductionRuleNumber: individual.deductionRuleNumber,
				deductionRuleDirection: individual.deductionRuleDirection,
			};
		});
	}

	function handleConfigChange(key, values, isRollup) {
		let tempPlans;
		if (isRollup) {
			tempPlans = rollupPlansToValidate;
		} else {
			tempPlans = individualPlansToValidate;
		}
		if (values) {
			tempPlans[key] = values;
		} else {
			delete tempPlans[key];
		}
		if (isRollup) {
			setRollupPlansToValidate(tempPlans);
			setRollupPlansToPost(Object.values(tempPlans));
		} else {
			setIndividualPlansToValidate(tempPlans);
			setIndividualPlansToPost(Object.values(tempPlans));
		}
	}

	function handleModalPostpone() {
		setIsModalOpen(false);
	}

	return (
		<Modal
			alternativeAction={handleModalPostpone}
			alternativeActionText={$.__('Do this later')}
			icon={'envelope-open-dollar-solid'}
			isOpen={isModalOpen}
			isProcessing={isProcessing}
			onClose={handleModalPostpone}
			primaryAction={
				rollupPlans &&
				rollupPlansToPost &&
				individualPlans &&
				individualPlansToPost &&
				rollupPlansToPost.length === rollupPlans.length &&
				individualPlansToPost.length === individualPlans.length
					? () => {
							setIsConfirmSheetOpen(true);
					  }
					: null
			}
			primaryActionText={$.__('Save')}
			sheetProps={{
				alternativeActionText: $.__('Go back'),
				content: (
					<DeductionMigrationConfigure
						individualPlans={individualPlansToPost}
						rollupPlans={rollupPlansToPost}
						subHeadingContent={
							<span>
								{$.__('Please verify this is correct.')}
								<span className="DeductionMigrationConfigure__subHeadingWarning">
									{$.__(' You will not be able to change this setting.')}
								</span>
							</span>
						}
					/>
				),
				icon: 'triangle-exclamation-regular',
				iconColor: 'attention',
				isOpen: isConfirmSheetOpen,
				isProcessing: isProcessing,
				onClose: () => {
					setIsConfirmSheetOpen(false);
				},
				primaryAction: () => {
					setIsProcessing(true);
					postDeductionMigrationModalData({
						rollupPlans: cleanRollupData(rollupPlansToPost),
						individualPlans: cleanIndividualData(individualPlansToPost),
					}).then(
						() => {
							window.setMessage($.__('Deduction Migration Saved.'), 'success');
							location.reload();
						},
						(error) => {
							setIsProcessing(false);
							console.error(error);
							window.setMessage($.__('Something went wrong.'), 'error');
						},
					);
				},
				primaryActionText: $.__('Yes, This is Correct'),
				title: $.__('Just Checking...'),
				type: 'mediumSheet',
			}}
			title={$.__('Quick question...')}
			type="medium"
		>
			<DeductionMigrationConfigure
				individualPlans={individualPlans}
				isConfigureMode={true}
				onConfigChange={handleConfigChange}
				rollupPlans={rollupPlans}
				subHeadingContent={
					<span>
						{$.__(
							'Do employees pre-pay or post-pay for their benefits? (In the past, post-pay was the only option.) ',
						)}
						<a
							className="DeductionMigrationConfigure__subHeadingLink"
							href="https://help.bamboohr.com/hc/en-us/articles/360045030774-Benefit-Deduction-Date-Settings"
							rel="noreferrer noopener"
							target="_blank"
						>
							{$.__('Learn more.')}
						</a>
					</span>
				}
			/>
		</Modal>
	);
}
