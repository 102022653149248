import { Component } from 'react';
import { IconButton, TextButton} from "@bamboohr/fabric";
import { PayeeModal } from 'payroll/modals/payee.react';
import { ConfirmDelete } from '../confirm-delete';
import { GarnishmentDeleteModal } from '../garnishment-delete-modal';
import { PayeeEmployeeModal } from '../payee-employee-modal/payee-employee-modal'
import './payee-row.styl';

import { GlobalPayeeEditModal } from '../global-payee-edit-modal/global-payee-edit-modal';

export class PayeeRow extends Component {
	_getStateById = (id) => {
		const { states } = this.props;
		const { abbrev } = states.find(state => state.id === id);
		return abbrev;
	};

	_handleEditClick = () => this.setState({ showEditModal: true });

	_handleEmployeeClick = () => this.setState({ showEmployeeModal: true });

	_handleDeleteClick = () => {
		const { canBeDeleted } = this.props;

		if (canBeDeleted) {
			this.setState({ showDeleteModal: true });
		} else {
			this.setState({ showGarnishmentDeleteModal: true });
		}
	};

	_handleCloseModal = (type) => {
		this.setState({ [type]: false });
	};

	_handleDeleteSuccess = (id) => {
		const { onDeleteSuccess } = this.props;

		this.setState({ showDeleteModal: false });

		if (typeof onDeleteSuccess === 'function') {
			onDeleteSuccess(id);
		}
	};

	_gatherGarnishmentPayeeInfo = () => {
		const {
			payeeName,
			payeeEmployees,
		} = this.props;

		return {
			payeeName,
			payeeEmployees,
		};
	};

	_getGarnishmentType = (garnishmentType) => {
		if(garnishmentType === 'LS' || garnishmentType === 'LF') {
			return 'Tax Levy'
		}
		if(garnishmentType === 'CS') {
			return 'Child Support'
		}
		return 'Other Garnishment'
	}

	_getPayeeEmployeesCount = (id, garnishmentType) => {
		const { byType, payeeEmployees } = this.props;

		if(garnishmentType !== undefined) {
			const payeeEmployeeArray = [];
			const globalPayeesById = byType.globalPayeeEmployees.byId;
			const globalPayeesByIdArray = Object.values(globalPayeesById);
			const globalPayee = globalPayeesByIdArray.find((payee) => payee.id == id);
			const globalEmployees = new Set();
			const uniqueEmployees = globalPayee.employees.filter(employee => {
				if(globalEmployees.has(employee.employeeId)) {
					return false;
				}
				globalEmployees.add(employee.employeeId);
				return true;
			});
			payeeEmployeeArray.push( ...uniqueEmployees )
			const employeeCount = payeeEmployeeArray.length;
			return employeeCount;
		}
		return payeeEmployees.length;
	}

	state = {
		showEditModal: false,
		showEmployeeModal: false,
		showDeleteModal: false,
		showGarnishmentDeleteModal: false,
	};

	render() {
		const {
			allowEditPayee,
			byType,
			payees,
			garnishmentType,
			id,
			payee,
			payeeName,
			payeeEmployees,
			addressLine1,
			addressLine2,
			city,
			state,
			stateId,
			zipcode,
			phone,
			email,
			onSaveSuccess,
		} = this.props;
		const {
			showEditModal,
			showEmployeeModal,
			showDeleteModal,
			showGarnishmentDeleteModal,
		} = this.state;

		const getGarnishmentType = this._getGarnishmentType(garnishmentType);
		const getPayeeEmployeesCount = this._getPayeeEmployeesCount(id, garnishmentType);
		const garnishmentDeleteModalData = this._gatherGarnishmentPayeeInfo();

		return (
            <tr className="BhrTable__row fab-Table__row">
                { allowEditPayee ? (
					<td className="BhrTable__cell fab-Table__cell">
						<TextButton
							key="edit-payee-button"
							onClick={ this._handleEditClick }
							type="button"
						>
							{ payeeName }
						</TextButton>
					</td>
				) : (
					<td className="BhrTable__cell fab-Table__cell">{ payeeName }</td>
				)}
                <td className="BhrTable__cell fab-Table__cell">
					<p>{ addressLine1 }</p>
					<p>{ addressLine2 }</p>
					<p>{ city }, { stateId ? this._getStateById(stateId) : state} { zipcode }</p>
				</td>
                <td className="BhrTable__cell fab-Table__cell">{ getGarnishmentType }</td>
                <td className="BhrTable__cell fab-Table__cell PayeeRow__employees truncate">
					{
						getPayeeEmployeesCount
						? <TextButton
							className='PayeeRow__employee-count-link'
							key='employee-modal-button'
							onClick={ this._handleEmployeeClick }
							type='button'
						>
							{ getPayeeEmployeesCount }
						</TextButton>
						: <span className='PayeeRow__employee-count'>{ getPayeeEmployeesCount }</span>
					}
				</td>
                <td className="BhrTable__cell fab-Table__cell txtRight PayeeRow__actions">
					<div className="fab-Table__cellMask">
						<div className="fab-Table__cellActions">
							<div className="fab-Table__cellAction">
								<IconButton
                                    clickAction={ this._handleDeleteClick }
                                    icon="trash-can-regular"
                                    outline={ true }
                                    secondary={ true }
                                />
							</div>
						</div>
					</div>
				</td>
                { garnishmentType === undefined && allowEditPayee ? (
					<PayeeModal
						isFromSettingsPage={ true }
						onClose={ () => this._handleCloseModal('showEditModal') }
						onSaveSuccess={ onSaveSuccess }
						payeeData={ this.props }
						type="edit"
						visible={ showEditModal }
					/>
				) : (
					<GlobalPayeeEditModal
						onClose={ () => this._handleCloseModal('showEditModal') }
						payee={ payee }
						type="edit"
						visible={ showEditModal }
					/>
				) }
                { showEmployeeModal && (
					<PayeeEmployeeModal
						byType={ byType }
						employeeCount={ this._getPayeeEmployeesCount(id, garnishmentType) }
						garnishmentType={ garnishmentType }
						isOpen={ showEmployeeModal }
						onClose={ () => this._handleCloseModal('showEmployeeModal') }
						payee={ payee }
						type="edit"
					/>
				)}
                <ConfirmDelete
					id={ id }
					onClose={ () => this._handleCloseModal('showDeleteModal') }
					onDelete={ this._handleDeleteSuccess }
					payeeName={ payeeName }
					visible={ showDeleteModal }
				/>
                <GarnishmentDeleteModal
					byType={ byType }
					data={ garnishmentDeleteModalData }
					garnishmentType={ garnishmentType }
					onClose={ () => this._handleCloseModal('showGarnishmentDeleteModal') }
					payee={ payee }
					payeeName={ payeeName }
					employeeCount={ this._getPayeeEmployeesCount(id, garnishmentType) }
					visible={ showGarnishmentDeleteModal }
				/>
            </tr>
        );
	}
}
