import {
	BlankState,
	BodyText,
	Flex,
	LayoutBox,
	TextButton,
} from '@bamboohr/fabric';
import { ExternalLink, Message } from '@bamboohr/utils/lib/message';
import { LaunchPlaid } from '../../../components/launch-plaid';
import {
	LinkPlaidStateProps,
	PlaidMetadata,
	handlePlaidLinkSuccess,
} from './utils';

export function LinkPlaidState(props: LinkPlaidStateProps): JSX.Element {
	const {
		plaidLinkToken,
		handleLinkSuccess,
		clientId,
		toggleBankInfoLoading,
		onAddManually,
	} = props;

	const manualAddBankMessage = (
		<BodyText color="neutral-weak" size="small" weight="regular">
			<Message
				link={(text: string) => (
					<TextButton inline={true} onClick={onAddManually} type="button">
						{text}
					</TextButton>
				)}
				text={$._("Can't find your bank? [Add it manually.]")}
			/>
		</BodyText>
	);

	return (
		<Flex alignItems="center" flexDirection="column" gap={4} marginTop={18}>
			<BlankState
				actions={[
					<LaunchPlaid
						key="addAccount"
						buttonVariant="outlined"
						buttonText={$.__('Add Account')}
						onError={(error) => console.log(error)}
						onSuccess={(token: string, metadata: PlaidMetadata): void => {
							handlePlaidLinkSuccess(
								token,
								metadata,
								clientId,
								handleLinkSuccess,
								toggleBankInfoLoading,
							);
						}}
						plaidLinkToken={plaidLinkToken}
					/>,
				]}
				icon={'building-columns-regular'}
				subtitle={
					//Used to prevent wrap in middle of link
					<LayoutBox maxWidth={'450px'} textAlign="center">
						<Message
							link={ExternalLink('https://help.bamboohr.com/s/article/1309887')}
							text={$._(
								'We use Plaid to securely link your bank account to BambooHR. [Learn more]',
							)}
						/>
					</LayoutBox>
				}
				title={$.__('Add Your Bank Account')}
			/>
			{manualAddBankMessage}
		</Flex>
	);
}
