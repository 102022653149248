import { Flex, LayoutBox } from '@bamboohr/fabric';
import React, { ReactElement } from 'react';
import { renderHeaderButtons, renderHeaderDropdown } from './etc';
import { AccessLevelHeaderProps } from './types';

export function AccessLevelHeader(props: AccessLevelHeaderProps): ReactElement {
	const { headerData, employeeModal, mainTemplateData } = props;

	const headerButtons = renderHeaderButtons(headerData, employeeModal).map(
		(button, index) => <React.Fragment key={index}>{button}</React.Fragment>,
	);

	return (
		<>
			{/*Remove this check when we refactor the no access page (LOCK-491) */}
			{headerData.levelType !== 'no_access' ? (
				<Flex justifyContent="space-between">
					{headerButtons.length > 0 && (
						<Flex flexDirection="row" gap={1.5} paddingBottom={2}>
							{headerButtons}
						</Flex>
					)}
					<LayoutBox paddingBottom={2}>
						{renderHeaderDropdown(headerData, mainTemplateData)}
					</LayoutBox>
				</Flex>
			) : null}
		</>
	);
}
export default AccessLevelHeader;
