import { BodyText, LayoutBox } from '@bamboohr/fabric';

export const StatusTooltipContent = (): JSX.Element => {
    return (
        <LayoutBox>
            <BodyText component="strong" size="medium">{$.__('Additional Details Needed')}</BodyText>
            <BodyText>{$.__('You will not be able to manage enrollments in this plan until all missing information is provided and the plan is tied to a Plan Year.')}</BodyText>
        </LayoutBox>
    )
}
