import {
	Button,
	Icon,
	LayoutBox,
	StandardModal,
	makeStyles,
} from '@bamboohr/fabric';
import { colors } from '@bamboohr/fabric/dist/definitions/json/colors.json';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Modal } from 'modal-legacy';

type ModalConfig = {
	alternativeAction: null | (() => void);
	isOpen: boolean;
	onClose: () => void;
	primaryAction: () => void;
	primaryActionText: string;
	title: string;
	type: 'medium' | 'aggressiveConfirmation' | 'custom' | 'fullScreen' | 'small';
};

const _getModalConfig = (
	visible: boolean,
	onClose: () => void,
	topic: string,
	isSmall: boolean,
): ModalConfig => ({
	alternativeAction: null,
	isOpen: visible,
	onClose,
	primaryAction: onClose,
	primaryActionText: $.__('Done'),
	title: topic,
	type: isSmall ? 'fullScreen' : 'medium',
});

const useStyles = makeStyles({
	iconWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '16px',
		listItem: '24px',
	},
	header: {
		fontSize: '18px',
		lineHeight: '26px',
		textAlign: 'center',
		color: colors.gray10,
	},
	list: {
		listStyle: 'decimal',
		paddingLeft: '16px',
	},
	listItem: {
		marginBottom: '16px',
		paddingLeft: '10px',
	},
	marginBottom: {
		marginBottom: '16px',
	},
});

type AnonymityModalProps = {
	visible: boolean;
	onClose: () => void;
	screenSize: { isSmallScreen: boolean };
};

export const AnonymityModal: React.FC<React.PropsWithChildren<AnonymityModalProps>> = ({
	visible,
	onClose,
	screenSize: { isSmallScreen },
}) => {
	const classes = useStyles();
	return ifFeature(
		'encore',
		<>
			<StandardModal isOpen={visible} onRequestClose={onClose}>
				<StandardModal.Body
					renderFooter={
						<StandardModal.Footer
							actions={[
								<></>,
								<Button key="done-button" onClick={onClose} type="button">
									{$.__('Done')}
								</Button>,
							]}
						/>
					}
					renderHeader={
						<StandardModal.Header
							hasCloseButton={true}
							title={$.__('Survey Anonymity')}
						/>
					}
				>
					<StandardModal.Constraint>
						<StandardModal.HeroHeadline
							icon="user-secret-solid"
							iconColor="neutral-extra-strong"
							text={$.__(
								'Is the Employee Satisfaction Survey Really Anonymous?',
							)}
						/>
						<LayoutBox marginTop={2}>
							<p className={classes.marginBottom}>
								{$.__(
									'Employee Satisfaction (eNPS) in BambooHR is completely anonymous, helping you feel free to provide honest feedback that can lead to real improvements. In order to ensure anonymity, we have taken the following steps:',
								)}
							</p>
						</LayoutBox>
						<ol className={classes.list}>
							<li className={classes.listItem}>
								{$.__(
									'All employees in BambooHR are assigned an Employee #. We assign a random number to that Employee # and we tie your basic employee demographic information along with it. (Employees are not anonymous at this stage)',
								)}
							</li>
							<li className={classes.listItem}>
								{$.__(
									'We send you the Employee Satisfaction email survey. When you click to complete the survey, it takes you to a separate URL where the Employee # and Random number have been deleted* from the database. Only your demographic information is tied to your response.',
								)}
							</li>
						</ol>
						<p>
							{$.__(
								'*This information is completely deleted from our database so we are not able to look up who sent which responses.',
							)}
						</p>
					</StandardModal.Constraint>
				</StandardModal.Body>
			</StandardModal>
		</>,
		<Modal
			{..._getModalConfig(
				visible,
				onClose,
				$.__('Survey Anonymity'),
				isSmallScreen,
			)}
		>
			<div className={classes.iconWrapper}>
				<Icon brand={true} name="fab-spy-48x40" />
			</div>
			<h2 className={classes.header}>
				{$.__('Is the Employee Satisfaction Survey Really Anonymous?')}
			</h2>
			<p className={classes.marginBottom}>
				{$.__(
					'Employee Satisfaction (eNPS) in BambooHR is completely anonymous, helping you feel free to provide honest feedback that can lead to real improvements. In order to ensure anonymity, we have taken the following steps:',
				)}
			</p>
			<ol className={classes.list}>
				<li className={classes.listItem}>
					{$.__(
						'All employees in BambooHR are assigned an Employee #. We assign a random number to that Employee # and we tie your basic employee demographic information along with it. (Employees are not anonymous at this stage)',
					)}
				</li>
				<li className={classes.listItem}>
					{$.__(
						'We send you the Employee Satisfaction email survey. When you click to complete the survey, it takes you to a separate URL where the Employee # and Random number have been deleted* from the database. Only your demographic information is tied to your response.',
					)}
				</li>
			</ol>
			<p>
				{$.__(
					'*This information is completely deleted from our database so we are not able to look up who sent which responses.',
				)}
			</p>
		</Modal>,
	);
};
