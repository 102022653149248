import { Headline, LayoutBox } from '@bamboohr/fabric';
import React, { FC } from 'react';
import { SectionDivider } from './section-divider';

export interface FormSectionProps {
	title: string;
}

export const FormSection: FC<React.PropsWithChildren<FormSectionProps>> = ({ children, title }) => {
	return (
		<fieldset>
			<SectionDivider />
			<LayoutBox marginTop={2} marginBottom={1.5}>
				<Headline size={'small'}>{title}</Headline>
			</LayoutBox>
			{children}
		</fieldset>
	);
};
