import {
	Fragment,
} from 'react';
import {
	Button,
} from '@fabric/button';

import {
	useReducerContext,
} from '../state';
import { BodyText, LayoutBox } from '@bamboohr/fabric';

export default function ToggleButton() {
	const {
		isEnabled,
		dispatch,
		TOGGLE,
	} = useReducerContext();

	return (
		<Fragment>
			<LayoutBox marginBottom={1.5} marginTop={2.5}>
				<BodyText color='neutral-strong' component='p' id='section-headline' size='large' weight="medium">
					{ isEnabled ? (
							$.__('ACA Features are Currently Enabled')
						) : (
							$.__('ACA Features are Currently Disabled')
					) }
				</BodyText>
			</LayoutBox>
			<Button
				clickAction={ () => dispatch(TOGGLE()) }
				data-bi-id={ `settings-aca-settings-aca-tracking-${ isEnabled ? 'disable' : 'enable' }-button` }
				text={ isEnabled ? $.__('Disable ACA Tracking') : $.__('Enable ACA Tracking') }
				type='submit'
			/>
		</Fragment>
	);
}
