import { useEffect, useState } from 'react';
import { CardSize, StandardModal, Button, Flex, Table, Headline } from '@bamboohr/fabric';
import Ajax from '@utils/ajax';

export function EmployeesAssignedModal(props) {
	const { isOpen, onClose, project } = props;
	const [employees, setEmployees] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (project.id) {
			setIsLoading(true);
			Ajax.get(`/settings/time_tracking/projects/employees/${project.id}`)
				.then((response) => {
					setEmployees(response.data);
					setIsLoading(false);
				})
				.catch(() => {
					onClose();
					window.errorFallBack();
				});
		}
	}, [project.id, onClose]);

	const columns = [
		{
			header: $.__('Name'),
			cell: (row) => (
				<a className='fab-Link' href={`/employees/employee.php?id=${row.id}`} id={row.id}>
					{row.name}
				</a>
			),
			sortBy: (row) => row.name,
		},
	];

	const header = (
		<Flex alignItems='center' justifyContent='space-between'>
			<Headline icon='user-group-regular' size='small'>
				{$.__('People on %1 (%2)', project.name, employees.length)}
			</Headline>
			<Button href={`/settings/time_tracking/projects/edit/${project.id}`} variant='outlined'>
				{$.__('Edit Project')}
			</Button>
		</Flex>
	);

	return (
		<StandardModal isLoading={isLoading} isOpen={isOpen} onRequestClose={onClose}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<Button key='done' onClick={onClose}>
								{$.__('Done')}
							</Button>,
						]}
					/>
				}
				renderHeader={<StandardModal.Header title={project.name} />}
				size={CardSize.MEDIUM}
			>
				<StandardModal.Constraint>
					<Flex flexDirection='column' gap={2}>
						{header}
						<Table
							caption={$.__('Employees Assigned to %1', project.name)}
							columns={columns}
							rowKey={(row) => row.id}
							rows={employees}
							stickyHeader={true}
						/>
					</Flex>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
