import { Ajax } from '@bamboohr/utils';
import { isEnabled } from '@bamboohr/utils/lib/feature';
import { noop } from 'lodash';
import { Fragment, useState, useCallback, useEffect, ReactElement } from 'react';
import { LayoutBox, RadioGroup, RadioGroupItemProps, TextButton, Tooltip } from '@bamboohr/fabric';
import { PoMicroFrontend } from 'micro-frontend.react';
import { DirectDeposit } from 'payroll/direct-deposit.react';
import { DirectDepositModal } from 'payroll/modals/direct-deposit-modal.react';
import { getIsPayrollDirectDepositUpdateUxEnabled } from 'payroll/direct-deposit.react/utils.js'

import { DirectDepositInfoProps } from '../types';
import { FormSection } from './form-section';

export function DirectDepositInfo(props: DirectDepositInfoProps): ReactElement {
	const [accounts, setAccounts] = useState<Record<string, unknown>[]>([]);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [label, setLabel] = useState<string>('');
	const [value, setValue] = useState<string>('');
	const [options, setOptions] = useState<RadioGroupItemProps[]>(null);
	const [editPermissionData, setEditPermissionData] = useState({});

	const { employeeId, employeeName, onChange, isPaidByCheck } = props;

	useEffect(() => {
		if (!getIsDirectDepositInternalPermissionsEnabled()) {
			return;
		}

		if (!employeeId) {
			setEditPermissionData({});
			return;
		}

		const directDepositKey = `direct_deposit.${employeeId}`;

		Ajax.get(`/api/perms?keys=${directDepositKey}`).then((response) => {
			setEditPermissionData((prevData) => ({
				...prevData,
				[employeeId]: !!response?.data?.[directDepositKey]?.edit,
			}));
		});
	}, []);

	useEffect(() => {
		setLabel($.__('How will %1$s get paid?', employeeName));
	}, [employeeName]);
	useEffect(() => {
		setOptions([
			{
				id: 'payByCheck',
				label: $.__('Pay by Check'),
				value: 'payByCheck',
			},
			{
				id: 'directDeposit',
				label: $.__('Direct Deposit'),
				value: 'directDeposit',
			},
		]);
	}, []);

	const getHasEditPermission = useCallback(() => {
		if (!getIsDirectDepositInternalPermissionsEnabled()) {
			return true;
		}

		return employeeId ? editPermissionData[employeeId] : undefined;
	}, [employeeId, editPermissionData]);

	const handleRadioChange = useCallback(
		(value: string) => {
			setValue(value === 'payByCheck' ? 'payByCheck' : 'directDeposit');
			onChange('isPaidByCheck', value === 'payByCheck', false);
		},
		[onChange]
	);

	const handleModalOpen = useCallback(() => {
		setModalOpen(true);
	}, []);

	const handleModalClose = useCallback(() => {
		setModalOpen(false);
	}, []);

	const handleSaveSuccess = useCallback(
		(newAccounts: any) => {
			setAccounts(newAccounts || []);
			handleModalClose();

			if (!newAccounts) {
				handleRadioChange('payByCheck');
			}
		},
		[handleModalClose, handleRadioChange]
	);

	const handleModalSaveSuccess = useCallback(
		(response: any) => {
			const {
				data: {
					direct_deposits: { BANK },
				},
			} = response;
			handleSaveSuccess(BANK);
		},
		[handleSaveSuccess]
	);

	if (!options) {
		return null;
	}

	const hasEditPermission = getHasEditPermission();
	const isDirectDepositUpdateUxEnabled = getIsPayrollDirectDepositUpdateUxEnabled();

	const WrapperComponent = hasEditPermission ? Fragment : Tooltip;

	if (isDirectDepositUpdateUxEnabled){
		//TODO: Add check for V2 Migration
		return (
			<FormSection title={$.__('Direct Deposit')}>
				<PoMicroFrontend
					customClassNamePrefix='employee-pay-info-direct-deposit-tab'
					employeeId={employeeId}
					inlineExperience={true}
					payroll={{ payrollServicesHost: window.PAYROLLSERVICES_HOST }}
					route='/employees/pay_info/direct_deposit_summary'
				/>
			</FormSection>
		)
	}

	return (
		<FormSection title={$.__('Direct Deposit')}>
			<WrapperComponent
				content={$.__("You don't have access to edit Direct Deposit.")}
				enterDelay={300}
			>
				<RadioGroup
					disabled={!hasEditPermission}
					items={options}
					label={label}
					onChange={({ value }) => handleRadioChange(value as string)}
					value={value}
				/>
			</WrapperComponent>
			{!isPaidByCheck && (
				<LayoutBox paddingLeft={2.5}>
					{accounts.length ? (
						<DirectDeposit
							deposits={accounts}
							employeeId={employeeId}
							hasTrax={true}
							onSaveSuccess={handleSaveSuccess}
							permissionLevel={3}
							useTextButton={true}
						/>
					) : (
						<div>
							{hasEditPermission ? (
								<TextButton
									onClick={handleModalOpen}
									type="button"
								>
									+ {$.__('Add Direct Deposit Account(s)')}
								</TextButton>
							) : (
								<Tooltip
									content={$.__("You don't have access to edit Direct Deposit.")}
									enterDelay={300}
								>
									<LayoutBox component="span" tabIndex={0}>
										<TextButton
											disabled={true}
											onClick={noop}
											type="button"
										>
											+ {$.__('Add Direct Deposit Account(s)')}
										</TextButton>
									</LayoutBox>
								</Tooltip>
							)}
							<DirectDepositModal
								accounts={accounts}
								employeeId={employeeId}
								hasEditPermission={hasEditPermission}
								hasTrax={true}
								onCancelClick={handleModalClose}
								onSaveSuccess={handleModalSaveSuccess}
								visible={modalOpen}
							/>
						</div>
					)}
				</LayoutBox>
			)}
		</FormSection>
	);
}

function getIsDirectDepositInternalPermissionsEnabled() {
	return isEnabled('DIRECT_DEPOSIT_INTERNAL_PERMISSIONS');
}
