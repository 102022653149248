import { BenefitPlanTypeEnumContract } from '../../constants';
import { PlanTypeEnumContract } from '../../types';

export type PaySymbol = 'fixed' | 'percent' | '';

export interface BenefitsBannerGetContract {
	showBanner: boolean;
}

export interface BenefitPlanCoverages {
	id: string;
	benefitCoverageId: string;
	numberOfEnrollments: number;
}
export interface BenefitPlanListContract {
	active: boolean;
	activeFuturePlanYearIds: string[];
	carrier: string;
	coverages: BenefitPlanCoverages[];
	eligible: EligibilityContract[];
	endDate: string;
	hasHadEnrollments: boolean;
	id: number;
	isDraft: boolean;
	isEndDateRequired: boolean;
	isRestorable: boolean;
	missingPlanDetails: boolean;
	planCategoryDataOptions: PlanCategoryDataOptionsContract | null;
	planName: string;
	scheduledChanges: string;
	startDate: string;
	status: BenefitPlanStatusContract;
	type: PlanTypeEnumContract;
}

export interface BenefitPlanContract {
	deductionRule: DeductionRuleContract;
	endYmd: string;
	id: number;
	isVariable: boolean;
	name: string;
	startYmd: string;
	type: BenefitPlanTypeEnumContract;
}

export interface BenefitPlanStatusContract {
	eligibleNotEnrolled: number;
	eligibleWhenMarkedManually: number;
	enrolled: number;
	notEnrolledOrWaived: number;
	electionCount: number;
	enrollmentWindowCount: number;
}

export interface BenefitSettingsContract {
	benefitPlans: BenefitPlanListContract[];
	hasTrax?: boolean;
	migrationsNeeded: {
		deductionDateRule: boolean;
	};
}

export interface PlanYearsContract {
	planYears: PlanYear[];
}

export interface PlanYear {
	id: string;
	name: string;
	planCount: number;
	startDate: string;
	endDate: string;
	isDraft: boolean;
	pendingElectionCount: number;
	approvedElectionCount: number;
	incompleteElectionCount: number;
	benefitPlanIds: string[];
	carrierCount: number;
	eligibleEmployeesCount: number;
}

export interface DeductionRuleContract {
	direction: string;
	number: number;
}

export interface DuplicatePlanContract {
	endYmd: string;
	name: string;
	startYmd: string;
}

export interface EligibilityContract {
	filters: string[];
	listOfEmployees: string[];
}

export interface EnrolledEmployeeContract {
	displayName: string;
	id: number;
	effectiveDate: string;
	employmentStatus: string;
}

export interface EligibleEmployeeContract {
	displayName: string;
	id: number;
}

export interface EligibleEmployeeRequestContract {
	employeeId: number;
	effectiveDate: string;
}

export interface MissingFieldsContract {
	areRequiredFieldsMissing: boolean;
}

export interface PlanCategoryDataOptionsContract {
	// calculatorSettings?: PlanCalculatorSettings; available from BE but not currently used
	typeId?: string | null;
}

type UnknownObject = Record<string, unknown>; // used as a stand-in to reduce the number of interfaces unnecessarily copied over from the Po repository

export interface CriticalIllnessCategoryDataOptionsContract
	extends PlanCategoryDataOptionsContract {
	typeId: string;
	employeeCoverageAmount: UnknownObject;
	spousePartnerCoverageAmount: UnknownObject | null;
	childCoverageAmount: UnknownObject | null;
}

export enum SupplementalPlanType {
	HospitalIndemnity = '8',
	CriticalIllness = '9',
	Accident = '10',
	Cancer = '11',
}
