import Ajax from '@utils/ajax';
import { AxiosResponse } from 'axios';
import { GoalSettingsData } from '../types';

export const getGoalSettingsData = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		Ajax.get('/settings/performance/home/goals')
			.then((response: AxiosResponse<GoalSettingsData>) => {
				const { data } = response;
				if (data && data?.success) {
					resolve(data);
				}

				reject(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
