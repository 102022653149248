import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette, typography }) => ({
    employeeAddress: {
        fontStyle: 'normal',
    },
    employeeContactInfo: {
        marginBottom: '15px',
    },
    employeeInfoLabel: {
        color: palette.gray[700],
        fontSize: typography.medium.fontSize,
    },
    employeeInfoSection: {
        '&:not(:first-of-type)': {
            marginTop: '20px',
        },
    },
    employeeModalDescription: {
        borderBottom: `1px solid ${palette.gray[300]}`,
        marginBottom: '16px',
        paddingBottom: '15px',
    },
}));