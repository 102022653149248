import { Ajax } from '@bamboohr/utils';
import {
	AiSummarizedFeedbackResponse,
	OneOnOnesFeatureCheckResponse,
	PerformanceToolsResponse,
} from './types';

export const getOneOnOnesFeatureCheck = () =>
	Ajax.get<OneOnOnesFeatureCheckResponse>('/settings/one-on-ones/tools');

export const getPerformanceTools = () =>
	Ajax.get<PerformanceToolsResponse>('/performance/tools');

export const updateUsingAiFeedback = () => {
	return Ajax.put<AiSummarizedFeedbackResponse>(
		'/settings/performance/home/ai-summarized-feedback',
	);
};

export const getUsingAiFeedback = () => {
	return Ajax.get<AiSummarizedFeedbackResponse>(
		'/settings/performance/home/ai-summarized-feedback',
	);
};
