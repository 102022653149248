import { keyBy } from 'lodash';

export enum JournalEntryAccountingIntegrationAppName {
	QuickBooksOnlineGeneralLedger = 'quickbooksOnlineGeneralLedger',
	XeroGeneralLedger = 'xeroGeneralLedger',
}

export const JOURNAL_ENTRY_ACCOUNTING_INTEGRATION_APP_NAME = keyBy(
	JournalEntryAccountingIntegrationAppName,
);
