import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { getGoalSettingsData } from './services/get-goal-settings-data';
import { ContextType, FiltersPayload, GoalSettingsData } from './types';

const Context = createContext<ContextType>(undefined);

export default Context;

interface Props {
	value: Record<string, unknown>;
	children: JSX.Element;
}

export const Provider = (props: Props): JSX.Element => {
	const { value, children } = props;

	const [selectedFilters, setSelectedFilters] = useState<FiltersPayload>(null);
	const [cancelButtonClicked, setCancelButtonClicked] = useState(false);
	const [usingCascadingGoals, setUsingCascadingGoals] = useState<boolean>(false);



	useEffect(() => {
		getGoalSettingsData().then((response: GoalSettingsData) => {
			const { filters, cascadingGoals } = response;
			const filtersSelected = { ...filters };

			setSelectedFilters(filtersSelected);
			setUsingCascadingGoals(cascadingGoals);
		});
	}, []);

	const state = useMemo(
		() => [
			{
				cancelButtonClicked,
				selectedFilters,
				usingCascadingGoals
			},
			{
				setCancelButtonClicked,
				setSelectedFilters,
				setUsingCascadingGoals,
			},
		],
		[cancelButtonClicked, selectedFilters, usingCascadingGoals]
	);

	return <Context.Provider value={state as ContextType}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useGoalSettingsContext = (): ContextType => useContext(Context);
