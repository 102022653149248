import {
	BodyText,
	Button,
	Checkbox,
	Flex,
	IconButton,
	IconV2,
	LayoutBox,
	Link,
	Table,
	TableColumn,
	Tooltip,
} from '@bamboohr/fabric';
import { useEffect, useState } from 'react';
import { usePerformanceSettingsContext } from '../../context';
import { ReviewCycleConfigurationData } from '../../types';
import { DeactivateReviewCycleModal } from './components/deactivate-review-cycle-modal.react';
import { DeleteReviewCycleModal } from './components/delete-review-cycle-modal.react';
import { ReviewCyclesBlankState } from './components/review-cycles-blank-state.react';
import { ScheduleIcon } from './components/schedule-icon.react';
import { getUsingAiFeedback, updateUsingAiFeedback } from '../../services';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const ReviewCyclesSettings = (): JSX.Element => {
	const [{ reviewCycleData, performanceTools }] =
		usePerformanceSettingsContext();
	const [activeRowData, setActiveRowData] = useState(
		{} as ReviewCycleConfigurationData,
	);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [toggleModalIsOpen, setToggleModalIsOpen] = useState(false);

	const [usingAiSummarizedFeedback, setUsingAiSummarizedFeedback] =
		useState(false);

	const onAiFeedbackCheckboxUpdate = () => {
		updateUsingAiFeedback().then((response) => {
			setUsingAiSummarizedFeedback(response.data.enabled);
		});
	};

	useEffect(() => {
		if (performanceTools.aiSummarizedFeedbackTool) {
			getUsingAiFeedback().then((response) => {
				setUsingAiSummarizedFeedback(response.data.enabled);
			});
		}
	}, []);

	const [activeToggles] = useState(() => {
		if (reviewCycleData?.length) {
			return Object.fromEntries(
				reviewCycleData.map((rcc) => {
					return [rcc.id, rcc.status === 'active'];
				}),
			);
		}

		return {};
	});

	const handleCreateReviewCycleClick = () => {
		window.location.href = '/settings/performance/home/setup';
	};

	const handleToggleChange = (row: ReviewCycleConfigurationData) => {
		setActiveRowData(row);
		setToggleModalIsOpen(true);
	};

	const toggleDeleteModal = (row) => {
		setActiveRowData(row);
		setIsDeleteModalOpen(true);
	};

	const columns: TableColumn<ReviewCycleConfigurationData>[] = [
		{
			header: $.__('Name'),
			key: 'name-key',
			cell: (row) => (
				<Flex alignItems="center" maxWidth="440px">
					<Link href={`/settings/performance/home/setup/${row.id}`}>
						{row.name}
					</Link>
				</Flex>
			),
			sortBy: (row) => row.name.toLowerCase(),
		},
		{
			align: 'center' as 'left' | 'center' | 'right',
			header: $.__('Schedule'),
			key: 'schedule-key',
			cell: (row) => {
				return <ScheduleIcon rowData={row} />;
			},
			width: '15%',
		},
		{
			align: 'center',
			header: $.__('Employees'),
			key: 'employees-key',
			cell: (row) => <p> {row.employees} </p>,
			width: '15%',
		},
		{
			align: 'center',
			header: $.__('Status'),
			key: 'active-key',
			cell: (row) => {
				let status = '';
				switch (row.status) {
					case 'inactive':
						status = $.__('Inactive');
						break;
					case 'active':
						status = $.__('Active');
						break;
					default:
						status = $.__('Draft');
				}

				return (
					<Flex justifyContent="center">
						<BodyText>{status}</BodyText>
					</Flex>
				);
			},
			sortBy: (row) => row.status,
			width: '15%',
		},
		{
			headerAriaLabel: $.__('Review cycle configuration utilities'),
			key: 'utility-wrapper',
			showOnHover: true,
			cell: {
				type: 'actions',
				actions: (row) => {
					const isReviewCycleActive =
						activeToggles[row.id] && row.status === 'active';
					const deactivateActionData = {
						icon: 'eye-slash-regular',
						action: () => handleToggleChange(row),
						tooltipContent: $.__('Deactivate'),
					};

					const actions = [
						{
							icon: 'trash-can-regular',
							action: () => toggleDeleteModal(row),
							tooltipContent: $.__('Delete'),
						},
					];

					if (isReviewCycleActive) {
						actions.unshift(deactivateActionData);
					}

					return actions;
				},
			},
			width: '10%',
		},
	];

	return reviewCycleData?.length ? (
		<>
			{ifFeature(
				'AI_SUMMARIZED_FEEDBACK_TOGGLE',
				<Flex marginY={3} alignItems="center" justifyContent="space-between">
					<Button
						data-bi-id="performance-settings-create-review-cycle"
						onClick={() => handleCreateReviewCycleClick()}
						size="medium"
						startIcon="plus-regular"
						type="button"
						color="secondary"
					>
						{$.__('Create a Review Cycle')}
					</Button>
					{performanceTools.aiSummarizedFeedbackTool && (
						<Checkbox
							label={
								<Flex justifyContent="center" gap={0.5}>
									<BodyText>{$.__('Include Feedback Summaries')}</BodyText>
									<Tooltip
										content={$.__(
											"Once an employee has three or more feedback responses, our AI tool will summarize them into a neat package for easy reading. (Note: only standard questions are included.)",
										)}
									>
										<IconButton
											noBoundingBox
											icon={
												<IconV2 color="neutral-medium" name="circle-info-regular" size={16} />
											}
											type="button"
										/>
									</Tooltip>
								</Flex>
							}
							checked={usingAiSummarizedFeedback}
							onChange={onAiFeedbackCheckboxUpdate}
							value="toggleFeedbackAISummary"
						/>
					)}
				</Flex>,
				<LayoutBox margin="24px 0">
					<Button
						data-bi-id="performance-settings-create-review-cycle"
						onClick={() => handleCreateReviewCycleClick()}
						size="medium"
						startIcon="plus-regular"
						type="button"
						color="secondary"
					>
						{$.__('Create a Review Cycle')}
					</Button>
				</LayoutBox>,
			)}

			<DeactivateReviewCycleModal
				activeRowData={activeRowData}
				modalIsOpen={toggleModalIsOpen}
				setModalIsOpen={setToggleModalIsOpen}
			/>
			<DeleteReviewCycleModal
				activeRowData={activeRowData}
				isDeleteModalOpen={isDeleteModalOpen}
				setIsDeleteModalOpen={setIsDeleteModalOpen}
			/>
			<Table
				caption={$.__('Review Cycle Configurations Table')}
				columns={columns}
				rowKey={(row: ReviewCycleConfigurationData) => row.id}
				rows={reviewCycleData}
				stickyHeader={true}
			/>
		</>
	) : (
		<ReviewCyclesBlankState />
	);
};
