import {
	BodyText,
	IconTile,
	LayoutBox,
	StandardModal,
} from '@bamboohr/fabric';

import {
	handleCloseModal,
} from '../domain/handle-close-modal';
import {
	ModalBodyConnector,
} from './modal-body-connector';
import {
	ModalFooterConnector,
} from './modal-footer-connector';
import {
	ModalHeaderConnector,
} from './modal-header-connector';
import {
	useStyles,
} from './modal.styles';

interface CompanyTaxSettingsModalProps {
	clientEin: string;
	clientName: string;
	isLoading: boolean;
	isProcessing: boolean;
}

export function Modal(props: CompanyTaxSettingsModalProps) {
	const {
		clientEin,
		clientName,
		isLoading,
		isProcessing,
	} = props;
	const classes = useStyles();
	return (
		<StandardModal
			isOpen={ true }
			isProcessing={ isLoading || isProcessing }
			onRequestClose={ handleCloseModal }
		>
			<StandardModal.Body
				renderFooter={ (
					<ModalFooterConnector />
				) }
				renderHeader={ (
					<ModalHeaderConnector />
				) }
			>
				<StandardModal.UpperContent>
					<div className={ classes.upperContainer }>
						<LayoutBox marginRight={ 2 }>
							<IconTile icon="buildings-regular" size={ 44 } />
						</LayoutBox>
						<BodyText size='medium' weight='bold'>
							{ clientName } ({ clientEin })
						</BodyText>
					</div>
				</StandardModal.UpperContent>
				<ModalBodyConnector />
			</StandardModal.Body>
		</StandardModal>
	);
}
