import React from "react";

import {
    BodyText,
    Button,
    Headline,
    LayoutBox,
    StandardModal
} from '@bamboohr/fabric';
import { useStyles } from './global-payee-edit-modal.styles';
import { Message } from "@bamboohr/utils/lib/message";


export function GlobalPayeeEditModal(props) {

    const {
        visible,
        onClose,
        payee,
    } = props;

    const styles = useStyles();

    return (
        <StandardModal isOpen={ visible } onRequestClose={ onClose }>
            <StandardModal.Body
                renderFooter={(
                    <StandardModal.Footer
                        actions={[
                            <Button
                                key="button"
                                onClick={ onClose }
                                type="button"
                            >
                                {$.__('Done')}
                            </Button>
                        ]}
                    />
                )}
                renderHeader={(
                    <StandardModal.Header
                        hasCloseButton={ true }
                        title={ ($.__('Payee Details')) }
                    />
                )}
            >
                <StandardModal.Constraint>
                    <section className={ styles.employeeModalDescription }>
                        <div className={ styles.modalTitleSection }>
                            <LayoutBox marginBottom={1}>
                                <Headline component="h5" icon="building-columns-regular" size="small">
                                    {$.__('Shared Payee')}
                                </Headline>
                            </LayoutBox>
                        </div>
                        <BodyText>
                            <Message text={$.__('This payee was set up by BambooHR and is shared by multiple organizations. **If any of the information for this payee needs to be updated, please contact our payroll support team.**')} />
                        </BodyText>
                    </section>
                    <section className={ styles.employeeContactInfo }>
                        <div className={ styles.employeeInfoSection }>
                            <div className={ styles.employeeInfoLabel }>{ $.__('Payee Name') }</div>
                            <div>{ payee.payeeName }</div>
                        </div>
                        <div className={ styles.employeeInfoSection }>
                            <div className={ styles.employeeInfoLabel }>{ $.__('Address') }</div>
                            <address className={ styles.employeeAddress }>
                                <div>{ payee.addressLine1 }</div>
                                <div>{ payee.addressLine2 }</div>
                                <div>{ payee.city }, { payee.state } { payee.zipcode }</div>
                            </address>
                        </div>
                        { payee.phone && (
                            <div className={ styles.employeeInfoSection }>
                                <div className={ styles.employeeInfoLabel }>{ $.__('Phone Number') }</div>
                                <div>{ payee.phone }</div>
                            </div>
                        ) }
                        { payee.email && (
                            <div className={ styles.employeeInfoSection }>
                                <div className={ styles.employeeInfoLabel }>{ $.__('Email') }</div>
                                <div>{ payee.email }</div>
                            </div>
                        ) }
                    </section>
                </StandardModal.Constraint>
            </StandardModal.Body>
        </StandardModal>
    );
}
