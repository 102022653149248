import {
	Modal,
} from 'modal-legacy';
import {
	useState,
} from 'react';
import {
	useReducerContext,
} from '../state';
import TranslationInput from '../translation-input';


export default function EditListModal({
	url = 'lists.php',
	params,
	isOpen,
	listid,
	langid,
	processing,
	data,
	useAjaxEffect,
	useSuccessEffect,
}) {
	const {
		dispatch,
		CLOSE_MODAL,
	} = useReducerContext();

	const [listsRT, setListsRT] = useState({});

	useAjaxEffect({
		method: 'get',
		url,
		params: {
			listid,
			langid,
			json: true,
			...(params || {}),
		},
	}, [listid, langid], isOpen);

	useSuccessEffect(() => {
		setListsRT(data.listsRT);
	});

	function onSave(newValue) {
		setListsRT({
			...listsRT,
			[newValue.remoteId]: newValue,
		});
	}

	return (
		<Modal
			alternativeAction={ null }
			isOpen={ isOpen }
			isLoading={ processing }
			onClose={ () => dispatch(CLOSE_MODAL()) }
			primaryAction={ () => dispatch(CLOSE_MODAL()) }
			primaryActionText={ $.__('Close') }
			title={ $.__('Translate List Values') }
		>
			{ (data && data.values && isOpen) && (
				<fieldset>
					{ data.values.map(listValue => (
						<div className="fab-FormRow" key={ listValue.id }>
							<div className="fab-FormColumn">
								<label
									className="fab-Label"
									htmlFor={ `field_trans_${ listValue.id }` }
								>
									{ listValue.label }
								</label>
								<div className="fab-InputWrapper">
									<TranslationInput
										item={ listValue }
										onSave={ onSave }
										resourceTranslations={ listsRT }
									/>
								</div>
							</div>
						</div>
					)) }
				</fieldset>
			) }
		</Modal>
	);
}

/**
 * @typedef FormData
 * @property {object[]} fields
 * @property {object} listsRT
 */
