import { IconV2, LayoutBox, TextButton } from '@bamboohr/fabric';
import { ENPS_ON_MOBILE_ANONYMITY_BANNER_ENABLED } from '../../../../../reports/shared/feature-toggles';
import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

type AnonymityBannerProps = {
	onLearnMoreClick: () => void;
};

const AnonymityBanner: React.FunctionComponent<React.PropsWithChildren<AnonymityBannerProps>> = ({ onLearnMoreClick }) => {
	return (
		<div className='AnonymityBanner'>
			{ifFeature(
				'encore',
				<LayoutBox marginTop={0.25}>
					<IconV2 name='user-secret-solid' size={38} color='neutral-forced-white' />
				</LayoutBox>,
				<ba-icon class='AnonymityBanner__icon' name='fab-spy-38x32' />
			)}
			<div className='AnonymityBanner__textContainer'>
				<span className='AnonymityBanner__text'>{$.__('Psst . . . You are now anonymous.')}</span>
				{ENPS_ON_MOBILE_ANONYMITY_BANNER_ENABLED ? (
					<TextButton
						className='AnonymityBanner__button'
						dark={ifFeature('encore', true)}
						onClick={onLearnMoreClick}
						inline={ifFeature('encore', true)}
					>
						{$.__('Learn more.')}
					</TextButton>
				) : (
					<a
						className='AnonymityBanner__link'
						href='https://help.bamboohr.com/hc/en-us/articles/360006948434-Employee-Satisfaction-from-the-Employee-s-Perspective'
						rel='noopener noreferrer'
						target='_blank'
					>
						{$.__('Learn more.')}
					</a>
				)}
			</div>
		</div>
	);
};

export default AnonymityBanner;
