import {
	BlankState,
	LayoutBox,
	Table,
} from '@bamboohr/fabric';
import React from 'react';
import { getColumns } from './helpers';

export const InvoiceHistoryTable = ({ billingCurrency, onRowSelect, rows }) => {
	return (
		<LayoutBox position="relative">
			{rows.length > 0 && (
				<Table
					caption={$.__('Invoice History')}
					columns={getColumns({ billingCurrency })}
					initialSort={{
						columnIndex: 1,
						isAsc: false,
					}}
					onRowSelect={onRowSelect}
					rowKey={({ id }) => id}
					rows={rows}
				/>
			)}
			{rows.length === 0 && (
				<LayoutBox marginY={9}>
					<BlankState
						icon='bs-party-hat'
						subtitle={$.__('Please change your filter and try again.')}
						title={$.__(
							"Party's Over! There are no invoices for this time frame.",
						)}
					/>
				</LayoutBox>
			)}
		</LayoutBox>
	);
};
