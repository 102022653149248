import { BackupCodesContent } from 'multi-factor-authentication.mod';

import '../interstitial-backup-codes/interstitial-backup-codes.styl';

interface BackupCode {
	backupCode: string;
	dateGenerated: string;
	dateUsed: string | null;
	id: string;
	userId: number
}

export interface InterstitialBackupCodesProps {
	onDone: () => void;
	userId: number;
	onStartProcessing(type: boolean): void;
	onEndProcessing(type: boolean): void;
	onGenerateBackupCodes?: (codes: Array<BackupCode>) => void;
	printRequestCount: number;
}

export function BackupCodesApp(props: InterstitialBackupCodesProps): JSX.Element {
	const { userId, onEndProcessing, onStartProcessing, onGenerateBackupCodes, printRequestCount } = props;

	return (
		<>
			<div className='InterstitialBackupCodes__content'>
				<div className='InterstitialBackupCodes__codes'>
					<BackupCodesContent
						onEndGeneratingNewCodes={(codes?: Array<BackupCode>) => {
							onEndProcessing(false);
							if (codes && onGenerateBackupCodes) {
								onGenerateBackupCodes(codes);
							}
						}}
						onStartGeneratingNewCodes={() => onStartProcessing(true)}
						printRequestCount={printRequestCount}
						userId={userId}
					/>
				</div>
			</div>
		</>
	);
}
