import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(() => ({
	dropdown: {
		margin: '0 1px',
		maxWidth: '365px',
		maxHeight: '358px',
		width: '300px',
	},
}));
