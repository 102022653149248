import { ReactElement, useState } from 'react';
import {
	Flex,
	IconV2,
	LayoutBox,
	StyledBox,
} from '@bamboohr/fabric';

import { UpsellBannerContent } from './upsell-banner-content';
import { UpsellBannerVideoPreview } from './upsell-banner-video-preview';
import { BenefitsAdminUpsellModal } from '../';

interface BenefitsAdminUpsellBannerProps {
	dismissBanner: () => void;
	isCompanyInTrial: boolean;
	onDemoRequest: (data) => void;
}

export function BenefitsAdminUpsellBanner(
	props: BenefitsAdminUpsellBannerProps,
): ReactElement {
	const { dismissBanner, isCompanyInTrial, onDemoRequest } = props;
	const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);

	const handleOpenUpsellModal = () => {
		setIsUpsellModalOpen(true);
	};

	const handleCloseUpsellModal = () => {
		setIsUpsellModalOpen(false);
	};

	return (
		<StyledBox
			backgroundColor="neutral-medium"
			borderRadius="small"
			boxShadow="small"
			marginBottom={3}
			paddingX={2}
			paddingY={3}
		>
			<Flex alignItems="start" gap={2}>
				<LayoutBox marginTop={0.5}>
					<IconV2 color="primary-strong" name="message-heart-solid" size={24} />
				</LayoutBox>
				<Flex justifyContent="space-between" gap={2}>
					<UpsellBannerContent
						dismissBanner={dismissBanner}
						onClick={handleOpenUpsellModal}
					/>
					<UpsellBannerVideoPreview onClick={handleOpenUpsellModal} />
				</Flex>
			</Flex>
			<BenefitsAdminUpsellModal
				isCompanyInTrial={isCompanyInTrial}
				isOpen={isUpsellModalOpen}
				onClose={handleCloseUpsellModal}
				onDemoRequest={onDemoRequest}
			/>
		</StyledBox>
	);
}
