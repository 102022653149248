import { PlanTypeEnumContract } from '../types';

export const SMALL_PLAN_ICONS = {
	[PlanTypeEnumContract.health]: 'fab-heart-ekg-16x13',
	[PlanTypeEnumContract.dental]: 'fab-tooth-16x16',
	[PlanTypeEnumContract.vision]: 'fab-eye-16x12',
	[PlanTypeEnumContract.supplemental]: 'fab-heart-plus-20x17',
	[PlanTypeEnumContract.retirement]: 'fab-piggy-bank-16x16',
	[PlanTypeEnumContract.reimbursement]: 'fab-money-reverse-16x16',
	[PlanTypeEnumContract.hsa]: 'fab-heart-money-16x14',
	[PlanTypeEnumContract.flex]: 'fab-wallet-16x15',
	[PlanTypeEnumContract.life]: 'fab-medical-shield-14x16',
	[PlanTypeEnumContract.disability]: 'fab-wheelchair-14x16',
	[PlanTypeEnumContract.other]: 'fab-star-16x15',
};

export const getSmallPlanIcon = (planType: PlanTypeEnumContract): string => {
	return SMALL_PLAN_ICONS[planType] ? SMALL_PLAN_ICONS[planType] : '';
};
