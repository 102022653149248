import { Component, Fragment, createRef } from 'react';

import { isEqual } from 'lodash';

import { BodyText, defaultSorter, LayoutBox, ProgressiveLoader, Table } from '@bamboohr/fabric';
import { PoMicroFrontend } from 'micro-frontend.react';

import Ajax from '@utils/ajax';

import { showSlidedown } from 'Alerts.mod';
import { isEnabled } from 'FeatureToggle.util';

import moment from 'moment';

import { getReverseEmploymentSuffix } from '../../components/modals/domain'
import { DeleteTaxModal } from '../modals/delete-tax-modal';
import { EndTaxModal } from '../modals/end-tax-modal';

import { formatTableData, getTaxesTableColumns, getTaxesTableGroups, isTaxActive, paginateRowData } from './utils';

import './taxes-table.styl';
import { UnlockTaxFrequencyModal } from './components';

export class TaxesTable extends Component {
	constructor(props) {
		super(props);
		const { allowableEndDates, companyTaxes, filters, tableType } = props;

		const { filteredTaxes, rowData, paginatedRowData } = formatTableData(companyTaxes, filters, tableType);
		const tpauEnabled = isEnabled('TPAU_VIEW_ACCESS');

		this.state = {
			processing: false,
			companyTaxes,
			currentPage: 1,
			currentRowData: paginatedRowData[0],
			formattedDates: allowableEndDates,
			totalRowData: rowData,
			paginatedRowData,
			modalType: null,
			targetRowData: {},
			tpauEnabled,
			filteredTaxes,
		};
	}

	_rowDisabled(row) {
		return !isTaxActive(row);
	}

	_deleteTax = () => {
		this.setState({
			processing: true,
		});
		const {
			targetRowData: { group, clientTaxTypeIncludeId, taxTypeEngine, taxTypeName },
		} = this.state;

		Ajax.delete(`/settings/payroll/ajax/taxes/delete_tax_type/${group}/${clientTaxTypeIncludeId}`)
			.then((response) => {
				if (response.status === 200) {
					const message = taxTypeEngine === 'OptionalEmployeePaidTax' || taxTypeEngine === 'PaidFamilyMedicalLeaveV2'
				    ? $.__('2 taxes have been deleted, %1$s and %2$s.', taxTypeName, getReverseEmploymentSuffix(taxTypeName))
					: $.__('%1$s has been deleted.', taxTypeName);

					this._refreshTableData(() => showSlidedown(message, 'success'));
				}
			})
			.catch(() => {
				this._clearModalData(() => showSlidedown($.__(`Uh oh...something went wrong, and your tax wasn't deleted. Try it again?`), 'error')
				);
			});
	};

	_endTax = (endDate) => {
		this.setState({
			processing: true,
		});

		const {
			targetRowData: { group, clientTaxTypeIncludeId, taxTypeEngine, taxTypeName },
		} = this.state;

		const params = {
			clientId: group,
			clientTaxTypeIncludeId,
			endDate,
		};

		Ajax.put('/settings/payroll/ajax/taxes/end_tax_type', params)
			.then((response) => {
				if (response.status === 200) {
					let slidedownString = $.__('The end date has been successfully removed.');

					if (endDate) {
						const formattedEndDate = moment(endDate, 'YYYY-MM-DD').format(moment.defaultFormat);
						const message = taxTypeEngine === 'OptionalEmployeePaidTax' || taxTypeEngine === 'PaidFamilyMedicalLeaveV2'
						? $.__('2 taxes have been set to end on %1$s, %2$s and %3$s.', formattedEndDate, taxTypeName, getReverseEmploymentSuffix(taxTypeName))
						: $.__('%1$s has been set to end on %2$s.', taxTypeName, formattedEndDate);
		
						slidedownString = message;
					}

					this._refreshTableData(() => showSlidedown(slidedownString, 'success'));
				} else {
					this._clearModalData(() => showSlidedown($.__(`Uh oh...something went wrong, and your tax wasn't ended. Try it again?`), 'error')
					);
				}
			})
			.catch(() => {
				this._clearModalData(() => showSlidedown($.__(`Uh oh...something went wrong, and your tax wasn't ended. Try it again?`), 'error')
				);
			});
	};

	_refreshTableData = (callbackFunction) => {
		return Ajax.get('/settings/payroll/ajax/taxes/tax_type')
			.then((response) => {
				const { filters, tableType } = this.props;
				const { companyTaxes } = response.data;

				const { filteredTaxes, rowData, paginatedRowData } = formatTableData(companyTaxes, filters, tableType);

				const { currentPage } = this.state;
				const currentPageIndex = currentPage - 1;

				this.setState(
					{
						companyTaxes,
						processing: false,
						filteredTaxes,
						totalRowData: rowData,
						paginatedRowData,
						currentRowData: paginatedRowData[currentPageIndex],
					},
					this._clearModalData(callbackFunction)
				);
			})
			.catch(() => {
				this._clearModalData(() => showSlidedown($.__(`Uh oh...something went wrong. Please try to refresh your page.`), 'error'));
			});
	};

	_clearModalData = (callbackFunction) => {
		this.setState(
			{
				modalType: null,
				processing: false,
				targetRowData: {},
			},
			() => {
				if (callbackFunction && typeof callbackFunction === 'function') {
					callbackFunction();
				}
			}
		);
	};

	_handleTableAction = (value, rowData) => {
		const { clientTaxTypeIncludeId, group, taxTypeName } = rowData;

		// Trigger Add, End, Delete, or Edit
		switch (value) {
			case 'unlockFederalTaxFrequency':
			case 'emailSupport':
			case 'delete':
			case 'end':
				this.setState({
					modalType: value,
					targetRowData: rowData,
				});
				break;

			case 'add':
				window.location.href = `/settings/payroll/taxes/tax_type/add_tax_rate?clientId=${group}&taxTypeName=${taxTypeName}&clientTaxTypeIncludeId=${clientTaxTypeIncludeId}`;
				break;

			case 'updateCodesRates':
				window.location.href = `/settings/payroll/taxes/tax_type/update_codes_rates?clientId=${group}&taxId=${clientTaxTypeIncludeId}`;
				break;

			case 'edit':
			case 'readOnly':
				window.location.href = `/settings/payroll/taxes/edit_tax?clientId=${group}&taxTypeName=${taxTypeName}&clientTaxTypeIncludeId=${clientTaxTypeIncludeId}`;
				break;
				
			default:
		}
	};

	_isTaxFrequencyLocked = (clientId) => {
		return this.state.companyTaxes.find((companyTaxObject) => companyTaxObject.clientId === clientId)?.isFederalTaxFrequencyLocked;
	};

	_handleTableSort = (columnIndex, sortBy, sortAsc) => {
		this._sortBy = sortBy;
		this._sortAsc = sortAsc;

		this._sortData();
	};

	_sortData = () => {
		// if there's no sort info, don't try to sort
		if(this._sortBy === null || this._sortAsc === null){
			return;
		}

		const { totalRowData } = this.state;

		const sortedRows = defaultSorter(totalRowData, this._sortBy, this._sortAsc);
		const paginatedRowData = paginateRowData(sortedRows);

		this.setState({
			currentPage: 1,
			paginatedRowData,
			currentRowData: paginatedRowData[0],
		});
	}

	_handlePageChange = (pageData) => {
		const { nextPage } = pageData;

		this.setState((prevState) => ({
			currentPage: nextPage,
			currentRowData: prevState.paginatedRowData[nextPage - 1],
		}));

		this._taxesTableDivRef.current.scrollIntoView();
	};

	_sortBy = null
	_sortAsc = null
	_taxesTableDivRef = createRef();

	componentDidMount(){
		const { tableType } = this.props;
		if (tableType === "setupTaxes") {
			this._handleTableSort(0, (row) => row.currentTaxSetupStatus.priority, true);
		}
	}

	componentDidUpdate(prevProps) {
		const { filters, tableType } = this.props;
		const { companyTaxes } = this.state;

		if (!isEqual(filters, prevProps.filters)) {
			const { filteredTaxes, rowData, paginatedRowData } = formatTableData(companyTaxes, filters, tableType);

			this.setState({
				filteredTaxes,
				currentRowData: paginatedRowData[0],
				totalRowData: rowData,
				paginatedRowData,
				currentPage: 1
			}, () => {
				this._sortData();
			});
		}
	}


	render() {
		const { allowableEndDates, tableType } = this.props;

		const { currentPage, currentRowData, filteredTaxes, totalRowData, modalType, targetRowData, processing, companyTaxes, tpauEnabled } =
			this.state;

		const groupsData = getTaxesTableGroups(filteredTaxes);
		const totalRowDataLength = totalRowData.length;

		return (
			<Fragment>
				{tpauEnabled ? <LayoutBox marginTop={2}><BodyText size="emphasis-extra-small" weight="semibold">{tableType === 'completeTaxes' ? 'Taxes' : 'Tax Set Up'}</BodyText></LayoutBox> : null}
				<div className='TaxesTable' ref={this._taxesTableDivRef}>
					<Table
						caption={tableType === 'setupTaxes' ? 'A table of taxes that need to be set up.' : 'A table of taxes.'}
						columns={getTaxesTableColumns(this._handleTableAction, this._isTaxFrequencyLocked, this._refreshTableData, tableType)}
						groups={groupsData}
						note={tpauEnabled && tableType === 'setupTaxes' && !currentRowData 
							? <BodyText color="neutral-weak">No new taxes to set up. You are on top of your tax maintenance!</BodyText>
						: undefined}
						onSort={this._handleTableSort}
						rowKey={(row) => row.clientTaxTypeIncludeId}
						rowMutedBy={(row) => this._rowDisabled(row)}
						rows={currentRowData}
					/>
				</div>

				{totalRowDataLength > 50 && (
					<ProgressiveLoader
						currentPage={currentPage}
						onPageChange={this._handlePageChange}
						pageSize='50'
						pagination={true}
						totalItemsCount={totalRowDataLength}
					/>
				)}

				{modalType === 'end' && (
					<EndTaxModal
						allowableEndDates={allowableEndDates}
						clearModalData={this._clearModalData}
						endTax={this._endTax}
						processing={processing}
						targetRowData={targetRowData}
					/>
				)}

				{modalType === 'delete' && (
					<DeleteTaxModal
						clearModalData={this._clearModalData}
						deleteTax={this._deleteTax}
						processing={processing}
						targetRowData={targetRowData}
					/>
				)}

				{/* Renders Payroll Support Modal - custom memory route in Po settings/payroll/routes.tsx */}
				<PoMicroFrontend
					biId='payroll-settings-email-tax-admin-modal'
					clientId={targetRowData.group}
					clientName={companyTaxes.find((companyTax) => targetRowData.group === companyTax.clientId)?.clientName}
					isOpen={modalType === 'emailSupport'}
					onClose={this._clearModalData}
					route='/settings/payroll/emailTaxAdminModal'
				/>

				<UnlockTaxFrequencyModal
					clientId={targetRowData.group}
					handleCloseModal={this._clearModalData}
					isOpen={modalType === 'unlockFederalTaxFrequency'}
					reloadTable={this._refreshTableData}
				/>
			</Fragment>
		);
	}
}
