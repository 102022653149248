import { Flex, LayoutBox, Section } from '@bamboohr/fabric';
import { Component } from 'react';

import { TaxTypeDetails } from '../components/tax-type-details/tax-type-details';

import moment from 'moment';

import { CompanyTaxField } from '../components/company-tax-field/company-tax-field';

import { renderDateSelectOption } from '../utils';

import { redirect } from 'BambooHR.util';

import { showSlidedown } from 'Alerts.mod';

import {
	AddTaxRateFieldName,
	AddTaxRateFieldProps,
	AddTaxRateState,
	createInitialState,
	saveNewRate,
} from './utils';

export interface AddTaxRateProps {
	/**
	 * List of allowable effective dates to be displayed in the dropdown
	 */
	allowableEffectiveDates: Array<{
		/** @example YYYY-MM-DD */
		value: string;
		/** @example "Current Quarter", "2nd Quarter", "3rd Quarter", "4th Quarter", "Start of Next Year", etc */
		quarterText: string;
		/** @example M/D/YYYY */
		quarterDate: string;
		/** @example "menu-item-[number]" */
		id: string;
	}>;
	/**
	 * List of allowable pay frequencies to be displayed in the dropdown
	 * @example { 27: "Quarterly", etc }
	 */
	allowablePayFrequencies: Record<number, string>;
	/** The ID for the client */
	clientId: number;
	/** Name and EIN for the client */
	clientInfo: {
		/** Name of the client */
		name: string;
		/** The EIN number of the client/location */
		ein: string;
	};
	/** The client tax type include id */
	clientTaxTypeIncludeId: number;
	employerTaxId: string;
	isCalculated: boolean;
	/** The maximum allowed tax rate */
	maxTaxRate: number;
	/** The minimum allowed tax rate */
	minTaxRate: number;
	/** The current tax rate */
	taxRate: number;
	/** The name of the tax type */
	taxTypeName: string;
	/** The state for the tax type */
	taxTypeState: string;
}

export class AddTaxRate extends Component<AddTaxRateProps, AddTaxRateState> {
	_successCallback = () => {
		const { effectiveDate } = this.state;

		const formattedEffectiveDate = moment(
			effectiveDate.value,
			'YYYY-MM-DD',
		).format(moment.defaultFormat);

		const { taxTypeName } = this.props;
		redirect(
			'/settings/payroll/taxes',
			$.__(
				'Your rate change for %1$s has been saved and will take effect on %2$s.',
				taxTypeName,
				formattedEffectiveDate,
			),
			'success',
		);
	};

	_failureCallback = (errorMessage?: string) => {
		if (errorMessage) {
			showSlidedown(errorMessage, 'error');
		} else {
			showSlidedown(window.DEFAULT_ERROR_MESSAGE, 'error');
		}

		document.dispatchEvent(new CustomEvent('SiteFooterActions:endProcessing'));
	};

	_saveTaxRate = () => {
		document.dispatchEvent(
			new CustomEvent('SiteFooterActions:startProcessing'),
		);

		const callbackFunctions = {
			success: this._successCallback,
			failure: this._failureCallback,
		};

		saveNewRate(
			this.state,
			this.props,
			this._updateFormData,
			callbackFunctions,
		);
	};

	_updateFormData = (
		field: AddTaxRateFieldName,
		updates: Partial<AddTaxRateFieldProps>,
	) => {
		this.setState((prevState) => ({
			...prevState,
			[field]: {
				...prevState[field],
				...updates,
			},
		}));
	};

	state = createInitialState(this.props);

	componentDidMount() {
		const footerSaveButton = document.querySelector(
			'[data-action="SiteFooterAction:saveTaxRate"]',
		);
		footerSaveButton.addEventListener('click', this._saveTaxRate);
	}

	componentWillUnmount() {
		const footerSaveButton = document.querySelector(
			'[data-action="SiteFooterAction:saveTaxRate"]',
		);
		footerSaveButton.removeEventListener('click', this._saveTaxRate);
	}

	render() {
		const { clientInfo, employerTaxId, taxTypeName, taxTypeState } = this.props;

		const { effectiveDate, payFrequencyId, taxRate } = this.state;

		const taxTypeDetails = [];

		if (clientInfo) {
			taxTypeDetails.push({
				header: $.__('Company/EIN'),
				value: `${clientInfo.name} (${clientInfo.ein})`,
			});
		}

		if (taxTypeState) {
			taxTypeDetails.push({
				header: $.__('State'),
				value: taxTypeState,
			});
		}

		if (taxTypeName) {
			taxTypeDetails.push({
				header: $.__('Tax Type'),
				value: taxTypeName,
			});
		}

		if (employerTaxId) {
			taxTypeDetails.push({
				header: $.__('Tax ID'),
				value: employerTaxId,
			});
		}

		return (
			<div>
				<LayoutBox marginBottom={5}>
					<AddTaxRateSection sectionHeader={$.__('Tax Details')}>
						<TaxTypeDetails details={taxTypeDetails} />
					</AddTaxRateSection>
				</LayoutBox>
				<AddTaxRateSection sectionHeader={$.__('New Rate')}>
					<CompanyTaxField
						{...effectiveDate}
						fieldType="select"
						label={$.__('Effective Date')}
						renderOptionContent={renderDateSelectOption}
						renderToggleContent={renderDateSelectOption}
						updateFormData={this._updateFormData}
						width={8}
					/>
					<CompanyTaxField
						{...payFrequencyId}
						fieldType="payFrequency"
						label={$.__('Pay Frequency')}
						placeholder="First Select Tax Type"
						selectedTaxType={this.props}
						updateFormData={this._updateFormData}
						width={8}
					/>
					<CompanyTaxField
						{...taxRate}
						fieldType="taxRate"
						label={$.__('Tax Rate')}
						selectedTaxType={this.props}
						unit="%"
						updateFormData={this._updateFormData}
					/>
				</AddTaxRateSection>
			</div>
		);
	}
}

interface AddTaxRateSectionProps {
	children: React.ReactNode;
	sectionHeader: string;
}

function AddTaxRateSection(props: AddTaxRateSectionProps) {
	const { children, sectionHeader } = props;

	return (
		<Section title={sectionHeader}>
			<Flex flexDirection="column" gap={2} marginTop={2}>
				{children}
			</Flex>
		</Section>
	);
}
