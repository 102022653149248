import { SelectProps } from '@bamboohr/fabric';
import Ajax from '@utils/ajax';

import { isTaxRateValid } from '../components/company-tax-field/utils';
import { AddTaxRateProps } from './add-tax-rate';

/**
 * The field names for the AddTaxRate component
 */
export type AddTaxRateFieldName =
	| 'effectiveDate'
	| 'payFrequencyId'
	| 'taxRate'
	| 'notes';

/**
 * The props for a field in the AddTaxRate component
 */
export type AddTaxRateFieldProps = {
	name: AddTaxRateFieldName;
	editable?: boolean;
	items?: SelectProps['items'];
	required: boolean;
	disabled: boolean;
	error: boolean;
	value: string | null;
	width?: number;
};

/**
 * The state for the AddTaxRate component
 */
export type AddTaxRateState = Record<AddTaxRateFieldName, AddTaxRateFieldProps>;

/**
 * Creates the initial state for the AddTaxRate component
 * @param addTaxRateData The props for the AddTaxRate component
 * @returns the initial state
 */
export function createInitialState(
	addTaxRateData: AddTaxRateProps,
): AddTaxRateState {
	return {
		effectiveDate: {
			name: 'effectiveDate',
			items: addTaxRateData.allowableEffectiveDates,
			required: true,
			disabled: false,
			error: false,
			value: null,
		},
		payFrequencyId: {
			name: 'payFrequencyId',
			items: formatPayFrequencies(addTaxRateData.allowablePayFrequencies),
			required: true,
			disabled: true,
			error: false,
			value: null,
		},
		taxRate: {
			name: 'taxRate',
			editable: true,
			required: true,
			disabled: false,
			error: false,
			value: null,
			width: 3,
		},
		notes: {
			name: 'notes',
			required: false,
			disabled: false,
			error: false,
			value: null,
		},
	};
}

/**
 * Converts the allowable pay frequencies object into an array of { value: number, text: string }
 * @param payFrequencies allowable pay frequencies object
 * @returns an array of { value: number, text: string }
 */
function formatPayFrequencies(
	payFrequencies: AddTaxRateProps['allowablePayFrequencies'],
) {
	const formattedPayFrequencies: Array<{ value: number; text: string }> = [];

	for (const key in payFrequencies) {
		formattedPayFrequencies.push({
			value: parseInt(key),
			text: payFrequencies[key],
		});
	}

	return formattedPayFrequencies;
}

/**
 * Adds clientTaxTypeIncluded, clientId and the state to the save object
 * @param saveObject the object to be saved
 * @param taxTypeData the Add Tax Rate Props
 * @returns a new object with the added properties
 */
function formatForSave(
	saveObject: Record<string, string | number>,
	taxTypeData: AddTaxRateProps,
) {
	const newSaveObject = { ...saveObject };

	newSaveObject.clientTaxTypeIncludeId = taxTypeData.clientTaxTypeIncludeId;
	newSaveObject.clientId = taxTypeData.clientId;
	newSaveObject.state = taxTypeData.taxTypeState;

	return newSaveObject;
}

/**
 * Validates and saves the new rate
 * @param formData The state of the AddNewTax component
 * @param taxTypeData the props of the AddNewTax component
 * @param updateFormData Function to update the component's state if there's an error
 * @param callbackFunctions Callback functions to run after the save is complete or if there's an error
 */
export function saveNewRate(
	formData: AddTaxRateState,
	taxTypeData: AddTaxRateProps,
	updateFormData: (name: string, fieldData: { error: boolean }) => void,
	callbackFunctions: {
		success: () => void;
		failure: (errorMessage?: string) => void;
	},
) {
	let readyForSave = true;
	let saveObject: Record<string, string | number> = {};

	for (const key in formData) {
		const fieldData = formData[key as AddTaxRateFieldName];
		if (!fieldData.disabled && fieldData.required) {
			let rowValid = true;
			if (!fieldData.value || fieldData.value === '') {
				rowValid = false;
			}

			// Check min/max values
			if (key === 'taxRate') {
				const { minTaxRate, maxTaxRate } = taxTypeData;

				// eslint-disable-next-line max-depth
				if (
					!isTaxRateValid(parseFloat(fieldData.value), minTaxRate, maxTaxRate)
				) {
					rowValid = false;
				}
			}

			if (rowValid) {
				saveObject[fieldData.name] = fieldData.value;
			} else {
				readyForSave = false;
				updateFormData(fieldData.name, { error: true });
			}
		} else {
			saveObject[fieldData.name] = fieldData.value;
		}
	}

	if (readyForSave) {
		saveObject = formatForSave(saveObject, taxTypeData);

		Ajax.post('/settings/payroll/ajax/taxes/add_new_tax_rate', saveObject)
			.then((result) => {
				if (result.status === 200) {
					callbackFunctions.success();
				} else {
					callbackFunctions.failure(
						$.__('There was a problem. Please try again or contact support.'),
					);
				}
			})
			.catch(() => {
				callbackFunctions.failure(
					$.__('There was a problem. Please try again or contact support.'),
				);
			});
	} else {
		callbackFunctions.failure(
			$.__('Whoops...please fill out all required fields and try again.'),
		);
	}
}
