import {
	BodyText,
	Button,
	LayoutBox,
	StandardModal,
	TextButton,
} from '@bamboohr/fabric';
import { useState } from 'react';
import { usePerformanceSettingsContext } from '../../../../context';
import { ReviewCycleConfigurationData } from '../../../../types';
import { deactivateReviewCycleConfiguration } from '../../services/deactivate-review-cycle-configuration';

interface Props {
	activeRowData: ReviewCycleConfigurationData;
	modalIsOpen: boolean;
	setModalIsOpen: (isOpen: boolean) => void;
}

export const DeactivateReviewCycleModal = (props: Props): JSX.Element => {
	const { activeRowData, modalIsOpen, setModalIsOpen } = props;

	const [{ reviewCycleData }, { setReviewCycleData, setSlidedownData }] =
		usePerformanceSettingsContext();
	const { name, id } = activeRowData;
	const [isProcessing, setIsProcessing] = useState(false);

	const handleCancelClick = () => {
		setModalIsOpen(false);
	};

	const deactivateReviewCycle = () => {
		setIsProcessing(true);

		deactivateReviewCycleConfiguration(id)
			.then(() => {
				const copiedTableData = [...reviewCycleData];
				const affectedIndex = copiedTableData.findIndex((obj) => obj.id === id);
				copiedTableData[affectedIndex].status = 'inactive';

				setSlidedownData({
					message: $.__('Review Cycle was successfully deactivated.'),
					type: 'success',
				});
				setReviewCycleData(copiedTableData);
			})
			.catch(() => {
				setSlidedownData({
					message: $.__(
						'Oops, something went wrong. Try reloading the page and deactivating the review cycle again.',
					),
					type: 'error',
				});
			})
			.finally(() => {
				setIsProcessing(false);
				setModalIsOpen(false);
			});
	};

	return (
		<StandardModal
			isOpen={modalIsOpen}
			onRequestClose={() => setModalIsOpen(false)}
		>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<TextButton
								key="cancelButton"
								onClick={handleCancelClick}
								processing={isProcessing}
								type="button"
								data-bi-id="settings-performance-cancel-review-cycle-deactivation"
							>
								{$.__('Cancel')}
							</TextButton>,
							<Button
								key="activateButton"
								onClick={deactivateReviewCycle}
								processing={isProcessing}
								type="button"
								data-bi-id="settings-performance-confirm-review-cycle-deactivation"
							>
								{$.__('Deactivate')}
							</Button>,
						]}
					/>
				}
				renderHeader={<StandardModal.Header title={$.__('Just Checking...')} />}
			>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline
						icon="triangle-exclamation-regular"
						iconColor="warning-strong"
						text={$.__('Do you wish to deactivate %1 review cycle?', name)}
					/>
					<LayoutBox textAlign="center" paddingTop={0.5}>
						<BodyText>
							{$.__(
								'Employees in the review cycle will no longer be able to complete new assessments or feedback. However, past assessments or feedback will be available.',
							)}
						</BodyText>
					</LayoutBox>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
};
