import { LayoutBox, TextButton, makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ReactNode, MouseEvent, KeyboardEvent } from 'react';
import Slider from 'react-slick';

type CarouselProps = {
	children: ReactNode[];
};

const useStyles = makeStyles(({ palette, spacing }) => ({
	slickContainer: {
		'& .slick-list': {
			marginRight: ifFeature('encore', undefined, '-2px'),
			overflow: 'hidden',
		},
		'& .slick-slide': {
			height: 'inherit',
			/*
			 react-slick wraps each slide in a div
			 there is currently not a way to override this behavior
			 see following github issue https://github.com/akiran/react-slick/issues/1877
			 */
			'& > div': {
				height: 'calc(100% - 2px)',
			},
		},
		'& .slick-track': {
			display: 'flex',
			gap: ifFeature('encore', spacing(2.5)),
		},
	},

	/* @startCleanup encore */
	slickNext: {
		right: '-18px',

		'& $slickArrow': {
			transform: 'rotate(270deg)',
		},

		'&::before': {
			content: `''`,
			position: 'absolute',
			height: '48px',
			width: '26px',
			top: '-1px',
			left: '-1px',
			background: 'linear-gradient(to right, transparent, white 100%)',
		},
	},
	slickPrev: {
		left: '6px',

		'& $slickArrow': {
			transform: 'rotate(90deg)',
		},

		'&::before': {
			content: `''`,
			position: 'absolute',
			height: '48px',
			width: '26px',
			top: '-1px',
			left: '-1px',
			background: 'linear-gradient(to left, transparent, white 100%)',
		},
	},
	slickArrowContainer: {
		position: 'absolute',
		top: 'calc(50% - 23px)',
		zIndex: 2,
		backgroundColor: 'white',
		height: '46px',
		border: `1px solid ${palette.gray[500]}`,
		borderRadius: '2px',
		width: '24px',
		display: 'flex !important',
		justifyContent: 'center',
		alignItems: 'center',

		'&.slick-disabled': {
			display: 'none !important',
		},

		'&:hover': {
			cursor: 'pointer',
		},
	},
	slickArrow: {
		height: '12px',
		width: '20px',
		position: 'relative',

		'&::before': {
			content: `''`,
			position: 'absolute',
			top: '5px',
			left: 0,
			height: '3px',
			width: '51%',
			background: `linear-gradient(130deg, ${palette.gray[500]}, ${palette.gray[500]} 50%, white 130%)`,
			transform: 'skew(0deg, 40deg)',
		},

		'&::after': {
			content: `''`,
			position: 'absolute',
			top: '5px',
			right: 0,
			height: '3px',
			width: '50%',
			background: `linear-gradient(230deg, ${palette.gray[500]}, ${palette.gray[500]} 50%, white 130%)`,
			transform: 'skew(0deg, -40deg)',
		},
	},
	/* @endCleanup encore */
}));

type SpecificArrowProps = {
	className?: string;
	onClick?: (e: MouseEvent | KeyboardEvent) => void;
};

type GenericArrowProps = {
	slickClasses: string;
	arrowClassName: string;
	onClick: (e: MouseEvent | KeyboardEvent) => void;
};

const Carousel: React.FC<React.PropsWithChildren<CarouselProps>> = ({ children }) => {
	const classes = useStyles();

	/* @startCleanup encore */
	const Arrow = ({ onClick, slickClasses, arrowClassName }: GenericArrowProps) => (
		<div
			className={`${arrowClassName} ${classes.slickArrowContainer} ${slickClasses}`}
			onClick={onClick}
			onKeyPress={onClick}
			role='button'
		>
			<div className={classes.slickArrow}></div>
		</div>
	);
	/* @endCleanup encore */

	const PrevArrow = ({ onClick, className }: SpecificArrowProps) => {
		// The Slider component passes null if we are at the end of the list.
		// This is the only way to know if the button is disabled
		const disabled = !onClick;

		return ifFeature(
			'encore',
			<TextButton onClick={onClick} type='button' disabled={disabled} startIcon='arrow-left-regular'>
				{$.__('Previous')}
			</TextButton>,
			<Arrow arrowClassName={classes.slickPrev} onClick={onClick} slickClasses={className} />
		);
	};
	const NextArrow = ({ onClick, className }: SpecificArrowProps) => {
		const disabled = !onClick;

		return ifFeature(
			'encore',
			<LayoutBox position='absolute' top={0} right={0}>
				<TextButton onClick={onClick} disabled={disabled} type='button' endIcon='arrow-right-regular'>
					{$.__('More Surveys')}
				</TextButton>
			</LayoutBox>,
			<Arrow arrowClassName={classes.slickNext} onClick={onClick} slickClasses={className} />
		);
	};

	const settings = {
		draggable: false,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
		speed: 300,
		easing: 'easeOutQuint',
		cssEase: 'ease-in-out',
		className: classes.slickContainer,
	};

	// Needed, since react-slick gets messed up if we render the slider with no children
	if (children.length === 0) {
		return null;
	}

	return ifFeature(
		'encore',
		<LayoutBox position='relative'>
			<Slider {...settings}>{children}</Slider>
		</LayoutBox>,
		<Slider {...settings}>{children}</Slider>
	);
};

export default Carousel;
