import { Component } from 'react';
import { Modal } from 'modal-legacy';
import Ajax from '@utils/ajax';
import { Message, ExternalLink } from '@bamboohr/utils/lib/message';

import {
	Flex,
	BodyText,
	Table,
	Headline,
	CurrencyField,
	StyledBoxV2,
} from '@bamboohr/fabric';

const AMOUNT_REGEX = /^(\d*\.)?\d+$/;

export class AccountVerificationModal extends Component {
	_getSheetProps = () => {
		const { failedSheetOpen, successSheetOpen, verifyingSheetOpen } =
			this.state;

		if (verifyingSheetOpen) {
			return this._renderVerifyingSheet();
		}

		if (failedSheetOpen) {
			return this._renderFailedSheet();
		}

		if (successSheetOpen) {
			return this._renderSuccessSheet();
		}

		return null;
	};

	_renderFailedSheet = () => {
		const { onSubmit } = this.props;

		return {
			alternativeAction: null,
			title: $.__('Sorry, unable to verify account'),
			icon: 'lock-regular',
			iconColor: 'danger',
			iconV2Color: 'error-strong',
			isOpen: true,
			onClose: this._handleSheetClose,
			primaryActionText: $.__('Done'),
			primaryAction: () => {
				this._handleSheetClose();

				if (onSubmit) {
					onSubmit();
				}
			},
			header: (
				<Headline color="neutral-strong" size="small">
					{$.__(
						`You've exceeded the allowed number of attempts to enter the correct deposit amount.`,
					)}
				</Headline>
			),
			content: (
				<BodyText justify="center">
					<Message
						link={ExternalLink('mailto:payrollimp@bamboohr.com')}
						text={$._(
							'Please email [payrollimp@bamboohr.com] and we will help you complete your bank account verification.',
						)}
					/>
				</BodyText>
			),
		};
	};

	_renderSuccessSheet = () => {
		return {
			alternativeAction: null,
			title: $.__('Success!'),
			icon: 'badge-check-regular',
			iconColor: 'success',
			iconV2Color: 'primary-strong',
			isOpen: true,
			onClose: () => this._handleSheetClose(true),
			primaryActionText: $.__('Done'),
			primaryAction: () => this._handleSheetClose(true),
			header: (
				<Headline color="primary" size="small">
					{$.__(`Your account verification is complete`)}
				</Headline>
			),
			content: (
				<BodyText justify="center">
					{$.__(
						'Your company and bank account info has been verified. Your payroll project manager will be reaching out to assist you with completing your payroll setup.',
					)}
				</BodyText>
			),
		};
	};

	_renderVerifyingSheet = () => {
		return {
			alternativeAction: null,
			title: $.__('Verifying Deposit Amount'),
			icon: 'building-columns-regular',
			iconColor: 'success',
			iconV2Color: 'primary-strong',
			isOpen: true,
			onClose: () => this._handleSheetClose(true),
			primaryActionText: $.__('OK, Got It'),
			primaryAction: () => this._handleSheetClose(true),
			content: (
				<BodyText weight="semibold" size="large" justify="center">
					{$.__(
						'Thank you for entering the deposit amount. We will contact you once we receive a verification response.',
					)}
				</BodyText>
			),
		};
	};

	_handleSheetClose = (reloadPage) => {
		const { onClose, onSubmit } = this.props;

		this.setState({
			failedSheetOpen: false,
			successSheetOpen: false,
		});

		// Close parent modal as well
		window.closeMessage();
		onClose();

		if (reloadPage && onSubmit) {
			onSubmit();
		}
	};

	_handleAmountChangeFocus = (event) => {
		const { value } = event.target;

		if (value && value !== '0.00') {
			return;
		}

		this.setState({ amount: '0.00' });
	};

	_handleAmountChange = ({ amount }) => {
		this.setState({ amount: amount || '0.00', hasError: false });
	};

	_handleNeedHelpClick = () => {
		const url =
			'https://help.bamboohr.com/hc/en-us/articles/360036626833-Company-Bank-Account#do-you-need-help-confirming-the-penny-amount';

		window.open(url, '_blank');
	};

	_handleModalClose = () => {
		const { onClose } = this.props;

		setTimeout(() => {
			window.closeMessage();
		}, 1000);

		onClose();
	};

	_handleFormSubmit = () => {
		const { amount } = this.state;
		const { clientId, bankId } = this.props;

		if (!AMOUNT_REGEX.test(amount)) {
			this.setState({ hasError: true });
			window.setMessage(
				$.__('Oops, the deposit amount should be a valid dollar amount.'),
				'error',
			);
			return;
		}

		Ajax.post('/settings/payroll/ajax/bank_account/verify_pennies', {
			clientId,
			bankId,
			pennyAmount: amount,
		})
			.then((response) => {
				if (response.status === 200) {
					if (response.data.error === true) {
						if (response.data.timesRemaining === null) {
							this.setState({
								failedSheetOpen: true,
							});
						}

						window.setMessage(response.data.message, 'error');
						return;
					}

					if (response.data.isNpcOnboardingFlowManual) {
						this.setState({
							verifyingSheetOpen: true,
						});

						return;
					}

					this.setState({
						successSheetOpen: true,
					});
				}
			})
			.catch(() => {
				window.setMessage(
					$.__(
						'There was an error during the bank verification process. Please contact BambooHR Payroll Support',
					),
					'error',
				);
			});
	};

	state = {
		amount: '0.00',
		hasError: false,
		failedSheetOpen: false,
		successSheetOpen: false,
		verifyingSheetOpen: false,
	};

	render() {
		const { isOpen, bankName, accountNum } = this.props;
		const { amount, hasError } = this.state;

		return (
			<Modal
				additionalAction={this._handleNeedHelpClick}
				additionalActionText={$.__('Need help?')}
				headline={$.__('Enter the deposited amount to verify your account')}
				icon={'building-columns-regular'}
				iconV2Color="primary-strong"
				isOpen={isOpen}
				onClose={this._handleModalClose}
				primaryAction={this._handleFormSubmit}
				primaryActionKey="Enter"
				primaryActionText={$.__('Verify Account')}
				sheetProps={this._getSheetProps()}
				title={$.__('Bank Account Verification')}
			>
				<Flex flexDirection="column" gap={3} textAlign="center">
					<BodyText>
						{$.__(
							'We sent a small deposit to your %1$s %2$s account and retrieved it in one withdrawal. After it appears in your bank account activity, enter the amount below.',
							bankName,
							accountNum,
						)}
					</BodyText>

					<Table
						rows={[{ bankName, accountNum }]}
						rowKey={({ accountNum }) => accountNum}
						columns={[
							{
								header: $.__('Bank Statement'),
								cell: () => '...NPC00001',
								width: '100%',
							},
							{ cell: () => '$*.**' },
						]}
					/>

					<StyledBoxV2
						padding={3}
						backgroundColor="neutral-extra-weak"
						borderRadius="small"
					>
						<CurrencyField
							minValue={0}
							label={$.__('Enter deposit amount')}
							status={hasError ? 'error' : undefined}
							onChange={this._handleAmountChange}
							onFocus={this._handleAmountChangeFocus}
							value={{ amount }} // double curly intended here
							width={5}
						/>
					</StyledBoxV2>
				</Flex>
			</Modal>
		);
	}
}
