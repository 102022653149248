import { useEffect, useState } from "react";
import {
    Avatar,
    BodyText,
    Button,
    Divider,
    Flex,
    Headline,
    LayoutBox,
    StandardModal,
    TextButton
} from '@bamboohr/fabric';

export const PayeeEmployeeModal = ({
   byType,
   employeeCount,
   garnishmentType,
   isOpen,
   onClose,
   payee,
}) => {

    const [payeeEmployee, setPayeeEmployee] = useState(null);

    useEffect(() => {
        const payeeEmployeeArray = [];
        if(garnishmentType === undefined) {
            const customPayeeEmployees = payee.payeeEmployees;
            customPayeeEmployees.forEach((employee) => {
                const customPayeeEmployeesByIdArray = Object.values(byType.employees.byId);
                const customPayeeEmployee = customPayeeEmployeesByIdArray.find((employeeName) => employeeName.name === employee);
                payeeEmployeeArray.push(customPayeeEmployee);
            })
        } else {
            const globalPayeeById = payee.id;
            const globalPayeeEmployeesByIdArray = byType.globalPayeeEmployees.byId[globalPayeeById].employees;
            const globalEmployees = new Set();
            const uniqueEmployees = globalPayeeEmployeesByIdArray.filter(employee => {
                if(globalEmployees.has(employee.employeeId)) {
                    return false;
                }
                globalEmployees.add(employee.employeeId);
                return true;
            });
            payeeEmployeeArray.push( ...uniqueEmployees )
        }
        setPayeeEmployee(payeeEmployeeArray);
    }, [garnishmentType])

    return (
        <StandardModal byType={ byType } isOpen={ isOpen } onRequestClose={ onClose } payee={ payee }>
            <StandardModal.Body
                renderFooter={(
                    <StandardModal.Footer
                        actions={[
                            <Button
                                key="button"
                                onClick={ onClose }
                                type="button"
                            >
                                {$.__('Done')}
                            </Button>
                        ]}
                    />
                )}
                renderHeader={(
                    <StandardModal.Header
                        hasCloseButton={ true }
                        title={ ($.__('Employees With Garnishments')) }
                    />
                )}
            >
                <StandardModal.UpperContent>
                    <LayoutBox>
                        <Headline color="neutral-strong" component="h5" size="extra-small">
                            { payee.payeeName }
                        </Headline>
                        <BodyText color="neutral-weak" component="p">
                            {`${payee.addressLine1}${payee.addressLine2 ? ` ${payee.addressLine2}` : ''}, ${payee.city}, ${payee.state} ${payee.zipcode}`}
                        </BodyText>
                    </LayoutBox>
                </StandardModal.UpperContent>
                <StandardModal.Constraint
                    spacingOverrides={{
                        top: StandardModal.Constraint.Spacing.NONE,
                        bottom: StandardModal.Constraint.Spacing.NONE,
                        right: StandardModal.Constraint.Spacing.NONE,
                        left: StandardModal.Constraint.Spacing.NONE,
                    }}
                >
                    <LayoutBox>
                        <BodyText color="neutral-medium" size="medium">
                            {$.__n(
                                'Garnishments for %1$s employee are being paid to this payee.',
                                'Garnishments for %1$s employees are being paid to this payee.',
                                employeeCount
                            )}
                        </BodyText>
                        <LayoutBox marginBottom={3} marginTop={2}>
                            {payeeEmployee && payeeEmployee.map((employee, index) => (
                                <>
                                    <Flex alignItems="center" gap={1.5} key={employee.id || employee.employeeId}>
                                        <Avatar
                                            alt={ $.__('Image of %1', employee.name || employee.employeeName) }
                                            size={42}
                                            src={ employee.profilePictureUrl || employee.employeeProfilePictureUrl }
                                        />
                                        <LayoutBox>
                                            <LayoutBox marginTop="-8px">
                                                <TextButton
                                                    href={employee.profileUrl || employee.employeeProfileUrl}
                                                    target="_blank"
                                                >
                                                    {employee.name || employee.employeeName}
                                                </TextButton>
                                            </LayoutBox>
                                            <LayoutBox marginTop="-8px">
                                                <BodyText>{employee.position || employee.employeePosition}</BodyText>
                                            </LayoutBox>
                                        </LayoutBox>
                                    </Flex>
                                    {index < payeeEmployee.length - 1 && <Divider light={true} marginY={2}/>}
                                </>
                            ))}
                        </LayoutBox>
                    </LayoutBox>
                </StandardModal.Constraint>
            </StandardModal.Body>
        </StandardModal>
    );
}
