import { CardSize } from '@fabric/card';
import { Interstitial } from '@fabric/interstitial';
import { useState } from 'react';

import { removeStoredRememberToken, setStoredRememberToken } from 'multi-factor-authentication.mod';

import { CODE_LENGTH_LOGIN } from '../../domain';
import { InterstitialAuth, InterstitialBackupCodes, InterstitialSetup } from '../';
import { postMultiFactorAuthenticationLoginCode } from '../../service';
import { redirectToLogin } from '../../utils';

type MultiFactorAuthSetupProps = {
	mfaSecret: string;
	onAuthenticated(): void;
	userEmail: string;
	userId: number;
};

type InterstitialSteps = {
	auth: string;
	backupCodes: string;
	setup: string;
};
type InterstitialStep = keyof InterstitialSteps;

export function MultiFactorAuthSetup(props: MultiFactorAuthSetupProps): JSX.Element {
	const { mfaSecret, onAuthenticated, userEmail, userId } = props;

	const [isInvalidOneTimeCode, setIsInvalidOneTimeCode] = useState<boolean>(false);
	const [step, setStep] = useState<InterstitialStep>('setup');

	const doAuthentication = async (code: string, rememberMe: boolean) => {
		if (code.length !== CODE_LENGTH_LOGIN) {
			setIsInvalidOneTimeCode(true);
			return 'invalid code';
		}

		if (!rememberMe) {
			removeStoredRememberToken();
		}

		const { rememberToken, status, statusText } = await postMultiFactorAuthenticationLoginCode(userId, code, rememberMe);
		if (status === 200) {
			if (rememberToken) {
				setStoredRememberToken(rememberToken);
			}
			setIsInvalidOneTimeCode(false);
			setStep('backupCodes');
			return 'success';
		}
		setIsInvalidOneTimeCode(true);
		if (statusText.includes('failed attempts')) {
			redirectToLogin();
			return statusText;
		}
		return 'invalid code';
	};

	return (
		<Interstitial cardSize={CardSize.SMALL}>
			{step === 'auth' && (
				<InterstitialAuth
					allowBackupCodes={false}
					isInvalid={isInvalidOneTimeCode}
					onBack={() => {
						setIsInvalidOneTimeCode(false);
						setStep('setup');
					}}
					onChange={() => {
						setIsInvalidOneTimeCode(false);
					}}
					onNext={doAuthentication}
				/>
			)}
			{step === 'backupCodes' && <InterstitialBackupCodes onDone={() => onAuthenticated()} userId={userId} />}
			{step === 'setup' && (
				<InterstitialSetup
					onNextStep={() => {
						setStep('auth');
					}}
					secret={mfaSecret}
					userEmail={userEmail}
				/>
			)}
		</Interstitial>
	);
}
