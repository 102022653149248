import { 
    useEffect, 
    useState 
} from "react";

import {
    Flex,
    Select,
    Label,
} from '@bamboohr/fabric'

export const StateSelector = ({
    allStates = [],
    clearNewPayeeId,
    deductionType,
    federalStateObject,
    globalPayeeId,
    isSetUpPayees,
    selectedPayee,
    selectedState,
    setSelectedState
}) => {

    const [isDisabled, setIsDisabled] = useState(false);
    const [stateFilterItems, setStateFilterItems] = useState([]);

    useEffect(() => {
        const items = allStates.map((state) => {
			return {
				text: state.name,
				value: state.id,
				key: state.id
			};
		});

        const stateSelectItems = [
            {
                items,
                type: 'group',
                value: 'allStates'
            }
        ];

        // Note: isLegacyGlobalPayee checks if the payee is a Legacy Payee(payee that is a child support or tax levy that has a deductionPayeeId instead of a globalPayeeId)
        const isLegacyGlobalPayee = ((deductionType == 3 || deductionType == 18) && selectedPayee && globalPayeeId === null );
        if(isLegacyGlobalPayee) {
            setIsDisabled(true)
        }

        // 18 = tax levy, 3 = child support, 19 = other garnishment
        if (deductionType == 18){
            const federalItem = [{
                text: 'Federal',
                value: federalStateObject.id,
                key: federalStateObject.id,
            }];
            stateSelectItems.push({
                anchor: 'bottom',
                items: federalItem,
                type: 'group',
                value: 'federal',
            });
        }
        setStateFilterItems(stateSelectItems);
    }, [allStates, deductionType, setStateFilterItems])

    const stateSelectHandler = (value) => {
        setSelectedState(value);
        clearNewPayeeId();
    }

    return (
        <Flex flexDirection="column">
            {!isSetUpPayees && <Label>{$.__('State')}</Label>}
            <Select
                canSelectMultiple={ false }
                id='stateFilter'
                isClearable={ true }
                isDisabled={ isDisabled }
                items={ stateFilterItems }
                onClear={ () => setSelectedState([]) }
                onDeselect={ (value, currentValue) => setSelectedState(currentValue) }
                onSelect={ (value, currentValue) => stateSelectHandler(currentValue) }
                placement={ {side: 'top'}}
                selectedValues={ selectedState }
                size={isSetUpPayees && "small"}
                width={ 4 }
            />
        </Flex>
    );
}