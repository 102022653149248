import { Component } from 'react';
import { isEmpty } from 'lodash';

import { TextButton } from '@fabric/button';
import { FileUpload } from '@fabric/file-upload';

import { showSlidedown } from 'Alerts.mod';

import {
	companyFiles,
	COMPANY_FILES,
	determineRemainingFilesNeeded,
	getFilesDownloadList,
} from '../../utils/shared';

import {
	BANK_FILES,
	BANK_FILES_ARRAY,
} from '../../../bank-info.react/utils/shared';

import {
	Button,
	Flex,
	Label,
	LayoutBox,
	StandardModal,
} from '@bamboohr/fabric';

export class UploadFormsModal extends Component {
	get _isCompany() {
		const { type } = this.props;
		return type === 'company';
	}

	get _isBank() {
		const { type } = this.props;
		return type === 'bank';
	}

	get _numFilesNeeded() {
		const { company, bank } = this.props;

		if (this._isCompany) {
			const { form8821, form8655, beneficialOwnerAddendum } = company;

			if (beneficialOwnerAddendum.fileStatus) {
				return determineRemainingFilesNeeded(
					form8821.fileStatus,
					form8655.fileStatus,
					beneficialOwnerAddendum.fileStatus,
				);
			}

			return determineRemainingFilesNeeded(
				form8821.fileStatus,
				form8655.fileStatus,
			);
		}

		const { formAchAuthorization } = bank;
		return determineRemainingFilesNeeded(formAchAuthorization.fileStatus);
	}

	get _basicFilesArray() {
		const { company, bank } = this.props;
		if (this._isCompany) {
			const { form8821, form8655, beneficialOwnerAddendum } = company;
			form8821.type = COMPANY_FILES.FORM_8821;
			form8655.type = COMPANY_FILES.FORM_8655;

			if (beneficialOwnerAddendum.fileStatus) {
				beneficialOwnerAddendum.type = COMPANY_FILES.BENEFICIAL_OWNER;
				return [form8821, form8655, beneficialOwnerAddendum];
			}

			return [form8821, form8655];
		}
		// Bank scenario
		const { formAchAuthorization } = bank;
		formAchAuthorization.type = BANK_FILES.FORM_ACH_AUTHORIZATION;

		return [formAchAuthorization];
	}

	_handleTempUploadRemove = (removedFile) => {
		this.setState((prevState) => {
			return {
				uploadedFiles: prevState.uploadedFiles.filter(
					(file) => file.tempFileId !== removedFile.id,
				),
				isDisabled: true,
			};
		});
	};

	_handleTempUploadSuccess = (file, fileType) => {
		const { uploadedFiles } = this.state;
		const { id: tempFileId } = file;
		const uploadedFilesCopy = Array.from(uploadedFiles);

		uploadedFilesCopy.push({
			fileType,
			tempFileId,
		});

		this.setState({
			uploadedFiles: uploadedFilesCopy,
			isDisabled: uploadedFilesCopy.length !== this._numFilesNeeded,
		});
	};

	_handlePrimaryClick = () => {
		const { clientId, onSubmit } = this.props;
		const { uploadedFiles } = this.state;

		this.setState(
			{
				isProcessing: true,
			},
			() => {
				onSubmit(clientId, uploadedFiles);
			},
		);
	};

	_handleUploadError = () => {
		showSlidedown(
			$.__(
				'Uh oh...there was an error uploading your document. Please try again later.',
			),
			'error',
		);
	};

	_renderModalContents = () => {
		const { showDownloads, company, bank, clientId } = this.props;

		if (isEmpty(company) && isEmpty(bank)) {
			return null;
		}

		const filesContent = this._basicFilesArray.map((file) => {
			const { displayFileName, type: fileType } = file;
			const displayName = displayFileName
				? displayFileName.split('.')[0]
				: null;

			const key = `${fileType}-upload`;
			return (
				<div key={key}>
					<Label required>{displayName || displayFileName}</Label>
					<FileUpload
						acceptedTypes={['application/pdf']}
						onError={this._handleUploadError}
						initialFiles={
							file.fileStatus === 'uploaded'
								? [{ id: file.fileId, name: displayFileName, type: '.pdf' }]
								: undefined
						}
						isDisabled={file.fileStatus === 'uploaded'}
						onRemove={this._handleTempUploadRemove}
						onUpload={(uploadedFile) =>
							this._handleTempUploadSuccess(uploadedFile, fileType)
						}
					/>
				</div>
			);
		});

		//Filter out forms that may not be needed for legacy clients
		const filteredCompanyFiles = this._basicFilesArray.map(
			({ type: neededFileType }) =>
				companyFiles.find(
					({ type: companyFileType }) => companyFileType === neededFileType,
				),
		);
		return (
			<LayoutBox marginLeft={2}>
				<Flex flexDirection="column" gap={2}>
					{filesContent}
				</Flex>

				{showDownloads && (
					<LayoutBox marginTop={2}>
						{getFilesDownloadList(
							this._isCompany ? filteredCompanyFiles : BANK_FILES_ARRAY,
							clientId,
						)}
					</LayoutBox>
				)}
			</LayoutBox>
		);
	};
	get renderModalContents() {
		return this._renderModalContents;
	}
	set renderModalContents(value) {
		this._renderModalContents = value;
	}

	state = {
		uploadedFiles: [],
		isProcessing: false,
		isDisabled: true,
	};

	render() {
		const { isProcessing, isDisabled } = this.state;
		const { visible, onClose } = this.props;

		const title = this._isCompany
			? $.__('Upload Signed Forms')
			: $.__('Upload Bank Account Forms');
		const headline = this._isCompany
			? $.__('Upload your completed company payroll forms')
			: $.__('Upload your signed bank authorization forms');

		const modalOptions = {
			type: 'small',
			title,
			headerType: 'text',
			headline,
			icon: 'fab-bank-20x19',
			isProcessing,
			isOpen: visible,
			onClose,
			primaryAction: isDisabled ? null : this._handlePrimaryClick,
			primaryActionText: $.__('Upload'),
			content: this._renderModalContents(),
		};

		return (
			<StandardModal isOpen={visible} onRequestClose={onClose}>
				<StandardModal.Body
					renderFooter={
						<StandardModal.Footer
							actions={[
								<TextButton type="button" onClick={onClose}>
									{$.__('Cancel')}
								</TextButton>,
								<Button
									onClick={this._handlePrimaryClick}
									processing={isProcessing}
									disabled={isDisabled}
									type="button"
								>
									{$.__('Upload')}
								</Button>,
							]}
						/>
					}
					renderHeader={<StandardModal.Header title={title} />}
				>
					<StandardModal.StandardHeadline
						text={headline}
						icon="file-arrow-up-solid"
					/>
					<StandardModal.Constraint>
						{this._renderModalContents()}
					</StandardModal.Constraint>
				</StandardModal.Body>
			</StandardModal>
		);
	}
}
