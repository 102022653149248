import { ReactElement, useEffect, useState } from 'react';
import {
	Button,
	CardSize,
	IconV2,
	StandardModal,
	TextButton,
} from '@bamboohr/fabric';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	primaryAction: () => void;
}

export function ResetEmailModal(props: Props): ReactElement {
	const {
		isOpen = false,
		onClose = () => {
			return null;
		},
		primaryAction = () => {
			return null;
		},
	} = props;

	const [modalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		setModalOpen(isOpen);
	}, [isOpen]);

	return (
		<StandardModal isOpen={modalOpen}>
			<StandardModal.Body
				renderHeader={
					<StandardModal.Header
						hasCloseButton
						title={$.__('Just Checking...')}
					/>
				}
				renderFooter={
					<StandardModal.Footer
						actions={[
							<TextButton
								onClick={() => {
									onClose();
								}}
								key="cancel"
								type="button"
							>
								{$.__('Cancel')}
							</TextButton>,
							<Button
								onClick={() => {
									primaryAction();
								}}
								color="primary"
								key="done"
								type="button"
								variant="contained"
								width={6}
							>
								{$.__('OK')}
							</Button>,
						]}
					/>
				}
				size={CardSize.SMALL}
			>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline
						icon="arrow-rotate-right-solid"
						subtext={$.__(
							'This will replace the current email message with the standard message.',
						)}
						text={$.__(
							'Are you sure you want to reset the email message to the BambooHR default message?',
						)}
					/>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
