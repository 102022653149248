import { BodyText, Flex, Truncate, TextButton } from "@bamboohr/fabric";

export const SelectItem = ({ item, state }) => {
    const {
        address,
        payeeName,
        text,
        type,
    } = item;

    if (type === 'text-button') {
        return (
            <TextButton>
                {text}
            </TextButton>
        );
    }

    if (type === 'name-and-address') {
        return (
            <Flex flexDirection="column">
                <BodyText>
                    <Truncate lines={ 1 } trimWhitespace={false} width={ 200 }>{payeeName}</Truncate>
                </BodyText>
                <BodyText size="small">
                    <Truncate lines={ 1 } trimWhitespace={true}>
                        <div>{address}</div>
                    </Truncate>
                </BodyText>
            </Flex>
        );
    }

    return (
        <div>
            {text}
        </div>
    ) 
}
