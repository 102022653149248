import { styled, StandardModal, Button, TextButton, CardSize, GenericTable, makeStyles, Headline, LayoutBox } from '@bamboohr/fabric';
import { UpcomingSurvey, UpcomingSurveysRequest } from '../types';
import Label from './label';
import useEditQuestion from './utils/hook';
import { Question } from './types';
import Action from './action';
import { useState } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface QuestionRow extends Question {
	id: number;
	hovering: boolean;
}

const QuestionTable = GenericTable<QuestionRow>();

interface EditQuestionModalProps {
	hideEditQuestion: () => void;
	updateUpcomingSurveys: (surveys: UpcomingSurvey[]) => void;
	isOpen: boolean;
	topic: string;
	topicId: number;
	surveyId: number;
	scheduleData: UpcomingSurveysRequest;
}

interface UseQuestionTable {
	hover: (id: number | null) => void;
	stopHover: () => void;
	questionRows: QuestionRow[];
}

const useQuestionTable = (listOfQuestions: readonly Question[]): UseQuestionTable => {
	const [hoveringOn, hover] = useState<number | null>(null);
	return {
		hover,
		stopHover: () => hover(null),
		questionRows: listOfQuestions.map((q, i) => ({ ...q, id: i, hovering: i === hoveringOn })),
	};
};

const StyledHeadline = styled(StandardModal.StandardHeadline)({
	marginBottom: 24,
});

const useStyles = makeStyles(({ spacing }) => ({
	tableWrapper: {
		padding: spacing(1),
		'& tr:last-child td': {
			borderBottom: ifFeature('encore', undefined, 0),
		},
	},
}));

const EditQuestionModal: React.FC<React.PropsWithChildren<EditQuestionModalProps>> = ({
	isOpen,
	hideEditQuestion,
	topic,
	topicId,
	updateUpcomingSurveys,
	scheduleData,
	surveyId,
}) => {
	const { save, select, neverAsk, enable, listOfQuestions, loading, canSave, showDone } = useEditQuestion({
		updateUpcomingSurveys,
		topicId,
		scheduleData,
		hideEditQuestion,
		surveyId,
	});
	const { hover, questionRows, stopHover } = useQuestionTable(listOfQuestions);
	const classes = useStyles();
	return (
		<StandardModal isLoading={loading} isOpen={isOpen} onRequestClose={hideEditQuestion}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={
							showDone
								? [
										<Button key='done' onClick={hideEditQuestion} type='button'>
											{$.__('Done')}
										</Button>,
								  ]
								: ifFeature(
										'encore',
										[
											<TextButton key='cancel' onClick={hideEditQuestion} type='button'>
												{$.__('Cancel')}
											</TextButton>,
											<Button disabled={!canSave} key='save' onClick={save} type='button'>
												{$.__('Save')}
											</Button>,
										],
										[
											<Button disabled={!canSave} key='save' onClick={save} type='button'>
												{$.__('Save')}
											</Button>,
											<TextButton key='cancel' onClick={hideEditQuestion} type='button'>
												{$.__('Cancel')}
											</TextButton>,
										]
								  )
						}
					/>
				}
				renderHeader={<StandardModal.Header title={topic} />}
				size={CardSize.MEDIUM}
			>
				{ifFeature(
					'encore',
					<LayoutBox margin={3}>
						<Headline size='small' color='neutral-strong' icon='clipboard-list-regular'>
							{$.__('Choose a question to ask')}
						</Headline>
					</LayoutBox>,
					<StyledHeadline
						// @ts-expect-error Update from  jade icon to encore Icon
						icon='fab-chat-note-18x18'
						text={$.__('Choose a question to ask')}
					/>
				)}

				<div className={classes.tableWrapper}>
					<QuestionTable
						columns={[
							{
								cell: {
									type: 'checkbox',
									selected: (row) => row.isScheduledQuestion,
									disabled: (row) => !row.isQuestionEnabled,
								},
								key: 'checkbox',
								verticalAlign: 'middle',
							},
							{
								cell: (props) => <Label {...props} />,
								key: 'label',
								cellPaddingReset: 'vertical',
							},
							{
								cell: (props) => <Action {...props} enable={() => enable(props.id)} neverAsk={() => neverAsk(props.id)} />,
								key: 'action',
								verticalAlign: 'middle',
							},
						]}
						onRowMouseEnter={(_, { id }) => hover(id)}
						onRowMouseLeave={stopHover}
						onRowSelect={({ 0: key }) => select(key as number)}
						rows={questionRows}
					/>
				</div>
			</StandardModal.Body>
		</StandardModal>
	);
};

export default EditQuestionModal;
