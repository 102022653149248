import {
	BodyText,
	Dropdown,
	DropdownTypes,
	Flex,
	Headline,
	InPageMessaging,
	LayoutBox,
	Select,
} from '@bamboohr/fabric';

import { isEnabled as isFeatureEnabled } from 'FeatureToggle.util';

import { PLAN_FILTER_LABEL, PLAN_TYPE_DROPDOWN_LABEL } from '../constants';
import { useBenefitsSettingsStore } from '../data/benefits-settings-store';
import { PlanFilter } from '../types/plan-filter';
import { FEATURE_TOGGLE_KEYS } from '../../etc/feature-toggle';
import { PlanTypeEnumContract } from '../../types';

interface BenefitPlansHeaderProps {
	benefitPlansMissingDetailsCount: number;
	benefitPlansMissingPlanYearCount: number;
	onFilterChange: (value: PlanFilter) => void;
	onNewTypeSelect: (type: PlanTypeEnumContract) => void;
	planFilter: PlanFilter;
}

export function BenefitPlansHeader(props: BenefitPlansHeaderProps) {
	const { benefitPlansMissingDetailsCount, benefitPlansMissingPlanYearCount, onFilterChange, onNewTypeSelect, planFilter } = props;
	const [{ isBenefitsAdminEnabled }] = useBenefitsSettingsStore();

	const newPlanDropdownItems = Object.values(PlanTypeEnumContract).map(
		(type) => ({
			text: PLAN_TYPE_DROPDOWN_LABEL[type],
			value: type,
		}),
	);

	const dropdownBiId = 'add-new-plan';

	const filter = (
		<Select
			biId="plan-filter"
			isClearable={false}
			items={[
				{
					text: PLAN_FILTER_LABEL[PlanFilter.Active],
					value: PlanFilter.Active,
				},
				{
					text: PLAN_FILTER_LABEL[PlanFilter.Inactive],
					value: PlanFilter.Inactive,
				},
				{ text: PLAN_FILTER_LABEL[PlanFilter.All], value: PlanFilter.All },
			]}
			onChange={(selectedValues: any[]) => onFilterChange(selectedValues[0])}
			selectedValues={[planFilter]}
			size="small"
			variant="single"
			width={6}
		/>
	);

	return (
		<>
			<Headline size="small">{$.__('Plans')}</Headline>
			{isFeatureEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_HOME_PAGE) &&
				benefitPlansMissingDetailsCount > 0 && (
					<LayoutBox marginBottom={3} marginTop={2}>
						<InPageMessaging
							body={$.__n(
								'This plan will need to be updated before you can continue managing employee enrollments.',
								'These plans will need to be updated before you can continue managing employee enrollments.',
								benefitPlansMissingDetailsCount,
							)}
							header={$.__n(
								'%s plan is missing required details based on updated plan settings.',
								'%s plans are missing required details based on updated plan settings.',
								benefitPlansMissingDetailsCount,
							)}
							type="error"
						/>
					</LayoutBox>
				)}
				{isFeatureEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_DATA_PARITY_PLAN_YEAR) && benefitPlansMissingPlanYearCount > 0 && isBenefitsAdminEnabled && (
					<LayoutBox marginBottom={3} marginTop={2}>
						<InPageMessaging
							body={$.__(
								'You will not be able to manage enrollments in plans until they are added to a Plan Year.',
							)}
							header={$.__n(
								'%s plan is not tied to an active Plan Year.',
								'%s plans are not tied to an active Plan Year.',
								benefitPlansMissingPlanYearCount,
							)}
							icon="calendar-exclamation-solid"
							type="warning"
						/>
					</LayoutBox>
				)}
			<Flex alignItems="flex-end" justifyContent="space-between" marginY={2}>
				<Dropdown
					ButtonProps={{
						color: 'secondary',
						size: 'small',
						startIcon: 'circle-plus-regular',
						variant: 'outlined',
					}}
					biId={dropdownBiId}
					items={newPlanDropdownItems}
					onSelect={(selected) => onNewTypeSelect(selected)}
					type={DropdownTypes.BUTTON}
				>
					{$.__('Create New Plan')}
				</Dropdown>
				<Flex alignItems="center" gap={1}>
					<BodyText size="small">{$.__('Showing')}</BodyText>
					{filter}
				</Flex>
			</Flex>
		</>
	);
}
