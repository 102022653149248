import { Flex, IconButton, Tooltip, Typography } from "@bamboohr/fabric";

export function EmployeesColumnHeader() {
	return (
		<Flex flexDirection='row' gap={0.5}>
			<Typography>{$.__('Employees')}</Typography>
			<Tooltip content={$.__('Number of employees able to log time to this project')}>
				<IconButton floatingIcon={true} icon="circle-info-solid" noBoundingBox={true} />
			</Tooltip>
		</Flex>
	)
}
