import { isEnabled } from 'FeatureToggle.util';
import { Fragment, useEffect, useState } from 'react';

import { canUseLocalStorage } from '@utils/localstorage';

import { BankAccountForm } from '../../../components/bank-account-form';
import { BankInfoHeader } from '../../../components/bank-info-header';

import { StatusRow } from '../../../../company-information.react/components/status-row';

import { Button, InPageMessaging, LayoutBox } from '@bamboohr/fabric';
import { usePlaidLink } from 'react-plaid-link';
import { MultiBanksTable } from '../../../components/multi-banks-table';
import { BANK_APP_STATE } from '../../../utils/shared';

export function BankVerifiedStateComponent(props) {
	const isPlaidAdoptionAndSufficientBalanceEnabled = isEnabled(
		'PLAID_ADOPTION_AND_SUFFICIENT_BALANCE',
	);
	const isPlaidAccountBalanceVerificationEnabled = isEnabled(
		'PLAID_ACCOUNT_BALANCE_VERIFICATION',
	);

	const {
		company,
		onSubmit,
		isProcessing,
		onInputChange,
		plaidLinkToken,
		handlePlaidUpdateSuccess,
		popDownloadFormsModal,
		popUploadFormsModal,
		popVerificationsModal,
	} = props;

	const bank = company.bankAccounts[0];

	const [showPlaidConnect, setShowPlaidConnect] = useState(null);

	const plaidIsNotConnected =
		bank?.plaidStatus === null || bank?.plaidStatus === 'Exempt';

	useEffect(() => {
		if (canUseLocalStorage) {
			// eslint-disable-next-line no-lonely-if
			if (!!bank && showPlaidConnect === null) {
				let val;
				if (isPlaidAccountBalanceVerificationEnabled) {
					val = localStorage.getItem(
						`_plaid_messaging_2_day_${bank.accountNickname + bank.bankId}_`,
					);
					localStorage.removeItem(
						`_plaid_messaging_${bank.accountNickname + bank.bankId}_`,
					);
				} else {
					val = localStorage.getItem(
						`_plaid_messaging_${bank.accountNickname + bank.bankId}_`,
					);
				}
				if (!val) {
					setShowPlaidConnect(true);
				} else {
					setShowPlaidConnect(false);
				}
			}
		} else if (plaidIsNotConnected) {
			setShowPlaidConnect(true);
		} else {
			setShowPlaidConnect(false);
		}
	}, []);

	const handleHidePlaidConnect = () => {
		document.dispatchEvent(
			new CustomEvent('PlaidBannerActions:Dismissed', { detail: bank?.bankId }),
		);
		if (!canUseLocalStorage) {
			setShowPlaidConnect(false);
		} else {
			setShowPlaidConnect(false);
			if (isPlaidAccountBalanceVerificationEnabled) {
				localStorage.setItem(
					`_plaid_messaging_2_day_${
						bank?.accountNickname + bank?.bankId || 'showing'
					}_`,
					'hide',
				);
			} else {
				localStorage.setItem(
					`_plaid_messaging_${
						bank?.accountNickname + bank?.bankId || 'showing'
					}_`,
					'hide',
				);
			}
		}
	};

	const { open, ready } = usePlaidLink({
		token: plaidLinkToken,
		onSuccess: (token, metadata) => {
			handlePlaidUpdateSuccess(token, metadata, bank.bankId, bank.clientId);
		},
	});
	return (
		<Fragment>
			<BankInfoHeader />

			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<LayoutBox
					marginTop="18px"
					marginBottom={
						bank.plaidStatus === 'Failed' ||
						bank.plaidStatus === 'Error' ||
						plaidIsNotConnected ||
						showPlaidConnect
							? '24px'
							: undefined
					}
				>
					{(bank.plaidStatus === 'Failed' || bank.plaidStatus === 'Error') && (
						<InPageMessaging
							body={$.__(
								'Please connect again to continue smooth payroll processing.',
							)}
							customAction={
								<Button
									color="secondary"
									onClick={() => {
										if (ready) {
											open();
										}
									}}
									size="teenie"
									type="button"
									variant="outlined"
								>
									{$.__('Reconnect')}
								</Button>
							}
							header={$.__(
								'Your Plaid connection has expired for %1$s',
								bank.accountNickname,
							)}
							icon="triangle-exclamation-solid"
							type="warning"
						/>
					)}
					{plaidIsNotConnected && showPlaidConnect && (
						<InPageMessaging
							body={$.__(
								'With real-time account info, we make sure your team gets paid on time, every time.',
							)}
							customAction={
								<Button
									color="secondary"
									size="teenie"
									type="button"
									variant="outlined"
									onClick={() => {
										if (ready) {
											open();
										}
									}}
								>
									{$.__('Start Now')}
								</Button>
							}
							header={$.__(`Connect %1$s with Plaid.`, bank.accountNickname)}
							icon="plug-circle-plus-solid"
							type="info"
							onClose={() => {
								handleHidePlaidConnect();
							}}
						/>
					)}
				</LayoutBox>
			) : (
				<StatusRow
					status={BANK_APP_STATE.VERIFIED}
					plaidLinkToken={plaidLinkToken}
					handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
				/>
			)}

			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<MultiBanksTable
					company={company}
					handleOpenDownloadModal={popDownloadFormsModal}
					handleOpenUploadModal={popUploadFormsModal}
					handleOpenVerificationModal={popVerificationsModal}
					plaidLinkToken={plaidLinkToken}
					handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
				/>
			) : (
				<BankAccountForm
					isEdit
					bankAccount={company.bankAccounts[0]}
					company={company}
					isProcessing={isProcessing}
					isVerified={true}
					onInputChange={onInputChange}
					onSubmit={onSubmit}
					plaidLinkToken={plaidLinkToken}
					handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
					showCompletedFormsSection={!company.isLegacy}
					statusSpacing={true}
				/>
			)}
		</Fragment>
	);
}
