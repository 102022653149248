import { useState } from 'react';
import { LayoutBox, Tooltip } from '@bamboohr/fabric';
import { StatusTooltipContent } from './status-tooltip-content';

interface StatusTooltipProps {
    children: JSX.Element;
    isOpen: boolean;
}

export const StatusTooltip = (props: StatusTooltipProps): JSX.Element => {
    const { isOpen, children } = props;
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
    return (
        <Tooltip
            content={<StatusTooltipContent />}
            enterDelay={300}
            open={isOpen && isTooltipOpen}
        >
            <LayoutBox
                onMouseEnter={() => setIsTooltipOpen(true)}
                onMouseLeave={() => setIsTooltipOpen(false)}
            >
               {children}
            </LayoutBox>
        </Tooltip>
    )
}
