import { AxiosError, AxiosResponse } from 'axios';
import { ajax } from '@utils/ajax';
import { MFA_ENDPOINT } from 'multi-factor-authentication.mod';
import {
	AuthType,
	DeleteMfaResponse,
	GetMfaUserSettingsResponse,
	MfaChannelItem,
	MfaChannelTypes,
	MfaUserSettings,
	PostCreateMultiFactorAuthenticationUserResponse,
	PostMultiFactorAuthenticationBackupCodeResponse,
	PostMultiFactorAuthenticationLoginCodeResponse,
	PostResendSmsCodeResponse,
	PostSmsCodeResponse,
	PutMultiFactorAuthenticationChannel,
	SerializedDeleteMultiFactorAuthenticationChannel,
	SerializedPostCreateMultiFactorAuthenticationUserResponse,
	SerializedPostMultiFactorAuthenticationBackupCodeResponse,
	SerializedPostMultiFactorAuthenticationLoginCodeResponse,
	SerializedPutMultiFactorAuthenticationChannel,
} from './types';
import { AuthOptions } from './constants';

const ajaxInstance = ajax.create('multi-factor-auth', {
	baseURL: MFA_ENDPOINT,
});

export async function getUserMfaSettings(userId: number): Promise<GetMfaUserSettingsResponse | MfaUserSettings> {
	try {
		const { data } = await ajaxInstance.request<GetMfaUserSettingsResponse>({
			method: 'get',
			url: '/:userId',
			urlParams: {
				userId,
			},
		});
		let preferredChannel: AuthType = AuthOptions.APP;
		const enabledChannels = data?.mfaChannels?.reduce((accumulator, channelName): AuthOptions[] => {
			const { mfaChannelInfo = {} as Record<MfaChannelTypes, MfaChannelItem> } = data;
			const { isEnabled, isPreferred } = mfaChannelInfo[channelName];

			const channelNameToAuthOptionMap = {
				sms: AuthOptions.SMS,
				authenticator: AuthOptions.APP,
				backup_codes: AuthOptions.BACKUP,
			};

			if (isPreferred) {
				preferredChannel = channelNameToAuthOptionMap[channelName];
			}

			if (isEnabled) {
				accumulator.push(channelNameToAuthOptionMap[channelName]);
			}

			return accumulator;
		}, []);
		return { ...data, preferredChannel, enabledChannels };
	} catch (error) {
		const { response } = error as AxiosError;
		return {
			error: response,
			startDate: '',
			status: undefined,
			mfaChannels: [],
			mfaChannelInfo: {} as Record<MfaChannelTypes, MfaChannelItem>,
		};
	}
}

export async function postSmsCode(userId: number): Promise<PostSmsCodeResponse> {
	try {
		await ajaxInstance.request<GetMfaUserSettingsResponse>({
			method: 'post',
			url: '/:userId/sms',
			urlParams: {
				userId,
			},
		});
		return {
			success: true,
		};
	} catch (error) {
		const { response } = error as AxiosError;
		if (response.status === 429) {
			return {
				error: response,
				success: false,
			};
		}
		throw new Error(error);
	}
}

export async function postResendSmsCode(userId: number): Promise<PostResendSmsCodeResponse> {
	try {
		await ajaxInstance.request<GetMfaUserSettingsResponse>({
			method: 'post',
			url: '/:userId/sms',
			urlParams: {
				userId,
			},
		});

		return {
			success: true,
		};
	} catch (error) {
		const { response } = error as AxiosError;
		return {
			error: response,
			success: false,
		};
	}
}

export async function postCreateMultiFactorAuthenticationUser(
	userId: number
): Promise<SerializedPostCreateMultiFactorAuthenticationUserResponse> {
	try {
		const { data } = await ajaxInstance.request<PostCreateMultiFactorAuthenticationUserResponse>({
			method: 'post',
			url: '/:userId',
			urlParams: {
				userId,
			},
		});
		return {
			id: data.id,
			secret: data.generatedMfaSecret,
			success: true,
		};
	} catch (error) {
		const { response } = error as AxiosError;
		return {
			error: response,
			id: -1,
			secret: '',
			success: false,
		};
	}
}

export async function postMultiFactorAuthenticationBackupCode(
	userId: number,
	backupCode: string
): Promise<SerializedPostMultiFactorAuthenticationBackupCodeResponse> {
	try {
		const { status } = await ajaxInstance.request<PostMultiFactorAuthenticationBackupCodeResponse>({
			data: {
				backupCode,
			},
			method: 'post',
			url: '/:userId/backup_codes/authenticate',
			urlParams: {
				userId,
			},
		});
		return {
			status,
		};
	} catch (error) {
		const { response } = error as AxiosError;
		const {
			data: { error: statusText },
			status,
		} = response as AxiosResponse<PostMultiFactorAuthenticationBackupCodeResponse>;
		return {
			status,
			statusText,
		};
	}
}

export async function postMultiFactorAuthenticationLoginCode(
	userId: number,
	oneTimeCode: string,
	rememberMe = true,
	mfaChannel?: MfaChannelTypes
): Promise<SerializedPostMultiFactorAuthenticationLoginCodeResponse> {
	const postData = {
		mfaAuthenticationCode: oneTimeCode,
		rememberDevice: rememberMe,
	};

	if (mfaChannel) {
		// @ts-ignore - when we clean up toggles this will go into the above and it won't be a problem
		postData.mfaChannel = mfaChannel;
	}

	try {
		const {
			data: { rememberToken },
			status,
		} = await ajaxInstance.request<PostMultiFactorAuthenticationLoginCodeResponse>({
			data: postData,
			method: 'post',
			url: '/:userId/authenticate',
			urlParams: {
				userId,
			},
		});
		window.pendo?.track?.('Login | MFA | Auth Response', { userId, ...postData, success: true, status });
		return {
			rememberToken,
			status,
		};
	} catch (error) {
		const { response } = error as AxiosError;
		const {
			data: { error: statusText },
			status,
		} = response as AxiosResponse<PostMultiFactorAuthenticationLoginCodeResponse>;
		window.pendo?.track?.('Login | MFA | Auth Response', { userId, ...postData, success: false, status });
		return {
			status,
			statusText,
		};
	}
}

export async function putMultiFactorAuthenticationChannel(
	userId: number,
	mfaChannel: MfaChannelTypes,
	generateSecret = false,
	phone = '',
	countryCode = ''
): Promise<SerializedPutMultiFactorAuthenticationChannel> {
	try {
		const { data } = await ajaxInstance.request<PutMultiFactorAuthenticationChannel>({
			method: 'put',
			url: '/:userId/:mfaChannel',
			urlParams: {
				userId,
				mfaChannel,
			},
			data: {
				generateSecret,
				phone,
				countryCode,
			},
		});
		return data;
	} catch (error) {
		throw new Error(error);
	}
}

export async function deleteMultiFactorAuthenticationChannel(
	userId: number,
	mfaChannel: string
): Promise<SerializedDeleteMultiFactorAuthenticationChannel> {
	try {
		const { status } = await ajaxInstance.request<DeleteMfaResponse | string>({
			method: 'delete',
			url: '/:userId/:mfaChannel',
			urlParams: {
				userId,
				mfaChannel,
			},
		});
		return {
			status,
		};
	} catch (error) {
		const { response } = error as AxiosError;
		const { status } = response as AxiosResponse<DeleteMfaResponse>;
		return {
			status,
		};
	}
}
