import {
	BodyText,
	Flex,
	LayoutBox,
	Section,
	TextArea,
	TextButton,
	TextField
} from '@fabric/button';
import { Message } from '@utils/message';
import { PureComponent, ReactElement } from 'react';
import { ResetEmailModal } from './components/reset-email-template';

interface Errors {
	body?: boolean;
	subject?: boolean;
}

interface State {
	body: string;
	errorList?: Errors;
	isDefault: boolean;
	modalOpen?: boolean;
	subject: string;
	subjectInfoState: boolean;
	subjectNote: ReactElement;
}

interface Props extends State {
	handleReset: () => Promise<State>;
	handleStateChange: (newState?: State) => void;
}

export class EditEmailForm extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		const { body = '', isDefault = true, subject = '' } = props;

		this.state = {
			body,
			errorList: {},
			isDefault,
			modalOpen: false,
			subject,
			subjectInfoState: false,
			subjectNote: null,
		};

		this._handleChange = this._handleChange.bind(this);
		this._resetEmail = this._resetEmail.bind(this);
	}

	_handleChange(event): void {
		const { body, errorList, subject } = this.state;

		const {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			handleStateChange = (): void => {},
		} = this.props;

		const newState = {
			body,
			errorList,
			isDefault: false,
			subject,
			subjectInfoState: false,
			subjectNote: null,
		};

		newState[event.target.dataset.stateKey] = event.target.value;
		newState.errorList[event.target.dataset.stateKey] = false;

		if (event.target.dataset.stateKey === 'subject') {
			if (event.target.value.length >= 200) {
				newState.errorList[event.target.dataset.stateKey] = true;
				newState.subjectNote = (
					<span>
						<Message
							params={[event.target.value.length]}
							text={$._(
								`Whoops, to make sure your subject doesn’t get cut off, keep it under 200 characters—you’re at {1} now.`,
							)}
						/>
					</span>
				);
			} else if (
				event.target.value.length > 189 &&
				event.target.value.length < 200
			) {
				newState.subjectInfoState = true;
				newState.subjectNote = (
					<span>
						<Message
							params={[event.target.value.length]}
							text={$._(
								`Head’s up! It’s best to keep the subject under 200 characters—you’re at {1} now.`,
							)}
						/>
					</span>
				);
			}
		}

		newState.errorList[event.target.dataset.stateKey] =
			newState.errorList[event.target.dataset.stateKey] || !event.target.value;

		this.setState(newState, () => {
			handleStateChange(newState);
		});
	}

	_resetEmail(): void {
		const { handleReset, handleStateChange } = this.props;

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;

		try {
			handleReset()
				.then((emailData) => {
					const { body, subject } = emailData;

					const newState = {
						body,
						isDefault: true,
						modalOpen: false,
						subject,
						subjectInfoState: false,
						subjectNote: null,
					};

					self.setState(newState, () => {
						handleStateChange(newState);
					});
				})
				.catch((error) => {
					window.setMessage(
						$.__(
							'An error has occurred getting the default message, please try again',
						),
						'error',
					);
					throw error;
				});
		} catch (error) {
			console.error('Expected a promise during reset');
		}
	}

	render(): ReactElement {
		const {
			body = '',
			isDefault,
			modalOpen = false,
			subject = '',
		} = this.state;

		return (
			<Section height="100%" paddingTop="24px">
				<Flex alignItems="start" flexDirection="column" gap={2.5}>
					<LayoutBox textAlign="start">
						<BodyText color="neutral-extra-strong" inline={true} weight="regular">
							{$._(
								'Customize the welcome email that goes out to your employees to speak in your company voice and make it your own. You can even personalize it by including any of the placeholders ',
							)}
						</BodyText>
						<BodyText color="primary" inline={true} weight="regular">
							{$.__('[First Name]')}
						</BodyText>
						<BodyText color="neutral-extra-strong" inline={true} weight="regular">
							,{' '}
						</BodyText>
						<BodyText color="primary" inline={true} weight="regular">
							{$.__('[Last Name]')}
						</BodyText>
						<BodyText color="neutral-extra-strong" inline={true} weight="regular">
							,{' '}
						</BodyText>
						<BodyText
							color="primary"
							component="strong"
							inline={true}
							weight="regular"
						>
							{$.__('[Job Title]')}
						</BodyText>
						<BodyText color="neutral-extra-strong" inline={true} weight="regular">
							,{' '}
						</BodyText>
						<BodyText
							color="primary"
							component="strong"
							inline={true}
							weight="regular"
						>
							{$.__('[Department]')}
						</BodyText>
						<BodyText color="neutral-extra-strong" inline={true} weight="regular">
							{$.__(' or ')}
						</BodyText>
						<BodyText
							color="primary"
							component="strong"
							inline={true}
							weight="regular"
						>
							{$.__('[Company Name]')}
						</BodyText>
						<BodyText color="neutral-extra-strong" inline={true} weight="regular">
							{$._(
								' and they will automagically be filled in when the New Hire Packet is created.',
							)}
						</BodyText>
					</LayoutBox>
					<TextField
						id="subjectLine"
						label={$.__('Subject')}
						onChange={(e) => {
							this._handleChange({
								...e,
								target: { ...e.target, dataset: { stateKey: 'subject' } },
							});
						}}
						value={subject}
						width={10}
					/>
					<TextArea
						id="messageBody"
						label={$.__('Email Message')}
						multiline={true}
						onChange={(e) => {
							this._handleChange({
								...e,
								target: { ...e.target, dataset: { stateKey: 'body' } },
							});
						}}
						value={body}
						width={10}
					/>
					{!isDefault && (
						<TextButton
							color="secondary"
							onClick={() => {
								this.setState({ modalOpen: true });
							}}
							size="small"
							startIcon="arrow-rotate-right-regular"
							title={$.__('Reset to Default')}
							type="button"
						>
							{$.__('Reset to Default')}
						</TextButton>
					)}
					<ResetEmailModal
						isOpen={modalOpen}
						onClose={() => {
							this.setState({ modalOpen: false });
						}}
						primaryAction={this._resetEmail}
					/>
				</Flex>
			</Section>
		);
	}
}
