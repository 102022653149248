import {
	Flex,
	StandardModal,
} from '@bamboohr/fabric';

import {
	findSuppressCourtesyTax,
} from '../domain/find-suppress-courtesy-tax';
import {
	L_STATE_SECTION_LABEL,
} from '../language';
import {
	useCompanyTaxSettingsStore,
} from '../store/use-company-tax-settings-store';
import {
	StateOhioCourtesyTaxConnector,
} from './state-ohio-courtesy-tax-connector';

export function StateConnector() {
	const [{
		clientTaxSettings,
		isLoadingClientTaxSettings,
	}] = useCompanyTaxSettingsStore();
	const ohioCourtesyTax = findSuppressCourtesyTax(clientTaxSettings);
	if (isLoadingClientTaxSettings || !ohioCourtesyTax || !ohioCourtesyTax.metadata.isCourtesyTaxApplicable) {
		return null;
	}
	return (
		<Flex
			flexDirection="column"
			gap={ 2 }
		>
			<StandardModal.StandardHeadline
				text={ L_STATE_SECTION_LABEL }
			/>
			<StateOhioCourtesyTaxConnector />
		</Flex>
	);
}
