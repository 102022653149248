import classNames from 'classnames';
import { noop } from 'lodash';
import { Button, LayoutBox } from '@bamboohr/fabric';

export function PathHeader(props) {
	const {
		buttonEnabled = true,
		onClick = noop,
		pathTypeName,
		showButton = false
	} = props;

	const _handleOnClick = onClick;

	let translatedHeading = '';
	let translatedButtonText = '';

	switch (pathTypeName) {
		case 'division':
			translatedButtonText = $.__('Add a Division Approval');
			translatedHeading = $.__('Approval Paths by Division');
			break;
		case 'department':
			translatedButtonText = $.__('Add a Department Approval');
			translatedHeading = $.__('Approval Paths by Department');
			break;
		case 'location':
			translatedButtonText = $.__('Add a Location Approval');
			translatedHeading = $.__('Approval Paths by Location');
			break;
	}

	const buttonClassNames = classNames(
		'fl-right',
		'fab-Button',
		'fab-Button--outline',
		'fab-Button--teenie',
		{ 'disabled': !buttonEnabled },
	);

	return (
		<div className="AdvancedApprovalPathHeader">
			<span className="AdvancedApprovalPathHeader__header">{ translatedHeading }</span>
			{
				showButton && (
					<LayoutBox
						alignSelf={'end'}
						marginLeft={'auto'}
					>
						<Button
							clickAction={ _handleOnClick }
							isDisabled={ !buttonEnabled }
							color='secondary'
							size="small"
							type="button"
							children={translatedButtonText}
							startIcon='circle-plus-regular'
						/>
					</LayoutBox>
				)
			}
		</div>
	)
}
