import { Button, StandardModal, TextButton } from '@bamboohr/fabric';

export function MoveAwayModal(props) {
	const { visible, onClose, onSave, onDontSave } = props;

	return (
		<StandardModal isOpen={visible} onRequestClose={onClose}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<TextButton type="button" onClick={onClose}>
								{$.__('Cancel')}
							</TextButton>,
							<Button type="button" color="secondary" onClick={onDontSave}>
								{$.__("Don't Save")}
							</Button>,
							<Button type="button" onClick={onSave}>
								{$.__('Save Changes')}
							</Button>,
						]}
					/>
				}
				renderHeader={<StandardModal.Header title={$.__('Just Checking...')} />}
			>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline
						icon="triangle-exclamation-solid"
						iconColor="warning-medium"
						text={$.__(
							"You haven't saved your changes. Your changes will be lost if you navigate away from this page without saving.",
						)}
					/>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
