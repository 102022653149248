import { Section, Button } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @startCleanup encore */
import { ClipboardPulse16x20 } from '@bamboohr/grim';
import './employee-wellbeing-header.styl';
/* @endCleanup encore */

type EmployeeWellbeingHeaderProps = {
	onKillSwitchClick?: () => void;
	showKillSwitch?: boolean;
	borderBottom?: boolean;
};

export const EmployeeWellbeingHeader: React.FC<React.PropsWithChildren<EmployeeWellbeingHeaderProps>> = ({
	onKillSwitchClick = () => ({}),
	showKillSwitch = false,
	borderBottom = true,
}) => {
	const buttonLabel = $.__('Turn Off Employee Wellbeing');

	return ifFeature(
		'encore',
		<Section.Header
			actions={
				showKillSwitch && (
					<Button onClick={onKillSwitchClick} variant='outlined' color='secondary' type='button'>
						{buttonLabel}
					</Button>
				)
			}
			divider='bottom'
			size='large'
			title={$.__('Employee Wellbeing')}
		/>,
		<div className={`EmployeeWellbeingHeader ${borderBottom ? '' : 'EmployeeWellbeingHeader--noBorderBottom'}`}>
			<div className='EmployeeWellbeingHeader__heading'>
				<div className='EmployeeWellbeingHeader__headingIcon'>
					<ClipboardPulse16x20 />
				</div>
				<div className='EmployeeWellbeingHeader__title'>{$.__('Employee Wellbeing')}</div>
				{showKillSwitch && (
					<>
						<div className='EmployeeWellbeingHeader__headingSpacer'></div>
						<Button clickAction={onKillSwitchClick} outline={true} secondary={true} text={buttonLabel} type='button' />
					</>
				)}
			</div>
		</div>
	);
};
