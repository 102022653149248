import { ifFeature } from '@bamboohr/utils/lib/feature';
import useVisualEffects from './visual-effects';
import { Flex, makeStyles, TextField } from '@bamboohr/fabric';

interface SurveyTextFieldProps {
	freeResponse: string;
	questionNumber: number;
	onChange: (value: string) => void;
	isSmallScreen: boolean;
	disabled: boolean;
}

/* @startCleanup encore */
const useStyles = makeStyles({
	freeResponse: {
		marginBottom: '28px',
		display: 'flex',
		justifyContent: 'center',
		'& textarea': {
			overflow: 'auto !important',
		},
	},
	largeFreeResponse: {
		'& textarea': {
			transition: 'min-height 0.5s',
			minHeight: 0,
			height: 'unset !important',
		},
		'& textarea:focus': {
			minHeight: 110,
		},
	},
	expandedFreeResponse: {
		'& textarea': {
			minHeight: 110,
		},
	},
	paddingSmall: {
		padding: '0 20px',
	},
});
/* @endCleanup encore */

export const SurveyTextField: React.FC<React.PropsWithChildren<SurveyTextFieldProps>> = ({
	isSmallScreen,
	freeResponse,
	onChange,
	questionNumber,
	disabled,
}) => {
	/* @startCleanup encore */
	const classes = useStyles();
	const { expandedFreeResponse } = classes;
	const { textAreaClass, expandTextArea } = useVisualEffects({ classes: { expandedFreeResponse }, questionNumber });
	/* @endCleanup encore */
	return ifFeature(
		'encore',
		<Flex justifyContent='center' marginBottom={2.5} paddingX={5}>
			<TextField
				disabled={disabled}
				key={questionNumber}
				label={isSmallScreen && $.__("Anything you'd like to add?")}
				multiline={true}
				onChange={({ target: { value } }) => onChange(value)}
				placeholder={isSmallScreen ? $.__('Type your comment here...') : $.__("Anything you'd like to add?")}
				minRows={3}
				maxRows={5}
				size='medium'
				value={freeResponse}
				width={100}
			/>
		</Flex>,
		<div className={`${classes.freeResponse} ${isSmallScreen ? classes.paddingSmall : ''}`}>
			<TextField
				classes={{ root: `${classes.freeResponse} ${isSmallScreen ? '' : classes.largeFreeResponse} ${textAreaClass}` }}
				disabled={disabled}
				key={questionNumber}
				label={isSmallScreen && $.__("Anything you'd like to add?")}
				multiline={true}
				onBlur={() => expandTextArea(isSmallScreen)}
				onChange={({ target: { value } }) => onChange(value)}
				placeholder={isSmallScreen ? $.__('Type your comment here...') : $.__("Anything you'd like to add?")}
				rows={isSmallScreen ? 3 : 1}
				rowsMax={5}
				size='medium'
				value={freeResponse}
				width={10}
			/>
		</div>
	);
};
