import { BlankState, Button, LayoutBox } from '@bamboohr/fabric';

export const ReviewCyclesBlankState = (): JSX.Element => {
	const handleCreateReviewCycleClick = () => {
		window.location.href = '/settings/performance/home/setup';
	};

	return (
		<LayoutBox marginTop="94px">
			<BlankState
				actions={[
					<Button
						onClick={() => handleCreateReviewCycleClick()}
						startIcon="circle-plus-regular"
						color="secondary"
					>
						{$.__('Create a Review Cycle')}
					</Button>,
				]}
				icon="bs-inbox"
				subtitle={$.__('Setup Assessments and Feedback for employees.')}
				title={$.__('No review cycles currently exist')}
			/>
		</LayoutBox>
	);
};
