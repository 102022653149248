import {
	BodyText,
	Flex,
	LayoutBox,
	PageHeader,
	Popover,
	TextButton,
	makeStyles,
} from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import { redirect } from 'BambooHR.util';
import React, { useRef, useState } from 'react';

const useStyles = makeStyles({
	unorderedList: {
		listStyleType: 'disc',
	},
});

export function InvoiceHistoryHeader() {
	const classes = useStyles();
	const [isBilledForPopoverOpen, setIsBilledForPopoverOpen] = useState(false);
	const ref = useRef(null);

	return (
		<LayoutBox marginBottom={2}>
			<Flex flexDirection='row' justifyContent='space-between'>
				<PageHeader
					back={$.__('Billing')}
					divider={false}
					onBack={() => redirect('/settings/account/billing')}
					title={$.__('Invoice History')}
				/>
					<Flex alignItems='flex-end' paddingBottom={2}>
						<TextButton onClick={() => setIsBilledForPopoverOpen(true)} type='button' ref={ref}>
							{$.__('What am I billed for?')}
						</TextButton>
						<Popover
							anchorEl={ref.current}
							hasMaxWidth={false}
							onClose={() => setIsBilledForPopoverOpen(false)}
							open={isBilledForPopoverOpen}
							title={$.__('What am I billed for?')}
							placement='left'
						>
							<LayoutBox marginRight={5} maxWidth='490px'>
								<LayoutBox marginLeft={2.5}>
									<ul className={classes.unorderedList}>
										<li>
											<BodyText>
												{$.__('Each billing cycle, you are billed for the active employees for each of your products*')}
											</BodyText>
										</li>
										<li>
											<BodyText>
												{$.__(
													'The active employee count is taken on a different random day each billing cycle sometime during the week before your invoice date.'
												)}
											</BodyText>
										</li>
										<li>
											<BodyText>
												{$.__(
													'When you subscribe to a new product, you are charged for the number of intended employees you provided your sales rep during the sales process until you reach that number. The billing team can adjust that number for you if needed.'
												)}
											</BodyText>
										</li>
									</ul>
								</LayoutBox>
								<LayoutBox marginY={1}>
									<BodyText size='small'>
										{$.__(
											'*Includes contractors, interns, part-time employees, employees with a future hire date, and terminated employees that are still active.'
										)}
									</BodyText>
								</LayoutBox>
								<Message
									link={{
										'data-bi-id': 'invoice-history-billing-faq-link',
										extra: 'externalLink',
										href: 'https://help.bamboohr.com/s/article/991457',
									}}
									text={$.__('See [Billing FAQ] for more info')}
								/>
							</LayoutBox>
						</Popover>
					</Flex>
			</Flex>
		</LayoutBox>
	);
}
