import { Fragment } from 'react';
import {TextButton } from '@bamboohr/fabric';

import { isEnabled as isFeatureEnabled } from 'FeatureToggle.util';

import { StatusTooltip } from '../components';
import { useBenefitsSettingsStore } from '../../../data/benefits-settings-store';
import { FEATURE_TOGGLE_KEYS } from '../../../../etc/feature-toggle';

interface NotEnrolledProps {
	eligibleWhenMarkedManually: number;
	onClick: () => void;
	planId: number;
	planIsMissingPlanYearOrDetails: boolean;
	setIsEligibilityModalOpen: (open: boolean) => void;
}

export function NotEnrolled(props: NotEnrolledProps): JSX.Element {
	const {
		eligibleWhenMarkedManually,
		onClick,
		planIsMissingPlanYearOrDetails,
		setIsEligibilityModalOpen,
	} = props;
	const [{ isBenefitsAdminEnabled }] = useBenefitsSettingsStore();

	return (
		<Fragment>
			<StatusTooltip
				isOpen={isFeatureEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_DATA_PARITY_PLAN_YEAR) && planIsMissingPlanYearOrDetails && isBenefitsAdminEnabled}
			>
				<>
					{eligibleWhenMarkedManually > 0 && (
						<div>
							<TextButton
								color="secondary"
								disabled={isFeatureEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_DATA_PARITY_PLAN_YEAR) && planIsMissingPlanYearOrDetails && isBenefitsAdminEnabled}
								inline={true}
								onClick={() => {
									setIsEligibilityModalOpen(true);
								}}
								type="button"
							>
								{$.__('%s will be eligible when marked manually.', eligibleWhenMarkedManually)}
							</TextButton>
						</div>
					)}
					<div className="Columns__statusNotEnrolled">{$.__('No one is marked enrolled in this plan.')}</div>
				</>
			</StatusTooltip>
			<div>
				{(planIsMissingPlanYearOrDetails && isFeatureEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_DATA_PARITY_PLAN_YEAR) && isBenefitsAdminEnabled) ? (
					<></>
				) : (
					<TextButton
						children={$.__('Enroll someone.')}
						inline={true}
						onClick={onClick}
						type="button"
					/>
				)}
			</div>
		</Fragment>
	);
}
