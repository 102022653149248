import React, { ChangeEvent, useState } from 'react';
import { Button, LayoutBox, StandardModal, TextButton } from '@bamboohr/fabric';

import { useBenefitsSettingsStore } from '../data/benefits-settings-store';
import { BenefitPlanListContract } from '../types/contracts';
import { deletePlan } from '../utils/api-service';

interface DeleteModalProps {
	isOpen: boolean;
	onClose: () => void;
	onDelete: (planName: string) => void;
	onProcessingChange: (isProcessing: boolean) => void;
	rowData: BenefitPlanListContract;
}

export function DeleteModal(props: DeleteModalProps): JSX.Element {
	const {
		isOpen,
		onClose,
		onDelete,
		onProcessingChange,
		rowData,
	} = props;

	if (!rowData) {
		return null;
	}

	const { electionCount, enrolled, enrollmentWindowCount } =
		rowData.status || {};
	const [{ isBenefitsAdminEnabled }] = useBenefitsSettingsStore();
	const [isValid, setIsValid] = useState<boolean>(false);

	const onConfirm = () => {
		onProcessingChange(true);
		deletePlan(rowData.id).then(
			(response) => {
				if (response) {
					onDelete(rowData.planName);
				} else {
					console.error(response);
					window.setMessage($.__('Something went wrong.'), 'error');
					onProcessingChange(false);
				}
			},
			(error) => {
				console.error(error);
				window.setMessage(error, 'error');
				onProcessingChange(false);
			},
		);
	};

	const subtext = isBenefitsAdminEnabled
		? $.__(
				'%1$s employees will be unenrolled, and %2$s pending elections will be deleted from %3$s enrollment windows.',
				enrolled,
				electionCount,
				enrollmentWindowCount,
			)
		: $.__('%1$s employees will be unenrolled.', enrolled);

	return (
		<StandardModal isOpen={isOpen} onRequestClose={onClose}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<TextButton
								color="secondary"
								key="cancel"
								onClick={onClose}
								type="button"
							>
								{$.__('Cancel')}
							</TextButton>,
							<Button
								disabled={!isValid}
								key="confirm"
								onClick={onConfirm}
								type="button"
							>
								{$.__('Yes, Delete Plan')}
							</Button>,
						]}
					/>
				}
				renderHeader={
					<StandardModal.Header
						hasCloseButton={true}
						title={$.__('Are you sure?')}
					/>
				}
				size={isBenefitsAdminEnabled ? 'medium' : 'small'}
			>
				<StandardModal.Constraint
					spacingOverrides={{
						bottom: StandardModal.Constraint.Spacing.MEDIUM,
						top: StandardModal.Constraint.Spacing.MEDIUM,
					}}
				>
					<StandardModal.HeroHeadline
						icon="trash-can-regular"
						iconColor="error-medium"
						subtext={subtext}
						text={$.__('Are you sure that you want to delete this plan?')}
					/>
					<LayoutBox>
						<StandardModal.ConfirmationContent
							confirmationText={$.__(
								`Type 'Delete' to permanently delete this plan.`,
							)}
							onConfirmationChange={(event: ChangeEvent<HTMLInputElement>) => {
								setIsValid(
									event.currentTarget.value.toLowerCase() === $.__('delete'),
								);
							}}
						/>
					</LayoutBox>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
