import { Fragment, useState } from 'react';
import { BodyText, Flex, LayoutBox, TextButton, Tooltip } from '@bamboohr/fabric';

import { isEnabled as isFeatureEnabled } from 'FeatureToggle.util';
import { redirect } from 'BambooHR.util';

import { Draft } from './statuses/draft';
import { Enrolled } from './statuses/enrolled';
import { Inactive } from './statuses/inactive';
import { NotEnrolled } from './statuses/not-enrolled';

import './status.styl';
import { PoMicroFrontend } from 'micro-frontend.react';
import { StatusTooltipContent } from './components';
import { getPlanUnsupportedCoverages } from './utils/get-plan-unsupported-coverages';
import { EligibilityModal } from '../../eligibility-modal/eligibility-modal';
import { AdditionalPlanDetails, BenefitPlanCoverages, BenefitPlanStatusContract, PlanCategoryDataOptionsContract, PlanYear } from '../../types';
import { CoverageTypes } from '../../../types/coverage-type';
import { coverageOptionsPlanTypes, PlanTypeEnumContract } from '../../../types';
import { useBenefitsSettingsStore } from '../../data/benefits-settings-store';
import { FEATURE_TOGGLE_KEYS } from '../../../etc/feature-toggle';
import { getPlanIsMissingCoverageLevels } from './utils/get-plan-is-missing-coverage-levels';
import { isPlanMissingCoverageLevels } from './utils/is-plan-missing-coverage-levels';

interface StatusProps {
	coverages: BenefitPlanCoverages[];
	endDate: string;
	getBenefitPlans: () => void;
	isActive: boolean;
	isDraft: boolean;
	isFutureDated: boolean;
	isRestorable?: boolean;
	missingPlanDetails: boolean;
	missingPlanYear: boolean;
	planCarrier: string;
	planCategoryDataOptions: PlanCategoryDataOptionsContract | null | undefined;
	planId: number;
	planName: string;
	planType: PlanTypeEnumContract;
	planYears: PlanYear[];
	startDate: string;
	status: BenefitPlanStatusContract;
	supportedCoverageLevels: CoverageTypes;
}

export function Status(props: StatusProps): JSX.Element {
	const {
		coverages,
		getBenefitPlans,
		isActive,
		isDraft,
		isFutureDated,
		isRestorable,
		missingPlanDetails,
		missingPlanYear,
		planCarrier,
		planCategoryDataOptions,
		planId,
		planName,
		planType,
		planYears,
		status,
		startDate,
		endDate,
		supportedCoverageLevels,
	} = props;
	const [{ isBenefitsAdminEnabled }] = useBenefitsSettingsStore();

	const [isEligibilityModalOpen, setIsEligibilityModalOpen] = useState(false);
	const [isMissingCoveragesModalOpen, setIsMissingCoveragesModalOpen] = useState<boolean>(false);
	const [isAddToPlanYearModalOpen, setIsAddToPlanYearModalOpen] = useState<boolean>(false);
	const planDates = { startYmd: startDate, endYmd: endDate || '2079-06-06' };


	const selectedCoverageLevelIds = Object.values(coverages).map((plan: BenefitPlanCoverages) => plan.benefitCoverageId);
	const planIsMissingCoverageOptions: boolean = getPlanIsMissingCoverageLevels(supportedCoverageLevels, selectedCoverageLevelIds);
	const planUnsupportedCoverages = getPlanUnsupportedCoverages(supportedCoverageLevels, coverages).length;

	const showUnsupportedCoverageStatus = isBenefitsAdminEnabled && Boolean(planUnsupportedCoverages);
	const showMissingCoveragesStatus =
		isBenefitsAdminEnabled &&
		coverageOptionsPlanTypes.includes(planType) &&
		planIsMissingCoverageOptions &&
		Boolean(!planUnsupportedCoverages)

	const additionalPlanDetails: AdditionalPlanDetails = {
		benefitPlanId: String(planId),
		benefitType: planType,
		carrier: planCarrier,
		endDate,
		planName,
		startDate,
	}

	const bulkEnrollPath = `/settings/benefits/plan/${planId}/bulk_enroll`;
	const bulkStatusPath = `/settings/benefits/plan/${planId}/bulk_status`;
	const handleOnClick = (redirectPath) => {
		const isMissingCoverageLevels = isPlanMissingCoverageLevels(
			isBenefitsAdminEnabled,
			planType,
			planCategoryDataOptions,
			selectedCoverageLevelIds,
			supportedCoverageLevels,
		);
		return isMissingCoverageLevels
			? setIsMissingCoveragesModalOpen(true)
			: redirect(redirectPath);
	};

	return (
		<Fragment>
			{isDraft ? (
				<Draft planId={planId} />
			) : (
				<Fragment>
					{isActive || isFutureDated ? (
						<Fragment>
							{status.enrolled > 0 ? (
								<Enrolled
									onClick={() => handleOnClick(bulkStatusPath)}
									planIsMissingPlanYearOrDetails={missingPlanYear || missingPlanDetails}
									setIsEligibilityModalOpen={setIsEligibilityModalOpen}
									status={status}
								/>
							) : (
								<NotEnrolled
									eligibleWhenMarkedManually={status.eligibleWhenMarkedManually}
									onClick={() => handleOnClick(bulkEnrollPath)}
									planId={planId}
									planIsMissingPlanYearOrDetails={missingPlanYear || missingPlanDetails}
									setIsEligibilityModalOpen={setIsEligibilityModalOpen}
								/>
							)}
						</Fragment>
					) : (
						<Inactive isRestorable={isRestorable} planId={planId} />
					)}
					<EligibilityModal
						id={planId}
						isOpen={isEligibilityModalOpen}
						onClose={() => {
							setIsEligibilityModalOpen(false);
						}}
						planDates={planDates}
						planName={planName}
					/>
					<PoMicroFrontend
						isOpen={isMissingCoveragesModalOpen}
						onClose={() => setIsMissingCoveragesModalOpen(false)}
						planId={planId.toString()}
						route="/settings/benefits/missing-coverages-modal"
					/>

					{isFeatureEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_HOME_PAGE) ? (
						missingPlanDetails && (
							<LayoutBox marginTop={2}>
								<Tooltip
									content={<StatusTooltipContent />}
									placement='bottom-start'
								>
									<Flex>
										<BodyText
											color="error-strong"
											icon="triangle-exclamation-regular"
										>
											{$.__('Additional Details Needed')}
										</BodyText>
									</Flex>
								</Tooltip>
							</LayoutBox>
						)
					) : (
						<>
							{showUnsupportedCoverageStatus && (
								<PoMicroFrontend
									planId={planId}
									planIsMissingCoverageOptions={planIsMissingCoverageOptions}
									route="/settings/benefits/unsupported-coverages-popover"
									unsupportedCoverageLevelAmount={planUnsupportedCoverages}
								/>
							)}
							{showMissingCoveragesStatus && (
								<PoMicroFrontend
									planId={planId}
									route="/settings/benefits/missing-coverages-popover"
								/>
							)}
						</>
					)}
					{isFeatureEnabled(FEATURE_TOGGLE_KEYS.BENEFITS_DATA_PARITY_PLAN_YEAR) && missingPlanYear && isBenefitsAdminEnabled && (
						<>
							<LayoutBox marginTop={2}>
									<Flex flexDirection='column'>
										<BodyText
											color="warning-medium"
											icon="calendar-exclamation-regular"
										>
											{$.__('Plan is not assigned to a Plan Year.')}
										</BodyText>
										<LayoutBox marginLeft={3}>
											<TextButton
												data-bi-id="plans-add-to-plan-year"
												inline={true}
												onClick={() => setIsAddToPlanYearModalOpen(true)}
												type="button"
											>{$.__('Add to Plan Year')}</TextButton>
										</LayoutBox>
									</Flex>
							</LayoutBox>
							<PoMicroFrontend
								benefitPlan={additionalPlanDetails}
								isOpen={isAddToPlanYearModalOpen}
								onClose={() => setIsAddToPlanYearModalOpen(false)}
								onSave={getBenefitPlans}
								planYears={planYears}
								route="/settings/benefits/plan-year-addition-modal"
							/>
						</>
					)}
				</Fragment>
			)}
		</Fragment>
	);
}
