import { PlanTypeEnumContract } from '../types';

export const PLAN_ICONS = {
	[PlanTypeEnumContract.health]: 'fab-heart-ekg-28x24',
	[PlanTypeEnumContract.dental]: 'fab-tooth-28x28',
	[PlanTypeEnumContract.vision]: 'fab-eye-28x20',
	[PlanTypeEnumContract.supplemental]: 'fab-heart-plus-28x24',
	[PlanTypeEnumContract.retirement]: 'fab-piggy-bank-28x26',
	[PlanTypeEnumContract.reimbursement]: 'fab-money-reverse-28x28',
	[PlanTypeEnumContract.hsa]: 'fab-heart-money-28x24',
	[PlanTypeEnumContract.flex]: 'fab-wallet-28x26',
	[PlanTypeEnumContract.life]: 'fab-medical-shield-24x28',
	[PlanTypeEnumContract.disability]: 'fab-wheelchair-25x28',
	[PlanTypeEnumContract.other]: 'fab-star-28x27',
};

export const getPlanIcon = (planType: PlanTypeEnumContract): string => {
	return PLAN_ICONS[planType] ? PLAN_ICONS[planType] : '';
};
