import { FullScreenModal, Button, makeStyles } from '@bamboohr/fabric';
import { EmployeeWellbeingSurvey } from 'base/survey/employee_wellbeing';
import { EmployeeWellbeingSurveyService } from 'base/survey/employee_wellbeing/types';
import { useContext, useEffect, useState } from 'react';
import { SurveyStatus } from '../utils/';
import BackendSurveyPreviewService from './service';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const useStyles = makeStyles(({ palette }) => ({
	content: {
		display: 'inline',
		/* @startCleanup encore */
		'& > div > div': {
			backgroundColor: ifFeature('encore', undefined, palette.gray[100]),
		},
		/* @endCleanup encore */
	},
}));

const SurveyPreviewModal: React.FC<React.PropsWithChildren<unknown>> = () => {
	const { isPreviewing, hidePreview, previewEndpoint } = useContext(SurveyStatus);
	const [service, setService] = useState<EmployeeWellbeingSurveyService>(new BackendSurveyPreviewService(previewEndpoint));

	const classes = useStyles();

	useEffect(() => {
		setService(new BackendSurveyPreviewService(previewEndpoint));
	}, [isPreviewing]);

	return (
		<FullScreenModal isOpen={isPreviewing} onRequestClose={hidePreview}>
			<div className={classes.content}>
				<FullScreenModal.Body
					renderFooter={
						<FullScreenModal.Footer
							actions={[
								<Button key='button-1' onClick={hidePreview} type='button'>
									{$.__('Close')}
								</Button>,
							]}
						/>
					}
					renderHeader={<FullScreenModal.Header title={$.__('Survey Preview')} />}
				>
					<FullScreenModal.Constraint>
						<EmployeeWellbeingSurvey service={service} isPreview={true} />
					</FullScreenModal.Constraint>
				</FullScreenModal.Body>
			</div>
		</FullScreenModal>
	);
};

export default SurveyPreviewModal;
