/** copied from `./index.js` and refactored into a functional component */

import { BlankState, LayoutBox } from '@bamboohr/fabric';
import moment from 'moment';
import { useState } from 'react';
import TimeTrackingNotificationModal from 'time-tracking/modals/time-tracking-notification';
import { nonReactRender } from '../setup-checklist.react/components/enable-modal/future';
import './styles.styl';
import { Table } from './table';
import { TimeTrackingEmployee } from './types';

export interface EmployeeSettingsProps {
	canChangeFutureDates: boolean;
	canToggleTimeTracking: boolean;
	settingsMode: 'global' | 'group';
	clockInIdEnabled: boolean;
	customOvertimeEnabled: boolean;
	employees: TimeTrackingEmployee[];
	reloadEmployees: () => void;
	renderActions: (props) => JSX.Element;
	renderAddEmployeesButton: () => JSX.Element;
	showEnableOptionsWhenAddingEmployees: boolean;
}

export function EmployeeSettings(props: EmployeeSettingsProps) {
	const {
		canChangeFutureDates,
		canToggleTimeTracking,
		settingsMode,
		clockInIdEnabled,
		customOvertimeEnabled,
		employees,
		reloadEmployees,
		renderActions,
		renderAddEmployeesButton,
	} = props;
	const [
		isChangeAllStartDatesButtonProcessing,
		setIsChangeAllStartDatesButtonProcessing,
	] = useState(false);
	const [notificationModalOpen, setNotificationModalOpen] = useState(false);
	const [notificationModalEmployees, setNotificationModalEmployees] = useState(
		[],
	);
	const [notificationModalType, setNotificationModalType] = useState(
		'someEmployeesNotRescheduled',
	);

	const onSaveAllStartDates = (paySchedules: { date: string }[]) => {
		const dates = paySchedules
			.map((ps) => moment(ps.date).format('MMMM D, YYYY'))
			.join(` ${$.__('and')} `);
		window.setMessage(
			$.__('Got it! Time tracking will start %1$s', dates),
			'success',
		);
		reloadEmployees();
	};

	const onSaveAllStartDatesFail = (errorResponse: {
		type: string;
		employees: unknown[];
	}) => {
		setNotificationModalOpen(true);
		setNotificationModalEmployees(errorResponse.employees);
		setNotificationModalType(errorResponse.type);
		reloadEmployees();
	};

	return (
		<div>
			{canToggleTimeTracking && (
				<div style={{ marginBottom: 16 }}>
					{renderActions({
						changeStartDates: canChangeFutureDates
							? () => {
									setIsChangeAllStartDatesButtonProcessing(true);
									// Need to update this to use the react modal... but for times sake...
									nonReactRender(
										'edit',
										() => setIsChangeAllStartDatesButtonProcessing(false),
										onSaveAllStartDates,
										Function.prototype,
										[],
										onSaveAllStartDatesFail,
									);
							  }
							: undefined,
						isChangingStartDates: isChangeAllStartDatesButtonProcessing,
					})}
				</div>
			)}

			{employees.length === 0 && (
				<LayoutBox marginTop={7.5}>
					<BlankState
						actions={[renderAddEmployeesButton()]}
						icon="bs-clock"
						title={$.__(`There aren't any employees tracking time right now.`)}
					/>
				</LayoutBox>
			)}

			{employees.length > 0 && (
				<Table
					canToggleTimeTracking={canToggleTimeTracking}
					clockInIdEnabled={clockInIdEnabled}
					customOvertimeEnabled={customOvertimeEnabled}
					employees={employees}
					reloadEmployees={reloadEmployees}
					settingsMode={settingsMode}
				/>
			)}

			<TimeTrackingNotificationModal
				employees={notificationModalEmployees}
				isOpen={notificationModalOpen}
				onClose={() => setNotificationModalOpen(false)}
				type={notificationModalType}
			/>
		</div>
	);
}
