import { Component } from 'react';
import { isEnabled } from 'FeatureToggle.util';
import Ajax from '@utils/ajax';
import { Typography, IconV2 } from '@bamboohr/fabric';
import { Button } from '@fabric/button';
import { Popover } from 'popover.react';

import ChecklistItem from './components/checklist-item';
import PlaceholderItem from './components/placeholder-item';
import EnableNowModal from './components/enable-modal/now';
import EnableFutureModal from './components/enable-modal/future';
import TimeTrackingNotificationModal from 'time-tracking/modals/time-tracking-notification';

import './styles.styl';

const futureSchedulingEnabled = isEnabled('timeTrackingFutureScheduling');
const partialHolidaysEnabled = isEnabled('partial_holidays_full_stack_toggle');
const timeTrackingTrialEnabled = isEnabled('time_tracking_trial');

function WrapIf({ condition, wrap, children }) {
	return condition ? wrap(children) : children;
}

export class SetupChecklist extends Component {

	constructor(props) {
		super(props);

		const checklistData = props;

		// eslint-disable-next-line react/state-in-constructor
		this.state = {
			currentStep: checklistData.currentChecklistStep,
			showEnableNowModal: false,
			showEnableFutureModal: false,
			processingFutureButton: false,
			notificationModalOpen: false,
			notificationModalEmployees: [],
			notificationModalType: 'someEmployeesNotRescheduled',
			items: [
				{
					ariaLabel: $.__('Verify Your Pay Schedules'),
					title: $.__('Verify Your Pay Schedules'),
					helpText: timeTrackingTrialEnabled ? $.__('Employee timesheets will start and end as defined by your pay period. Notifications for approvals are based on your pay day. Double check that everything is accurate.') : $.__('Check your pay schedules so we can setup timesheets and approvals.'),
					linkText: $.__('Go to Pay Schedules'),
					link: '/settings/pay_schedules?time_tracking_setup=pay_schedule',
					key: 'schedule'
				},
				{
					ariaLabel: $.__('Verify Your Business Locations'),
					title: $.__('Verify Your Business Locations'),
					helpText: timeTrackingTrialEnabled ? $.__('We use the employee’s location to calculate standard overtime rules based on state, territory, and province rules. Double check that all your addresses are accurate.') : $.__('Check your location addresses so we can calculate overtime correctly.'),
					linkText: $.__('Go to Locations'),
					link: `${ checklistData.locationListUrl }&time_tracking_setup=locations`,
					key: 'locations'
				},
				{
					ariaLabel: $.__('Holidays Settings'),
					title: $.__('Holidays Settings'),
					helpText: timeTrackingTrialEnabled ? $.__('Check your company holidays so we can calculate holiday hours and pay correctly.') : $.__('Check your company holidays so we can calculate holiday hours and pay correctly'),
					linkText: $.__('Go to Holiday Settings'),
					link: `/settings/holidays/view?time_tracking_setup=holidays`,
					key: 'holidays'
				},
				{
					ariaLabel: $.__('Manage Settings'),
					title: $.__('Manage Settings'),
					helpText: partialHolidaysEnabled ? $.__('Select time entry type, approvals, overtime rules, and mobile preferences.') : $.__('Set up your timesheet, holiday pay, and overtime preferences.'),
					linkText: $.__('Go to Settings'),
					link: '/settings/time_tracking?tab=settings&origin-setup-checklist=true',
					key: 'manage'
				},
				{
					ariaLabel: $.__('Add Employees'),
					title: $.__('Add Employees'),
					helpText: timeTrackingTrialEnabled ? $.__('As you add employees, verify they are assigned to the right Time Tracking settings, location, and pay schedule.') : $.__('Select which employees will be using time tracking.'),
					linkText: $.__('Add Employees'),
					link: '/settings/time_tracking?tab=employees&origin-setup-checklist=true',
					key: 'employees'
				},
			]
		};
	}

	componentDidMount() {
		if (!partialHolidaysEnabled) {
			this.setState(prevState => ({
				items: prevState.items.filter(item => item.key !== 'holidays')
			}));
		}
	}

	_countRemainingItems = () => {
		const { items, currentStep } = this.state;
		return items.length - currentStep;
	};

	_handleCheck = (id) => {
		const { currentStep } = this.state;
		if (id === currentStep) {
			this.setState(prevState => ({
				currentStep: prevState.currentStep + 1,
			}), async () => {
				try{
					await this._completeStep(currentStep);
				} catch ( error ){
					this.setState(prevState => ({ currentStep: prevState.currentStep - 1}));
					window.setMessage($.__(`Whoops! Something went wrong completing that step. Please refresh and try again.`), 'error');
				}
			});
		}
	};

	_completeStep = (stepToComplete) => {
		return Ajax.post('/settings/time_tracking/checklist/complete_step', {
			step: stepToComplete,
		});
	};

	_renderItems = () => {
		const { items, currentStep } = this.state;
		return items.map((item, index) => {
			item.id = index;
			item.isChecked = index < currentStep;
			if (item.id <= currentStep) {
				return (
					<ChecklistItem { ...item } key={ item.title } onCheck={ this._handleCheck } />
				);
			}

			return (
				<PlaceholderItem key={ item.title } />
			);
		});
	};

	_handleToggleEnableNowModal = () => {
		const { showEnableNowModal } = this.state;
		const pageContainer = document.querySelector('.BhrPage-wrap')
		if(pageContainer){
			pageContainer.style.visibility = 'visible'
		}

		this.setState({ showEnableNowModal: !showEnableNowModal });
	};

	_handleA11yEnableNowModal = () => {
		const pageContainer = document.querySelector('.BhrPage-wrap')
		if(pageContainer){
			pageContainer.style.visibility = 'hidden'
		}
	}

	_handleOpenScheduleModal = () => {
		this.setState({
			showEnableFutureModal: true,
			processingFutureButton: true,
		});
	};

	_handleCloseScheduleModal = () => {
		this.setState({ showEnableFutureModal: false });
	};

	_handleScheduleRequestComplete = () => {
		this.setState({ processingFutureButton: false });
	};

	_handleNotificationModalClose = () => {
		this.setState({ notificationModalOpen: false });
		window.location.reload();
	};

	_handleCompleteChecklistAndEnableNow = () => {
		this.setState({ showEnableNowModal: false });

		Ajax.post('/settings/time_tracking/checklist/complete_and_enable_now')
			.then((response) => {
				if (futureSchedulingEnabled && response.data?.status === 'WARNING') {
					this.setState({
						notificationModalOpen: true,
						notificationModalEmployees: response.data.employees,
						notificationModalType: response.data.type,
					});
				} else {
					window.location.assign('/settings/time_tracking');
				}
			})
			.catch(() => {
				window.errorFallBack();
			});
	};

	_handleCompleteChecklistAndSchedule = () => {
		window.location.assign('/settings/time_tracking');
	}

	_handleFutureModalFailure = (errorResponse) => {
		if (futureSchedulingEnabled) {
			this.setState({ showEnableFutureModal: false }, () => {
				this.setState({
					notificationModalOpen: true,
					notificationModalEmployees: errorResponse.employees,
					notificationModalType: errorResponse.type,
				});
			});
		}
	};

	render() {
		const {
			notificationModalEmployees,
			notificationModalOpen,
			notificationModalType,
			showEnableNowModal,
			showEnableFutureModal,
		} = this.state;

		const popoverOptions = {
			position: 'top',
			template: {
				name: 'popover-standard',
				data: {
					title: $.__n('%1 Task Remaining', '%1 Tasks Remaining', this._countRemainingItems()),
					content: $.__('Before enabling Time Tracking, review and check off each of the setup steps listed above.'),
				},
			},
			triggerEvent: 'hover',
		};

		const unfinished = this._countRemainingItems() > 0;

		return (
			<div className="TTChecklist">
				<div className="TTChecklist__header">
					<span className="TTChecklist__header__icon">
						<IconV2 name="stopwatch-regular" size={38} />
					</span>
					<div className="TTChecklist__header__text">
						<h3 className="TTChecklist__header__text--title">{ $.__('Time Tracking Setup Checklist') }</h3>
						<p>{ $.__('Get clocking with Time Tracking in a flash') }</p>
					</div>
				</div>

				<Typography>{$.__('Below are all the details that need to be in place before your employees start time tracking. When you’re ready, you can enable time tracking for your employees immediately or schedule a future start date.')}</Typography>

				<div className="TTChecklist__list">
					{ this._renderItems() }
				</div>

				<div className="TTChecklist__buttons">
					<WrapIf
						condition={ unfinished }
						wrap={ children => <Popover settings={ popoverOptions } wrapperClassName="TTChecklist__popover--enable">{ children }</Popover> }
					>
						<Button clickAction={ this._handleToggleEnableNowModal } data-bi-id="settings-time-tracking-enable-now-button" isDisabled={ unfinished } size="large" text={ $.__('Enable Now') } />
					</WrapIf>

					<WrapIf
						condition={ unfinished }
						wrap={ children => <Popover settings={ popoverOptions }>{ children }</Popover> }
					>
						<Button clickAction={ this._handleOpenScheduleModal } data-bi-id="settings-time-tracking-schedule-start-date" isDisabled={ unfinished } outline={ true } size="large" text={ $.__('Schedule Start Date') } />
					</WrapIf>
				</div>

				<EnableNowModal
					onClose={ this._handleToggleEnableNowModal }
					onOpen={this._handleA11yEnableNowModal}
					onSave={ this._handleCompleteChecklistAndEnableNow }
					visible={ showEnableNowModal }
				/>

				{ showEnableFutureModal && (
					<EnableFutureModal
						onClose={ this._handleCloseScheduleModal }
						onFail={ this._handleFutureModalFailure }
						onRequestComplete={ this._handleScheduleRequestComplete }
						onSave={ this._handleCompleteChecklistAndSchedule }
						type="checklist"
					/>
				) }

				{ futureSchedulingEnabled && (
					<TimeTrackingNotificationModal
						employees={ notificationModalEmployees }
						isOpen={ notificationModalOpen }
						onClose={ this._handleNotificationModalClose }
						type={ notificationModalType }
					/>
				) }
			</div>
		);
	}
}
