import { Button, IconV2, LayoutBox, StyledBoxV2 } from '@bamboohr/fabric';
import { Question } from 'NHPTemplates.mod/Form/Interfaces';
import { TemplateSaveData } from 'NHPTemplates.mod/Form/store/Interfaces';
import { getFormSaveData } from 'NHPTemplates.mod/Form/utils';
import { Employee } from 'NHPTemplates.mod/Previewer/Components/EmployeeInfo/employeeInterface';
import { Finished } from 'NHPTemplates.mod/Previewer/Components/Finished';
import { Team } from 'NHPTemplates.mod/Previewer/Components/Finished/teamInterface';
import { GetIntroduced } from 'NHPTemplates.mod/Previewer/Components/GetIntroduced';
import { NewEmployeeInfo } from 'NHPTemplates.mod/Previewer/Components/NewEmployeeInfo';
import { PreviewerHeader } from 'NHPTemplates.mod/Previewer/Components/PreviewerHeader';
import { Tasks } from 'NHPTemplates.mod/Previewer/Components/Tasks';
import { WhenAndWhere } from 'NHPTemplates.mod/Previewer/Components/WhenAndWhere';
import {
	parseQuestions,
	showQuestionsSection,
} from 'NHPTemplates.mod/Previewer/Containers/utils';
import {
	designerPanda,
	hrManagerPanda,
	managerPanda,
	recruiterPanda
} from 'NHPTemplates.mod/Previewer/pandas';
import { PureComponent, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';
import { BrowserRouter, Route, withRouter } from 'react-router-dom';
import { Previewer, Props } from '../../Components/Previewer';

type State = {
	firstDayDate: string;
	headerName: string;
	contact: Employee;
	team: Team;
	employeeName: string;
	gtkyQuestions: Array<Question>;
	arriveByTime: string;
	arrivalLocation: string;
	otherInstructions: string;
	sendGetToKnowYouEmail: 'no' | 'yes' | boolean;
	modalKey: number;
	modalState: boolean;
};

type Props = State &
	RouteComponentProps<any> & {
		logo: Logo;
		companyName: string;
		withFinishedFooter: boolean;
		headerAction: () => void;
	};

class NHPTPreviewerContainerComponent extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			firstDayDate: props.firstDayDate,
			headerName: props.headerName,
			contact: props.contact,
			employeeName: props.employeeName,
			gtkyQuestions: parseQuestions(props.gtkyQuestions),
			team: props.team,
			arriveByTime: props.arriveByTime,
			arrivalLocation: props.arrivalLocation,
			otherInstructions: props.otherInstructions,
			sendGetToKnowYouEmail: props.sendGetToKnowYouEmail,
			modalKey: 0,
			modalState: window.location.hash.includes('preview'),
		};

		this._showPreviewModal = this._showPreviewModal.bind(this);
		this._refreshData = this._refreshData.bind(this);
	}

	_refreshData(): void {
		const data: TemplateSaveData = getFormSaveData() as TemplateSaveData;
		this.setState({
			headerName: data.name,
			contact: data.contactEmployeeId ? recruiterPanda : undefined,
			team: {
				manager: recruiterPanda,
				members: [hrManagerPanda, managerPanda, designerPanda],
			},
			employeeName: 'Panda',
			gtkyQuestions: parseQuestions(data.gtkyQuestions),
			arriveByTime: data.arriveByTime,
			arrivalLocation: data.location,
			otherInstructions: data.otherInstructions,
			sendGetToKnowYouEmail: data.sendGetToKnowYouEmail,
		});
	}

	_showPreviewModal(e: Event): void {
		const { history } = this.props;

		history.push({
			hash: 'preview/when_and_where',
		});

		this._refreshData();

		this.setState({
			modalKey: Date.now(),
			modalState: true,
		});
	}

	componentDidMount(): void {
		this._refreshData();
		document.addEventListener(
			'nhpTemplate:name:showPreview',
			this._showPreviewModal,
		);
	}

	componentWillUnmount(): void {
		document.removeEventListener(
			'nhpTemplate:name:showPreview',
			this._showPreviewModal,
		);
	}

	render(): ReactElement {
		const { logo, companyName, headerAction } = this.props;

		const {
			headerName,
			contact,
			employeeName,
			gtkyQuestions = [],
			team,
			arriveByTime,
			arrivalLocation,
			otherInstructions,
			sendGetToKnowYouEmail,
			modalKey,
			modalState,
		} = this.state;

		const whenAndWhereProps = {
			companyName,
			headerName,
			contact,
			employeeName,
			arriveByTime,
			location: arrivalLocation,
			otherInstructions,
		};

		const components = {
			whenAndWhereComponent: (
				<WhenAndWhere
					{...whenAndWhereProps}
					asSection={true}
					withHeader={true}
				/>
			),
			newEmployeeInfoComponent: <NewEmployeeInfo isNHPT={true} />,
			getIntroducedComponent: <GetIntroduced gtkyQuestions={gtkyQuestions} />,
			tasksComponent: <Tasks isNHPT={true} />,
			finishedComponent: (
				<Finished
					companyName={companyName}
					employeeName={employeeName}
					isNHPTemplate={true}
					team={team}
				>
					<WhenAndWhere {...whenAndWhereProps} withHeader={false} />
				</Finished>
			),
		};

		const headerButtons = (actions: Array<() => any>) => {
			return (
				<>
					<LayoutBox marginRight="18px">
						<Button
							onClick={() => {
								this.setState(
									{
										modalState: false,
									},
									() => {
										if (typeof actions[0] === 'function') {
											actions[0]();
										}
									},
								);
							}}
							color="secondary"
							dark={true}
							size="medium"
							startIcon={
								<IconV2
									color="neutral-forced-white"
									name="pen-to-square-regular"
									size={16}
								/>
							}
							variant="outlined"
						>
							{$.__('Edit Template')}
						</Button>
					</LayoutBox>
					<Button
						onClick={() => {
							this.setState(
								{
									modalState: false,
								},
								() => {
									if (typeof actions[1] === 'function') {
										actions[1]();
									}
								},
							);
						}}
						dark={true}
						size="medium"
						type="button"
						variant="contained"
					>
						{$.__('Save Template')}
					</Button>
				</>
			);
		};

		const header = (headerProps) => {
			return (
				<>
					<PreviewerHeader
						{...headerProps}
						name={headerName}
						renderActionButtons={headerButtons}
						subtitle={$.__('Previewing New Hire Packet Template')}
					/>
					<StyledBoxV2
							backgroundColor="neutral-forced-white"
							padding="15px 48px"
						>
							<img
								alt={logo.alt}
								className={logo.classes}
								src={logo.logoSrc}
								style={{
									maxHeight: '60px',
									maxWidth: '300px',
									height: 'auto',
									width: `${logo.width}px`,
								}}
							/>
						</StyledBoxV2>
				</>
			);
		};

		return (
			<BrowserRouter>
				<Route>
					<Previewer
						{...components}
						companyName={companyName}
						headerAction={headerAction}
						isNHPT={true}
						key={modalKey}
						loading={false}
						logo={logo}
						modalState={modalState}
						renderHeader={header}
						showGetIntroduced={showQuestionsSection(
							sendGetToKnowYouEmail,
							gtkyQuestions,
						)}
						showTasks={true}
					/>
				</Route>
			</BrowserRouter>
		);
	}
}

const NHPTPreviewerContainer = withRouter(NHPTPreviewerContainerComponent);

export { NHPTPreviewerContainer };
