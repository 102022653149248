import { Button } from '@bamboohr/fabric';
import { ReactElement } from 'react';

export function HeaderAddEmployeeButton({ employeeModal }): ReactElement {
	return (
		<Button
			color="primary"
			data-bi-id="settings-access-levels-add-employee-btn"
			key="primary"
			onClick={() => employeeModal()}
			outline={true}
			size="small"
			startIcon={'circle-plus-regular'}
			text={$.__('Add Employee')}
			type="button"
		/>
	);
}
