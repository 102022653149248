import {
	useState,
} from 'react';

import { BodyText, Button, LayoutBox, StandardModal, TextButton } from '@bamboohr/fabric';
import Ajax from '@utils/ajax';

import {
	useReducerContext,
} from '../state';


/** @type {React.FunctionComponent} */
export default function ACADisableModal() {
	const context = useReducerContext();
	const {
		modal,
		dispatch,
		CLOSE_MODAL,
	} = context;
	const [processing, setProcessing] = useState(false);

	const onClose = () => dispatch(CLOSE_MODAL());

	return (
		<StandardModal
			data-bi-id="settings-disable-aca-tracking"
			isOpen={ modal === 'DISABLE' }
			onRequestClose={ onClose }
		>
			<StandardModal.Body
				renderFooter={ (
					<StandardModal.Footer
						actions={ [
							<TextButton
								key="secondary"
								onClick={ onClose }
								type="button"
							>
								{$.__('Cancel')}
							</TextButton>
							,
							<Button
								key="primary"
								onClick={ () => primaryAction(context, setProcessing) }
								processing={processing}
								type="button"
							>
								{ $.__('Yes, Disable ACA') }
							</Button>
						] }
					/>
				) }
			>
				<LayoutBox
					marginBottom={4}
					marginLeft={3}
					marginRight={3}
					marginTop={1}
				>
					<StandardModal.HeroHeadline
						icon='triangle-exclamation-regular'
						iconColor='warning-medium'
						text={ $.__('Are you sure that you want to disable ACA Tracking?') }
					/>
					<LayoutBox marginTop={1}>
						<BodyText color='neutral-strong' component='p' id='disable-aca-text' size='medium' weight='regular'>
							{ $.__('Once you disable ACA Tracking, you will not be able to track or report on any ACA related fields in your account unless you re-enable ACA tracking in the future.') }
						</BodyText>
					</LayoutBox>
				</LayoutBox>
			</StandardModal.Body>
		</StandardModal>
	);
}

/**
 * @param {import('../state').Context} context
 * @param {React.Dispatch<boolean>} setProcessing
 */
function primaryAction({
	dispatch,
	DISABLE,
}, setProcessing) {
	setProcessing(true);

	Ajax.post('/settings/account/aca.php', 'disable_aca=true')
		.catch((err) => {
			console.error(err);

			if (err.response.status === 403) {
				throw Ajax.createError(window.DEFAULT_PERMISSIONS_ERROR_MESSAGE, err.response);
			}

			throw Ajax.createError(window.DEFAULT_ERROR_MESSAGE, err.response);
		})
		.then(({ data, response }) => {
			if (data.success === false) {
				console.error(data.error);
				throw Ajax.createError(data.errorMessage, response);
			}

			window.setMessage(data.successMessage, 'success');
			dispatch(DISABLE());
			location.reload();
		})
		.catch((err) => {
			window.setMessage(err.message, 'error');
			setProcessing(false);
		});
}
