import { Component } from 'react';
import { AdvancedApprovalsModal } from '../advanced-approvals-modal';
import { DISABLE_APPROVAL } from '../../utils';
import { Dropdown } from '@fabric/dropdown';

export class HeaderActions extends Component {

	_handleAdvancedApprovalsModalClose = (saved) => {
		const {
			getWorkflowData,
			id
		} = this.props;

		this.setState(() => {
			return {
				advancedApprovalsModalVisible: false
			};
		}, () => {
			if (saved) {
				getWorkflowData(id);
			}
		});
	};

	_handleAdvancedApprovalsModalOpen = () => {
		this.setState(() => {
			return {
				advancedApprovalsModalVisible: true
			};
		});
	};

	_handleDeleteCustomApproval =() => {
		const {
			name,
			handleDeleteApproval,
			id,
		} = this.props;

		handleDeleteApproval(id, name);
	};

	_handleUpdateApprovalStatus = () => {
		const {
			curAppStatus,
			handleDisableApproval,
			handleEnableApproval,
			id,
		} = this.props;

		const {
			status
		} = curAppStatus;

		if (status === DISABLE_APPROVAL) { // If disabling
			handleDisableApproval(id);
		} else {
			handleEnableApproval(id);
		}
	};

	dropdownOptionActions = {
		AAP_MODAL: 1,
		HANDLE_STATUS: 2,
		DELETE_PATH: 3
	};

	state = {
		advancedApprovalsModalVisible: false
	};

	render() {
		const {
			active,
			advancedApprovalToolEnabled,
			curAppStatus,
			id,
			name,
			pathType,
			template,
			type
		} = this.props;

		const {
			advancedApprovalsModalVisible
		} = this.state;

		if (!advancedApprovalToolEnabled) {
			if (type !== 'custom') {
				return null;
			}
		}

		let dropdownOptions = [];

		if (advancedApprovalToolEnabled && active) {
			dropdownOptions.push({
				text: $.__('Advanced Approvals'),
				value: this.dropdownOptionActions.AAP_MODAL
			});
		}

		if (type === 'custom') {
			dropdownOptions.push({
				text: curAppStatus.label,
				value: this.dropdownOptionActions.HANDLE_STATUS
			});
		}

		if (!template && type === 'custom') {
			dropdownOptions.push({
				text: $.__('Delete Approval'),
				value: this.dropdownOptionActions.DELETE_PATH
			});
		}

		return (
			<div className="Approvals__headActions">
				<Dropdown
					ButtonProps={{
						color: 'secondary',
						icon: 'gear-solid',
						size: 'medium',
						variant: 'outlined',
					}}
					items={ dropdownOptions }
					onSelect={ (option) => {
						switch (option) {
							case this.dropdownOptionActions.AAP_MODAL:
								this._handleAdvancedApprovalsModalOpen();
								break;
							case this.dropdownOptionActions.DELETE_PATH:
								this._handleDeleteCustomApproval();
								break;
							case this.dropdownOptionActions.HANDLE_STATUS:
								this._handleUpdateApprovalStatus();
								break;
						}
					} }
				/>
				<AdvancedApprovalsModal
					id={ id }
					modalOpen={ advancedApprovalsModalVisible }
					onClose={ (saved, selectedPath) => { this._handleAdvancedApprovalsModalClose(saved, selectedPath); } }
					selectedPath={ pathType }
					workflowName={ name }
				/>
			</div>
		);
	}
}
