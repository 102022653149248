import {
	coverageOptionsPlanTypes,
	CoverageTypes,
	PlanTypeEnumContract,
} from '../../../../types';
import { getIsCriticalIllnessPlanThatSupportsCoverageAmounts } from '../../../../utils/get-is-critical-illness-plan-that-supports-coverage-amounts';
import { PlanCategoryDataOptionsContract } from '../../../types';
import { getPlanIsMissingCoverageLevels } from './get-plan-is-missing-coverage-levels';

export const isPlanMissingCoverageLevels = (
	isBenefitsAdminEnabled: boolean,
	planType: PlanTypeEnumContract,
	planCategoryDataOptions: PlanCategoryDataOptionsContract | null,
	selectedCoverageLevelIds: string[],
	supportedCoverageLevels: CoverageTypes,
): boolean =>
	isBenefitsAdminEnabled &&
	coverageOptionsPlanTypes.includes(planType) &&
	!getIsCriticalIllnessPlanThatSupportsCoverageAmounts({
		benefitType: planType,
		planCategoryDataOptions,
	}) &&
	getPlanIsMissingCoverageLevels(
		supportedCoverageLevels,
		selectedCoverageLevelIds,
	);
