import { BodyText, makeStyles, Theme } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface LabelProps {
	questionText: string;
	lastAskedDate?: string;
	isScheduledQuestion: boolean;
	isQuestionEnabled: boolean;
}

const useStyles = makeStyles<Theme, Omit<LabelProps, 'questionText' | 'lastAskedDate'>>(({ palette, constructs, typography }) => ({
	title: ({ isScheduledQuestion, isQuestionEnabled }) => ({
		fontSize: ifFeature('encore', typography.large.fontSize, 15),
		color: ifFeature('encore', constructs.text.neutral.strong, isScheduledQuestion ? palette.primary.main : palette.gray[800]),
		textDecoration: isQuestionEnabled ? 'unset' : 'line-through',
		fontWeight: ifFeature('encore', isScheduledQuestion ? typography.fontWeightSemibold : typography.fontWeightRegular, 'bold'),
	}),
	date: {
		color: ifFeature('encore', constructs.text.neutral.weak, palette.gray[700]),
		fontSize: ifFeature('encore', typography.small.fontSize, 13),
	},
	root: {
		margin: '22px 0 18px',
	},
}));

const Label: React.FC<React.PropsWithChildren<LabelProps>> = ({ questionText, lastAskedDate, isScheduledQuestion, isQuestionEnabled }) => {
	const classes = useStyles({ isScheduledQuestion, isQuestionEnabled });
	return (
		<div className={classes.root}>
			<div className={classes.title}>{questionText}</div>
			{lastAskedDate && (
				<div className={classes.date}>{$.__('Last asked: %1', lastAskedDate, { note: 'The parameter is a date' })}</div>
			)}
		</div>
	);
};

export default Label;
