import { BodyText, Flex } from '@bamboohr/fabric';
import { FilterListData } from '../../types';
import { FilterAccordion } from './components/filter-accordion.react';
import { convertFiltersToArray } from './util';

interface Props {
	filterListData: FilterListData;
	selectedEmployeeCount: number;
}

export const EmployeeFilter = (props: Props): JSX.Element => {
	const { filterListData, selectedEmployeeCount } = props;

	const hasNoEmployeesSelected = selectedEmployeeCount === 0;

	const renderAccordions = () => {
		const employeeFilterCategories = convertFiltersToArray(filterListData);

		return employeeFilterCategories.map((category) => {
			if (category) {
				const { categoryValue, filters, icon, name } = category;

				return (
					<FilterAccordion
						categoryValue={categoryValue}
						filters={filters}
						icon={icon}
						key={categoryValue}
						title={name}
					/>
				);
			}
		});
	};

	return (
		<>
			<Flex
				alignItems="center"
				paddingTop={0.5}
				marginBottom={2}
				justifyContent="space-between"
			>
				<BodyText color="neutral-strong" size="small">
					{$.__('Filter Employees By')}
				</BodyText>

				<BodyText
					size="small"
					icon="circle-user-regular"
					color={hasNoEmployeesSelected ? 'warning' : 'neutral-strong'}
					weight={hasNoEmployeesSelected ? 'semibold' : 'regular'}
				>
					{$.__n(
						'%s Employee Included',
						'%s Employees Included',
						selectedEmployeeCount,
					)}
				</BodyText>
			</Flex>
			{renderAccordions()}
		</>
	);
};
