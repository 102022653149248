import React, { forwardRef, ReactElement, useState } from 'react';
import { LinkProps, Link as ReactRouterLink, Route, Switch, useLocation } from 'react-router-dom';

import { Box, IconV2Name, makeStyles, Tab, Tabs } from '@bamboohr/fabric';
import { isEnabled } from '@bamboohr/utils/lib/feature';

import { BenefitsAdminEmployees } from '../benefits-admin-employees';
import { DemoRequest } from '../benefits-admin-upsell';
import { BenefitsCarriers } from '../benefits-carriers';
import { BenefitsFiles } from '../benefits-files';
import { EnrollmentWindows } from '../enrollment-windows';
import { BenefitPlanYears } from '../plan-years/plan-years';
import { BenefitPlans } from '../plans.react/benefit-plans';
import { SelfEnrollments } from '../self-enrollments';
import { BenefitsAdminWrapper } from './benefits-admin-wrapper';

const useStyles = makeStyles(({ palette }) => ({
	content: {
		marginTop: '34px',
	},
	tabsContainer: {
		borderColor: palette.gray[300],
		borderStyle: 'solid',
		borderWidth: '0 0 1px 0',
	},
	tabsRoot: {
		marginLeft: '-8px',
		paddingLeft: 0,
	},
}));

interface BenefitsTabsProps {
	benAdminEmployeeCount: number;
	demoRequest: DemoRequest;
	isCompanyInTrial: boolean;
	showBenefitsAdminDemoRequestContent: boolean;
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
	const {id, role, to} = props;

return <ReactRouterLink
	{...props}
	className={`${props?.className || ''} fab-LinkUnstyled fab2-LinkUnstyled`}
	ref={ref}
	to={ role === 'tab' ? String(to) : String(id)}
/>
});

export const BenefitsTabs = (props: BenefitsTabsProps): ReactElement => {
	const { benAdminEmployeeCount, demoRequest, isCompanyInTrial, showBenefitsAdminDemoRequestContent } = props;
	const location = useLocation();
	const styles = useStyles();
	const [demoRequested, setDemoRequested] = useState(demoRequest);
	const countLabel = typeof benAdminEmployeeCount === 'number' ? ` (${benAdminEmployeeCount})`: '';

	const planYearsRoute = '/plan-years';
	const hideEmployeesTab = isEnabled('BENEFITS_PEOPLE_TAB_REMOVAL');
	interface BenefitTabProps {
		activeIcon: IconV2Name;
		biId: string;
		icon: IconV2Name | ReactElement;
		label: string;
		url: string;
	}

	const tabs: Record<string, BenefitTabProps> = {
		planYears: {
			activeIcon: 'calendar-clock-solid',
			biId: 'plan-years',
			icon: 'calendar-clock-regular',
			label: $.__('Plan Years'),
			url: planYearsRoute,
		},
		plans: {
			activeIcon: 'clipboard-list-solid',
			biId: 'plans',
			icon: 'clipboard-list-regular',
			label: $.__('Plans'),
			url: '/',
		},
		enrollment: {
			activeIcon: 'calendar-days-solid',
			biId: 'enrollment',
			icon: 'calendar-days-regular',
			label: $.__('Windows'),
			url: '/enrollment',
		},
		selfEnrollments: {
			activeIcon: 'calendar-check-solid',
			biId: 'self-enrollments',
			icon: 'calendar-check-regular',
			label: $.__('Elections'),
			url: '/self-enrollments',
		},
		employees: {
			activeIcon: 'users-solid',
			biId: 'employees',
			icon: 'users-regular',
			label: $.__(`Employees${countLabel}`),
			url: '/employees',
		},
		carriers: {
			activeIcon: 'briefcase-solid',
			biId: 'carriers',
			icon: 'briefcase-regular',
			label: $.__('Carriers'),
			url: '/carriers'
		},
		files: {
			activeIcon: 'file-solid',
			biId: 'files',
			icon: 'file-regular',
			label: $.__('Carrier PDFs'),
			url: '/files',
		},
	};

	return (
		<>
			<Box>
				<Tabs
					onChange={() => {
						/**/
					}}
					size='small'
					value={location?.pathname}
					variant={hideEmployeesTab ? 'fullWidth' : 'standard'}
				>
					{Object.values(tabs).map(({ activeIcon, biId, icon, label, url }) => {
						const tabIcon = location?.pathname === url ? activeIcon : icon;
						if (hideEmployeesTab && biId === 'employees') {
							return undefined;
						}
						return <Tab component={Link} data-bi-id={biId} icon={tabIcon} key={url} label={label} overflowProps={{ component: Link, to: url }} to={url} value={url} />;
					})}
				</Tabs>
			</Box>

			<Box className={styles.content}>
				<Switch>
					<Route exact={true} path={planYearsRoute}>
						<BenefitsAdminWrapper
							demoRequest={demoRequested}
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={setDemoRequested}
							tab={<BenefitPlanYears />}
						/>
					</Route>
					<Route exact={true} path='/'>
						<BenefitPlans
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={setDemoRequested}
							showBenefitsAdminDemoRequestContent={showBenefitsAdminDemoRequestContent}
						/>
					</Route>
					<Route exact={true} path='/enrollment'>
						<BenefitsAdminWrapper
							demoRequest={demoRequested}
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={setDemoRequested}
							tab={<EnrollmentWindows />}
						/>
					</Route>
					<Route exact={true} path='/self-enrollments'>
						<BenefitsAdminWrapper
							demoRequest={demoRequested}
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={setDemoRequested}
							tab={<SelfEnrollments />}
						/>
					</Route>
					{!hideEmployeesTab &&
						<Route exact={true} path='/employees'>
							<BenefitsAdminWrapper
								demoRequest={demoRequested}
								isCompanyInTrial={isCompanyInTrial}
								onDemoRequest={setDemoRequested}
								tab={<BenefitsAdminEmployees />}
							/>
						</Route>
					}
					<Route exact={true} path='/carriers'>
						<BenefitsAdminWrapper
							demoRequest={demoRequested}
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={setDemoRequested}
							tab={<BenefitsCarriers />}
						/>
					</Route>
					<Route exact={true} path='/files'>
						<BenefitsAdminWrapper
							demoRequest={demoRequested}
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={setDemoRequested}
							tab={<BenefitsFiles />}
						/>
					</Route>
				</Switch>
			</Box>
		</>
	);
};
