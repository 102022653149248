import { Component } from 'react';
import { remove } from 'lodash';
import { PayeeRow } from './payee-row';
import './payee-settings.styl';
import { getAuthToken, getGlobalPayees } from './apis';
import { BlankState, LayoutBox } from "@bamboohr/fabric";

export class PayeeSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			authToken: null,
			garnishmentData: props.byType,
			globalPayeeList: null,
			payees: props.payeeData,
		};
	}

	_getAuthToken = () => {
		getAuthToken().then(response => {
			if(response?.data?.token){
				this.setState({ authToken: response.data.token });
			}
		})
	}

	_getGlobalPayeeList = () => {
		if(this.state.authToken) {
			const globalPayeeId = this.props.byType.globalPayeeEmployees.allIds;
			getGlobalPayees(this.state.authToken, globalPayeeId).then(response => {
				this.setState({ globalPayeeList: response.data });
				const payeeEmployeesList = this.state.payees.concat(response.data);
				const sortedPayeeEmployeesList = payeeEmployeesList.sort((a, b) => a.payeeName.localeCompare(b.payeeName));
				this.setState({ payees: sortedPayeeEmployeesList })
			});
		}
	}

	_handleDeleteSuccess = (id) => {
		const { payees } = this.state;
		const payeesCopy = payees.slice();
		const newPayees = remove(payeesCopy, payee => payee.id !== id);
		this.setState({ payees: newPayees });
	};

	_handleSaveSuccess = (data) => {
		const { payees } = this.state;
		const newPayees = payees.slice();
		const payeeIndex = newPayees.findIndex(payee => payee.id === data.id);

		newPayees[payeeIndex] = data;

		this.setState({ payees: newPayees });
	};

	componentDidMount() {
		this._getAuthToken();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevState.authToken !== this.state.authToken) {
			this._getGlobalPayeeList();
		}
	}

	render() {
		const {
			payees,
		} = this.state;
		const {
			allowEditPayee,
			byType,
			states,
		} = this.props;

		if (payees.length == 0) {
			return (
                <LayoutBox paddingX={10} paddingY={12.5}>
                    <BlankState
                        icon="bs-tumbleweed"
                        subtitle={ $.__('You can add deductions or garnishments from an employee’s pay info tab.') }
                        title={ $.__('There are no active garnishments payees.') }
                    />
                </LayoutBox>
            );
		}

		return (
            <table className="BhrTable Payees__table fab-Table fab-Table--hoverable">
                <thead className="BhrTable__head">
                <tr className="BhrTable__row">
                    <th className="BhrTable__head fab-Table__header">{$.__('Payee Name')}</th>
                    <th className="BhrTable__head fab-Table__header">{$.__('Address')}</th>
                    <th className="BhrTable__head fab-Table__header">{$.__('Garnishment Type')}</th>
                    <th className="BhrTable__head fab-Table__header">{$.__('Employees')}</th>
                    <th className="BhrTable__head fab-Table__header"></th>
                </tr>
                </thead>
                <tbody className="BhrTable__body">
                {this.state.payees && this.state.payees.map(payee => (
                    <PayeeRow
                        allowEditPayee={allowEditPayee}
                        byType={byType}
                        key={payee.id}
                        onDeleteSuccess={this._handleDeleteSuccess}
                        onSaveSuccess={data => this._handleSaveSuccess(data)}
                        payee={payee}
                        payees={payees}
                        states={states}
                        {...payee}
                    />
                ))}
                </tbody>
            </table>
		);
	}
}
