import { isPast, isToday, parseISO } from '@bamboohr/utils/lib/datetime'

import { BenefitPlanListContract } from '../types/contracts';

export function isPlanExpired(plan: BenefitPlanListContract): boolean {
	if (plan && plan.endDate) {
		const parsedDate = parseISO(plan.endDate) as Date;
		return isPast(parsedDate) && !isToday(parsedDate);
	}
	return false;
}
