import { ifFeature } from '@bamboohr/utils/lib/feature';
import AppModal from './modal';

export default function UninstallModal(props) {
	const {
		app = {},
		actions: {
			uninstall,
		},
		alternativeActionText: alternativeActionTextProp,
	} = props;

	/**
	 * Add callback to show "Change Password" option from Site Nav dropdown
	 * if uninstalling Google SSO.
	 */
	const callback = app.name === 'googleSso'
		? () => {
			const siteNavItem = document.querySelector('.js-siteManageNavPasswordItem');

			if (siteNavItem) {
				window.SESSION_COMPANY.allowPasswordLogin = true;
				siteNavItem.classList.remove('hidden');
			}
		} : null;

		const headline = app.uninstallHeadline
			? app.uninstallHeadline
			: $.__(`Are you sure you want to uninstall %1$s?`, app.title);

		const canCompleteUninstall =
			app.canCompleteUninstall === undefined || !!app.canCompleteUninstall;

		const alternativeActionText = canCompleteUninstall
			? alternativeActionTextProp
			: alternativeActionTextProp || '';

		const primaryAction = canCompleteUninstall
			? () => uninstall(app, callback)
			: undefined;

		const primaryActionText = canCompleteUninstall
			? $.__('Yes, Uninstall This')
			: $.__('Close');

	return (
		<AppModal
			{ ...props }
			alternativeActionText={alternativeActionText}
			dangerousContent={ app.uninstallExtraContent }
			headline={headline}
			icon={ifFeature('encore', 'trash-can-regular', "fab-trash-can-43x48")}
			iconColor="danger"
			iconV2Color='primary-strong'
			primaryAction={primaryAction}
			primaryActionText={primaryActionText}
			title={ $.__('Uninstall') }
			type="uninstall"
		/>
	);
}
