import { IconButton } from '@fabric/button';
import { useReducerContext } from '../state';


/**
 * @param {object} props
 * @param {import('../state').Field['id']} props.id
 */
export default function RowActions({
	id,
	question,
}) {
	const {
		bem,
		listsFields,
		listValueCounts,
		fields,
		dispatch,
		OPEN_MODAL,
		fab,
	} = useReducerContext();

	const listsField = listsFields[id];
	const counts = Number(listValueCounts[id]);
	const {
		description = null,
	} = fields[id] || {};

	return (
        <div className={ bem('cellActions') }>
            { (listsField && counts > 0) && (
				<RowAction
					clickAction={ () => dispatch(OPEN_MODAL({ type: 'EDIT_LIST', id })) }
					icon={"bars-regular"}
					text={ $.__('Edit List Values') }
				/>
			) }
            { (description !== null) && (
				<RowAction
					clickAction={ () => dispatch(OPEN_MODAL({ type: 'EDIT_VALUE', id })) }
					icon={"pen-regular"}
					text={ $.__('Edit Value') }
				/>
			) }
            { question && question.ratings && (
				<RowAction
					clickAction={ () => dispatch(OPEN_MODAL({ type: 'EDIT_PERFORMANCE_LIST', id, question })) }
					icon={"bars-regular"}
					text={ $.__('Edit List Values') }
				/>
			) }
        </div>
    );
}

/**
 * @param {object} props
*/
export function RowAction(props) {
	const {
		fab,
	} = useReducerContext();

	return (
        <div className={ fab.Table('cellAction') }>
            <IconButton
                size="extra-small"
                type="button"
                variant="outlined"
                { ...props }
            />
        </div>
    );
}
