import React from 'react';
import { BlankState, Button, LayoutBox } from '@bamboohr/fabric';

export const BlankStateError = () => {
	return (
		<LayoutBox marginY={9}>
			<BlankState
				icon='bs-bigfoot'
				subtitle={$.__("It looks like the information on this page didn't load.")}
				title={$.__('Nothing to see here...')}
			>
				<Button onClick={() => window.location.reload()} type='button' variant='outlined'>
					{$.__('Refresh Page')}
				</Button>
			</BlankState>
		</LayoutBox>
	);
};
