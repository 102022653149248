import classNames from 'classnames';
import { Component } from 'react';

import { DisabledOverlay } from '../disabled-overlay';
import { PathHeader } from '../path-header.react';
import { AdvancedApprovalsConfirmationModalContainer } from '../../containers/advanced-approval-confirmation-modal';
import { HeaderContainer } from '../../containers/header';
import { NewPathContainer } from '../../containers/new-path';
import { PathContainer } from '../../containers/path';

export class AdvancedWorkflow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this._combinePropData(props),
		};
	}

	_combinePropData = (props) => {
		const {
			active,
			approverOptions,
			availableUsers,
			curAppStatus,
			customFieldCount,
			description,
			id,
			lastModified,
			name,
			pathType,
			pathTypeChildren,
			requestOptions,
			roleSectionNote,
			roleSectionText,
			roleSectionTitle,
			template,
			templateName,
			type,
			userGroups,
			workflowApprovals,
			workflowInitiators
		} = props;

		return {
			active,
			approverOptions,
			availableUsers,
			curAppStatus,
			customFieldCount,
			description,
			id,
			lastModified,
			name,
			pathType,
			pathTypeChildren,
			requestOptions,
			roleSectionNote,
			roleSectionText,
			roleSectionTitle,
			template,
			templateName,
			type,
			userGroups,
			workflowApprovals,
			workflowInitiators
		};
	}

	_handleCreateNew = () => {
		const { handleCreateNew } = this.props;
		handleCreateNew();
	}

	_handleEdit = (id) => {
		const { handleEdit } = this.props;
		handleEdit(id);
	}

	_handleEnableApproval = () => {
		const { handleEnableApproval } = this.props;
		const { id } = this.state;
		handleEnableApproval(id);
	}

	//Handles the creation of children containers for rendering
	_renderChildrenPathContainers = () => {
		const {
			pathTypeChildren
		} = this.props;

		return pathTypeChildren.map((child) => {
			let {
				id: childId,
				workflowPaths
			} = child;

			childId = Number(childId);

			return (
				<PathContainer
					id={ childId }
					key={ childId }
					onEdit={ () => { this._handleEdit(childId) } }
					selectedPaths={ workflowPaths }
				/>
			);
		});
	}

	render() {
		const {
			active,
			availablePathOptions,
			editState,
			newPathOpen,
			newPathSelectedOptions
		} = this.props;

		const {
			id,
			key,
			pathType,
			pathTypeChildren
		} = this.state;

		const showNewPath = (newPathOpen && active) || (pathTypeChildren.length === 0 && active && editState === false);

		const approvalsClassName = classNames('Approvals', 'Approvals--advanced', {
			'AdvancedApprovals--edit': editState
		});

		const pathsContainerClassName = classNames('PathsContainer', {
			'PathsContainer--edit': editState
		});

		return (
			<div className="SettingsApprovalsContainer" key={key}>
				<div className={approvalsClassName}>
					<HeaderContainer/>
					{
						!active && (
							<DisabledOverlay
								onEnableApproval={this._handleEnableApproval}
							/>
						)
					}
					<PathHeader
						buttonEnabled={(!editState && Object.keys(availablePathOptions).length > 0)}
						onClick={this._handleCreateNew}
						pathTypeName={pathType}
						showButton={pathTypeChildren.length > 0 && active}
					/>
					{
						showNewPath && (
							<NewPathContainer/>
						)
					}
						<div className={pathsContainerClassName}>
							{
								newPathSelectedOptions.length > 0 && (
									<PathContainer
										canSaveInitial={true}
										id={0}
										parentId={id}
										selectedPaths={newPathSelectedOptions}
									/>
								)
							}
							{this._renderChildrenPathContainers()}
							<PathContainer
								catchAll={true}
								id={id}
								onEdit={(id) => {
									this._handleEdit(id)
								}}
							/>
						</div>
					<AdvancedApprovalsConfirmationModalContainer
						parentId={id}
					/>
				</div>
			</div>
		);
	}
}
