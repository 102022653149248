import { CardSize, Interstitial } from '@bamboohr/fabric';
import { useEffect, useState } from 'react';

import { removeStoredRememberToken, setStoredRememberToken } from 'multi-factor-authentication.mod';

import { CODE_LENGTH_BACKUP, CODE_LENGTH_LOGIN } from '../../domain';
import { InterstitialAuth } from '../';
import {
	getUserMfaSettings,
	postMultiFactorAuthenticationBackupCode,
	postMultiFactorAuthenticationLoginCode,
	postResendSmsCode,
} from '../../service';
import {
	AuthType,
	GetMfaUserSettingsResponse,
	MfaChannelOptions,
	MfaUserSettings,
	SerializedPostMultiFactorAuthenticationBackupCodeResponse,
	SerializedPostMultiFactorAuthenticationLoginCodeResponse,
} from '../../types';
import { isSerializedPostMultiFactorAuthenticationLoginCodeResponse, redirectToLogin } from '../../utils';
import { AuthOptions } from '../../constants';


type MultiFactorAuthLoginProps = {
	onAuthenticated(): void;
	userId: number;
};

export function MultiFactorAuthLogin(props: MultiFactorAuthLoginProps): JSX.Element {
	const { onAuthenticated, userId } = props;

	const [isInvalidOneTimeCode, setIsInvalidOneTimeCode] = useState<boolean>(false);
	const [userMfaSettings, setUserMfaSettings] = useState<MfaUserSettings>();
	const [hitLimit, setHitLimit] = useState(false);

	const doGetSettings = async (userId: number): Promise<MfaUserSettings> => {
		const userMfaSettings: GetMfaUserSettingsResponse | MfaUserSettings = await getUserMfaSettings(userId);
		return userMfaSettings as MfaUserSettings;
	};

	const doResendSms = async () => {
		return await postResendSmsCode(userId).then((response) => {
			if (!response.success) {
				setHitLimit(true);
				return window.setMessage($.__(`You've hit your attempts limit. Please come back later and try again.`), 'error');
			}
			return setHitLimit(false);
		});
	};

	useEffect(() => {
		doGetSettings(userId).then((userSettings) => {
			if (userSettings.preferredChannel === AuthOptions.SMS) {
				doResendSms();
			}
			setUserMfaSettings(userSettings);
		});
	}, []);

	const doAuthentication = async (code: string, rememberMe: boolean, mfaChannel: AuthType) => {
		let response: SerializedPostMultiFactorAuthenticationBackupCodeResponse | SerializedPostMultiFactorAuthenticationLoginCodeResponse;

		if (!rememberMe) {
			removeStoredRememberToken();
		}

		const authOptionToMfaChannelMap = {
			[AuthOptions.SMS]: MfaChannelOptions.SMS,
			[AuthOptions.APP]: MfaChannelOptions.AUTHENTICATOR,
			[AuthOptions.BACKUP]: MfaChannelOptions.BACKUP_CODES,
		};

		if (code.length === CODE_LENGTH_LOGIN) {
			response = await postMultiFactorAuthenticationLoginCode(userId, code, rememberMe, authOptionToMfaChannelMap[mfaChannel]);
		} else if (code.length === CODE_LENGTH_BACKUP) {
			response = await postMultiFactorAuthenticationBackupCode(userId, code);
		}
		if (response) {
			const { status, statusText } = response;
			setIsInvalidOneTimeCode(false);
			if (status === 200) {
				if (isSerializedPostMultiFactorAuthenticationLoginCodeResponse(response) && response.rememberToken) {
					setStoredRememberToken(response.rememberToken);
				}
				onAuthenticated();
				return 'success';
			}
			setIsInvalidOneTimeCode(true);
			if (statusText?.includes('failed attempts')) {
				redirectToLogin();
				return statusText;
			}
			return statusText || 'unknown error';
		}
		setIsInvalidOneTimeCode(true);
		return 'invalid code';
	};

	const lastFourPhoneNumber = userMfaSettings?.mfaChannelInfo?.sms.phone ? userMfaSettings.mfaChannelInfo.sms.phone : '';

	return (
		<Interstitial cardSize={CardSize.SMALL}>
			<InterstitialAuth
				enabledMfaChannels={userMfaSettings?.enabledChannels}
				hitLimit={hitLimit}
				isInvalid={isInvalidOneTimeCode}
				lastFour={lastFourPhoneNumber}
				onChange={() => {
					setIsInvalidOneTimeCode(false);
				}}
				onNext={doAuthentication}
				onResendSms={doResendSms}
				preferredChannel={userMfaSettings?.preferredChannel}
			/>
		</Interstitial>
	);
}
