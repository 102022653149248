import React, { useState } from 'react';

import { TileV2, Button, LayoutBox, Truncate } from '@bamboohr/fabric';

export function FileTile(props) {
	const {
		fileName,
		fileType,
		clientId,
		onFileDownload,
		horizontal = false,
	} = props;
	const [isDownloaded, setIsDownloaded] = useState(false);
	const buttonText = isDownloaded ? 'Download Again' : 'Download';

	const initFileDownload = () => {
		setIsDownloaded(true);
		onFileDownload(fileType);
	};

	return (
		<TileV2
			actions={[
				<Button
					color="secondary"
					download={true}
					href={`/payroll/documents/prefilled_client_doc/${fileType}/${clientId}`}
					key="download_file"
					onClick={initFileDownload}
					size="small"
					variant={isDownloaded ? 'outlined' : undefined}
				>
					{buttonText}
				</Button>,
			]}
			actionPlacement={horizontal ? 'right' : 'bottom'}
			orientation={horizontal ? 'horizontal' : 'vertical'}
			icon="file-pdf-regular"
			title={
				<LayoutBox title={fileName} width={horizontal ? undefined : 150}>
					<Truncate>{fileName}</Truncate>
				</LayoutBox>
			}
			titleSize="medium"
		/>
	);
}
