import { ReactElement } from 'react';
import { SelectField, TextField, IconButton, Tooltip, Label, IconV2 } from '@bamboohr/fabric';
import { SmsMfaSetupProps } from './types';
import { getSmsCountries } from './get-sms-countries/get-sms-countries';

export function SmsInputContainer(props: SmsMfaSetupProps): ReactElement {
	const { phoneNumber, updatePhoneNumber, countryCode, updateCountryCode, countries } = props;

	return (
		<>
			<SelectField
				id='smsMfaSelect'
				isClearable={false}
				items={getSmsCountries(countries)}
				label={
					<>
						<Label required={true}>{$.__('Country Code')}</Label>
						<Tooltip content={$.__('SMS verification not available in all countries')}>
							<IconButton
								ariaLabel='sms-country-code'
								color='secondary'
								data-bi-id='sms-country-code'
								floatingIcon={true}
								icon={<IconV2 name="circle-question-regular" size={12} />}
								noBoundingBox={true}
								type='button'
							/>
						</Tooltip>
					</>
				}
				name='smsMfaSelect'
				onSelect={(value: string): void => {
					updateCountryCode(value);
				}}
				required={true}
				showSearch='always'
				value={countryCode}
				width={7}
			/>
			<TextField
				id='smsNumberField'
				label={$.__('Phone Number')}
				onChange={(e): void => {
					updatePhoneNumber(e.target.value);
				}}
				required={true}
				value={phoneNumber}
				width={7}
			/>
		</>
	);
}
