import { Button, LayoutBox, makeStyles, TextButton } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const useStyles = makeStyles(({ palette, constructs }) => ({
	root: {
		padding: '24px 0',
		borderTop: ifFeature('encore', `1px solid ${constructs.border.neutral.xWeak}`, `1px solid ${palette.gray[300]}`),
		marginTop: 32,
	},
}));

type FooterProps = {
	onCancelClick: () => void;
	isSaveButtonDisabled: boolean;
	hideCancelButton: boolean;
};

export const Footer: React.FC<React.PropsWithChildren<FooterProps>> = ({ onCancelClick, isSaveButtonDisabled, hideCancelButton }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<LayoutBox marginRight={ifFeature('encore', 2, '4px')} display='inline-block'>
				<Button data-bi-id='employee-wellbeing-setup-save-button' disabled={isSaveButtonDisabled} type='submit'>
					{$.__('Save')}
				</Button>
			</LayoutBox>
			{!hideCancelButton &&
				ifFeature(
					'encore',
					<Button variant='outlined' onClick={onCancelClick} type='button'>
						{$.__('Cancel')}
					</Button>,
					<TextButton onClick={onCancelClick} type='button'>
						{$.__('Cancel')}
					</TextButton>
				)}
		</div>
	);
};
