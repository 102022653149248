import { Button, Checkbox, Divider, StandardModal, StyledBox, TextButton, makeStyles } from '@bamboohr/fabric';
import { useState } from 'react';
import { setTaxFrequencyLock } from './LockAction';

const useStyles = makeStyles(({ palette, spacing }) => ({
	verificationBanner: {
		backgroundColor: palette.grey[100],
		marginBlock: spacing(2, 1),
		padding: spacing(2, 3),
	},
	labelContent: {
		color: palette.grey[800],
	},
	warningIcon: {
		fill: palette.warning.main,
		marginTop: spacing(2.5),
	},
}));

export const UnlockTaxFrequencyModal = ({ isOpen, handleCloseModal, clientId, reloadTable }) => {
	const [confirmed, setConfirmed] = useState(false);
	const [unlockButtonProcessing, setUnlockButtonProcessing] = useState(false);
	const classes = useStyles();

	const handleUnlockTaxFrequency = () => {
		setUnlockButtonProcessing(true);

		//Always set locked == false because this modal is only open when setting unlocked
		setTaxFrequencyLock(false, clientId, () =>
			// Closes the modal
			reloadTable().then(() => {
				// Since the modal isn't unmounted, reset confirmed before it gets opened again
				setConfirmed(false);
				setUnlockButtonProcessing(false);
			})
		);
	};

	const header = <StandardModal.Header title={$.__('Just Checking...')} />;
	const footer = (
		<StandardModal.Footer
			actions={[
				<TextButton key='cancel' onClick={handleCloseModal} type='button'>
					{$.__('Cancel')}
				</TextButton>,
				<Button
					disabled={!confirmed}
					key='unlock'
					onClick={handleUnlockTaxFrequency}
					processing={unlockButtonProcessing}
					type='button'
				>
					{$.__('Unlock Federal Taxes')}
				</Button>,
			]}
		/>
	);

	return (
		<StandardModal isOpen={isOpen} onRequestClose={handleCloseModal}>
			<StandardModal.Body renderFooter={footer} renderHeader={header}>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline
						icon="triangle-exclamation-regular"
						iconColor="warning-strong"
						subtext={$.__(
							'This will allow the customer to make a one time edit. You must verify that the customer has provided legal documentation for this change.'
						)}
						text={$.__('Are you sure you want to unlock federal taxes?')}
					>
						<Divider color="neutral-weak" marginTop={4} />
						<StyledBox backgroundColor="neutral-medium" borderColor="neutral-weak" borderRadius="small" borderStyle="solid" marginBottom={1} marginTop={3} padding={2}>
							<Checkbox
								checked={confirmed}
								classes={{ labelContent: classes.labelContent }}
								label={$.__('I have verified that the customer has legal documentation.')}
								onChange={(e) => setConfirmed(e.checked)}
							/>
						</StyledBox>
					</StandardModal.HeroHeadline>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
};
