import { Button, Headline, Icon, List } from '@bamboohr/fabric';
import { useContext } from 'react';
import { Data, SurveyStatus } from '../utils/';
import { InPageMessaging } from '@fabric/in-page-messaging';
import Upsell from 'Upsell';
import { EmbedVideo } from '../shared/embed-video';
import './marketing-promo.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Message } from '@bamboohr/utils/lib/message';

export const MarketingPromo: React.FC<React.PropsWithChildren<unknown>> = () => {
	const {
		state: { reportPreviewUrl, videoUrl, showPreviewLinks },
	} = useContext(Data);

	const { showPreview, displayPromoPage, demoPreviouslyRequested, onRequestDemo } = useContext(SurveyStatus);

	if (!displayPromoPage) {
		return null;
	}

	return ifFeature(
		'encore',
		<Upsell>
			<Upsell.Title>{$.__('Gain Valuable Insights on Employee Engagement with Employee Wellbeing')}</Upsell.Title>
			<Upsell.Video title={$.__('Employee Wellbeing Video')} videoId='1tgt6nbvb9' />
			<Upsell.Content
				actions={[
					<Button
						data-bi-id='employee-wellbeing-settings-request-demo-button'
						disabled={demoPreviouslyRequested}
						onClick={onRequestDemo}
						size='large'
						type='button'
						width={100}
					>
						{$.__('Schedule a Personalized Demo')}
					</Button>,
				]}
				request={{
					demoRequestedBy: {
						firstName: '',
						lastName: '',
					},
					demoRequestedDate: '',
					isDemoRequested: demoPreviouslyRequested,
				}}
			>
				<Headline size='small'>{$.__('With Employee Wellbeing, you can:')}</Headline>
				<List>
					<List.Item>
						<Message
							text={$.__(
								"**Give your people a voice** by allowing them to share how they're feeling -- about your organization, their work, and how they're being supported."
							)}
						/>
					</List.Item>
					<List.Item>
						<Message
							text={$.__(
								'**Improve culture, increase retention, and prevent premature turnover** by knowing exactly what your employees need and want.'
							)}
						/>
					</List.Item>
					<List.Item>
						<Message
							text={$.__(
								"**Develop effective strategies for change** when you drill deep into the issues and relationships that affect employees' everyday experience."
							)}
						/>
					</List.Item>
				</List>
			</Upsell.Content>
		</Upsell>,
		<div className='EmployeeWellbeingMarketingPromo'>
			<div className='EmployeeWellbeingMarketingPromo__title'>
				{$.__(`It looks like Employee Wellbeing isn't part of your current package.`)}
			</div>

			<div className='EmployeeWellbeingMarketingPromo__container'>
				<div className='EmployeeWellbeingMarketingPromo__video'>
					<EmbedVideo videoSrc={videoUrl} videoTitle={$.__('Promo Video')} />
				</div>

				<div className='EmployeeWellbeingMarketingPromo__summary'>
					{$.__(
						'Employee Wellbeing allows your people to share how they’re feeling—about your organization, their work, and how they’re being supported. Knowing what they need and want means you can make the right changes to improve culture, increase retention, and prevent burnout and premature turnover.'
					)}
				</div>

				<div className='EmployeeWellbeingMarketingPromo__previewButtons'>
					{showPreviewLinks && (
						<>
							<div>
								<a onClick={() => showPreview()} rel='noopener noreferrer' target='_blank'>
									<Icon name='fab-eye-16x12' />
									<span>{$.__('Preview Sample Survey')}</span>
								</a>
							</div>
							<div>
								<a href={reportPreviewUrl} rel='noopener noreferrer' target='_blank'>
									<Icon name='fab-bar-chart-arrow-16x16' />
									<span>{$.__('Preview Report (Demo Data)')}</span>
								</a>
							</div>
						</>
					)}
				</div>

				{demoPreviouslyRequested ? (
					<div className='EmployeeWellbeingMarketingPromo__marketingPromoContactMessage'>
						<InPageMessaging
							body={$.__('We will be contacting you shortly to schedule a personal demo.')}
							header={$.__(`Thanks for your interest in BambooHR's employee wellness survey`)}
							icon='fab-check-circle-36x36'
							success={true}
						/>
					</div>
				) : (
					<div className='EmployeeWellbeingMarketingPromo__marketingPromoContactMessage'>
						<Button onClick={onRequestDemo} size='biggie' type='button'>
							{$.__('Contact Us To Learn More')}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};
