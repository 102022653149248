import { Component, Fragment } from 'react';
import { Message } from '@bamboohr/utils/lib/message';
import { previewApprovalModal, showPreviewModal } from '../../utils';
import { Button } from '@fabric/button';
import { Divider, Headline, Icon } from '@bamboohr/fabric';

export class Header extends Component {
	_handlePreviewCustomApproval(id) {
		previewApprovalModal(id)
			.then((response) => showPreviewModal(response))
			.catch(window.errorFallBack);
	}

	_handleRestoreCustomApproval() {
		const { handleRestoreApproval, id, templateName } = this.props;

		handleRestoreApproval(id, templateName);
	}

	render() {
		const {
			active,
			advancedWorkflow,
			customFieldCount,
			description,
			lastModified,
			id,
			name,
			pathType,
			roleSectionNote,
			template,
			templateName,
			type,
		} = this.props;

		const showRestoreApprovalButton = !pathType && lastModified !== '0000-00-00 00:00:00' && template;

		return (
			<div className='Approvals__head'>
				<div className='Approvals__headHeaderContainer'>
					<Headline color='primary' size='medium'>
						{name}
					</Headline>
				</div>
				{description && <p className='Approvals__description'>{description}</p>}
				{roleSectionNote && (
					<p className='Approvals__section-text Approvals__section-note'>
						<Message
							text={$._(
								`Note: By adding an employee to this workflow, you may be giving them access to sensitive information that they normally don't have permission to view.`
							)}
						/>
					</p>
				)}
				<Fragment>
					{type === 'custom' && (
						<div className='Approvals__head--action clearfix'>
							{active && (
								<Fragment>
									<a
										className={`fab-Button fab-Button--small 'fab-Button--secondary' fl-left`}
										href={`/settings/workflows/custom/edit_custom_approval?workflowId=${id}`}
									>
										{$.__('Edit Approval Form')}
									</a>
									<span className='Approvals__head-preview'>
										{$.__n('%1$s field', '%1$s fields', customFieldCount)} |{' '}
										<a
											onClick={() => {
												this._handlePreviewCustomApproval(id);
											}}
										>
											{$.__('Preview Form')}
										</a>
									</span>
								</Fragment>
							)}
							<div className='fl-right'>
								{showRestoreApprovalButton && (
									<Button
										clickAction={() => {
											this._handleRestoreCustomApproval();
										}}
										data-template-name={templateName}
										size='small'
										text={$.__('Restore Default Approval')}
										type='button'
									/>
								)}
							</div>
						</div>
					)}
					{advancedWorkflow && active && (
						<Fragment>
							<p className='Approvals__head-sub-text'>
								{$.__('Emails will be sent to notify the appropriate people when an item is waiting for their review.')}
							</p>
						</Fragment>
					)}
					<Divider color='neutral-extra-weak' marginTop={'12px'}/>
				</Fragment>
			</div>
		);
	}
}
