import { FullScreenModal, Button } from '@bamboohr/fabric';

import { EnpsSurvey } from '../../../../../../../survey/enps/components/survey.react';

type SurveyPreviewModalProps = {
	visible: boolean;
	onClose: () => void;
}

export const SurveyPreviewModal: React.FC<React.PropsWithChildren<SurveyPreviewModalProps>> = ({ onClose, visible }) => {
	const companyName = window.SESSION_COMPANY.name;

	return (
		<FullScreenModal
			isOpen={ visible }
			onRequestClose={ onClose }
		>
			<FullScreenModal.Body
				renderFooter={ <FullScreenModal.Footer actions={ [<Button key="button-1" onClick={ onClose }>Close</Button>] } /> }
				renderHeader={ <FullScreenModal.Header title="Survey Preview" /> }
			>
				<FullScreenModal.Constraint>
					<EnpsSurvey companyName={ companyName } isPreview={ true } onPreviewSubmit={ onClose } />
				</FullScreenModal.Constraint>
			</FullScreenModal.Body>
		</FullScreenModal>
	);
};
