import {
	Dropdown
} from '@bamboohr/fabric';


import {
	COMPANY_TAX_SETTINGS_BI_ID_DROPDOWN_OPEN,
} from './constants';
import {
	generateCompanyTaxSettingsGroupedItems,
} from './domain/generate-company-tax-settings-grouped-items';
import {
	handleCompanyTaxSettingsSelect,
} from './domain/handle-company-tax-settings-select';
import {
	L_COMPANY_TAX_SETTINGS,
} from './language';
import type {
	CompanyTaxDataCollection,
} from './types';

interface CompanyTaxSettingsDropdownProps {
	companyTaxes: CompanyTaxDataCollection;
}

export function CompanyTaxSettingsDropdown(props: CompanyTaxSettingsDropdownProps) {
    const {
        companyTaxes,
    } = props;
    return (
        <Dropdown
            ButtonProps={ {
                ariaLabel: L_COMPANY_TAX_SETTINGS,
                color: 'secondary',
                startIcon: 'gear-regular',
                variant: 'outlined',
                size: 'small',
            } }
            biId={ COMPANY_TAX_SETTINGS_BI_ID_DROPDOWN_OPEN }
            items={ generateCompanyTaxSettingsGroupedItems(companyTaxes) }
            onSelect={ handleCompanyTaxSettingsSelect }
        />
	);
}
