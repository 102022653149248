import { WistiaVideo } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

type EmbedVideoProps = {
	videoSrc: string;
	videoTitle: string;
};

export const EmbedVideo: React.FC<React.PropsWithChildren<EmbedVideoProps>> = ({ videoSrc, videoTitle }) => {
	const iframeProps = {
		allowFullScreen: true,
		className: 'wistia_embed',
		height: '100%',
		name: 'wistia_embed',
		src: videoSrc,
		title: videoTitle,
		width: '100%',
	};

	return ifFeature(
		'encore',
		<WistiaVideo wistiaId={videoTitle} iframeProps={iframeProps} />,
		<>
			<iframe {...iframeProps} className='wistia_embed' frameBorder='0' name='wistia_embed' scrolling='no'></iframe>
			<script async={true} src='//fast.wistia.net/assets/external/E-v1.js'></script>
		</>
	);
};
