import { PureComponent, ReactElement } from 'react';

import { BodyText, Flex, Table } from '@bamboohr/fabric/';
import { Modal } from 'modal-legacy';

interface TemplateListData {
	id: string;
	name: string;
}

interface Props {
	handleDelete: (id: string) => Promise<Record<string, any>>;
	templateListData: Array<TemplateListData>;
}

interface State {
	modalOpen: boolean;
	processingRequest: boolean;
	templateId: string;
}

export class TemplateList extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this._handleCloseDeleteModal = this._handleCloseDeleteModal.bind(this);
		this._handleDeleteError = this._handleDeleteError.bind(this);
		this._onDeleteTemplate = this._onDeleteTemplate.bind(this);
		this._openDeleteModal = this._openDeleteModal.bind(this);
	}

	_handleCloseDeleteModal(): void {
		this.setState({
			modalOpen: false,
			processingRequest: false,
			templateId: '',
		});
	}

	_handleDeleteError(): void {
		this.setState(
			{
				processingRequest: false,
			},
			() => {
				if (window.setMessage) {
					window.setMessage(
						$.__(
							'An error occurred while deleting the template, please try again later',
						),
						'error',
					);
				}
			},
		);
	}

	_onDeleteTemplate(): void {
		const { templateId } = this.state;

		const { handleDelete } = this.props;

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;

		this.setState(
			{
				processingRequest: true,
			},
			() => {
				handleDelete(templateId)
					.then(() => {
						if (window.setMessage) {
							window.setMessage(
								'New Hire Packet Template deleted successfully',
								'success',
							);
						}
						self._handleCloseDeleteModal();
					})
					.catch(() => {
						self._handleDeleteError();
					});
			},
		);
	}

	_openDeleteModal(templateId: string): void {
		this.setState({
			modalOpen: true,
			templateId,
		});
	}

	state = {
		modalOpen: false,
		processingRequest: false,
		templateId: '',
	};

	render(): ReactElement {
		const { templateListData } = this.props;

		const { modalOpen, processingRequest } = this.state;

		const columns = [
			{
				header: 'Name',
				cell: (row) => {
					return (
						<a
							href={`/settings/onboarding/new_hire_packet_template/edit/${row.id}`}
						>
							<BodyText component="span" weight="regular">
								{row.name}
							</BodyText>
						</a>
					);
				},
			},
			{
				showOnHover: true,
				cell: {
					type: 'actions',
					actions: (row) => [
						{
							href: `/settings/onboarding/new_hire_packet_template/duplicate/${row.id}`,
							icon: 'copy-regular',
							tooltipContent: $.__('Duplicate Template'),
						},
						{
							icon: 'trash-solid',
							action: () => this._openDeleteModal(row.id),
							tooltipContent: $.__('Delete Template'),
						},
					],
				},
			},
		];

		return (
			<div className="NHPTemplateListWrapper">
				<Table
					caption={$.__('List of New Hire Packet Templates')}
					columns={columns}
					rowKey={(row: { id: string }) => row.id}
					rows={templateListData}
				/>
				<Modal
					alternativeActionText={$.__(`Don't Delete`)}
					headline={$.__(
						'Are you sure you want to delete this New Packet Template?',
					)}
					icon="trash-can-regular"
					iconColor="danger"
					iconV2Color="error-strong"
					isOpen={modalOpen}
					isProcessing={processingRequest}
					onClose={this._handleCloseDeleteModal}
					primaryAction={this._onDeleteTemplate}
					primaryActionText={$.__('Delete Template')}
					title={$.__('Delete Template?')}
				>
					<Flex alignItems="center" justifyContent="center">
							<BodyText>
								{$.__(
									'It will no longer be available to create New Hire Packets.',
								)}
							</BodyText>
						</Flex>
				</Modal>
			</div>
		);
	}
}
