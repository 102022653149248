import {
    CoverageContract,
    FixedAmountCoverageContract,
    SalaryBasedCoverageContract,
} from './basic-life-coverage-amount-types';

import { DisabilityDisbursementType } from './disability-disbursement-type';
import { DisabilityType } from './disability-type';
import { LifeInsuranceType } from './life-insurance-type';
import {
    BasicLifeOrAddEnum,
    LifeSubTypeEnum,
    VoluntaryLifeOrAddEnum,
} from './life-or-add-enums';
import { PlanCalculatorSettings } from './plan-calculator-settings';

export interface PlanCategoryDataOptions {
    calculatorSettings?: PlanCalculatorSettings;
    typeId?: string | null;
}

export interface DisabilityCategoryDataOptions extends PlanCategoryDataOptions {
    disabilityDisbursementAmount: number | null;
    disabilityDisbursementCurrencyCode: string;
    disabilityDisbursementType: DisabilityDisbursementType;
    disabilityMaxBenefitAmount: number | null;
    disabilityType: DisabilityType;
    isMaxBenefitAmountChecked?: boolean;
}

export interface LifeCategoryDataOptions extends PlanCategoryDataOptions {
    lifeInsuranceType: LifeInsuranceType | LifeSubTypeEnum | null;
    lifeInsuranceSpouseCoverage?: boolean | null;
    lifeInsuranceChildCoverage?: boolean | null;
    type?: LifeSubTypeEnum | null;
}
export interface BasicLifeCategoryDataOptionsContract {
    lifeInsuranceType: LifeSubTypeEnum | LifeInsuranceType | null;
    spouseCoverage?: boolean | null;
    childCoverage?: boolean | null;
    type?: LifeSubTypeEnum | null;
    coverage?:
        | CoverageContract
        | FixedAmountCoverageContract
        | SalaryBasedCoverageContract;
}

export type DisabilityCategoryDataOptionsContract = PlanCategoryDataOptions &
    DisabilityCategoryDataOptions;
export type LifeCategoryDataOptionsContract = PlanCategoryDataOptions &
    LifeCategoryDataOptions;
export type PlanCategoryDataOptionsContract = PlanCategoryDataOptions &
    (
        | LifeCategoryDataOptions
        | DisabilityCategoryDataOptions
        | BasicLifeCategoryDataOptionsContract
        | VoluntaryLifeCategoryDataOptionsContract
        | CriticalIllnessCategoryDataOptionsContract
    );

export interface CriticalIllnessCategoryDataOptionsContract {
    typeId: string;
    employeeCoverageAmount: CriticalIllnessCoverageAmountContract;
    spousePartnerCoverageAmount: CriticalIllnessCoverageAmountContract | null;
    childCoverageAmount: CriticalIllnessCoverageAmountContract | null;
}

export enum CriticalIllnessAmountType {
    percentage = 'percentage',
    range = 'range',
}

export interface CriticalIllnessCoverageAmountContract {
    amount?: number | null;
    incrementSize?: number | null;
    minimum?: number | null;
    maximum?: number | null;
    percentMaximum?: number | null;
    type?: CriticalIllnessAmountType | null;
}

export interface VoluntaryCoverageContract {
    type: string;
    employee: VoluntaryCoverageAmountContract;
    spouse: VoluntaryCoverageAmountContract | null;
    child: VoluntaryCoverageAmountContract | null;
}

export interface VoluntaryCoverageAmountContract {
    increment: number | null;
    minimumAmount?: number | null;
    maximumAmount: number | null;
    multiplier?: number | null;
    percentOfEmployeeCoverage?: number | null;
}

export interface VoluntaryLifeCategoryDataOptionsContract
    extends PlanCategoryDataOptions {
    lifeInsuranceType?: LifeInsuranceType | VoluntaryLifeOrAddEnum | null;
    type: BasicLifeOrAddEnum | VoluntaryLifeOrAddEnum | null;
    spouseCoverage: boolean;
    childCoverage: boolean;
    coverage: VoluntaryCoverageContract;
    lifeInsuranceSpouseCoverage?: boolean; // replaced by 'spouseCoverage'
    lifeInsuranceChildCoverage?: boolean; // replaced by 'childCoverage'
}
