import { Button, Flex, Label, Select, StandardModal, StyledBox, TextButton } from '@bamboohr/fabric';

import Ajax from '@utils/ajax';

import { showSlidedown } from 'Alerts.mod';
import { isEnabled } from 'FeatureToggle.util';
import { cloneDeep } from 'lodash';
import {
	useEffect,
	useState,
} from 'react';

import { renderDateSelectOption } from '../../utils';
import { LoadingPlaceholder } from '../company-tax-field/loading-placeholder/loading-placeholder';
import { getReverseEmploymentSuffix, showSiblingMessage } from './domain';

export const EndTaxModal = (props) => {
	const vtccAccessEnabled = isEnabled('VERMONT_CHILD_CARE_TAX_VIEW_ACCESS');

	const [selectedDate, setSelectedDate] = useState('');
	const [items, setItems] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const {
		clearModalData,
		endTax,
		processing,
		targetRowData,
	} = props;

	const handleAjaxFailure = () => {
		showSlidedown($.__('We were unable to generate your effective dates. Please try again or contact support@bamboohr.com'), 'error');
	};

	useEffect(() => {
		const {
			clientTaxTypeIncludeId,
			group,
		} = targetRowData;

		Ajax.get(`/settings/payroll/taxes/tax_type/end_dates?clientTaxTypeIncludeId=${ clientTaxTypeIncludeId }&clientId=${ group }`).then((response) => {
			if (response.status === 200 && response.data) {
				const allowableEndDates = cloneDeep(response.data);
				if (targetRowData.taxEndDate) {
					allowableEndDates.push({
						anchor: 'bottom',
						type: 'group',
						items: [{
							quarterDate: $.__('Clear End Date'),
							quarterText: $.__('Keep Active'),
							value: null,
						}],
					});
				}

				setItems(allowableEndDates);
				setIsLoading(false);
			} else {
				handleAjaxFailure();
			}
		}).catch(() => {
			handleAjaxFailure();
		});

	}, [targetRowData]);

	const selectPlaceholder = isLoading ? <LoadingPlaceholder /> : '–Select–';

	let headline;
	if (vtccAccessEnabled && showSiblingMessage(targetRowData.employeePercent, targetRowData.payer, targetRowData.taxTypeEngine, targetRowData.taxTypeName, targetRowData.stateName)) {
		headline = $.__('End Tax %1$s and %2$s as of date below?', targetRowData.taxTypeName, getReverseEmploymentSuffix(targetRowData.taxTypeName));
	} else if (targetRowData.taxTypeName) {
		headline = $.__('End Tax %1$s as of the date below?', targetRowData.taxTypeName);
	} else {
		headline = $.__('End this Tax as of the date below?');
	}

	return <StandardModal isOpen={ true } isProcessing={ processing } onRequestClose={ clearModalData }>
		<StandardModal.Body
			renderFooter={
				<StandardModal.Footer
					actions={[
						<TextButton
							key='cancelEndButton'
							onClick={ clearModalData }
							type="button"
						>
							{ $.__('Cancel') }
						</TextButton>,
						<Button key='saveEndButton' onClick={ () => endTax(selectedDate) }>
							{$.__('Save')}
						</Button>,
					]}
				/>
			}
			renderHeader={<StandardModal.Header title={ $.__('End Tax') } />}
		>
			<StandardModal.Constraint>
				<StandardModal.HeroHeadline icon='calendar-xmark-solid' iconColor='primary-strong' text={ headline } />
				<StyledBox backgroundColor="neutral-medium" marginTop={ 1 } padding="9px 0 30px 0">
					<Flex alignItems="center" flexDirection="column">
						<Flex alignItems="flex-start" flexDirection="column" paddingTop="15px">
							<Label>
							{ $.__('When will this tax end?') }
							</Label>
							<Select
								isClearable={ false }
								isDisabled={ isLoading }
								items={ items }
								onSelect={ date => setSelectedDate(date) }
								placeholder={ selectPlaceholder }
								renderOptionContent={ renderDateSelectOption }
							renderToggleContent={ renderDateSelectOption }
								selectedValues={ [selectedDate] }
							width={ 9 }
							/>
						</Flex>
					</Flex>
				</StyledBox>
			</StandardModal.Constraint>
		</StandardModal.Body>
	</StandardModal>
};
