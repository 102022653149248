import {useEffect, useState} from "react";
import {
	Avatar,
	BodyText,
	Button,
	CardContentSpacing,
	Divider,
	Flex,
	Headline,
	LayoutBox,
	StandardModal,
	TextButton
} from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';

export function GarnishmentDeleteModal(props) {

	const {
		byType,
		garnishmentType,
		onClose,
		data,
		employeeCount,
		payee,
		payeeName,
		visible,
	} = props;

	const employeesShownMax = 5;
	const showMoreMax = 3;
	const [payeeEmployee, setPayeeEmployee] = useState(null);
	const [showMoreEmployeesCount, setShowMoreEmployeesCount] = useState((employeeCount - employeesShownMax) > showMoreMax ? showMoreMax : (employeeCount - employeesShownMax));
	const [employeesToShow, setEmployeesToShow] = useState(employeesShownMax);

	const numberOfEmployees = employeeCount;

	useEffect(() => {
		const payeeEmployeeArray = [];
		if(garnishmentType === undefined) {
			const customPayeeEmployees = payee.payeeEmployees;
			customPayeeEmployees.forEach((employee) => {
				const customPayeeEmployeesByIdArray = Object.values(byType.employees.byId);
				const customPayeeEmployee = customPayeeEmployeesByIdArray.find((employeeName) => employeeName.name === employee);
				payeeEmployeeArray.push(customPayeeEmployee);
			})
		} else {
			const globalPayeeById = payee.id;
			const globalPayeeEmployeesByIdArray = byType.globalPayeeEmployees.byId[globalPayeeById].employees;
			const globalEmployees = new Set();
			const uniqueEmployees = globalPayeeEmployeesByIdArray.filter(employee => {
				if(globalEmployees.has(employee.employeeId)) {
					return false;
				}
				globalEmployees.add(employee.employeeId);
				return true;
			});
			payeeEmployeeArray.push( ...uniqueEmployees )
		}
		setPayeeEmployee(payeeEmployeeArray);
	}, [employeesToShow, garnishmentType, showMoreEmployeesCount])

	const buildEmployeeList = (payeeEmployee) => {
		const showMorePayeeEmployees = () => {
			if(employeesToShow < payeeEmployee.length) {
				setShowMoreEmployeesCount(payeeEmployee.length - employeesToShow >= showMoreMax ? showMoreMax : payeeEmployee.length - employeesToShow);
				setEmployeesToShow(employeesToShow + showMoreEmployeesCount)
			}
		}

		return (
            <LayoutBox>
                {payeeEmployee.slice(0, employeesToShow).map((employee, index) => (
					<>
						<LayoutBox marginTop={2} key={index.toString()}>
                            <Flex alignItems="center" gap={2}>
                                <Avatar
                                    alt={ $.__('Image of %1', employee.employeeName) }
                                    size={48}
                                    src={ employee.employeeProfilePictureUrl }
                                />
                                <Headline
                                    color="neutral-strong"
                                    url={employee.profileUrl || employee.employeeProfileUrl}
                                    size="small"
                                    target="_blank"
                                >
                                    {employee.name || employee.employeeName}
                                </Headline>
                            </Flex>
                        </LayoutBox>
					</>
				))}
                {payeeEmployee.length > employeesToShow && (
					<TextButton
						color="secondary"
						muted={true}
						onClick={showMorePayeeEmployees}
						text={<Message params={[showMoreEmployeesCount]} text={$._('Show **{1}** More')}/>}
						type="button"
					/>
				)}
            </LayoutBox>
        );
	}

	return (
        <StandardModal isOpen={visible} onRequestClose={onClose}>
            <StandardModal.Body
				renderFooter={(
					<StandardModal.Footer
						actions={[
							<Button
								key="button"
								onClick={ onClose }
								type="button"
							>
								{$.__('Got It')}
							</Button>
						]}
					/>
				)}
				renderHeader={(
					<StandardModal.Header
						hasCloseButton={ true }
						title={ ($.__('Just Checking...')) }
					/>
				)}
			>
				<StandardModal.Constraint spacingOverrides={ {top: CardContentSpacing.BIGGIE} }>
					<StandardModal.HeroHeadline
						icon="trash-can-regular"
						iconColor="error-medium"
						subtext={
							<LayoutBox marginTop={1.5}>
								<BodyText component="span">
									{$.__n(
										'In order to delete this payee, update the garnishments of the employee below to a different payee, or end their deduction.',
										'In order to delete this payee, update the garnishments of the employees below to a different payee, or end their deduction.',
										numberOfEmployees
									)}
								</BodyText>
							</LayoutBox>
						}
						text={
							<LayoutBox>
								<Message params={[payeeName]}
										 text={$._('Are you sure you want to delete the payee **{1}**?')}/>
							</LayoutBox>
						}
					/>
					<LayoutBox marginTop={4}>
                        <Divider />
                        {payeeEmployee && buildEmployeeList(payeeEmployee)}
                    </LayoutBox>

				</StandardModal.Constraint>
			</StandardModal.Body>
        </StandardModal>
    );
}