import {
	StandardModal,
	Button,
	TextButton,
	Checkbox,
	StyledBox,
} from '@bamboohr/fabric';
import { useState } from 'react';
import { putUnlockCompanyInformation } from '../../../utils/shared';

export const CompanyNameChangeModal = ({
	isOpen,
	setIsOpen,
	refreshData,
	setContactFields,
	clientId,
}) => {
	const [confirmed, setConfirmed] = useState(false);
	const [unlockButtonProcessing, setUnlockButtonProcessing] = useState(false);

	const handleUnlockCompanyLegalName = () => {
		setUnlockButtonProcessing(true);
		putUnlockCompanyInformation({ locked: false }, clientId)
			.then(() => {
				refreshData();
				setContactFields();
				setIsOpen(false);
				setConfirmed(false);
				setUnlockButtonProcessing(false);
			})
			.catch(() => {
				setUnlockButtonProcessing(false);
				window.setMessage(
					$.__('Uh oh...something went wrong. Please try again later.'),
					'error',
				);
			});
	};

	const header = <StandardModal.Header title={$.__('Just Checking...')} />;
	const footer = (
		<StandardModal.Footer
			actions={[
				<TextButton key="cancel" onClick={() => setIsOpen(false)} type="button">
					{$.__('Cancel')}
				</TextButton>,
				<Button
					disabled={!confirmed}
					key="unlock"
					onClick={handleUnlockCompanyLegalName}
					processing={unlockButtonProcessing}
					type="button"
				>
					{$.__('Unlock Company Legal Name')}
				</Button>,
			]}
		/>
	);

	return (
		<StandardModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
			<StandardModal.Body renderFooter={footer} renderHeader={header}>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline
						iconColor="warning-medium"
						icon={'triangle-exclamation-solid'}
						text={$.__('Are you sure you want to unlock company legal name?')}
						subtext={$.__(
							'This will allow the customer to make a one time edit. You must verify that the customer has provided legal documentation for this change.',
						)}
					>
						<StyledBox
							paddingY={2}
							paddingX={3}
							margin={2}
							backgroundColor="neutral-medium"
							borderRadius={'small'}
						>
							<Checkbox
								checked={confirmed}
								onChange={(e) => setConfirmed(e.checked)}
								label={$.__(
									'I have verified that the customer has legal documentation.',
								)}
							/>
						</StyledBox>
					</StandardModal.HeroHeadline>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
};
