import { useState, useEffect, createRef, useRef } from 'react';
import classNames from 'classnames';
import { isNull, isEmpty } from 'lodash';

import { closestElement } from '@utils/dom';

import { Select } from '@fabric/select';
import {
	Popover,
	TextField,
	Button,
	TextButton,
	Flex,
	Tooltip,
	MaskedInput,
	IconButton,
	IconV2,
	BodyText,
	LayoutBox,
} from '@bamboohr/fabric';
import { Message, ExternalLink } from '@bamboohr/utils/lib/message';
import { validateUrlHttpOptional } from '@bamboohr/utils/lib/validation/rules';

import {
	isEinEnabled,
	isInvalidAddress,
	validateEinNumber,
	mapContactToSelectedId,
	getAppStateFromData,
	mapStateAbbreviationToId,
	formatStatesListForSelect,
	getStateAbbreviationFromId,
	formatContactsListForSelect,
	MISSING_INFO,
	COMPANY_FORM_FIELDS,
	payrollIdExplanation,
	trimWebsiteURL,
	isValidPhoneNumber,
	isPayrollAccessEnabled,
} from '../../utils/shared';

import { CompletedForms } from '../completed-forms';
import { MoveAwayModal } from '../move-away-modal';

import { CompanyNameChangeModal } from './company-name-change-modal/company-name-change-modal';
import { PreviousContactAccessModal } from './previous-contact-access-modal/previous-contact-access-modal';
import { PageLoader } from '../page-loader/page-loader';

export const unformatPhoneNumber = (phoneNumber) =>
	phoneNumber.replace(/[_()]/g, '');

export function CompanyInfoForm(props) {
	const {
		data: { companyLegalNameLocked, clientId },
		stateList,
		contactList,
		customErrorBag,
		data,
		isProcessing,
		onInputChange,
		onMoveAwayModalClose,
		setFooterEnabled,
		showCompletedFormsSection,
		refreshData,
		revokePreviousContactPermission,
		onRevokePreviousContactPermission,
	} = props;

	const [validatingEin, setValidatingEin] = useState(false);
	const [validEin, setValidEin] = useState(true);
	const [validPhoneNumber, setValidPhoneNumber] = useState(true);
	const [validAddress, setValidAddress] = useState(true);
	const [validWebsite, setValidWebsite] = useState(true);
	const [moveAwayModelOpen, setMoveAwayModalOpen] = useState(false);
	const [goToPageUrl, setGoToPageUrl] = useState('');
	const [errorBag, setErrorBag] = useState({
		companyLegalName: false,
		ein: false,
		dbaName: false,
		street1: false,
		city: false,
		state: false,
		zip: false,
		contactName: false,
		workEmail: false,
		workPhone: false,
	});

	let stateDropdownRef = createRef();
	let contactDropdownRef = createRef();
	const questionRef = useRef();
	const originalContactRef = useRef(data.selectedContactId);

	const [popoverOpen, setPopoverOpen] = useState(false);
	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [revokeAccessModalOpen, setRevokeAccessModalOpen] = useState(false);

	useEffect(() => {
		setMoveAwayListeners();
	}, []);

	useEffect(() => {
		if (setFooterEnabled) {
			setFooterEnabled(!companyLegalNameLocked);
		}
		setContactFields();
	}, [companyLegalNameLocked, contactList]);

	useEffect(() => {
		const { showAwayModal } = props;

		if (showAwayModal) {
			_handleCancelClick();
		}
	}, [props.showAwayModal]);

	useEffect(() => {
		if (stateDropdownRef.current !== null) {
			stateDropdownRef.current.setState({ isInvalid: errorBag.state });
		}

		if (contactDropdownRef.current !== null) {
			contactDropdownRef.current.setState({ isInvalid: errorBag.contactName });
		}
	}, [errorBag.state, errorBag.contactName]);

	useEffect(() => {
		if (stateDropdownRef.current !== null) {
			if (customErrorBag) {
				stateDropdownRef.current.setState({
					isInvalid: props.customErrorBag.state,
				});
			}
		}

		if (contactDropdownRef.current !== null) {
			if (customErrorBag) {
				contactDropdownRef.current.setState({
					isInvalid: props.customErrorBag.contactName,
				});
			}
		}
	}, [props.customErrorBag]);

	useEffect(() => {
		if (window.hasFormChanges) {
			return;
		}

		//Form has reset - set new original contact
		originalContactRef.current = data.selectedContactId;
	}, [data]);

	const handleOnStateSelect = (stateId, e) => {
		const abbreviation = getStateAbbreviationFromId(stateId, stateList);

		window.hasFormChanges = true;
		onInputChange(COMPANY_FORM_FIELDS.STATE, abbreviation);

		if (typeof setFooterEnabled === 'function') {
			setFooterEnabled(true);
		}
	};

	const handleOnContactSelect = (contactId) => {
		onInputChange('selectedContactId', contactId);
		window.hasFormChanges = true;

		const contact = contactList.filter(
			(contact) => contact.userId == contactId,
		)[0];

		onInputChange(COMPANY_FORM_FIELDS.CONTACT_NAME, contact.contactName);
		onInputChange(COMPANY_FORM_FIELDS.WORK_EMAIL, contact.workEmail);
		onInputChange(COMPANY_FORM_FIELDS.WORK_EXT, contact.workExtension);
		onInputChange(COMPANY_FORM_FIELDS.WORK_PHONE, contact.workPhone);

		if (
			originalContactRef.current &&
			contactId !== originalContactRef.current &&
			revokePreviousContactPermission === undefined
		) {
			setRevokeAccessModalOpen(true);
		}

		if (typeof setFooterEnabled === 'function') {
			setFooterEnabled(true);
		}
	};

	const handleOnInputChange = (e) => {
		const { id, value } = e.target;
		if (id === COMPANY_FORM_FIELDS.EIN) {
			const strippedEin = value.replace(/-|_/g, '');
			//prevent initial masked value from causing form changes
			if (data[id] === strippedEin) {
				return;
			}
		}
		if (Object.hasOwn(data, id) && value === data[id]) {
			return;
		}

		window.hasFormChanges = true;
		if (setFooterEnabled) {
			setFooterEnabled(true);
		}

		onInputChange(id, value);
	};

	const handleCloseMoveAwayModal = () => {
		setMoveAwayModalOpen(false);
		if (onMoveAwayModalClose) {
			onMoveAwayModalClose();
		}
	};

	const handleSaveMoveAwayModal = () => {
		setMoveAwayModalOpen(false);

		window.hasFormChanges = !isFormValid();

		handleOnSubmit();
	};

	const handleDontSaveMoveAwayModal = () => {
		const { onDontSaveMoveAway } = props;

		if (onDontSaveMoveAway) {
			onDontSaveMoveAway();
		} else {
			window.location = goToPageUrl;
		}
	};

	const isFieldInvalid = (id) => {
		return isNull(data[id]) || isEmpty(data[id]);
	};

	const isFormValid = () => {
		const fieldList = [
			COMPANY_FORM_FIELDS.LEGAL_NAME,
			COMPANY_FORM_FIELDS.EIN,
			COMPANY_FORM_FIELDS.DBA_NAME,
			COMPANY_FORM_FIELDS.STREET_1,
			COMPANY_FORM_FIELDS.CITY,
			COMPANY_FORM_FIELDS.STATE,
			COMPANY_FORM_FIELDS.ZIP,
			COMPANY_FORM_FIELDS.CONTACT_NAME,
			COMPANY_FORM_FIELDS.WORK_EMAIL,
			COMPANY_FORM_FIELDS.WORK_PHONE,
			COMPANY_FORM_FIELDS.PHONE_NUMBER,
			COMPANY_FORM_FIELDS.BUSINESS_TYPE,
		];

		let isFormValid = true;
		let newErrorBag = Object.assign({}, errorBag);

		fieldList.forEach((field) => {
			if (isFieldInvalid(field)) {
				newErrorBag[field] = true;
				isFormValid = false;
			} else {
				newErrorBag[field] = false;
			}
		});

		setErrorBag(newErrorBag);

		return isFormValid;
	};

	const handleOnSubmit = () => {
		const { onSubmit, recordId, useCustomErrorBag } = props;

		if (!useCustomErrorBag) {
			if (!isFormValid()) {
				window.setMessage(MISSING_INFO, 'error');
				return;
			}
		}

		onSubmit(data, recordId);

		window.hasFormChanges = false;
	};

	const handleOnInputBlur = (e) => {
		const { name, value } = e.target;

		if (name === COMPANY_FORM_FIELDS.PHONE_NUMBER) {
			const unformattedValue = unformatPhoneNumber(value);
			const isValid = isValidPhoneNumber(unformattedValue);
			setValidPhoneNumber(isValid);
		}

		// Handle blur EIN validation
		if (name === COMPANY_FORM_FIELDS.EIN) {
			const strippedEin = value.replace(/-|_/g, '');

			if (strippedEin === '') {
				setValidEin(false);
				onInputChange(COMPANY_FORM_FIELDS.EIN, null);
			} else {
				setValidatingEin(true);
				validateEinNumber(strippedEin)
					.then((res) => {
						setValidEin(res.data.isValidEin);
					})
					.catch(() => {
						window.setMessage(
							$.__(
								'Uh oh...something went wrong validating your EIN. Please try again.',
							),
							'error',
						);
					})
					.finally(() => setValidatingEin(false));
			}
		}

		// Handle blur Street1 to check for PO BOX
		if (name === COMPANY_FORM_FIELDS.STREET_1) {
			if (isInvalidAddress(value)) {
				setValidAddress(false);
			} else {
				setValidAddress(true);
			}
		}

		if (name === COMPANY_FORM_FIELDS.WEBSITE) {
			if (!value) {
				setValidWebsite(true);
				return;
			}
			const isValid = validateUrlHttpOptional(value);
			setValidWebsite(isValid);
			if (isValid) {
				onInputChange(COMPANY_FORM_FIELDS.WEBSITE, trimWebsiteURL(value) ?? '');
			}
		}
	};

	const setContactFields = () => {
		contactList.forEach((contact) => {
			if (data.selectedContactId == contact.userId) {
				onInputChange(COMPANY_FORM_FIELDS.CONTACT_NAME, contact.contactName);
				onInputChange(
					COMPANY_FORM_FIELDS.WORK_EMAIL,
					data.workEmail ? data.workEmail : contact.workEmail,
				);
				onInputChange(
					COMPANY_FORM_FIELDS.WORK_PHONE,
					data.workPhone ? data.workPhone : contact.workPhone,
				);
				onInputChange(
					COMPANY_FORM_FIELDS.WORK_EXT,
					data.workExtension ? data.workExtension : contact.workExtension,
				);
			}
		});
	};

	const setMoveAwayListeners = () => {
		const closestCompanyInformation = closestElement(
			'.CompanyInformationContainer',
		);
		document.addEventListener(
			'click',
			(event) => {
				const { target } = event;
				if (target.nodeName !== 'A') {
					return;
				}

				const isInsideCompanyInformation = closestCompanyInformation(target);
				if (isInsideCompanyInformation) {
					return;
				}

				if (target.getAttribute('target') === '_blank') {
					return;
				}

				event.preventDefault();

				let targetedUrl;
				if (event.srcElement.href) {
					targetedUrl = event.srcElement.href;
				} else {
					targetedUrl = event.srcElement.parentNode.attributes.href.nodeValue;
				}

				setGoToPageUrl(targetedUrl);

				if (window.hasFormChanges) {
					setMoveAwayModalOpen(true);
				} else {
					window.location = targetedUrl;
				}
			},
			true,
		);
	};

	const _handleCancelClick = () => {
		setMoveAwayModalOpen(true);
	};

	const buildLabelClassString = (field) => {
		let invalid = true;
		let bagToUse = customErrorBag ? customErrorBag : errorBag;
		if (field == COMPANY_FORM_FIELDS.STREET_1) {
			invalid =
				!validAddress ||
				bagToUse.street1 ||
				bagToUse.city ||
				bagToUse.state ||
				bagToUse.zip;
		}

		return classNames('fab-Label fab-Label--required', {
			'fab-Label--error':
				(field == COMPANY_FORM_FIELDS.STREET_1 && invalid) || hasError(field),
		});
	};

	const getFilesArray = (data) => {
		return [data.form8821, data.form8655, data.beneficialOwnerAddendum];
	};

	const hasError = (field) => {
		return customErrorBag ? customErrorBag[field] : errorBag[field];
	};

	if (!data || !stateList || !contactList) {
		return <PageLoader />;
	}

	let appState = getAppStateFromData(data);

	const filesArray = getFilesArray(data);
	const arrayHasValidFile = filesArray.some((file) => file.fileId !== null);

	const einLabelClass = classNames('fab-Label', 'fab-Label--required', {
		'fab-Label--error': !validEin || hasError(COMPANY_FORM_FIELDS.EIN),
	});

	const getStatus = () => {
		if (hasError(COMPANY_FORM_FIELDS.LEGAL_NAME)) {
			return 'error';
		}

		if (!companyLegalNameLocked && data.companyLegalName) {
			return 'info';
		}

		return '';
	};

	const getCompanyLegalNameNote = () => {
		if (!companyLegalNameLocked) {
			return $.__('The name you have registered with the IRS.');
		}

		return (
			<span ref={questionRef}>
				<IconButton
					icon="question-solid"
					onClick={() => setPopoverOpen((state) => !state)}
					variant="outlined"
				/>
			</span>
		);
	};

	const showUnlockText =
		companyLegalNameLocked && window?.SESSION_USER?.supportAdmin;

	const getSelectedState = () => {
		if (!data.state) {
			return [];
		}

		const id = mapStateAbbreviationToId(data.state, stateList);
		return id ? [id] : [];
	};

	return (
		<LayoutBox textAlign="left">
			<CompanyNameChangeModal
				clientId={clientId}
				isOpen={warningModalOpen}
				refreshData={refreshData}
				setContactFields={setContactFields}
				setIsOpen={setWarningModalOpen}
			/>
			<Popover
				anchorEl={questionRef.current || null}
				onClose={() => setPopoverOpen(false)}
				open={popoverOpen}
				title={$.__('Need To Edit Your Company Name?')}
			>
				<BodyText>
					<Message
						link={ExternalLink(
							'https://help.bamboohr.com/s/article/1406726#customer-support',
						)}
						text={$.__(
							'We need to verify that your company’s legal name is registered with the IRS. If you need to make a change, [contact us], and we will help you out. You’ll want to have ready a legal document (like a 941 tax form) that shows your name has been registered properly.',
						)}
					/>
				</BodyText>
			</Popover>
			<div className="fab-FormSection">
				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<span className="fab-FormField">
							<TextField
								disabled={companyLegalNameLocked}
								id={COMPANY_FORM_FIELDS.LEGAL_NAME}
								label={$.__('Company Legal Name')}
								name={COMPANY_FORM_FIELDS.LEGAL_NAME}
								note={getCompanyLegalNameNote()}
								notePlacement={companyLegalNameLocked ? 'inline' : 'block'}
								onChange={handleOnInputChange}
								required={true}
								status={getStatus()}
								type="text"
								value={data.companyLegalName || ''}
								width={8}
							/>
						</span>
						{showUnlockText && (
							<div>
								<TextButton
									onClick={() => setWarningModalOpen(true)}
									type="button"
								>
									{$.__('Unlock For Customer To Edit')}
								</TextButton>
							</div>
						)}
					</div>
				</div>
				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<span className="fab-FormField">
							<TextField
								label={$.__('DBA (Doing Business As)')}
								status={
									hasError(COMPANY_FORM_FIELDS.DBA_NAME) ? 'error' : undefined
								}
								id={COMPANY_FORM_FIELDS.DBA_NAME}
								name={COMPANY_FORM_FIELDS.DBA_NAME}
								note={$.__(
									"Your company's trade name or preferred public company name",
								)}
								notePlacement={'block'}
								onChange={handleOnInputChange}
								required={true}
								type="text"
								value={data.dbaName || ''}
								width={8}
							/>
						</span>
					</div>
				</div>

				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<label className={einLabelClass} htmlFor={COMPANY_FORM_FIELDS.EIN}>
							{$.__('EIN')}
						</label>
						<span className="fab-FormField">
							<MaskedInput
								status={
									!validEin || hasError(COMPANY_FORM_FIELDS.EIN)
										? 'error'
										: undefined
								}
								disabled={!isEinEnabled(appState) || validatingEin}
								id={COMPANY_FORM_FIELDS.EIN}
								MaskOptions={{
									mask: '00-0000000',
									//lazy: false will break the move away listener :(
									placeholderChar: '_',
								}}
								conformInitialValue
								onChange={(fakeEvent) =>
									handleOnInputChange({
										target: {
											...fakeEvent.target,
											id: COMPANY_FORM_FIELDS.EIN,
										},
									})
								}
								name={COMPANY_FORM_FIELDS.EIN}
								onBlur={(e) =>
									handleOnInputBlur({
										...e,
										target: { ...e.target, name: COMPANY_FORM_FIELDS.EIN },
									})
								}
								value={data.ein || ''}
							/>
						</span>
					</div>
				</div>

				{clientId ? (
					<div className="fab-FormRow">
						<div className="fab-FormColumn">
							<label className="fab-Label" htmlFor="clientId">
								{$.__('Payroll ID')}
							</label>
							<span className="fab-FormField">
								<Flex gap={1} alignItems="center">
									<p id="clientId">{clientId}</p>
									<Tooltip content={payrollIdExplanation}>
										<Flex>
											<IconV2
												name="circle-question-solid"
												color="neutral-medium"
												size={16}
											/>
										</Flex>
									</Tooltip>
								</Flex>
							</span>
						</div>
					</div>
				) : null}

				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<div className="fab-FormSection">
							<label
								className={buildLabelClassString(COMPANY_FORM_FIELDS.STREET_1)}
								htmlFor={COMPANY_FORM_FIELDS.STREET_1}
							>
								{$.__('Street Address')}
							</label>

							<div className="fab-FormRow fab-FormRow--tight">
								<TextField
									status={
										!validAddress || hasError(COMPANY_FORM_FIELDS.STREET_1)
											? 'error'
											: undefined
									}
									id={COMPANY_FORM_FIELDS.STREET_1}
									name={COMPANY_FORM_FIELDS.STREET_1}
									onBlur={handleOnInputBlur}
									onChange={handleOnInputChange}
									placeholder="Street 1"
									value={data.street1 || ''}
									width={8}
								/>
								{!validAddress && (
									<span className="fab-FormNote fab-FormNote--error">
										{$.__(
											'Address must be a valid physical address and cannot be a PO Box',
										)}
									</span>
								)}
							</div>

							<div className="fab-FormRow fab-FormRow--tight">
								<TextField
									id={COMPANY_FORM_FIELDS.STREET_2}
									name={COMPANY_FORM_FIELDS.STREET_2}
									onChange={handleOnInputChange}
									placeholder="Street 2"
									value={data.street2 || ''}
									width={8}
								/>
							</div>

							<div>
								<span className="fab-FormField">
									<TextField
										status={
											hasError(COMPANY_FORM_FIELDS.CITY) ? 'error' : undefined
										}
										id={COMPANY_FORM_FIELDS.CITY}
										name={COMPANY_FORM_FIELDS.CITY}
										onChange={handleOnInputChange}
										placeholder="City"
										value={data.city || ''}
										width={5}
									/>
								</span>

								<span className="fab-FormField">
									<Select
										id={COMPANY_FORM_FIELDS.STATE}
										isClearable={false}
										items={
											stateList ? formatStatesListForSelect(stateList) : []
										}
										name={COMPANY_FORM_FIELDS.STATE}
										onSelect={handleOnStateSelect}
										placeholder="State"
										ref={stateDropdownRef}
										selectedValues={getSelectedState()}
									/>
								</span>

								<span className="fab-FormField required">
									<TextField
										status={
											hasError(COMPANY_FORM_FIELDS.ZIP) ? 'error' : undefined
										}
										id={COMPANY_FORM_FIELDS.ZIP}
										name={COMPANY_FORM_FIELDS.ZIP}
										onChange={handleOnInputChange}
										placeholder="Zip"
										value={data.zip || ''}
										width={3}
									/>
								</span>
							</div>
						</div>
					</div>
				</div>

				<>
					<div className="fab-FormRow">
						<div className="fab-FormColumn">
							<span className="fab-FormField">
								<MaskedInput
									MaskOptions={{
										mask: '(000)-000-0000',
										onAccept: (value) => {
											handleOnInputChange({
												target: {
													id: COMPANY_FORM_FIELDS.PHONE_NUMBER,
													value: unformatPhoneNumber(value),
												},
											});
										},
									}}
									id={COMPANY_FORM_FIELDS.PHONE_NUMBER}
									label={$.__('Company phone number')}
									name={COMPANY_FORM_FIELDS.PHONE_NUMBER}
									onBlur={({ target }) =>
										handleOnInputBlur({
											target: {
												...target,
												name: COMPANY_FORM_FIELDS.PHONE_NUMBER,
											},
										})
									}
									required={true}
									status={
										!validPhoneNumber ||
										hasError(COMPANY_FORM_FIELDS.PHONE_NUMBER)
											? 'error'
											: undefined
									}
									type="text"
									value={data.phoneNumber || ''}
									width={5}
								/>
							</span>
						</div>
					</div>

					<div className="fab-FormRow">
						<div className="fab-FormColumn">
							<span className="fab-FormField">
								<TextField
									id={COMPANY_FORM_FIELDS.WEBSITE}
									label={$.__('Company website (if applicable)')}
									name={COMPANY_FORM_FIELDS.WEBSITE}
									placeholder="example.com"
									status={!validWebsite ? 'error' : undefined}
									onBlur={({ target }) =>
										handleOnInputBlur({
											target: { ...target, name: COMPANY_FORM_FIELDS.WEBSITE },
										})
									}
									onChange={handleOnInputChange}
									type="text"
									value={data.website || ''}
									width={7}
								/>
							</span>
						</div>
					</div>

					<div className="fab-FormRow">
						<div className="fab-FormColumn">
							<TextField
								id={COMPANY_FORM_FIELDS.BUSINESS_TYPE}
								label={$.__('Business Type')}
								name={COMPANY_FORM_FIELDS.BUSINESS_TYPE}
								onChange={handleOnInputChange}
								required={true}
								status={
									hasError(COMPANY_FORM_FIELDS.BUSINESS_TYPE)
										? 'error'
										: undefined
								}
								type="text"
								value={data.businessType || ''}
								width={7}
								note={
									<Tooltip
										content={$.__(
											'For example, “Real Estate,” “Lawn Care,” “Legal Services,” etc.',
										)}
									>
										<span>
											<IconV2
												name="circle-question-solid"
												color="neutral-medium"
												size={16}
											/>
										</span>
									</Tooltip>
								}
							/>
						</div>
					</div>
				</>
			</div>
			<div className="fab-FormSection">
				<legend className="fab-FormSection__legend">
					{$.__('Payroll Contact')}
				</legend>

				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<label
							className={buildLabelClassString(
								COMPANY_FORM_FIELDS.CONTACT_NAME,
							)}
							htmlFor={COMPANY_FORM_FIELDS.CONTACT_NAME}
						>
							{$.__('Payroll Contact')}
						</label>
						<span className="fab-FormField">
							<Select
								id="contact"
								isClearable={false}
								items={formatContactsListForSelect(contactList)}
								name="contact"
								onSelect={handleOnContactSelect}
								ref={contactDropdownRef}
								selectedValues={[
									mapContactToSelectedId(data.selectedContactId, contactList),
								]}
								width="7"
							/>
						</span>
					</div>
				</div>

				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<label
							className={buildLabelClassString(COMPANY_FORM_FIELDS.WORK_EMAIL)}
							htmlFor={COMPANY_FORM_FIELDS.WORK_EMAIL}
						>
							{$.__('Work Email')}
						</label>
						<span className="fab-FormField">
							<TextField
								status={
									hasError(COMPANY_FORM_FIELDS.WORK_EMAIL) ? 'error' : undefined
								}
								id={COMPANY_FORM_FIELDS.WORK_EMAIL}
								name={COMPANY_FORM_FIELDS.WORK_EMAIL}
								onChange={handleOnInputChange}
								required={true}
								value={data.workEmail || ''}
								width={8}
							/>
						</span>
					</div>
				</div>

				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<label
							className={buildLabelClassString(COMPANY_FORM_FIELDS.WORK_PHONE)}
							htmlFor={COMPANY_FORM_FIELDS.WORK_PHONE}
						>
							{$.__('Work Phone')}
						</label>
						<span className="fab-FormField">
							<TextField
								id={COMPANY_FORM_FIELDS.WORK_PHONE}
								name={COMPANY_FORM_FIELDS.WORK_PHONE}
								required={true}
								onChange={handleOnInputChange}
								status={
									hasError(COMPANY_FORM_FIELDS.WORK_PHONE) ? 'error' : undefined
								}
								value={data.workPhone || ''}
								width={5}
							/>
						</span>
					</div>

					<div className="fab-FormColumn">
						<label className="fab-Label" htmlFor={COMPANY_FORM_FIELDS.WORK_EXT}>
							{$.__('Ext.')}
						</label>
						<span className="fab-FormField">
							<TextField
								id={COMPANY_FORM_FIELDS.WORK_EXT}
								name={COMPANY_FORM_FIELDS.WORK_EXT}
								onChange={handleOnInputChange}
								required={true}
								value={data.workExtension || ''}
								width={3}
							/>
						</span>
					</div>
				</div>
			</div>
			{showCompletedFormsSection && arrayHasValidFile && (
				<CompletedForms files={filesArray} />
			)}
			<MoveAwayModal
				onClose={handleCloseMoveAwayModal}
				onDontSave={handleDontSaveMoveAwayModal}
				onSave={handleSaveMoveAwayModal}
				visible={moveAwayModelOpen}
			/>
			<PreviousContactAccessModal
				companyName={data.companyLegalName}
				isOpen={isPayrollAccessEnabled && revokeAccessModalOpen}
				onCancel={() => setRevokeAccessModalOpen(false)}
				onSubmit={(keepAccess) => {
					if (!isPayrollAccessEnabled) {
						return;
					}
					onRevokePreviousContactPermission(!keepAccess);
					setRevokeAccessModalOpen(false);
				}}
				previousContactName={
					contactList.find(
						(contact) => contact.userId == originalContactRef.current,
					)?.contactName
				}
			/>
			<div className="fab-FormSection">
				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<span className="fab-FormField">
							<Button
								clickAction={handleOnSubmit}
								isDisabled={companyLegalNameLocked && !window.hasFormChanges}
								isProcessing={isProcessing}
								outline={!window.hasFormChanges}
								text="Save"
								type="button"
							/>
						</span>
					</div>

					{window.hasFormChanges && (
						<div className="fab-FormColumn">
							<span className="fab-FormField">
								<TextButton
									clickAction={_handleCancelClick}
									text="Cancel"
									type="button"
								/>
							</span>
						</div>
					)}
				</div>
			</div>
		</LayoutBox>
	);
}
