import {
	Button,
	Flex,
	LayoutBox,
	Pill,
	PillType,
	TextButton,
} from '@bamboohr/fabric';

import { getInstalledAppLinkText } from './app-item.domain';
import Publisher from './publisher';
import {
	showAppModal,
} from '../app-modal';

import {
	bem,
} from '../../../utils';
import {
	App,
} from '../../context/state';
import {
	useSettingsApps,
} from '../../context';

import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface AppItemProps {
	app: App;
}

const AppItem: React.FunctionComponent<React.PropsWithChildren<AppItemProps>> = ({
	app,
	app: {
		publisher = {},
	},
}) => {
	const context = useSettingsApps();

	return (
		<div className={ bem('appItem', app.name) }>
			<Publisher { ...publisher } />
			<div className={ bem('appItemDetailWrapper') }>
				<span className={ bem('appItemTitle') }>
					<Flex alignItems="center">
						{app.title}
						{app.isPaid && (
							<LayoutBox marginLeft={1}>
								<Pill muted={true} type={PillType.Info}>
									{$.__('Paid')}
								</Pill>
							</LayoutBox>
						)}
					</Flex>
				</span>
				{ (!app.dontAllowChanges) && (
					ifFeature('encore', 
					<Flex alignContent='center' alignItems='center' gap='12px' marginTop='16px'>
						<Button
							color='secondary'
							onClick={ () => showAppModal(app, app.installed ? 'uninstall' : 'install', context) }
							size='small'
						>
							{ app.installed ? $.__('Uninstall') : $.__('Install') }
						</Button>
						{ (app.installed && app.hasConfig && !app.settingsUrl) && (
							<Button
								color='secondary'
								id="appSettingsButton"
								onClick={ () => showAppModal(app, 'config', context) }
								size='small'
								variant='outlined'
							>
								{ $.__('Settings') }
							</Button>
						) }
						{ (app.installed && app.settingsUrl) && (
							<TextButton
								href={ app.settingsUrl }
								rel="noopener external nofollow noreferrer"
								size='small'
								target="_blank"
							>
								{getInstalledAppLinkText(app.name)}
							</TextButton>
						) }
						{ app.infoUrl && (
							<TextButton
								href={ app.infoUrl }
								rel="noopener noreferrer"
								size='small'
								target="_blank"
							>
								{ $.__('Learn more') }
							</TextButton>
						) }
					</Flex>, 
					<div className={ bem('appItemActions') }>
						<span
							className={ _appItemActionClasses(app.installed ? 'uninstall' : 'install') }
							onClick={ () => showAppModal(app, app.installed ? 'uninstall' : 'install', context) }
						>
							{ app.installed ? $.__('Uninstall') : $.__('Install') }
						</span>
						{ (app.installed && app.hasConfig && !app.settingsUrl) && (
							<span
								className={ _appItemActionClasses('config') }
								onClick={ () => showAppModal(app, 'config', context) }
								id="appSettingsButton"
							>
								{ $.__('Settings') }
							</span>
						) }
						{ (app.installed && app.settingsUrl) && (
							<a
								className={ _appItemActionClasses('config') }
								href={ app.settingsUrl }
								rel="noopener external nofollow noreferrer"
								target="_blank"
							>
								{ $.__('Settings') }
							</a>
						) }
						{ app.infoUrl && (
							<a
								className={ _appItemActionClasses('learnMore') }
								href={ app.infoUrl }
								rel="noopener noreferrer"
								target="_blank"
							>
								{ $.__('Learn more') }
							</a>
						) }
					</div>
					)
				) }
			</div>
		</div>
	);
};

AppItem.displayName = 'AppItem';

export default AppItem;

function _appItemActionClasses(type): string {
	const mods = [type];

	if (type === 'learnMore') {
		mods.push('^fab-TextButton');
	} else {
		mods.push('^fab-Button', '^fab-Button--secondary', '^fab-Button--outline');
	}

	return bem('appItemAction', mods);
}
