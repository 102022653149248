import { Component } from 'react';
import { cloneDeep } from 'lodash';
import { Flex, Link, Section, LayoutBox, PageHeader } from '@bamboohr/fabric';
import { Button } from '@fabric/button';
import { redirect } from 'BambooHR.util';
import { PageFooter } from 'page-footer.react';
import { canUseLocalStorage } from '@utils/localstorage';
import { PageLoader } from '../../../../company-information.react/components/page-loader/page-loader';

import { BankAccountForm } from '../../../components/bank-account-form';

import {
	getCompanyBankData,
	postCompanyBankData,
	putCompanyBankData,
	putCompanyBankNickname,
	getUpdateMessage,
	BANK_SUBMISSION_MESSAGE_KEY,
	BANK_SUBMISSION_CREATE_MESSAGE,
	BANK_SUBMISSION_UPDATE_MESSAGE,
	BANK_SUBMISSION_MESSAGE,
	handlePlaidUpdateSuccess,
} from '../../../utils/shared';

const MISSING_INFO = $.__(
	'Whoops... No worries. Please fix any missing or incorrect information and try again.',
);
const SOMETHING_WRONG = $.__('Uh oh...something went wrong. Please try again.');

export class AddEditBankAccount extends Component {
	constructor(props) {
		super(props);
		const { isEdit } = props;

		this.state = {
			isLoading: isEdit,
			accountNickname: '',
			accountNumber: '',
			routingNumber: '',
			accountType: 'Checking',
			formAchAuthorization: undefined,
			bankLogo: '',
			bankName: '',
			isVerified: false,
			isLegacy: false,
			errorBag: {
				accountNickname: false,
				accountNumber: false,
				routingNumber: false,
				accountType: false,
			},
			showAwayModal: false,
			footerProcessing: false,
			footerEnabled: false,
		};
	}

	_handleInputChange = (companyEin, fieldName, value) => {
		this.setState((prevState) => {
			prevState[fieldName] = value;
			prevState.errorBag[fieldName] = false;

			return prevState;
		});
	};

	_handleFormCancel = (e) => {
		e.preventDefault();
		e.stopPropagation();

		this.setState({
			showAwayModal: true,
		});
	};

	_handleFormSubmit = () => {
		// Start footer button processing
		this._toggleFooterProcessing(true);

		if (!this._validateForm()) {
			window.setMessage(MISSING_INFO, 'error');
			this._toggleFooterProcessing(false);

			return;
		}

		const { clientId, bankId, isEdit } = this.props;

		const { isLegacy, isVerified } = this.state;

		const postData = cloneDeep(this.state);
		let callToMake = postCompanyBankData;

		if (isEdit) {
			postData.bankId = bankId;

			callToMake = isVerified ? putCompanyBankNickname : putCompanyBankData;
		}

		callToMake(clientId, postData)
			.then((response) => {
				const { data } = response;

				if ((!isEdit && data.success) || (isEdit && data.bankId)) {
					window.location = '/settings/payroll/bank_account/';

					if (isEdit) {
						this._setSessionMessage(
							getUpdateMessage(isLegacy || isVerified),
							'success',
						);
					} else {
						this._setSessionMessage(BANK_SUBMISSION_CREATE_MESSAGE);
					}
				} else {
					window.setMessage(SOMETHING_WRONG, 'error');
					this._toggleFooterProcessing(false);
				}
			})
			.catch(() => {
				window.setMessage(SOMETHING_WRONG, 'error');
				this._toggleFooterProcessing(false);
			});
	};

	_toggleFooterEnabled = (isEnabled) =>
		this.setState({ footerEnabled: isEnabled });

	_toggleFooterProcessing = (isProcessing) =>
		this.setState({ footerProcessing: isProcessing });

	_validateForm = () => {
		const { accountNickname, accountNumber, routingNumber, accountType } =
			this.state;

		const newErrorBag = {
			accountNickname: !accountNickname,
			accountNumber: !accountNumber,
			routingNumber: !routingNumber,
			accountType: !accountType,
		};

		this.setState({ errorBag: newErrorBag });

		return (
			!!accountNickname && !!accountNumber && !!routingNumber && !!accountType
		);
	};

	_setSessionMessage = (message) => {
		if (canUseLocalStorage) {
			localStorage.setItem(BANK_SUBMISSION_MESSAGE_KEY, 'true');
			localStorage.setItem(BANK_SUBMISSION_MESSAGE, message);
		}
	};

	_handlePlaidUpdateSuccess = (token, metadata) => {
		const { bankId, clientId } = this.props;
		this.setState({ footerProcessing: true }, () => {
			handlePlaidUpdateSuccess(token, metadata, { bankId, clientId }, () => {
				redirect(
					'/settings/payroll/bank_account',
					BANK_SUBMISSION_UPDATE_MESSAGE,
					'success',
				);
			}).finally(() => {
				this._toggleFooterProcessing(false);
			});
		});
	};

	_renderFooterContent = () => {
		const { footerProcessing, footerEnabled } = this.state;

		return (
			<Flex gap={2} alignItems="center">
				<Button
					onClick={this._handleFormSubmit}
					disabled={!footerEnabled}
					processing={footerProcessing}
				>
					{$.__('Save')}
				</Button>
				{footerEnabled ? (
					<Link
						size={'medium'}
						href="/settings/payroll/bank_account"
						onClick={this._handleFormCancel}
					>
						{$.__('Cancel')}
					</Link>
				) : null}
			</Flex>
		);
	};

	componentDidMount() {
		const { clientId, bankId, isEdit } = this.props;

		if (isEdit) {
			getCompanyBankData(clientId, bankId)
				.then((response) => {
					const {
						data: {
							accountNickname,
							accountNumber,
							accountType,
							formAchAuthorization,
							routingNumber,
							isVerified,
							isLegacy,
							bankLogo,
							bankName,
						},
					} = response;

					this.setState({
						isLoading: false,
						accountNickname,
						accountNumber,
						accountType,
						formAchAuthorization,
						routingNumber,
						isVerified,
						isLegacy,
						bankLogo,
						bankName,
					});
				})
				.catch(() => {
					window.setMessage(SOMETHING_WRONG, 'error');
					window.location = '/settings/payroll/bank_account/';
				});
		}
	}

	render() {
		const {
			isLoading,
			accountNickname,
			accountType,
			routingNumber,
			accountNumber,
			errorBag,
			formAchAuthorization,
			isVerified,
			isLegacy,
			bankLogo,
			bankName,
			showAwayModal,
		} = this.state;
		const { clientId, plaidLinkToken, isEdit } = this.props;

		const bankForm = (
			<BankAccountForm
				bankAccount={{
					accountNickname,
					accountType,
					routingNumber,
					accountNumber,
					formAchAuthorization,
					bankLogo,
					bankName,
				}}
				company={{
					clientId,
					ein: clientId,
				}}
				customErrorBag={errorBag}
				plaidLinkToken={plaidLinkToken}
				handlePlaidUpdateSuccess={this._handlePlaidUpdateSuccess}
				hideSubmitButton={true}
				isEdit={isEdit}
				isVerified={isVerified}
				onInputChange={this._handleInputChange}
				onSubmit={this._handleFormSubmit}
				setFooterEnabled={this._toggleFooterEnabled}
				showAwayModal={showAwayModal}
				showCompletedFormsSection={!isLegacy}
				topSpacing={true}
				useCustomErrorBag={true}
			/>
		);

		return (
			<>
				<PageHeader
					back={$.__('Bank Accounts')}
					divider={false}
					onBack={() => redirect('/settings/payroll/bank_account')}
					title={isEdit ? $.__('Edit Bank Account') : $.__('Add Bank Account')}
				/>
				{isLoading ? (
					<PageLoader />
				) : (
					<LayoutBox marginTop={2}>
						<Section>{bankForm}</Section>
					</LayoutBox>
				)}
				<PageFooter left={this._renderFooterContent()} />
			</>
		);
	}
}
