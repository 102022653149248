import { Select } from '@fabric/select';
import { IconButton, Label, LayoutBox } from '@bamboohr/fabric';
import { classNameFromObject } from '@utils/dom';


import './styles.styl';

export function TaskRow(props) {
	const {
		billable,
		hasError,
		index,
		name,
		onBillableChange,
		onNameChange,
		removeTask,
		renderDeleteAction,
		renderLabel
	} = props;

	const billableProps = {
		isClearable: false,
		items: [
			{
				text: $.__('Billable'),
				value: true
			},
			{
				text: $.__('Non Billable'),
				value: false
			}
		],
		selectedValues: [
			billable
		],
		onSelect: value => onBillableChange(index, value),
		width: 6
	};

	return (
		<div className="TaskRow fab-FormRow">
			<div className="fab-FormColumn">
				{renderLabel && (
					<LayoutBox marginBottom={0.5}>
						<Label htmlFor='task-name'>{ $.__('Add Tasks') }</Label>
					</LayoutBox>
				)}
				<div className="fab-InputWrapper">
					<input
						autoFocus={ true }
						className={ classNameFromObject({ 'fab-TextInput': true, 'fab-TextInput--width8': true, 'fab-TextInput--error': hasError }) }
						maxLength="50"
						name="task-name"
						onBlur={ () => { if (hasError) { window.setMessage($.__('Whoops! You already have a task by that name.'), 'error'); } } }
						onChange={ event => onNameChange(index, event) }
						type="text"
						value={ name }
					/>
				</div>
			</div>
			<div className="fab-FormColumn">
				{renderLabel && (
					<LayoutBox marginBottom={0.5}>
						<Label htmlFor="billable-select">{ $.__('Time logged to this task is...') }</Label>
					</LayoutBox>
				)}
				<Select { ...billableProps } />
			</div>
			{ renderDeleteAction && (
				<div className={ classNameFromObject({ 'TaskRow__deleteAction': true, 'TaskRow__deleteAction--hasLabel': renderLabel }) }>
					<IconButton
						ariaLabel={ $.__('Remove Task %1$s', index) }
						color='secondary'
						floatingIcon={true}
						icon="xmark-solid"
						noBoundingBox={true}
						onClick={() => removeTask(index)}
						type='button'
					/>
				</div>
			) }
		</div>
	);
}
