import {
	Table as FabricTable,
	IconV2,
	Flex,
	TableColumn,
	Link,
} from '@bamboohr/fabric';
import moment from 'moment';
import { Fragment } from 'react';
import disableModal from 'time-tracking/modals/disable-employee';
import enableTimeTrackingModal from 'time-tracking/modals/enable-time-tracking';
import ReactTooltip from 'tooltip.react';
import { isEnabled } from '@bamboohr/utils/lib/feature';
import './styles.styl';
import { TimeTrackingEmployee } from './types';

const tooltips = {
	noLocation: $.__('This employee has not been assigned to a location'),
	exempt: $.__('Overtime is not calculated for exempt employees'),
	noState: $.__(
		'Overtime cannot be calculated without a U.S. state or Canadian Province selected',
	),
	international: $.__(
		'Overtime cannot be calculated for addresses outside the U.S. or Canada',
	),
};

export interface TableProps {
	canToggleTimeTracking: boolean;
	settingsMode: 'global' | 'group';
	customOvertimeEnabled: boolean;
	employees: TimeTrackingEmployee[];
	reloadEmployees: () => void;
	clockInIdEnabled?: boolean;
}

export function Table({
	canToggleTimeTracking,
	settingsMode,
	customOvertimeEnabled,
	employees,
	reloadEmployees,
	clockInIdEnabled,
}: TableProps) {
	const disableTimeTrackingForEmployee = (employeeId) => {
		const record = employees.find((record) => record.employeeId === employeeId);
		disableModal.openModal(
			record.employeeId,
			record.preferredName,
			record.lastName,
			reloadEmployees,
		);
	};

	const changeSingleSchedule = (employeeId) => {
		const record = employees.find((record) => record.employeeId === employeeId);
		const { employeeId: id, startDate, preferredName } = record;

		const onSave = (request, startDate: string) => {
			if (startDate === 'immediately') {
				setMessage(
					$.__(
						'Alright, %1$s can now track time worked and access the timesheet.',
						preferredName,
					),
					'success',
				);
			} else {
				const formattedDate = moment(startDate).format('MMMM D, YYYY');
				setMessage(
					$.__(
						'Alright, %1$s is scheduled to start tracking time on %2$s.',
						preferredName,
						formattedDate,
					),
					'success',
				);
			}
			reloadEmployees();
		};

		enableTimeTrackingModal(
			{ id, startDate, preferredName },
			onSave,
			window.errorFallBack,
			false,
		);
	};

	const makeColumns = () => {
		const columns: TableColumn<TimeTrackingEmployee>[] = [
			{
				header: $.__('Name'),
				cell: (employee) => {
					return (
						<Fragment>
							<ConditionalWrap
								condition={employee.canSeeEmployee}
								wrap={(children) => (
									<Link
										href={`/employees/employee.php?id=${employee.employeeId}`}
									>
										{children}
									</Link>
								)}
							>
								{employee.displayName}
							</ConditionalWrap>
							{employee.hasFutureStartDate && (
								<div className="STE_EmpTable__startDate">
									{employee.startsOnString}
								</div>
							)}
						</Fragment>
					);
				},
				sortBy: (e) => e.displayName,
			},
			{
				header: settingsMode === 'group' ? $.__('Group') : $.__('Location'),
				cell: (employee) => {
					if (settingsMode === 'group') {
						return employee.configurationName;
					}
					if (employee.hasLocation) {
						return employee.location;
					}
					const tdContent = $.__('No Location');
					return (
						<ReactTooltip
							settings={{
								content: tooltips.noLocation,
							}}
						>
							<Flex alignContent="baseline" gap={1}>
								<IconV2
									color="warning-medium"
									name="triangle-exclamation-regular"
									size={20}
								/>
								<span>{tdContent}</span>
							</Flex>
						</ReactTooltip>
					);
				},
				sortBy: (e) => e.location,
			},
			{
				header: $.__('Overtime State'),
				cell: (employee) => {
					if (employee.isOvertimeExempt) {
						const tdContent = $.__('Exempt');
						return (
							<ReactTooltip settings={{ content: tooltips.exempt }}>
								<span>{tdContent}</span>
							</ReactTooltip>
						);
					} else if (customOvertimeEnabled) {
						return $.__('Custom');
					} else if (employee.hasLocation) {
						if (
							!employee.overtimeState &&
							employee.inOvertimeCountry === true
						) {
							const tdContent =
								employee.overtimeCountryISO === 'US'
									? $.__('No State')
									: $.__('No Province');
							return (
								<ReactTooltip settings={{ content: tooltips.noState }}>
									<Flex alignContent="baseline" gap={1}>
										<IconV2
											color="warning-medium"
											name="triangle-exclamation-regular"
											size={20}
										/>
										<span>{tdContent}</span>
									</Flex>
								</ReactTooltip>
							);
						} else if (
							!employee.overtimeState &&
							employee.inOvertimeCountry === false
						) {
							return (
								<ReactTooltip settings={{ content: tooltips.international }}>
									<span className="STE_EmpTable__data--gray">
										{$.__('Non U.S. or Canada')}
									</span>
								</ReactTooltip>
							);
						}

						return employee.overtimeState;
					}
				},
				sortBy: (e) => e.overtimeState,
			},
		];

		columns.push({
			header: $.__('Pay Schedule'),
			cell: (employee) => {
				if (employee.hasPaySchedule) {
					return employee.paySchedule;
				}
				const tdContent = $.__('No Pay Schedule');
				return (
					<Flex alignContent="baseline" gap={1}>
						<IconV2
							color="warning-medium"
							name="triangle-exclamation-regular"
							size={20}
						/>
						<span>{tdContent}</span>
					</Flex>
				);
			},
			sortBy: (e) => e.paySchedule,
		});

		// @startCleanup CLOCK_IN_ID
		if (isEnabled('CLOCK_IN_ID')) {
			// @endCleanup CLOCK_IN_ID
			if (clockInIdEnabled) {
				columns.push({
					header: $.__('Clock In ID'),
					cell: (employee) => {
						return employee.clockInId;
					},
					sortBy: (e) => e.clockInId,
				});
			}
			// @startCleanup CLOCK_IN_ID
		}
		// @endCleanup CLOCK_IN_ID

		if (canToggleTimeTracking) {
			columns.push({
				showOnHover: true,
				headerAriaLabel: $.__('Actions'),
				cell: {
					type: 'actions',
					actions: (employee) => {
						const actions = [
							{
								icon: 'trash-can-regular',
								action: () =>
									disableTimeTrackingForEmployee(employee.employeeId),
								tooltipContent: $.__('Disable Time Tracking'),
							},
						];
						if (employee.hasFutureStartDate) {
							actions.unshift({
								icon: 'calendar-days-regular',
								action: () => changeSingleSchedule(employee.employeeId),
								tooltipContent: $.__('Change scheduled start date'),
							});
						}
						return actions;
					},
				},
			});
		}

		return columns;
	};

	return (
		<FabricTable
			caption="Employees tracking time"
			columns={makeColumns()}
			initialSort={{ isAsc: true, columnIndex: 0 }}
			rowKey={(row) => (row as TimeTrackingEmployee).employeeId}
			rows={employees}
		/>
	);
}

function ConditionalWrap({
	condition,
	wrap,
	children,
}: {
	condition: boolean;
	wrap: (children: React.ReactNode) => React.ReactElement;
	children: React.ReactNode;
}) {
	return condition ? wrap(children) : <>{children}</>;
}
