import {
	Fragment,
	useMemo,
} from 'react';

import { BodyText, Label, LayoutBox } from '@bamboohr/fabric';

import {
	useReducerContext,
} from '../state';
import { HeadlineWrapper } from '../headline-wrapper';

export function CompanyInfoFieldset(props) {
	const {
		companyNameField,
		useForm,
		FormInput,
		FormSelect,
	} = props;

	const { errors, values: {
		contactPhone,
		ein,
		nameOfEmployer,
	}} = useForm();

	return (
		<Fragment>
			<HeadlineWrapper
				headlineText={ $.__('Enter Company Information') }
				subHeadlineText={ $.__('This information will be used to fill part 1 of your 1095-C forms and can be edited under ACA Settings.') }
			/>
			<LayoutBox display='flex' marginBottom={2}>
				<LayoutBox>
					<Input
						afterLabel={
							<BodyText
								color="neutral-weak"
								component="span"
								id="company-name-sublabel"
								size="extra-small"
								weight="regular"
							>
								({$.__('Legal Full Name')})
							</BodyText>
						}
						FormInput={FormInput}
						aria-label="company_name"
						error={errors[companyNameField] && !nameOfEmployer}
						label={$.__('Company Name')}
						name={ companyNameField }
						required={ true }
						width={ 8 }
					/>
				</LayoutBox>
				<LayoutBox marginLeft={2}>
					<Input
						FormInput={ FormInput }
						aria-label="employer_identification_number"
						error={errors.ein && !ein}
						label={ $.__('Employer Identification #') }
						name="ein"
						required={ true }
						width={ 8 }
					/>
				</LayoutBox>
			</LayoutBox>
			<FormRow>
				<Input
					FormInput={ FormInput }
					aria-label="contact_phone"
					error={errors.contactPhone && !contactPhone}
					label={ $.__('Contact Phone #') }
					name="contactPhone"
					required={ true }
					width={ 8 }
				/>
			</FormRow>
			<Address
				FormInput={ FormInput }
				FormSelect={ FormSelect }
				useForm={ useForm }
			/>
		</Fragment>
	);
}

/** @type {React.FunctionComponent<{ tight: boolean }>} */
function FormRow({
	children,
	tight = false,
}) {
	const classes = {
		marginBottom: 2,
	};

	if (tight) {
		classes.marginBottom = 1;
	}

	return (
		<LayoutBox {...classes}>
			<LayoutBox>
				{ children }
			</LayoutBox>
		</LayoutBox>
	);
}

/** @type {React.FunctionComponent} */
function FormField({ children, ...props }) {
	return (
		<LayoutBox display='inline-block' { ...props }>
			{ children }
		</LayoutBox>
	);
}


/** @type {React.FunctionComponent} */
function InputWrapper({
	children,
}) {
	return (
		<LayoutBox>
			{ children }
		</LayoutBox>
	);
}

/** @type {React.FunctionComponent<{ label: string|JSX.Element, name: string }>} */
function Input({
	FormInput,
	error,
	label,
	name,
	children,
	placeholder,
	...props
}) {
	return (
		<Fragment>
			{ label && (
			<LayoutBox component='div' marginBottom={0.25}>
				<Label
					{ ...props }
					component="span"
					status={error ? 'error' : undefined}
				>
					{label}
				</Label>
			</LayoutBox>
			) }
			<InputWrapper>
				{ children || (
					<FormInput
						name={ name }
						placeholder={ placeholder }
						{ ...props }
					/>
				) }
			</InputWrapper>
		</Fragment>
	);
}


/** @type {React.FunctionComponent} */
function Address(props) {
	const {
		FormInput,
		FormSelect,
		useForm,
	} = props;
	const {
		companyInfo: {
			countries,
			states,
		},
	} = useReducerContext();
	const {
		values: {
			city,
			countryId,
			employerAddress,
			stateId,
			zipCode,
		},
		errors,
	} = useForm();

	const tmpStates = useMemo(() => Object.values(states)
		.filter(state => state.countryId === countryId)
		.map(({ id, abbrev }) => ({
			value: id,
			text: abbrev,
		})), [countryId, states]);

	const tmpCountries = useMemo(() => Object.values(countries)
		.map(({ id, name }) => ({
			value: id,
			text: name,
		})), [countries]);

	return (
		<Fragment>
			<FormRow tight={ true }>
				<Input
					afterLabel={
						<BodyText
							color="neutral-weak"
							component="span"
							id="address-sublabel"
							size="extra-small"
							weight="regular"
						>
							({$.__('Primary Mailing/Filing')})
						</BodyText>
					}
					FormInput={FormInput}
					aria-label="company_address"
					error={errors.employerAddress && !employerAddress}
					label={$.__('Company Address')}
					name="employerAddress"
					placeholder={ $.__('Street Address') }
					required={ true }
					width={ 8 }
				/>
			</FormRow>
			<FormRow tight={ true }>
				<FormField key="city">
					<Input
						FormInput={ FormInput }
						aria-label="city"
						error={errors.city && !city}
						name="city"
						placeholder={ $.__('City') }
						required={ true }
						width={ 5 }
					/>
				</FormField>
				<FormField key="state" marginLeft={1}>
					{ tmpStates.length > 0 ? (
						<FormSelect
							isClearable={ false }
							items={ tmpStates }
							name="stateId"
							required={ true }
							width={ 3 }
						/>
					) : (
						<Input
							FormInput={ FormInput }
							aria-label="state"
							error={errors.stateId && !stateId}
							name="stateId"
							placeholder={ $.__('State') }
							required={ true }
							width={ 3 }
						/>
					) }
				</FormField>
				<FormField key="zip" marginLeft={1}>
					<Input
						FormInput={ FormInput }
						aria-label="zip_code"
						error={errors.zipCode && !zipCode}
						name="zipCode"
						placeholder={ $.__('ZIP Code') }
						required={ true }
						width={ 3 }
					/>
				</FormField>
			</FormRow>
			<FormRow tight={ true }>
				<FormSelect
					isClearable={ false }
					items={ tmpCountries }
					name="countryId"
					required={ true }
					width={ 8 }
				/>
			</FormRow>
		</Fragment>
	);
}
