import { uniqueId } from 'lodash';

import Ajax from '@utils/ajax';

import { toQueryString } from '@utils/url';
import { showSlidedown } from 'Alerts.mod';

import { PreEinStateComponent } from '../states/single-ein/1-pre-ein';
import { CompanySavedStateComponent } from '../states/single-ein/2-company-saved';
import { DocsDownloadedStateComponent } from '../states/single-ein/3-docs-downloaded';
import { DocsUploadedStateComponent } from '../states/single-ein/4-docs-uploaded';
import { VerifiedStateComponent } from '../states/single-ein/5-verified';
import { CompaniesTableStateComponent } from '../states/multi-ein/1-companies-table';
import { CompanyFormStateComponent } from '../states/multi-ein/2-company-form';
import { BodyText, Flex, TextButton } from '@bamboohr/fabric';
import { ifFeature, isEnabled } from '@bamboohr/utils/lib/feature';

export const isPayrollAccessEnabled = isEnabled('COMPANY_SETUP_PHASE_ONE');

export const getFileDownload = (type, clientId, downloadAttr) => {
	let a = document.createElement('a');
	if (downloadAttr) {
		a.setAttribute('download', type);
	}
	a.href = `/payroll/documents/prefilled_client_doc/${type}/${clientId}`;
	a.click();
};

export const getCompanyInformation = () => {
	return Ajax.get('/settings/payroll/company_information/data');
};

export const getCompanyInformationMulti = (clientId) => {
	return Ajax.get(
		`/settings/payroll/company_information/multi_ein/${clientId}`,
	);
};

export const putSaveCompanyInformation = (data) => {
	const payload = getPayloadObject(data);

	return Ajax.put(
		`/settings/payroll/company_information/${data.clientId}`,
		payload,
	);
};

export const postSaveCompanyInformation = (data, recordId = null) => {
	const payload = getPayloadObject(data, recordId);

	return Ajax.post('/settings/payroll/company_information', payload);
};

export const putUnlockCompanyInformation = (payload, clientId) => {
	return Ajax.put(
		`/settings/payroll/company_information/company_legal_name_locked/${clientId}`,
		payload,
	);
};

const NUMBER_REGEX = /[0-9]/;

/**
 * Ensure that the work phone number contains exactly 10 digits
 * @param workPhone {string} The work phone number to be submitted
 * @return {boolean}
 */
export const isValidPhoneNumber = (workPhone) => {
	if (typeof workPhone === 'string' && workPhone.length >= 10) {
		const numbersArray = workPhone
			.split('')
			.filter((char) => NUMBER_REGEX.test(char));
		return numbersArray.length === 10;
	}
	return false;
};

export const showBadPhoneMsg = () => showSlidedown(BAD_PHONE, 'error');

export const isInvalidAddress = (street1) => {
	const emailRegex =
		/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
	const poBoxRegex = /P(ost|ostal)?([ \.]*(O)(ffice)?)?([ \.]*Box)/i;

	const testCases = [emailRegex, poBoxRegex];
	return testCases.some((regex) => regex.test(street1));
};

export const stripEin = (fullEin) => {
	return fullEin.replace('-', '');
};

export const scrollToTopOfPage = () => {
	$('html, body').animate({ scrollTop: 0 });
};

export const getPayloadObject = (data, recordId) => {
	return {
		ein: data.ein ? stripEin(data.ein) : null,
		companyLegalName: data.companyLegalName ? data.companyLegalName : null,
		dbaName: data.dbaName ? data.dbaName : null,
		street1: data.street1 ? data.street1 : null,
		street2: data.street2 ? data.street2 : null,
		city: data.city ? data.city : null,
		state: data.state ? data.state : null,
		zip: data.zip ? data.zip : null,
		selectedContactId: data.selectedContactId ? data.selectedContactId : null,
		workEmail: data.workEmail ? data.workEmail : null,
		workPhone: data.workPhone ? data.workPhone : null,
		workExtension: data.workExtension ? data.workExtension : null,
		recordId: recordId ? recordId : null,
		website: data.website ? data.website : null,
		phoneNumber: data.phoneNumber ? data.phoneNumber : null,
		businessType: data.businessType ? data.businessType : null,
		revokePreviousPayrollClientPermission:
			data.revokePreviousPayrollClientPermission ?? undefined,
	};
};

export const getStatesList = () => {
	return Ajax.get('/ajax/get_states?country=1');
};

export const MULTI_EIN_TABLE_URL = '/settings/payroll/company_information/?';
export const MULTI_EIN_PAGE_URL =
	'/settings/payroll/company_information/multi_ein?';

export const COMPANY_SUBMISSION_MESSAGE_KEY =
	'X-BAMBOOHR-PAYROLL-COMPANY-APPLICATION-SHOW-MESSAGE';
export const COMPANY_SUBMISSION_MESSAGE =
	'X-BAMBOOHR-PAYROLL-COMPANY-APPLICATION-MESSAGE';
export const COMPANY_SUBMISSION_CREATE_MESSAGE =
	'Success! Please download and complete your company payroll forms below.';
export const COMPANY_SUBMISSION_UPDATE_MESSAGE =
	'Company info changes saved! You need to re-download your forms and sign again.';

export const BAD_EIN = $.__('EIN is not valid.');
export const BAD_ZIP = $.__(
	'Whoops..no worries. Please make sure your zip code is valid and only 5 digits.',
);
export const BAD_PHONE = $.__(
	'Whoops...no worries. Please make sure the phone numbers below have 10 digits.',
);

export const MISSING_INFO = $.__(
	'Whoops... No worries. Please fix any missing or incorrect information and try again.',
);
/**
 * @param {string} ein
 * @return {bool}
 */
export const validateEinNumber = (ein) =>
	Ajax.get(`/settings/payroll/ajax/company_information/validate_ein/${ein}`);

export const formatStatesListForSelect = (statesArray) => {
	return statesArray.map((state) => {
		return {
			text: state.name,
			value: state.id,
		};
	});
};

export const FILE_STATUSES = {
	DOWNLOAD_REQUIRED: 'downloadRequired',
	DOWNLOADED: 'downloaded',
	UPLOADED: 'uploaded',
};

export const COMPANY_FILES = {
	FORM_8655: 'form8655',
	FORM_8821: 'form8821',
	BENEFICIAL_OWNER: 'beneficialOwnerAddendum',
};

export const COMPANY_FORM_FIELDS = {
	LEGAL_NAME: 'companyLegalName',
	EIN: 'ein',
	DBA_NAME: 'dbaName',
	STREET_1: 'street1',
	STREET_2: 'street2',
	CITY: 'city',
	STATE: 'state',
	ZIP: 'zip',
	CONTACT_NAME: 'contactName',
	WORK_EMAIL: 'workEmail',
	WORK_PHONE: 'workPhone',
	WORK_EXT: 'workExtension',
	PHONE_NUMBER: 'phoneNumber',
	WEBSITE: 'website',
	BUSINESS_TYPE: 'businessType',
};

export const companyFiles = [
	{
		id: 'form8821',
		name: 'IRS Form 8821',
		type: COMPANY_FILES.FORM_8821,
	},
	{
		id: 'form8655',
		name: 'IRS Form 8655',
		type: COMPANY_FILES.FORM_8655,
	},
	{
		id: 'beneficialOwnerAddendum',
		name: 'Beneficial Owner(s) Addendum',
		type: COMPANY_FILES.BENEFICIAL_OWNER,
	},
];

export const getFilesDownloadList = (files, clientId) => (
	<>
		<BodyText>
			{$.__('You can redownload your files below if needed:')}
		</BodyText>
		<Flex flexDirection="column" alignItems="flex-start" marginTop={1}>
			{files.map((file) => (
				<TextButton
					key={file.id}
					onClick={() => {
						getFileDownload(file.type, clientId);
					}}
					inline
					type="button"
				>{`${file.name}.pdf`}</TextButton>
			))}
		</Flex>
	</>
);

export const takeToBankAccountSection = () => {
	window.location = '/settings/payroll/bank_account';
};

export const getMultiEinAddCompanyPageUrl = (recordId) => {
	let params = toQueryString({ recordId });

	return `${MULTI_EIN_PAGE_URL}${params}`;
};

export const getMultiEinEditCompanyPageUrl = (clientId) => {
	let params = toQueryString({ clientId });

	return `${MULTI_EIN_PAGE_URL}${params}`;
};

export const postUploadFiles = (clientId, files) => {
	const payload = {
		uploadedFiles: files,
	};

	return Ajax.post(
		`/payroll/documents/upload_payroll_doc/${clientId}`,
		payload,
	);
};

/* @startCleanup files_api_v2_enabled */
// this does not seem to be used anywhere
export const uploadTempFiles = (file) => {
	if (ifFeature('files_api_v2_enabled', true)) {
		throw Error('This function should not be called when the files_api_v2_enabled feature is enabled');
	}
	const fd = new FormData();
	fd.append('upload', file);

	return Ajax.post('/ajax/files/attachTemporary.php', fd);
};
/* @endCleanup files_api_v2_enabled */

export const reloadCurrentPage = () => {
	window.location.reload();
};

export const uuidGenerator = () => {
	return `${new Date().getTime()}-${uniqueId()}`; // getTime() is not unique enough if this function is invoked consecutively fast enough.
};

export const formatContactsListForSelect = (contactsArray) => {
	return contactsArray.map((contact) => {
		return {
			text: contact.contactName,
			value: contact.userId,
			workEmail: contact.workEmail,
			workPhone: contact.workPhone,
		};
	});
};

export const getStateAbbreviationFromId = (stateId, statesArray) => {
	return statesArray.filter((state) => state.id == stateId)[0].name;
};

export const mapStateAbbreviationToId = (stateAbbreviation, statesArray) => {
	return statesArray.filter((state) => state.name === stateAbbreviation)[0]?.id;
};

export const mapContactToSelectedId = (contactId, contacts) => {
	const selectedContact = contacts.filter(
		(contact) => contact.userId == contactId,
	);

	return selectedContact.length ? selectedContact[0].userId : null;
};

export const isEinEnabled = (appState) => {
	return (
		appState === COMPANY_APP_STATE.PRE_EIN ||
		appState === COMPANY_APP_STATE.COMPANY_SAVED ||
		appState === COMPANY_APP_STATE.DOCS_DOWNLOADED
	);
};

export const getAppStateFromData = (data) => {
	const files = [
		data.form8821.fileStatus,
		data.form8655.fileStatus,
		data.beneficialOwnerAddendum.fileStatus,
	];
	const statusArray = fileStatusArray(...files);

	const setArray = new Set(statusArray);

	// pre-ein
	// clientId == null
	if (!data || data.clientId === null) {
		return COMPANY_APP_STATE.PRE_EIN;
	}

	// company-saved
	// clientId != null, status == Pending, all not downloaded
	if (
		data.clientId !== null &&
		data.status === 'Pending' &&
		setArray.has(FILE_STATUSES.DOWNLOAD_REQUIRED) &&
		!setArray.has(FILE_STATUSES.UPLOADED)
	) {
		return COMPANY_APP_STATE.COMPANY_SAVED;
	}

	// docs-downloaded, all forms downloaded == true
	if (
		data.clientId !== null &&
		data.status === 'Pending' &&
		setArray.has(FILE_STATUSES.DOWNLOADED) &&
		!setArray.has(FILE_STATUSES.DOWNLOAD_REQUIRED)
	) {
		return COMPANY_APP_STATE.DOCS_DOWNLOADED;
	}

	// docs-downloaded, with files removed
	if (
		data.clientId !== null &&
		data.status === 'Pending' &&
		setArray.has(FILE_STATUSES.UPLOADED) &&
		setArray.has(FILE_STATUSES.DOWNLOAD_REQUIRED)
	) {
		return COMPANY_APP_STATE.DOCS_DOWNLOADED;
	}

	// docs-uploaded, all forms uploaded == true
	if (
		data.clientId !== null &&
		data.status === 'Pending' &&
		data.hasBank === false &&
		setArray.has(FILE_STATUSES.UPLOADED) &&
		!setArray.has(FILE_STATUSES.DOWNLOAD_REQUIRED) &&
		!setArray.has(FILE_STATUSES.DOWNLOADED)
	) {
		return COMPANY_APP_STATE.DOCS_UPLOADED;
	}

	// docs-uploaded, status pending and has bank
	if (data.status === 'Pending' && data.hasBank === true) {
		return COMPANY_APP_STATE.DOCS_UPLOADED;
	}

	// verified, with bank or no bank
	if (data.status === 'Verified') {
		return COMPANY_APP_STATE.VERIFIED;
	}
};

export const fileStatusArray = (...args) => [...args];

export const statusCardNeeded = (statuses) => {
	if (statuses.length == 1) {
		if (statuses[0].state) {
			return (
				statuses[0].state !== COMPANY_APP_STATE.PRE_EIN &&
				statuses[0].state !== COMPANY_APP_STATE.VERIFIED
			);
		}

		return (
			status !== COMPANY_APP_STATE.PRE_EIN ||
			status !== COMPANY_APP_STATE.VERIFIED
		);
	}

	return statuses.some((status) => {
		return (
			(status.state || status) !== COMPANY_APP_STATE.PRE_EIN &&
			(status.state || status) !== COMPANY_APP_STATE.VERIFIED
		);
	});
};

// Possible App State list
export const COMPANY_APP_STATE = {
	PRE_EIN: 'pre_ein',
	COMPANY_SAVED: 'company_saved',
	DOCS_DOWNLOADED: 'docs_downloaded',
	DOCS_UPLOADED: 'docs_uploaded',
	VERIFIED: 'verified',

	MULTI_COMPANIES_TABLE: 'multi_companies_table',
	MULTI_COMPANY_FORM: 'multi_company_form',
};

// Condensed States into a single Const for use further down
const STATE_PRE_EIN = COMPANY_APP_STATE.PRE_EIN;
const STATE_COMPANY_SAVED = COMPANY_APP_STATE.COMPANY_SAVED;
const STATE_DOCS_DOWNLOADED = COMPANY_APP_STATE.DOCS_DOWNLOADED;
const STATE_DOCS_UPLOADED = COMPANY_APP_STATE.DOCS_UPLOADED;
const STATE_VERIFIED = COMPANY_APP_STATE.VERIFIED;

const STATE_MULTI_COMPANIES_TABLE = COMPANY_APP_STATE.MULTI_COMPANIES_TABLE;
const STATE_MULTI_COMPANY_FORM = COMPANY_APP_STATE.MULTI_COMPANY_FORM;

// List of Parent State Components that renders each state
export const COMPONENTS = {
	[STATE_PRE_EIN]: PreEinStateComponent,
	[STATE_COMPANY_SAVED]: CompanySavedStateComponent,
	[STATE_DOCS_DOWNLOADED]: DocsDownloadedStateComponent,
	[STATE_DOCS_UPLOADED]: DocsUploadedStateComponent,
	[STATE_VERIFIED]: VerifiedStateComponent,

	[STATE_MULTI_COMPANIES_TABLE]: CompaniesTableStateComponent,
	[STATE_MULTI_COMPANY_FORM]: CompanyFormStateComponent,
};

// Next state based on current
export const TRANSITIONS = {
	STATE_PRE_EIN: COMPANY_APP_STATE.COMPANY_SAVED,
	STATE_COMPANY_SAVED: COMPANY_APP_STATE.DOCS_DOWNLOADED,
	STATE_DOCS_DOWNLOADED: COMPANY_APP_STATE.DOCS_UPLOADED,
	STATE_DOCS_UPLOADED: COMPANY_APP_STATE.VERIFIED,
};

export const getNextState = (currentState) => {
	return currentState in TRANSITIONS
		? TRANSITIONS[currentState]
		: STATE_PRE_EIN;
};

export const determineRemainingFilesNeeded = (...args) => {
	return args.filter((file) => file !== FILE_STATUSES.UPLOADED).length;
};

export const getComponentForState = (currentState) => COMPONENTS[currentState];

export const nullCompanyObject = () => {
	return {
		// Company Info
		companyLegalName: null,
		dbaName: null,
		ein: null,
		phoneNumber: null,
		website: null,
		businessType: null,

		// State Related
		clientId: null, // If null, we dont show the header information. If has value and status == verified, dont show as well.
		status: 'Pending', // Pending or verified
		hasBank: false,

		// Files data
		form8821: {
			fileId: null, // can be null if not uploaded
			displayFileName: null,
			fileStatus: null,
			url: null,
		},
		form8655: {
			fileId: null, // can be null if not uploaded
			displayFileName: null,
			fileStatus: null,
			url: null,
		},
		beneficialOwnerAddendum: {
			fileId: null, // can be null if not uploaded
			displayFileName: null,
			fileStatus: null,
			url: null,
		},

		// Address Info,
		street1: null,
		street2: null,
		city: null,
		state: null,
		zip: null,

		// Represents the selected contact user
		selectedContactId: 0,
		contactName: null,
		workEmail: null,
		workPhone: null,
		workExtension: null,
	};
};

export const payrollIdExplanation = $.__(
	'This ID helps our support team quickly find your payroll info when you need help. ',
);

/**
 * This will trim a url to just be the domain name. The BE expects this format for company websites.
 * @param {string} url the url of the company website
 * @returns {string | undefined} the trimmed domain name of the url
 */
export const trimWebsiteURL = (url) => {
	try {
		return new URL(url.startsWith('http') ? url : `https://${url}`).hostname;
	} catch (error) {
		showSlidedown(
			$.__('Something went wrong due to an invalid URL. Please try again'),
			'error',
		);
	}
};
