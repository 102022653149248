import { makeStyles } from '@bamboohr/fabric';

interface StyleProps {
	isSmallScreen: boolean;
}

interface ComponentProps extends StyleProps {
	imageUrl: string;
}

const useStyles = makeStyles({
	questionImage: ({ isSmallScreen }: StyleProps) => ({
		height: isSmallScreen ? 100 : 124,
	}),
	questionImageContainer: ({ isSmallScreen }: StyleProps) => ({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: isSmallScreen ? 25 : 32,
	}),
});

export const QuestionImage: React.FC<React.PropsWithChildren<ComponentProps>> = ({ isSmallScreen, imageUrl }) => {
	const classes = useStyles({ isSmallScreen });
	return (
		<div className={classes.questionImageContainer}>
			<img alt={$.__('Image for question.')} className={classes.questionImage} src={imageUrl} />
		</div>
	);
};
