import { useEffect, useState } from 'react';
import { Headline, LayoutBox, Flex, TextButton } from '@bamboohr/fabric';
import { SadToGoBox, CancellationType } from 'in-app-cancellation.mod';
import Ajax from '@utils/ajax';

export function BankInfoHeader(props) {
	const { anotherEinLink } = props;
	const [cancellationData, setCancellationData] = useState({});
	const [cancellationPending, setCancellationPending] = useState(false);

	const getCancellationStatusAll = () => {
		Ajax.get('/settings/cancellation/status_all').then((response) => {
			if (response.data) {
				setCancellationData(
					response.data.filter(
						(item) =>
							item.feature === CancellationType.PAYROLL ||
							item.feature === CancellationType.ACCOUNT,
					),
				);
				setCancellationPending(
					response.data.find(
						(item) => item.feature === CancellationType.PAYROLL,
					).pending_cancellation,
				);
			}
		});
	};

	useEffect(() => {
		getCancellationStatusAll();
	}, []);

	const generateUrl = () => {
		return 'https://help.bamboohr.com/hc/en-us/articles/360036626833-Company-Bank-Account#how-do-i-change-my-company-bank-account';
	};

	const openFormstackTab = () => {
		window.open(generateUrl(), '_blank');
	};

	return (
		<>
			<Flex alignItems="center">
				<Headline size="small">{$.__('Bank Account')}</Headline>

				{anotherEinLink && (
					<LayoutBox flex={1} textAlign="right">
						<TextButton
							color="secondary"
							onClick={openFormstackTab}
							size="small"
							type="button"
						>
							{$.__('Need to change your bank account?')}
						</TextButton>
					</LayoutBox>
				)}
			</Flex>
			{cancellationPending && (
				<LayoutBox paddingTop={2.875}>
					<SadToGoBox
						cancellationData={cancellationData}
						updateCancellationData={getCancellationStatusAll}
						isAddon={true}
					/>
				</LayoutBox>
			)}
		</>
	);
}
