import {
	BodyText,
	Button,
	Divider,
	Dropdown,
	Flex,
	Headline,
	LayoutBox
} from '@bamboohr/fabric/';
import { ReactElement } from 'react';

import { Message } from '@bamboohr/utils/lib/message';

import './styles.styl';

interface MenuItem {
	note: string;
	text: string;
	value: string;
}

interface Props {
	firstTemplate: boolean;
	isBlankState: boolean;
}

export function TemplateListHeader(props: Props): ReactElement {
	const { firstTemplate } = props;
	const { isBlankState } = props;

	const header = <Message text={$._('New Hire Packet Templates')} />;

	const subheader = (
		<Message
			text={$._(
				'New Hire Packet Templates allow you to have specific packet setups for different offices, locations, or roles.',
			)}
		/>
	);

	function handleSelect(value: string) {
		window.location.href = value;
	}

	const creationLink = firstTemplate
		? '/settings/onboarding/new_hire_packet_template/addInitialTemplate'
		: '/settings/onboarding/new_hire_packet_template/add';

	return (
		<Flex flexDirection="column">
			<LayoutBox>
				<Headline color="primary" size="small">
					{header}
				</Headline>
				<BodyText size="small">{subheader}</BodyText>
				<Flex justifyContent="space-between" marginY={2.5}>
					<Button
						color="secondary"
						href={creationLink}
						size="medium"
						startIcon="circle-plus-regular"
					>
						{$.__('New Template')}
					</Button>
					<Dropdown
						ButtonProps={{
							color: 'secondary',
							icon: 'gear-regular',
							title: 'Settings',
							variant: 'outlined',
						}}
						items={[
							{
								text: (
									<Flex flexDirection="column" gap={1}>
										<BodyText color="neutral-extra-strong">
											{$.__('Edit “Get to Know You” Email')}
										</BodyText>
										<BodyText color="neutral-weak" size="extra-small">
											{$.__(
												'Update the email that is sent out to existing employees when someone new joins. This will apply across all templates.',
											)}
										</BodyText>
									</Flex>
								),
								value: '/settings/tasklists/onboarding/welcome_email_template/',
							},
						]}
						onSelect={handleSelect}
						renderOptionContent={(item: MenuItem): ReactElement => {
							const { note, text } = item;
							return (
								<div className="fab-MenuOption__content NHPEmailLinkOption">
									<div className="fab-MenuOption__row">{text}</div>
									<div className="fab-MenuOption__row">
										<p className="fab-MenuOption__note">{note}</p>
									</div>
								</div>
							);
						}}
						width={2}
					/>
				</Flex>
				{isBlankState ? <Divider color="neutral-extra-weak" /> : undefined}
			</LayoutBox>
		</Flex>
	);
}
