import { Flex } from '@bamboohr/fabric';
import merge from 'lodash/merge';
import React, { useEffect, useState } from 'react';
import {
	InvoiceHistoryBody,
	InvoiceHistoryHeader,
} from './components';
import { filterInvoices, getDates, getPreselectedInvoices } from './helpers';
import { getCompanyInfo, getInvoices } from './services';

const InvoiceHistory = ({ selectedInvoices }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [allInvoices, setAllInvoices] = useState([]);
	const [billingCurrency, setBillingCurrency] = useState('');
	const [displayInvoices, setDisplayInvoices] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);

	const [datePickerState, setDatePickerState] = useState({
		endDate: null,
		firstInvoiceDate: null,
		startDate: null,
	});

	const mergeDatePickerState = (updatedState) => {
		setDatePickerState((prevState) => ({ ...merge(prevState, updatedState) }));
	};

	const filterByDateRange = (collection) => {
		return filterInvoices({ collection, ...datePickerState });
	};

	const handleDateChange = ({ endValue, startValue }) => {
		mergeDatePickerState({
			endDate: endValue,
			startDate: startValue,
		});
	};

	const handleRowSelect = (keysChanged, selected) => {
		const currentInvoices = [...displayInvoices];

		keysChanged.forEach((id) => {
			currentInvoices.find((inv) => inv.id === id).selected = selected;
		});

		const currentlySelected = currentInvoices.filter(
			(inv) => inv.selected === true,
		);

		setSelectedItems(currentlySelected);
		setDisplayInvoices(currentInvoices);
	};

	useEffect(() => {
		Promise.all([getCompanyInfo(), getInvoices()])
			.then((response) => {
				setBillingCurrency(response[0].data.billingCurrency);
				const invoices = response[1].data;
				const preSelectedInvoices = getPreselectedInvoices(
					selectedInvoices,
					invoices,
				);
				if (preSelectedInvoices) {
					const { updatedInvoices, ...dates } = preSelectedInvoices;
					mergeDatePickerState(dates);
					setAllInvoices(updatedInvoices);
				} else {
					mergeDatePickerState(getDates(invoices));
					setAllInvoices(invoices);
				}
				setIsLoading(false);
			})
			.catch((error) => {
				setIsError(true);
				setIsLoading(false);
				window.Rollbar.error(
					'POSH: Unable to retrieve invoice history page data',
					error,
				);
			});
	}, []);

	useEffect(() => {
		setDisplayInvoices(filterByDateRange(allInvoices));
	}, [allInvoices, datePickerState]);

	return (
		<Flex flexDirection="column" flexGrow={1} marginBottom={5}>
			<InvoiceHistoryHeader
				onDateChange={handleDateChange}
				selectedInvoices={selectedItems}
				{...datePickerState}
			/>
			<InvoiceHistoryBody
				billingCurrency={billingCurrency}
				isError={isError}
				isLoading={isLoading}
				onDateChange={handleDateChange}
				onRowSelect={handleRowSelect}
				rows={displayInvoices}
				selectedInvoices={selectedItems}
				{...datePickerState}
			/>
		</Flex>
	);
};

export default InvoiceHistory;
