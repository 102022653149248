import { BodyText, Flex } from '@bamboohr/fabric';
import { ReactElement } from 'react';
import { SmsMfaSetupProps } from './types';
import { SmsInputContainer } from './sms-input-container';

export function SmsMfaSetup(props: SmsMfaSetupProps): ReactElement {
	return (
		<>
			<BodyText color="neutral-extra-strong" weight="semibold">
				{$.__('We will send a verification code to the phone number you enter below.')}
			</BodyText>
			<Flex gap={2} marginY={3}>
				<SmsInputContainer {...props} />
			</Flex>
			<BodyText color="neutral-extra-strong" size="extra-small">
				{$.__(
					'By configuring 2-step login using Text Message(SMS), we collect your phone number for the sole purpose of sending you 2-step login codes. This information is not used for any other notification or marketing purpose. We will not text you without a code being requested first.'
				)}
			</BodyText>
		</>
	);
}
