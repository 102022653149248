import React, { FunctionComponent } from 'react';

import { AddButtonWrapper } from './add-button-wrapper';
import { InlineAddForm } from '../../../shared';

interface AddEmploymentStatusWrapperProps {
	acaStatuses: string[];
	listId: number;
	showAcaStatus: boolean;
}

export const AddEmploymentStatusWrapper: FunctionComponent<React.PropsWithChildren<AddEmploymentStatusWrapperProps>> = (props) => {
	const { acaStatuses, listId, showAcaStatus } = props;

	return (
		<React.Fragment>
			{showAcaStatus ? <AddButtonWrapper acaStatuses={acaStatuses} listId={listId} /> : <InlineAddForm listId={listId} />}
		</React.Fragment>
	);
};
