import { Flex, IconV2, Tooltip, makeStyles } from '@bamboohr/fabric';
import { useRef } from 'react';

const useStyles = makeStyles(({ palette, spacing }) => {
	return {
		infoCircleWrapper: {
			display: 'grid',
			placeItems: 'center',
			marginLeft: spacing(1),
			fill: palette.gray['600'],
		},
		tooltip: {
			maxWidth: '13rem',
		},
	};
});

export function NATooltip() {
	const iconRef = useRef(null);
	const styles = useStyles();

	return (
		<>
			<Tooltip
				anchorEl={() => iconRef.current}
				classes={{ tooltip: styles.tooltip }}
				content={$.__('This is always the same as your Federal Tax frequency')}
			/>
			<Flex alignItems='center'>
				N/A
				<span className={styles.infoCircleWrapper} ref={iconRef}>
					<IconV2 name="circle-info-solid" size={16} />
				</span>
			</Flex>
		</>
	);
}
