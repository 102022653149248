import { useContext, ReactElement } from 'react';
import { LayoutBox, Loader } from '@bamboohr/fabric';
import { ActiveMissingInfoContext } from '../context/active-missing-info-provider';
import { BackToChecklist } from './back-to-checklist';
import { ActiveMissingInfoTable } from './active-missing-info-table';

import { ActiveMissingInfoContextData } from '../types';

export function ActiveMissingInfoContent(): ReactElement {
	const { isLoading, hasMissingData } = useContext(
		ActiveMissingInfoContext,
	) as ActiveMissingInfoContextData;

	if (isLoading) {
		return (
			<LayoutBox marginY={20}>
				<Loader />
			</LayoutBox>
		);
	}

	if (!hasMissingData) {
		return (
			<LayoutBox marginY={9} textAlign="center">
				<BackToChecklist />
			</LayoutBox>
		);
	}

	if (hasMissingData) {
		return (
			<LayoutBox textAlign="center">
				<ActiveMissingInfoTable />
			</LayoutBox>
		);
	}
}
